import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link, useLocation, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { formatAmount } from "components/Functoins/functions"
import logo from "../../assets/images/Pham logo black text.png"
import apiUrl from "config"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  InputGroup,
  Button,
} from "reactstrap"
import "../../assets/style/style.css"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { useReactToPrint } from "react-to-print"
import axios from "axios"

const PurchaseSupplierReport = props => {
  //meta title
  document.title = "Bank Report"

  const [supplierdata, setSupplierdata] = useState([])
  const [filterDiv, setFilterdiv] = useState(false)
  const [projects, setProjects] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [totalAmount, setTotalAmount] = useState("")
  const [totalPaidAmount, setTotalPaidAmount] = useState("")
  const [state, setState] = useState({
    supplier_name: "",
    project_name: "",
  })

  const handleState = e => {
    const { name, value } = e.target
    setState(prData => ({
      ...prData,
      [name]: value,
    }))
  }

  const fetchProject = () => {
    const data2 = {
      method: "fetch_all_projects",
    }
    axios
      .post(`${apiUrl}/Projects/projects.php`, data2)
      .then(response => {
        if (Array.isArray(response.data)) {
          setProjects(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  //suppliers
  const fetchSuppliers = () => {
    const formData2 = {
      method: "fetch_supplier",
    }
    axios
      .post(`${apiUrl}/purchases/suppliers.php`, formData2)
      .then(response => {
        if (Array.isArray(response.data)) {
          setSuppliers(response.data)
        } else {
          console.log("Error in fetch suppliers")
        }
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    fetchSuppliers()
    fetchProject()
  }, [])

  const handleFilter = () => {
    setShowPaymentReport(false)
    setCheckPayment(false)
    if (state.supplier_name.length === 0) {
      toastr.error("Set Customer")
    } else {
      const URL = `${apiUrl}/purchases/addPurchases.php`
      const Pdata = new FormData()
      Pdata.append("supplier_name", state.supplier_name)
      Pdata.append("app_method", "fetch_supplier_bill_detail")
      setFilterdiv(true)
      axios
        .post(URL, Pdata)
        .then(response => {
          if (Array.isArray(response.data)) {
            let filteredBills = response.data

            if (state.supplier_name && state.project_name) {
              // Filter supplierdata based on both supplier_name and project_name
              filteredBills = response.data.filter(
                payment =>
                  payment.supplier_name === state.supplier_name &&
                  payment.project_name === state.project_name
              )
            } else if (state.supplier_name && !state.project_name) {
              // Filter supplierdata based on supplier_name only
              filteredBills = response.data.filter(
                payment => payment.supplier_name === state.supplier_name
              )
            } else if (!state.supplier_name && state.project_name) {
              // Filter supplierdata based on project_name only
              filteredBills = response.data.filter(
                payment => payment.project_name === state.project_name
              )
            }

            setSupplierdata(filteredBills)
          } else {
            console.log("Error in fetch Data")
            setSupplierdata([])
          }
        })
        .catch(error => console.log(error))
    }
  }

  //Quotation Report
  const [checkPayment, setCheckPayment] = useState(false)
  const [paymentReportData, setPaymentReportData] = useState([])
  const [TotalPaymentAmount, setTotalPaymentAmount] = useState([])
  const [showPaymentReport, setShowPaymentReport] = useState(false)

  const handleQuotationReport = () => {
    setCheckPayment(prevState => !prevState)
    if (!checkPayment) {
      quotationReport()
      setShowPaymentReport(true)
    } else {
      setShowPaymentReport(false)
      setPaymentReportData([])
    }
  }
  const quotationReport = () => {
    setPaymentReportData([])
    const URL = `${apiUrl}/purchases/payments.php`
    const data = new FormData()
    data.append("app_method", "fetch_purchase_payment")

    axios
      .post(URL, data)
      .then(response => {
        if (Array.isArray(response.data)) {
          let FetchData = response.data
          if (state.supplier_name && state.project_name) {
            FetchData = response.data.filter(
              item =>
                item.supplier_name === state.supplier_name &&
                item.project_name === state.project_name
            )
          } else if (state.supplier_name) {
            FetchData = response.data.filter(
              item => item.supplier_name === state.supplier_name
            )
          } else if (state.project_name) {
            FetchData = response.data.filter(
              item => item.project_name === state.project_name
            )
          }
          setPaymentReportData(FetchData)
        } else {
          console.log("error")
        }
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    const totalAmount = supplierdata.reduce(
      (acc, row) => acc + parseFloat(row.total_amount || 0),
      0.0
    )
    const totalPaid = supplierdata.reduce(
      (acc, row) => acc + parseFloat(row.paid_amount || 0),
      0.0
    )
    setTotalAmount(totalAmount)
    setTotalPaidAmount(totalPaid)

    const totalQuotationAmount = paymentReportData.reduce(
      (acc, item) => acc + parseFloat(item.total_amount || 0),
      0.0
    )
    setTotalPaymentAmount(totalQuotationAmount)
  }, [supplierdata, paymentReportData])
  //Print
  const printRef1 = useRef(null)
  const handlePrint1 = useReactToPrint({
    content: () => printRef1.current,
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Invoices" breadcrumbItem="Invoice Detail" /> */}
          <Row className="mb-3">
            <Col xl="12">
              <div>
                <h5 className="mt-2">Supplier's Report</h5>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg="12">
              <Card>
                <CardTitle>
                  <CardBody>
                    <Row>
                      <Col xl={3}>
                        <div className="mb-3">
                          <label className="">Suppliers</label>
                          <InputGroup>
                            <select
                              defaultValue="0"
                              className="form-select"
                              onChange={handleState}
                              name="supplier_name"
                              value={state.supplier_name}
                            >
                              <option value={""}>Select supplier...</option>

                              {suppliers.length > 0 ? (
                                suppliers
                                  .filter(e => !!e)
                                  .map((e, index) => (
                                    <option
                                      key={index}
                                      value={e.bussiness_name}
                                    >
                                      {`${e.bussiness_name} (${e.acc_no})`}
                                    </option>
                                  ))
                              ) : (
                                <option value="" disabled>
                                  No Data Found
                                </option>
                              )}
                            </select>
                          </InputGroup>
                        </div>
                      </Col>
                      <Col xl={3}>
                        <div className="col-sm-auto">
                          <label className="" htmlFor="autoSizingSelect">
                            Project
                          </label>
                          <InputGroup>
                            <select
                              defaultValue="0"
                              className="form-select"
                              onChange={handleState}
                              name="project_name"
                              value={state.project_name}
                            >
                              <option value={""}>Select project ...</option>
                              {projects.length > 0 ? (
                                projects
                                  .filter(e => !!e)
                                  .map((e, index) => (
                                    <option key={index} value={e.project_name}>
                                      {e.project_name} ({e.project_code})
                                    </option>
                                  ))
                              ) : (
                                <option value="" disabled>
                                  No project found
                                </option>
                              )}
                            </select>
                          </InputGroup>
                        </div>
                      </Col>
                      <Col xl={3}>
                        <Button
                          className="btn btn-primary mt-4"
                          color="primary"
                          onClick={handleFilter}
                        >
                          Filter
                        </Button>
                      </Col>
                    </Row>
                    {filterDiv && (
                      <Row>
                        <Col xl={2}>
                          <div className="form-check form-switch form-switch-sm mb-3 mt-2">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customerquotationreport"
                              onClick={handleQuotationReport}
                              checked={checkPayment}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customerquotationreport"
                            >
                              Payment's Report
                            </label>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                </CardTitle>
              </Card>
            </Col>
          </Row>
          {filterDiv && (
            <Row>
              <Col xl={12}>
                <Card>
                  <CardBody>
                    <div className="invoice-title">
                      {/* <h4 className="float-end font-size-16">
                      Order # {orderNo}
                    </h4> */}
                      <div className="mb-4">
                        <img
                          src={logo}
                          alt="logo-dark"
                          className="logo-dark-element"
                          height="80"
                        />
                      </div>
                    </div>
                    {/* <hr /> */}
                    <Row className="mb-3">
                      <Col xl={12}>
                        <h3 className="text-center">Pham Enterprises</h3>
                        <h5 className="text-center">Supplier's Report's</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl={12}>
                        <h5 className="text-center mt-2 mb-2">
                          <strong>Supplier's Purchase Bill's</strong>
                        </h5>
                        <table>
                          <thead
                            style={{
                              border: "1px solid black",
                              borderCollapse: "-moz-initial",
                            }}
                          >
                            <tr className="black-head">
                              <th>V.ID</th>
                              <th>Date</th>
                              <th>Suppliers</th>
                              <th>Project</th>
                              <th>Bill No</th>
                              <th>Total Amount</th>
                              <th>Paid Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {supplierdata.length > 0 ? (
                              supplierdata.map((item, index) => (
                                <tr key={item.id}>
                                  <td>
                                    <Link
                                      to={`/expenses/control/bills/view/${item.purchase_id}`}
                                    >
                                      {item.purchase_id}
                                    </Link>
                                  </td>
                                  <td>{item.created_date}</td>
                                  <td>{item.supplier_name}</td>
                                  <td>{item.project_name}</td>
                                  <td>{item.purchase_bill_no}</td>
                                  <td>{formatAmount(item.total_amount)}</td>
                                  <td>{formatAmount(item.paid_amount)}</td>
                                </tr>
                              ))
                            ) : (
                              <>
                                <tr>
                                  <td colSpan="9" className="border-0">
                                    <h6 className="text-center mt-2 mb-2">
                                      No Transactions Found{" "}
                                    </h6>
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                          <tbody>
                            <tr
                              style={{
                                border: "1px solid black",
                              }}
                            ></tr>
                            <tr>
                              <td colSpan={5}>
                                <b>Total</b>
                              </td>
                              <td>
                                <b> {formatAmount(totalAmount)}</b>
                              </td>
                              <td>
                                <b> {formatAmount(totalPaidAmount)}</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        {showPaymentReport && (
                          <>
                            <h5 className="text-center mb-3 mt-5">
                              <strong>Supplier Payment's Report</strong>
                            </h5>
                            <table>
                              <thead
                                style={{
                                  border: "1px solid black",
                                  borderCollapse: "-moz-initial",
                                }}
                              >
                                <tr className="black-head">
                                  <th>V.ID</th>
                                  <th>Date</th>
                                  <th>Customer</th>
                                  <th>Project</th>
                                  <th>Ref No</th>
                                  <th>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {paymentReportData.length > 0 ? (
                                  paymentReportData.map((item, index) => (
                                    <tr key={item.id}>
                                      <td>
                                        <Link
                                          to={`/expenses/control/payments/view/${item.vo_id}`}
                                        >
                                          {item.vo_id}
                                        </Link>
                                      </td>
                                      <td>{item.purchase_date}</td>
                                      <td>{item.supplier_name}</td>
                                      <td>{item.project_name}</td>
                                      <td>{item.purchase_ref_no}</td>
                                      <td>{formatAmount(item.total_amount)}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <>
                                    <tr>
                                      <td colSpan="9" className="border-0">
                                        <h6 className="text-center mt-2 mb-2">
                                          No Transactions Found{" "}
                                        </h6>
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                              <tbody>
                                <tr
                                  style={{
                                    border: "1px solid black",
                                  }}
                                ></tr>
                                <tr>
                                  <td colSpan={5}>
                                    <b>Total</b>
                                  </td>
                                  <td>
                                    <b> {formatAmount(TotalPaymentAmount)}</b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        )}
                        <div className="d-print-none mt-2 mb-2">
                          <div className="float-end">
                            <Link
                              onClick={handlePrint1}
                              className="btn btn-success  me-2"
                            >
                              <i className="fa fa-print" />
                            </Link>
                            {/* <Link to="#" className="btn btn-primary w-md ">
                            Send
                             </Link> */}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <div
            ref={printRef1}
            className="paymentPrint"
            style={{ display: "none" }}
          >
            <Row>
              <Col xl={12}>
                <div className="invoice-title">
                  <div className="mb-4">
                    <img
                      src={logo}
                      alt="logo-dark"
                      className="logo-dark-element"
                      height="80"
                    />
                  </div>
                </div>
                <hr />
                <Row>
                  <Col xl={12}>
                    <h3 className="text-center">Pham Enterprises</h3>
                    <h5 className="text-center">Supplier's Purchase Bill's</h5>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col xl={12}>
                    <table className="table-bordered border-black mt-2 ">
                      <thead
                        style={{
                          border: "1px solid black",
                          borderCollapse: "-moz-initial",
                        }}
                      >
                        <tr className="black-head">
                          <th>V.ID</th>
                          <th>Date</th>
                          <th>Suppliers</th>
                          <th>Project</th>
                          <th>Bill No</th>
                          <th>Total Amount</th>
                          <th>Paid Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {supplierdata.length > 0 ? (
                          supplierdata.map((item, index) => (
                            <tr key={item.id}>
                              <td>{item.purchase_id}</td>
                              <td>{item.created_date}</td>
                              <td>{item.supplier_name}</td>
                              <td>{item.project_name}</td>
                              <td>{item.purchase_bill_no}</td>
                              <td>{formatAmount(item.total_amount)}</td>
                              <td>{formatAmount(item.paid_amount)}</td>
                            </tr>
                          ))
                        ) : (
                          <>
                            <tr>
                              <td colSpan="9" className="border-0">
                                <h6 className="text-center mt-2 mb-2">
                                  No Transactions Found{" "}
                                </h6>
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                      <tbody>
                        <tr
                          style={{
                            border: "1px solid black",
                          }}
                        ></tr>
                        <tr>
                          <td colSpan={5}>
                            <b>Total</b>
                          </td>
                          <td>
                            <b> {formatAmount(totalAmount)}</b>
                          </td>
                          <td>
                            <b> {formatAmount(totalPaidAmount)}</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
                <br />
              </Col>
            </Row>
            {showPaymentReport && (
              <Row>
                <Col xl={12}>
                  <Row>
                    <Col xl={12}>
                      <h5 className="text-center">
                        Suppplier's Payment's Report
                      </h5>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xl={12}>
                      <table className="table-bordered border-black mt-2 ">
                        <thead
                          style={{
                            border: "1px solid black",
                            borderCollapse: "-moz-initial",
                          }}
                        >
                          <tr className="black-head">
                            <th>V.ID</th>
                            <th>Date</th>
                            <th>Customer</th>
                            <th>Project</th>
                            <th>Ref No</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paymentReportData.length > 0 ? (
                            paymentReportData.map((item, index) => (
                              <tr key={item.id}>
                                <td>{item.vo_id}</td>
                                <td>{item.purchase_date}</td>
                                <td>{item.supplier_name}</td>
                                <td>{item.project_name}</td>
                                <td>{item.purchase_ref_no}</td>
                                <td>{formatAmount(item.total_amount)}</td>
                              </tr>
                            ))
                          ) : (
                            <>
                              <tr>
                                <td colSpan="9" className="border-0">
                                  <h6 className="text-center mt-2 mb-2">
                                    No Transactions Found{" "}
                                  </h6>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                        <tbody>
                          <tr
                            style={{
                              border: "1px solid black",
                            }}
                          ></tr>
                          <tr>
                            <td colSpan={5}>
                              <b>Total</b>
                            </td>

                            <td>
                              <b> {formatAmount(TotalPaymentAmount)}</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <br />
                </Col>
              </Row>
            )}
            <div className="mt-5 mb-5">
              <Row>
                <Col sm={2}>
                  <div
                    style={{
                      borderTop: "1.5px solid black",
                      width: "100%",
                    }}
                  ></div>
                  <div className="text-center">
                    <b>Prepared By</b>
                  </div>
                </Col>
                <Col sm={2}>
                  <div
                    style={{
                      borderTop: "1.5px solid black",
                      width: "100%",
                    }}
                  ></div>
                  <div className="text-center">
                    <b>Reviewed By</b>
                  </div>
                </Col>
                <Col sm={2}>
                  <div
                    style={{
                      borderTop: "1.5px solid black",
                      width: "100%",
                    }}
                  ></div>
                  <div className="text-center">
                    <b>Approved By</b>
                  </div>
                </Col>
                <Col sm={2}>
                  <div
                    style={{
                      borderTop: "1.5px solid black",
                      width: "100%",
                    }}
                  ></div>
                  <div className="text-center">
                    <b>Received By</b>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col xl="12">
                  <div style={{ backgroundColor: "blue", padding: "10px" }}>
                    <img
                      src={`${apiUrl}/uploads/images/company_stamp.jpg`}
                      width="15%"
                      height="15%"
                    />

                    <p style={{ color: "white", marginTop: "5px" }}>
                      MUHAMMAD SUFYAN <br />
                      0092-307-572 7380 <br />
                      msufyan2011@gmail.com
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

PurchaseSupplierReport.propTypes = {
  match: PropTypes.any,
}

export default withRouter(PurchaseSupplierReport)
