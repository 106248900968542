import React, { useState, useEffect } from "react"
import logo from "assets/images/Pham logo black text.png"

import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  CardTitle,
  Button,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  FormFeedback,
  Form,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
// actions
import axios from "axios"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import apiUrl from "config"

const UserProfile = () => {
  //meta title
  document.title = "Profile"

  const Navigate = useNavigate()

  const dispatch = useDispatch()
  const ID = localStorage.getItem("userID")
  const role = localStorage.getItem("userRole")

  const [showPassword, setShowPassword] = useState(false)
  const [showFPassword, setShowFPassword] = useState(false)

  const [loading, setloading] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const togglePasswordVisibility2 = () => {
    setShowFPassword(!showFPassword)
  }

  const [data, setData] = useState({
    full_name: "",
    email: "",
    password: "",
    fpassword: "",
  })

  const handleChange = e => {
    const { name, value } = e.target
    setData(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const FetchUser = async id => {
    const URL = `${apiUrl}/users/users.php`
    const data = new FormData()
    data.append("fetch_id", id)
    data.append("app_method", "fetch_user_data")

    try {
      setloading(true)
      const response = await axios.post(URL, data)
      if (Array.isArray(response.data)) {
        const result = response.data
        setData(prevData => ({
          ...prevData,
          full_name: result[0]?.full_name || "",
          email: result[0]?.email || "",
          password: result[0]?.password || "",
          fpassword: result[0]?.password || "",
        }))
      } else {
        console.log("error")
      }
    } catch (error) {
      console.log(error)
    } finally {
      setloading(false)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    const URL = `${apiUrl}/users/users.php`
    const data2 = new FormData()
    data2.append("up_id", ID)
    data2.append("full_name", data.full_name)
    data2.append("email", data.email)
    data2.append("password", data.password)
    data2.append("fpassword", data.fpassword)
    data2.append("app_method", "update_user")
    if (data.full_name === "") {
      toastr.error("Set Full Name")
    } else if (data.password != data.fpassword) {
      toastr.error("Password are not matched")
    } else if (data.email === "") {
      toastr.error("Email is required")
    } else {
      axios
        .post(URL, data2)
        .then(response => {
          if (response.data === "success") {
            toastr.success("Upadte Successfully")
            Navigate("/")
          } else {
            toastr.error("Error in Update")
          }
        })
        .catch(error => console.log(error))
    }
  }

  useEffect(() => {
    FetchUser(ID)
  }, [ID])

  const [isBackingUp, setIsBackingUp] = useState(false)
  const handleBackup = async () => {
    const URL = `${apiUrl}/users/users.php`
    const Data = new FormData()
    Data.append("app_method", "trigger_backup")
    setIsBackingUp(true)
    try {
      const response = await axios.post(URL, Data) // API endpoint for triggering backup
      const backupLink = response.data.backupLink // Assuming the response contains the link to the backup file
      const downloadURL = `${apiUrl}/users/download_backup.php?file=${encodeURIComponent(
        backupLink.split("/").pop()
      )}`

      // Create a temporary link element to initiate download
      const link = document.createElement("a")
      link.href = downloadURL
      link.setAttribute("download", backupLink.split("/").pop())
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)

      // Show Toastr notification
      toastr.success("File is Successfully Downloaded")
    } catch (error) {
      console.error("Backup failed", error)
      toastr.error("Backup failed")
    } finally {
      setIsBackingUp(false)
    }
  }

  if (loading) {
    return (
      <div className="spinner-container">
        <img src={logo} alt="Loading" className="spinner-logo" />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumb title="" breadcrumbItem="Profile" /> */}
          <Row className="mb-2">
            <Col>
              <h5>Profile</h5>
            </Col>
          </Row>
          <Row>
            <Col xl={8}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Personal Detail</CardTitle>
                  <Form>
                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Full name
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="text"
                          className="form-control"
                          id="horizontal-firstname-Input"
                          name="full_name"
                          placeholder="Full Name"
                          value={data.full_name}
                          onChange={handleChange}
                        />
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-email-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Email
                      </Label>
                      <Col sm={9}>
                        <Input
                          type="email"
                          className="form-control"
                          id="horizontal-email-Input"
                          placeholder="Enter Your Email ID"
                          name="email"
                          value={data.email}
                          onChange={handleChange}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-password-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Password
                      </Label>
                      <Col sm={9}>
                        <InputGroup>
                          <Input
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            id="horizontal-password-Input"
                            placeholder="Enter Your Password"
                            name="password"
                            value={data.password}
                            onChange={handleChange}
                          />
                          <InputGroupText
                            onClick={togglePasswordVisibility}
                            style={{ cursor: "pointer" }}
                          >
                            <FontAwesomeIcon
                              icon={showPassword ? faEyeSlash : faEye}
                            />
                          </InputGroupText>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-password-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Confirm Password
                      </Label>
                      <Col sm={9}>
                        <InputGroup>
                          <Input
                            type={showFPassword ? "text" : "password"}
                            className="form-control"
                            id="horizontal-password-Input"
                            placeholder="Enter Your Password"
                            name="fpassword"
                            value={data.fpassword}
                            onChange={handleChange}
                          />
                          <InputGroupText
                            onClick={togglePasswordVisibility2}
                            style={{ cursor: "pointer" }}
                          >
                            <FontAwesomeIcon
                              icon={showFPassword ? faEyeSlash : faEye}
                            />
                          </InputGroupText>
                        </InputGroup>
                      </Col>
                    </Row>

                    <Row className="justify-content-end">
                      <Col sm={9}>
                        <div>
                          <Button
                            color="primary"
                            className="w-md"
                            onClick={handleSubmit}
                          >
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {role == "admin" ? (
            <div>
              <div>
                <small style={{ color: "red" }}>
                  *only admin have access this{" "}
                </small>
              </div>
              <Button
                className="btn btn-primary"
                color="success"
                onClick={handleBackup}
                disabled={isBackingUp}
              >
                <i className="fa fa-fw fa-download" /> {""}
                {isBackingUp ? "Data Backing Up..." : "Data Backup Now"}
              </Button>
            </div>
          ) : (
            <></>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
