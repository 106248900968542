import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import apiUrl from "config"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Collapse,
  Table,
  Container,
  Form,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import axios from "axios"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

const NonStockProducts = () => {
  //meta title
  document.title = "Add Product"
  const navigate = useNavigate()
  const [selectedFiles, setselectedFiles] = useState([])
  const [col1, setcol1] = useState(false)

  const [category, setCategory] = useState([])
  const [unit, setUnit] = useState([])

  //Unit submittion
  const [unit2, setUnit2] = useState("")
  const [unitModal, setUnitModal] = useState(false)
  //Category Submittion
  const [category2, setCategory2] = useState("")
  const [categoryModal, setCategoryModal] = useState(false)
  //Selected Options
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedUnit, setSelectedUnit] = useState(null)

  const [formData, setFormData] = useState({
    prd_name: "",
    code_number: "",
    sale_info: "",
    category: "",
    prd_note: "",
    prd_weight: "",
    w_unit: "",
    sale_price: "",
    method: "add_nonStock_product",
  })

  const handleChange = e => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  const handleCategory = selectedOption => {
    if (selectedOption.value === "add_category") {
      setCategoryModal(!categoryModal)
      setSelectedCategory(null)
    } else {
      setSelectedCategory(selectedOption)
      setFormData(prevData => ({
        ...prevData,
        category: selectedOption.value,
        // Keep the entire selected option object
      }))
    }
  }
  const handleUnit = selectedOption => {
    if (selectedOption.value === "add_unit") {
      setUnitModal(!unitModal)
      setSelectedUnit(null)
    } else {
      setSelectedUnit(selectedOption)
      setFormData(prevData => ({
        ...prevData,
        w_unit: selectedOption.value,
        // Keep the entire selected option object
      }))
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (formData.prd_name === "") {
      toastr.error("Product Name is Required")
    } else if (formData.category === "") {
      toastr.error("Select Category")
    } else if (formData.w_unit === "") {
      toastr.error("Set Unit")
    } else {
      axios
        .post(`${apiUrl}/products/addproduct.php`, formData)
        .then(response => {
          if (response.data === "success") {
            setFormData({
              prd_name: "",
              code_number: "",
              sale_info: "",
              category: "",
              prd_note: "",
              prd_weight: "",
              w_unit: "",
              sale_price: "",
            })
            toastr.success("Product Add Successfully")
            navigate("/products/control/list_management")
          } else {
            toastr.error(response.data)
          }
        })
        .catch(error => {
          console.log("Error submitting form:", error)
        })
    }
  }

  const t_col1 = () => {
    setcol1(!col1)
  }
  const fetchCategories = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const category2 = new FormData()
    category2.append("app_method", "fetch_product_cat")
    axios
      .post(URL, category2)
      .then(response => {
        if (Array.isArray(response.data)) {
          console.log(response.data)
          setCategory(response.data)
        } else {
          console.log("error in fecth category")
        }
      })
      .catch(error => console.log(error))
  }
  const fetchUnits = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const unitdata = new FormData()
    unitdata.append("app_method", "fetch_weight_unit")
    axios
      .post(URL, unitdata)
      .then(response => {
        if (Array.isArray(response.data)) {
          setUnit(response.data)
        } else {
          console.log("error in fetch unit")
        }
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    fetchCategories()
    fetchUnits()
  }, [])

  //Category Submittion
  const SubmitCategory = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const addCategory = new FormData()
    addCategory.append("category_name", category2)
    addCategory.append("app_method", "add_product_cat")
    if (category2 === "") {
      toastr.error("Category Name is Required")
    } else {
      axios
        .post(URL, addCategory)
        .then(response => {
          if (response.data === "success") {
            setCategoryModal(!categoryModal)
            toastr.success("Add Successfully")
            setCategory2("")
            fetchCategories()
            if (category2) {
              setSelectedCategory({
                value: category2,
                label: category2,
              })
              setFormData(prevData => ({
                ...prevData,
                category: category2,
              }))
            }
          } else {
            console.log("Error in add Categpry")
            toastr.error(response.data)
          }
        })
        .catch(error => console.log(error))
    }
  }
  //Unit Submittion
  const SubmitUnit = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const unitData = new FormData()
    unitData.append("unit_name", unit2)
    unitData.append("app_method", "add_unit")
    if (unit2 === "") {
      toastr.error("Unit Name is Required")
    } else {
      axios
        .post(URL, unitData)
        .then(response => {
          if (response.data === "success") {
            setUnitModal(!unitModal)
            toastr.success("Unit Add Successfully")
            fetchUnits()
            setUnit2("")
            if (unit2) {
              setSelectedUnit({
                value: unit2,
                label: unit2,
              })
              setFormData(prevData => ({
                ...prevData,
                w_unit: unit2,
              }))
            }
          } else {
            toastr.error(response.data)
          }
        })
        .catch(error => console.log(error))
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs
            title="Products"
            breadcrumbItem="Add Non-Stock Product"
          /> */}
          <Row className="mb-3">
            <Col>
              <div>
                <h5>Add Non-Stock Product</h5>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col sm="4">
                        <div className="mb-3">
                          <Label htmlFor="prd_name">Name*</Label>
                          <Input
                            id="prd_name"
                            name="prd_name"
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            value={formData.prd_name}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="code_number">Code / Number</Label>
                          <Input
                            id="code_number"
                            name="code_number"
                            value={formData.code_number}
                            onChange={handleChange}
                            type="number"
                            className="form-control"
                            placeholder="code / number"
                            disabled
                          />
                        </div>
                        <div className="mb-3">
                          <Label className="control-label" htmlFor="category">
                            Category
                          </Label>
                          <Select
                            classNamePrefix="select2-selection"
                            placeholder="Choose..."
                            title="Country"
                            name="category"
                            value={selectedCategory}
                            options={[
                              ...(category.length > 0
                                ? category.map(e => ({
                                    value: e.category_name,
                                    label: e.category_name,
                                  }))
                                : [{ value: "", label: "No data Found" }]),
                              {
                                label: "Add New",
                                options: [
                                  {
                                    value: "add_category",
                                    label: "+ Add New",
                                  },
                                ],
                              },
                            ]}
                            onChange={handleCategory}
                          />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="sale_information">
                            Sale Information
                          </Label>
                          <textarea
                            className="form-control mb-3"
                            id="sale_information"
                            rows="3"
                            placeholder=""
                            name="sale_info"
                            value={formData.sale_info}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="notes">Notes</Label>
                          <textarea
                            className="form-control mb-3"
                            id="notes"
                            rows="3"
                            name="prd_note"
                            onChange={handleChange}
                            value={formData.prd_note}
                            placeholder=""
                          />
                        </div>
                      </Col>

                      <Col sm="4">
                        <div className="mb-3">
                          <Row>
                            <Col sm="6">
                              <Label htmlFor="_weight">Weight</Label>
                              <Input
                                id="_weight"
                                name="prd_weight"
                                type="number"
                                value={formData.prd_weight}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="0"
                              />
                            </Col>
                            <Col sm="6">
                              <Label className="control-label" htmlFor="w_unit">
                                Unit
                              </Label>
                              <Select
                                classNamePrefix="select2-selection"
                                placeholder="Choose..."
                                title="Country"
                                onChange={handleUnit}
                                value={selectedUnit}
                                options={[
                                  ...(unit.length > 0
                                    ? unit.map(e => ({
                                        value: e.unit_name,
                                        label: e.unit_name,
                                      }))
                                    : [{ value: "", label: "No data found" }]),
                                  {
                                    label: "Add New",
                                    options: [
                                      {
                                        value: "add_unit",
                                        label: "+ Add New",
                                      },
                                    ],
                                  },
                                ]}
                                name="w_unit"
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="sale_price">Sale Price / Rate</Label>
                          <Input
                            id="sale_price"
                            name="sale_price"
                            value={formData.sale_price}
                            onChange={handleChange}
                            type="number"
                            className="form-control"
                            placeholder="0"
                          />
                        </div>

                        {/* <div className="mb-3">
                          <Row>
                            <Col sm="6">
                              <Label htmlFor="mrp_ex_tax">MRP Ex. Tax</Label>
                              <Input
                                id="mrp_ex_tax"
                                name="mrp_ex_tax"
                                type="number"
                                placeholder="0"
                                className="form-control"
                              />
                            </Col>
                            <Col sm="6">
                              <Label htmlFor="mrp_inc_tax">MRP Inc. Tax</Label>
                              <Input
                                id="mrp_inc_tax"
                                name="mrp_inc_tax"
                                type="number"
                                className="form-control"
                                placeholder="0"
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="mb-3">
                          <Row>
                            <Col sm="6">
                              <Label htmlFor="sm_pk_sz">Small Pack Size</Label>
                              <Input
                                id="sm_pk_sz"
                                name="sm_pk_sz"
                                type="number"
                                className="form-control"
                                placeholder="0"
                              />
                            </Col>
                            <Col sm="6">
                              <Label className="control-label">
                                Preferred Supplier
                              </Label>
                              <Select
                                classNamePrefix="select2-selection"
                                placeholder="Choose..."
                                title="Country"
                                options={options}
                              />
                            </Col>
                          </Row>
                        </div> */}
                      </Col>
                      {/* <Col sm="12">
                        <div className="accordion" id="accordion">
                          <div className="accordion-item mb-3">
                            <h2 className="accordion-header" id="headingOne">
                              <button
                                className={classnames(
                                  "accordion-button",
                                  "fw-medium",
                                  {
                                    collapsed: !col1,
                                  }
                                )}
                                type="button"
                                onClick={t_col1}
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: "white",
                                }}
                              >
                                Advance Options
                              </button>
                            </h2>

                            <Collapse
                              isOpen={col1}
                              className="accordion-collapse"
                            >
                              <div className="accordion-body">
                                <Form>
                                  <Row>
                                    <Col sm={4}>
                                      <Dropzone
                                        onDrop={acceptedFiles => {
                                          handleAcceptedFiles(acceptedFiles)
                                        }}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone">
                                            <div
                                              className="dz-message needsclick"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} />
                                              <div className="dz-message needsclick">
                                                <div className="mb-3">
                                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                </div>
                                                <h4>
                                                  Drop files here or click to
                                                  upload.
                                                </h4>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>
                                      <div
                                        className="dropzone-previews mt-3"
                                        id="file-previews"
                                      >
                                        {selectedFiles.map((f, i) => {
                                          return (
                                            <Card
                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                              key={i + "-file"}
                                            >
                                              <div className="p-2">
                                                <Row className="align-items-center">
                                                  <Col className="col-auto">
                                                    <img
                                                      data-dz-thumbnail=""
                                                      height="80"
                                                      className="avatar-sm rounded bg-light"
                                                      alt={f.name}
                                                      src={f.preview}
                                                    />
                                                  </Col>
                                                  <Col>
                                                    <Link
                                                      to="#"
                                                      className="text-muted font-weight-bold"
                                                    >
                                                      {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                      <strong>
                                                        {f.formattedSize}
                                                      </strong>
                                                    </p>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Card>
                                          )
                                        })}
                                      </div>
                                    </Col>

                                    <Col sm={4}>
                                      <div className="mb-3">
                                        <Label htmlFor="field1">Field 1</Label>
                                        <Input
                                          id="field1"
                                          name="field1"
                                          type="text"
                                          className="form-control"
                                          placeholder="Field 1"
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <Label htmlFor="field3">Field 3</Label>
                                        <Input
                                          id="field3"
                                          name="field3"
                                          type="text"
                                          className="form-control"
                                          placeholder="Field 3"
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <Label htmlFor="fieldA">Field A</Label>
                                        <textarea
                                          className="form-control"
                                          id="fieldA"
                                          rows="5"
                                          placeholder="Field A"
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <Label htmlFor="fieldC">Field C</Label>
                                        <textarea
                                          className="form-control"
                                          id="fieldC"
                                          rows="5"
                                          placeholder="Field C"
                                        />
                                      </div>
                                    </Col>
                                    <Col sm={4}>
                                      <div className="mb-3">
                                        <Label htmlFor="field2">Field 2</Label>
                                        <Input
                                          id="field2"
                                          name="field2"
                                          type="text"
                                          className="form-control"
                                          placeholder="Field 2"
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <Label htmlFor="field4">Field 4</Label>
                                        <Input
                                          id="field4"
                                          name="field4"
                                          type="text"
                                          className="form-control"
                                          placeholder="Field 4"
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <Label htmlFor="fieldB">Field B</Label>
                                        <textarea
                                          className="form-control"
                                          id="fieldB"
                                          rows="5"
                                          placeholder="Field B"
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <Label htmlFor="fieldD">Field D</Label>
                                        <textarea
                                          className="form-control"
                                          id="fieldD"
                                          rows="5"
                                          placeholder="Field D"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Form>
                              </div>
                            </Collapse>
                          </div>
                        </div>
                      </Col> */}
                      <div className="custom-horizontal-line"></div>
                      <Col sm="12">
                        <Row>
                          <Col sm="6"></Col>
                          <Col sm="6">
                            <div className="float-end">
                              <Button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                                color="primary"
                              >
                                Submit
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={categoryModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setCategoryModal(!categoryModal)
        }}
      >
        <div>
          <Form>
            <ModalHeader
              toggle={() => {
                setCategoryModal(!categoryModal)
              }}
            >
              Add Nominal Account
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col xl={8}>
                  <div className="mb-3">
                    <label>Category Name</label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Category Name"
                      name="category_name"
                      value={category2}
                      onChange={e => setCategory2(e.target.value)}
                    ></Input>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                  setCategoryModal(!categoryModal)
                }}
              >
                Close
              </Button>
              <Button color="primary" onClick={SubmitCategory}>
                Submit
              </Button>
            </ModalFooter>
          </Form>
        </div>
      </Modal>
      <Modal
        isOpen={unitModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setUnitModal(!unitModal)
        }}
      >
        <div>
          <Form>
            <ModalHeader
              toggle={() => {
                setUnitModal(!unitModal)
              }}
            >
              Add Weight Unit
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col xl={8}>
                  <div className="mb-3">
                    <label>Weight Unit</label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Unit Name"
                      name="weight_unit"
                      value={unit2}
                      onChange={e => setUnit2(e.target.value)}
                    ></Input>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                  setUnitModal(!unitModal)
                }}
              >
                Close
              </Button>
              <Button color="primary" onClick={SubmitUnit}>
                Submit
              </Button>
            </ModalFooter>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default NonStockProducts
