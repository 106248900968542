import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import "assets/style/style.css"
import logo from "assets/images/Pham logo black text.png"
import apiUrl from "config"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  TabContent,
  TabPane,
} from "reactstrap"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import profileImg from "../../assets/images/profile-img.png"

//i18n
import { withTranslation } from "react-i18next"
import axios from "axios"

const Dashboard = props => {
  // const localVal = JSON.parse(localStorage.getItem("authUser"))
  // let val = localVal.id
  // console.log(val)

  //meta title
  document.title = "Dashboard"
  const [activeTab, setActiveTab] = useState("2")

  const [bank, setBank] = useState([])
  const [projectsData, setProjectsData] = useState([])
  const [customerDetail, setCustomerDetail] = useState([])

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const FullName = localStorage.getItem("userName")

  //Customer Detail
  const [InvoiceAmount, setInvoicedAmount] = useState([])
  const [InvPayAmount, setInvPayAmount] = useState([])
  const [InvUnPaid, setInvUnPaid] = useState([])
  const [products, setProducts] = useState([])

  const fetchBank = async () => {
    const URL = `${apiUrl}/BankManagemnt/addbank.php`
    const Data = new FormData()
    Data.append("app_method", "fetch_bank")

    try {
      const response = await axios.post(URL, Data)
      return Array.isArray(response.data) ? response.data : []
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  const fetchProjects = async () => {
    const URL = `${apiUrl}/Projects/projects.php`
    const Pdata = new FormData()
    Pdata.append("app_method", "fetch_all_projects")
    try {
      const response = await axios.post(URL, Pdata)
      return Array.isArray(response.data) ? response.data : []
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  const fetchCustomers = async () => {
    const URL = `${apiUrl}/sales/customer.php`
    const Data = new FormData()
    Data.append("app_method", "fetch_customers")
    try {
      const response = await axios.post(URL, Data)
      return Array.isArray(response.data) ? response.data : []
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const results = await Promise.allSettled([
          fetchBank(),
          fetchProjects(),
          fetchCustomers(),
        ])

        results.forEach((result, index) => {
          if (result.status === "fulfilled") {
            switch (index) {
              case 0:
                setBank(result.value)
                break
              case 1:
                setProjectsData(result.value)
                break
              case 2:
                setCustomerDetail(result.value)
                break
              default:
                break
            }
          } else if (result.status === "rejected") {
            console.error(result.reason)
          }
        })
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  // const fetchProducts = () => {
  //   const URL = `${apiUrl}/products/addproduct.php"
  // const Product = new FormData()

  // Product.append("app_method", "get_product_detail")
  // axios
  //   .post(URL, Product)
  //     .then(response => {
  //       if (Array.isArray(response.data)) {
  //         setProducts(response.data)
  //       } else {
  //         setProducts([])
  //       }
  //     })
  //     .catch(error => console.log(error), setProducts([]))
  // }

  useEffect(() => {
    //For Customer
    const totalInvoiceAmount = customerDetail.reduce(
      (acc, item) => acc + parseFloat(item.total_invoiced_amount || 0),
      0.0
    )
    setInvoicedAmount(totalInvoiceAmount)
    const paidInvoiceAmount = customerDetail.reduce(
      (acc, item) => acc + parseFloat(item.total_pay_amount || 0),
      0.0
    )
    setInvPayAmount(paidInvoiceAmount)
    const UnPaidAmount = totalInvoiceAmount - paidInvoiceAmount
    setInvUnPaid(UnPaidAmount)
  }, [customerDetail])

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  if (loading) {
    return (
      <div className="spinner-container">
        <img src={logo} alt="Loading" className="spinner-logo" />
      </div>
    )
  }

  if (error) {
    return <div>Error loading data</div>
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {/* Render Breadcrumb */}
        {/* <Breadcrumbs
          title={props.t("Dashboards")}
          breadcrumbItem={props.t("Dashboard")}
        /> */}
        <Container fluid>
          <div className="ms-auto">
            <Row>
              <Col xl="6">
                <div className="d-flex flex-wrap gap-3 mb-2">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "4px",
                    }}
                  >
                    <h5 className="font-size-12">
                      Welcome{" "}
                      <strong
                        className="font-size-14"
                        style={{ color: "#556EE6" }}
                      >
                        {FullName !== "null" ? FullName : "Pham User"}
                      </strong>{" "}
                    </h5>
                  </div>
                  <Button
                    color="secondary"
                    className="btn-rounded btn-sm btn-secondary waves-effect waves-light"
                    onClick={() => toggleTab("2")}
                  >
                    Business Overview
                  </Button>
                  {/* <Button
                    color="primary"
                    className=" btn-rounded btn-sm  btn-info waves-effect waves-light"
                    onClick={() => toggleTab("1")}
                  >
                    Notifications
                  </Button> */}
                </div>
              </Col>

              {/* <Col xl="6">
                <div className="float-end">
                  <div className="form-check form-switch form-switch mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitchsizelg"
                      defaultChecked
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customSwitchsizelg"
                    ></label>
                  </div>
                </div>
              </Col> */}
            </Row>
          </div>
          <div className="custom-horizontal-line-dropdown"></div>
          <br />

          <Row>
            <Col xl="12">
              <Row>
                <Col xl="9">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Card>
                        <h5 className="card-header bg-transparent border-bottom text-uppercase font-size-14">
                          Updates
                        </h5>
                        <CardBody>
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                              <div className="flex-grow-1">
                                <h5 className="mt-0 mb-1 font-size-14">
                                  Getting Started Video, Quick overview to speed
                                  up things
                                </h5>
                                <ul className="list-inline float-sm-end mb-sm-0">
                                  <li className="list-inline-item">
                                    <Button
                                      color="primary"
                                      className=" btn-rounded btn-sm  btn-info waves-effect waves-light"
                                    >
                                      View
                                    </Button>
                                  </li>
                                </ul>
                                <div className="text-muted font-size-12">
                                  <i className="far fa-calendar-alt text-primary me-1" />
                                  2024-01-25
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item">
                              <div className="flex-grow-1">
                                <h5 className="mt-0 mb-1 font-size-14">
                                  Getting Started Video, Quick overview to speed
                                  up things
                                </h5>
                                <ul className="list-inline float-sm-end mb-sm-0">
                                  <li className="list-inline-item">
                                    <Button
                                      color="primary"
                                      className=" btn-rounded btn-sm  btn-info waves-effect waves-light"
                                    >
                                      View
                                    </Button>
                                  </li>
                                </ul>
                                <div className="text-muted font-size-12">
                                  <i className="far fa-calendar-alt text-primary me-1" />
                                  2024-01-25
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item">
                              <div className="flex-grow-1">
                                <h5 className="mt-0 mb-1 font-size-14">
                                  Getting Started Video, Quick overview to speed
                                  up things
                                </h5>
                                <ul className="list-inline float-sm-end mb-sm-0">
                                  <li className="list-inline-item">
                                    <Button
                                      color="primary"
                                      className=" btn-rounded btn-sm  btn-info waves-effect waves-light"
                                    >
                                      View
                                    </Button>
                                  </li>
                                </ul>
                                <div className="text-muted font-size-12">
                                  <i className="far fa-calendar-alt text-primary me-1" />
                                  2024-01-25
                                </div>
                              </div>
                            </li>
                            <li className="list-group-item">
                              <div className="flex-grow-1">
                                <h5 className="mt-0 mb-1 font-size-14">
                                  Getting Started Video, Quick overview to speed
                                  up things
                                </h5>
                                <ul className="list-inline float-sm-end mb-sm-0">
                                  <li className="list-inline-item">
                                    <Button
                                      color="primary"
                                      className=" btn-rounded btn-sm  btn-info waves-effect waves-light"
                                    >
                                      View
                                    </Button>
                                  </li>
                                </ul>
                                <div className="text-muted font-size-12">
                                  <i className="far fa-calendar-alt text-primary me-1" />
                                  2024-01-25
                                </div>
                              </div>
                            </li>
                          </ul>
                        </CardBody>
                      </Card>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="2">
                  <Row className="mb-4">
                    <Col xl={8}>
                      <Card className="d-flex h-100 mt-4 mb-4">
                        <div className="row">
                          <div className="col-md-6">
                            <h5 className="card-header bg-transparent text-uppercase font-size-14">
                              Banks
                            </h5>
                          </div>
                        </div>
                        <div className="custom-horizontal-line-dropdown"></div>
                        <CardBody>
                          <div className="table-responsive">
                            <table className="custom-table">
                              <tbody>
                                {bank.length > 0 ? (
                                  bank
                                    .filter(e => !!e)
                                    .map((e, index) => {
                                      // Calculate profit margin for each project
                                      const credit_amount = Number(
                                        e.credit_amount
                                      )
                                      const debit_amount = Number(
                                        e.debit_amount
                                      )
                                      const Balance =
                                        credit_amount - debit_amount

                                      return (
                                        <tr key={index}>
                                          <td style={{ color: "blue" }}>
                                            <Link
                                              to={`/bank/control/bank_activity/${e.id}`}
                                              state={{
                                                Name: e.bank_name,
                                                Code: e.bank_code,
                                              }}
                                            >
                                              {e.bank_name} ({e.bank_code})
                                            </Link>
                                          </td>

                                          <td style={{ textAlign: "right" }}>
                                            <Row>
                                              <Col
                                                xl="6"
                                                style={{ color: "green" }}
                                              >
                                                Credit :
                                              </Col>
                                              <Col xl="6">
                                                <div
                                                  className="float-end"
                                                  style={{ color: "green" }}
                                                >
                                                  {formatAmount(
                                                    e.credit_amount
                                                  )}
                                                </div>
                                              </Col>
                                            </Row>
                                            <br />
                                            <Row>
                                              <Col
                                                xl="6"
                                                style={{ color: "red" }}
                                              >
                                                Debit :
                                              </Col>
                                              <Col xl="6">
                                                <div
                                                  className="float-end"
                                                  style={{ color: "red" }}
                                                >
                                                  {formatAmount(e.debit_amount)}
                                                </div>
                                              </Col>
                                            </Row>
                                          </td>
                                          <td style={{ textAlign: "right" }}>
                                            Balance{" "}
                                            <b>{formatAmount(Balance)}</b>
                                          </td>
                                        </tr>
                                      )
                                    })
                                ) : (
                                  <tr>
                                    <td>
                                      <strong>No Bank in Record</strong>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    {/* <Col xl={4}>
                      <Card className="d-flex h-100 mt-4 mb-2">
                        <div className="row">
                          <div className="col-md-6">
                            <h5 className="card-header bg-transparent text-uppercase font-size-14">
                              Product
                            </h5>
                          </div>
                          <div className="col-md-6 text-md-right">
                            <h5 className="card-header bg-transparent text-uppercase font-size-14">
                              Quantity
                            </h5>
                          </div>
                        </div>
                        <div className="custom-horizontal-line-dropdown"></div>
                        <CardBody>
                          <div className="table-responsive">
                            <table className="custom-table">
                              <tbody>
                                <tr>
                                  <td>Low Stock</td>
                                  <td>0</td>
                                </tr>
                                <tr>
                                  <td>Out of Stock</td>
                                  <td>0</td>
                                </tr>
                                <tr>
                                  <td>Oversold</td>
                                  <td>0</td>
                                </tr>
                                <tr>
                                  <td>In Stock</td>
                                  <td>0</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </CardBody>
                      </Card>
                    </Col> */}

                    {/* <Col xl={4}>
                      <Card className="d-flex h-100 mt-5">
                        <div className="row">
                          <div className="col-md-4">
                            <h5 className="card-header bg-transparent text-uppercase font-size-14">
                              Sales
                            </h5>
                          </div>
                          <div className="col-md-8 text-md-right">
                            <div className="float-end">
                              <Dropdown
                                isOpen={alllocation}
                                className="dropdown-mega d-none d-lg-block ms-2"
                                toggle={() => setAlllocation(!alllocation)}
                              >
                                <DropdownToggle
                                  // tag="button"
                                  className=" px-3 font-size-6"
                                  style={{
                                    backgroundColor: "white",
                                    color: "black",
                                    border: "none",
                                  }}
                                >
                                  The Financial Year
                                  <span>
                                    <i className="mdi mdi-chevron-down" />
                                  </span>
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem>
                                    This Financial Year
                                  </DropdownItem>
                                  <DropdownItem>This Week</DropdownItem>
                                  <DropdownItem>This Month</DropdownItem>
                                  <DropdownItem>Last Month</DropdownItem>
                                  <DropdownItem>Quarter1</DropdownItem>
                                  <DropdownItem>Quarter2</DropdownItem>
                                  <DropdownItem>Quarter3</DropdownItem>
                                  <DropdownItem>Quarter4</DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                        <CardBody>
                          <div className="table-responsive">
                            <table className="custom-table">
                              <tbody>
                                <tr>
                                  <strong>
                                    <td>0.00</td>
                                  </strong>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <SimpleLineChart />
                        </CardBody>
                      </Card>
                    </Col> */}
                    {/* <Col xl={4}>
                      <Card className="d-flex h-100 mt-5">
                        <div className="row">
                          <div className="col-md-5">
                            <h5 className="card-header bg-transparent text-uppercase font-size-14">
                              Expenses
                            </h5>
                          </div>
                          <div className="col-md-7 text-md-right">
                            <div className="float-end">
                              <Dropdown
                                isOpen={alllocation2}
                                className="dropdown-mega d-none d-lg-block ms-2"
                                toggle={() => setAlllocation2(!alllocation2)}
                              >
                                <DropdownToggle
                                  // tag="button"
                                  className=" px-3 font-size-6"
                                  style={{
                                    backgroundColor: "white",
                                    color: "black",
                                    border: "none",
                                  }}
                                >
                                  The Financial Year
                                  <span>
                                    <i className="mdi mdi-chevron-down" />
                                  </span>
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem>
                                    This Financial Year
                                  </DropdownItem>
                                  <DropdownItem>This Week</DropdownItem>
                                  <DropdownItem>This Month</DropdownItem>
                                  <DropdownItem>Last Month</DropdownItem>
                                  <DropdownItem>Quarter1</DropdownItem>
                                  <DropdownItem>Quarter2</DropdownItem>
                                  <DropdownItem>Quarter3</DropdownItem>
                                  <DropdownItem>Quarter4</DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                        <CardBody>
                          <div className="table-responsive">
                            <table className="custom-table">
                              <tbody>
                                <tr>
                                  <strong>
                                    <td>-0.00</td>
                                  </strong>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </CardBody>
                      </Card>
                    </Col> */}
                    {/* <Col xl={4}>
                      <Card className="d-flex h-100 mt-5">
                        <div className="row">
                          <div className="col-md-6">
                            <h5 className="card-header bg-transparent text-uppercase font-size-14">
                              Profit and Loss
                            </h5>
                          </div>
                          <div className="col-md-6 text-md-right">
                            <div className="float-end">
                              <Dropdown
                                isOpen={alllocation3}
                                className="dropdown-mega d-none d-lg-block ms-2"
                                toggle={() => setAlllocation3(!alllocation3)}
                              >
                                <DropdownToggle
                                  // tag="button"
                                  className=" px-3 font-size-6"
                                  style={{
                                    backgroundColor: "white",
                                    color: "black",
                                    border: "none",
                                  }}
                                >
                                  The Financial Year
                                  <span>
                                    <i className="mdi mdi-chevron-down" />
                                  </span>
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem>
                                    This Financial Year
                                  </DropdownItem>
                                  <DropdownItem>This Week</DropdownItem>
                                  <DropdownItem>This Month</DropdownItem>
                                  <DropdownItem>Last Month</DropdownItem>
                                  <DropdownItem>Quarter1</DropdownItem>
                                  <DropdownItem>Quarter2</DropdownItem>
                                  <DropdownItem>Quarter3</DropdownItem>
                                  <DropdownItem>Quarter4</DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                        <CardBody>
                          <div className="table-responsive">
                            <table className="custom-table">
                              <tbody>
                                <tr>
                                  <td>Net Profit</td>
                                  <td>0.00</td>
                                </tr>

                                <br />
                                <tr>
                                  <td>
                                    <strong>Total Income</strong>
                                  </td>
                                  <td>
                                    <strong>Total Expenses</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td>0.00</td>
                                  <td>-0.00</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </CardBody>
                      </Card>
                    </Col> */}
                  </Row>
                  <Row className="mt-4">
                    <Col xl={8}>
                      <Card className="d-flex h-100 mt-4 mb-4">
                        <div className="row">
                          <div className="col-md-6">
                            <h5 className="card-header bg-transparent text-uppercase font-size-14">
                              Projects
                            </h5>
                          </div>
                        </div>
                        <div className="custom-horizontal-line-dropdown"></div>
                        <CardBody>
                          <div className="table-responsive">
                            <table className="custom-table">
                              <tbody>
                                {projectsData.length > 0 ? (
                                  projectsData
                                    .filter(e => !!e)
                                    .map((e, index) => {
                                      // Calculate profit margin for each project
                                      const income = Number(e.income_)
                                      const expense = Number(e.expense_)
                                      const projectProfitMargin =
                                        income !== null &&
                                        expense !== null &&
                                        income !== 0 &&
                                        expense !== 0
                                          ? ((income - expense) / income) * 100
                                          : 0

                                      return (
                                        <tr key={index}>
                                          <td style={{ color: "blue" }}>
                                            {e.project_name} ({e.project_code})
                                          </td>
                                          <td style={{ textAlign: "right" }}>
                                            <Row>
                                              <Col
                                                xl="6"
                                                style={{ color: "green" }}
                                              >
                                                Income
                                              </Col>
                                              <Col xl="6">
                                                <div
                                                  className="float-end"
                                                  style={{ color: "green" }}
                                                >
                                                  {formatAmount(e.income_)}
                                                </div>
                                              </Col>
                                            </Row>
                                            <br />
                                            <Row>
                                              <Col
                                                xl="6"
                                                style={{ color: "red" }}
                                              >
                                                Expense
                                              </Col>
                                              <Col xl="6">
                                                <div
                                                  className="float-end"
                                                  style={{ color: "red" }}
                                                >
                                                  {formatAmount(e.expense_)}
                                                </div>
                                              </Col>
                                            </Row>
                                          </td>
                                          <td style={{ textAlign: "right" }}>
                                            Profit Margin{" "}
                                            <b>
                                              {formatAmount(
                                                projectProfitMargin
                                              )}{" "}
                                              %
                                            </b>
                                          </td>
                                        </tr>
                                      )
                                    })
                                ) : (
                                  <tr>
                                    <td>
                                      <strong>No Project in Record</strong>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mt-4 mb-4">
                    <Col xl={4}>
                      <Card className="d-flex h-100  mt-4 mb-4">
                        <h5 className="card-header bg-transparent border-bottom text-uppercase font-size-14">
                          Customer Balances
                        </h5>
                        <CardBody>
                          <div className="table-responsive">
                            <table className="custom-table">
                              <tbody>
                                <tr>
                                  <td>
                                    {InvUnPaid >= 0 ? "UnPaid" : "Advance"}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      color: InvUnPaid >= 0 ? "red" : "green",
                                    }}
                                  >
                                    {formatAmount(
                                      InvUnPaid > 0
                                        ? InvUnPaid
                                        : Math.abs(InvUnPaid)
                                    )}
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <strong>Total Invoiced Amount</strong>
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "right",
                                    }}
                                  >
                                    <strong>Paid</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ color: "blue" }}>
                                    {formatAmount(InvoiceAmount)}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      color: "green",
                                    }}
                                  >
                                    {formatAmount(InvPayAmount)}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)

function formatAmount(value) {
  // Check if the value is valid
  if (!isNaN(value)) {
    // Format the value with commas for thousands separators and two decimal places
    return parseFloat(value).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  } else {
    return value // Return the value as is if it's not a valid number
  }
}
