import React, { useEffect, useState, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate, useParams } from "react-router-dom"
import Select from "react-select"
import "../../../assets/style/style.css"
import apiUrl from "config"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Collapse,
  Table,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  colspan,
  Modal,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  ToastHeader,
} from "reactstrap"
//Date Picker
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import classnames from "classnames"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import logo from "../../../assets/images/Pham logo black text.png"
// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import { formatAmount } from "components/Functoins/functions"
import { useReactToPrint } from "react-to-print"
import SocialModal from "components/SocialControl/Social"

const ViewPurchaseBill = props => {
  //meta title
  document.title = "View Purchase Bill"
  const { id } = useParams()
  const bill_id = id

  const iconRef = useRef(null)
  const iconRef2 = useRef(null)

  const navigate = useNavigate()

  const [subTotalAmount, setSubTotalAmount] = useState(0)
  const [FinalTotalAmount, setFinalTotalAmount] = useState(0)
  const [Tax_amount, setTax_amount] = useState(0)

  const [TotalExpenseAmount, setTotalExpenseAmount] = useState(0)

  const [expenses, setExpenses] = useState([])
  const [projects, setProjects] = useState([])

  const [prjCode, setprjCode] = useState("")

  const [payments, setPayments] = useState([])

  const [EditMode, setEditMode] = useState(false)

  const [bank, setBank] = useState([])
  const [pay_mode, setPaymode] = useState([])

  const [loading, setloading] = useState(false)

  //accordion
  const [col1, setcol1] = useState(true)
  const [col2, setcol2] = useState(true)
  const [col3, setcol3] = useState(true)

  const [count, setCount] = useState(false)

  const t_col1 = () => {
    setcol1(!col1)
  }
  const t_col2 = () => {
    setcol2(!col2)
  }
  const t_col3 = () => {
    setcol3(!col3)
  }

  //Eamil
  const [socialModal, setSocialModal] = useState(false)
  const [pdfUrl, setPdfUrl] = useState("")

  //Modal Mail Data
  const [ModalMailData, setModalMailData] = useState({
    from_mail: "",
    to_mail: "",
    mail_subj: "",
    email_message:
      "<p>Please find attached confirmation for your Order.<br>Thank you for your business.<br>Best Regards.<br><strong>Pham Enterprises</strong> ",
  })
  const handleModalMailData = e => {
    const { name, value } = e.target
    setModalMailData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  const [EmailMode, setEmailMode] = useState(false)
  const handleEmail = () => {
    const URL = `${apiUrl}/purchases/addPurchases.php`
    const post = new FormData()
    post.append("pur_id", bill_id)
    post.append("app_method", "fetch_bill_det_byId")
    axios
      .post(URL, post)
      .then(response => {
        if (Array.isArray(response.data)) {
          setSocialModal(!socialModal)
          setPdfUrl(response.data[0].pdf_url)
          setEmailMode(true)
        } else {
          console.log(response.data)
        }
      })
      .catch(error => console.log(error))
  }
  const SendEmail = () => {
    if (ModalMailData.to_mail === "") {
      toastr.error("Sent Email is Required")
    } else {
      const data = new FormData()
      data.append("from_mail", ModalMailData.from_mail)
      data.append("to_mail", ModalMailData.to_mail)
      data.append("mail_subj", ModalMailData.mail_subj)
      data.append("email_message", ModalMailData.email_message)
      data.append("pdf_url", pdfUrl)
      data.append("app_method", "send_email")
      const URL = `${apiUrl}/socialaccounts/emailhandle.php`
      axios
        .post(URL, data)
        .then(response => {
          if (response.data.message === "success") {
            toastr.success("Email Sent Successfully")
            setSocialModal(!socialModal)
            navigate("/expenses/control/bills")
          } else {
            // Handle case where response.data is not an array
            console.error("Response data is not an array.")
          }
        })
        .catch(error => console.log(error))
    }
  }
  //Toggle
  const toggleModal = () => {
    setSocialModal(!socialModal)
    navigate("/expenses/control/bills")
  }

  //Clear localstorage

  useEffect(() => {
    const clearLocalStorage = () => {
      localStorage.removeItem("delete_FEids")
    }

    const handlePopState = () => {
      clearLocalStorage()
    }

    navigate(clearLocalStorage)

    window.addEventListener("popstate", handlePopState)

    return () => {
      window.removeEventListener("popstate", handlePopState)
    }
  }, [navigate])

  //Supplier Datail Accordion
  const [formData, setFormData] = useState({
    payment_date_: new Date().toISOString().substr(0, 10),
    project_name: "",
    payment_mode: "",
    payment_ref_no: "PHAM-",
    payment_amount: "",
    date_: new Date().toISOString().substr(0, 10),
    bill_no: "PHAM-",
    cred_limt: "",
    balance_: "",
    total_amount: "",
    final_notes: "",
    discount_Amount: "",
    tax_percentage: "",
  })
  const handleChange = e => {
    const { name, value } = e.target
    if (value === "add_mode") {
      addMode()
    } else if (value === "add_bank") {
      addBankAcc()
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
      }))
    }

    if (name === "discount_Amount") {
      const Amount = parseFloat(value)
      if (Amount >= subTotalAmount) {
        toastr.error("Discount Amount is less than Sub Total")
      } else {
        setFormData(prevData => ({
          ...prevData,
          discount_Amount: value,
        }))
      }
    }
  }

  //Fetch Purchase Detail By ID
  const fetchPurchaseData = async id => {
    try {
      setloading(true) // Set loading state to true while fetching data
      const URL = `${apiUrl}/purchases/addPurchases.php`
      const post = new FormData()
      post.append("pur_id", id)
      post.append("app_method", "fetch_bill_det_byId")
      const response = await axios.post(URL, post)

      if (Array.isArray(response.data)) {
        if (response.data.length > 0) {
          const purchaseData = response.data[0]
          setFormData(prevData => ({
            ...prevData,
            project_name: purchaseData.project_name || "",
            bill_no: purchaseData.purchase_bill_no || "",
            date_: purchaseData.purchase_date || "",
            final_notes: purchaseData.final_notes || "",
            total_amount: purchaseData.total_amount || "",
            balance_: purchaseData.balance_ || "",
            tax_percentage: purchaseData.tax_percentage || "",
            discount_Amount: purchaseData.discount || "",
          }))

          setTax_amount(parseFloat(purchaseData.tax_amount) || 0)
          setSubTotalAmount(parseFloat(purchaseData.sub_total) || 0)
          setFinalTotalAmount(parseFloat(purchaseData.total_amount) || 0)
        } else {
          setFormData(prevData => ({
            ...prevData,
          }))
        }
      } else {
        console.log("Error in fetchPurchaseData: Response is not an array")
      }
    } catch (error) {
      console.error("Error fetching purchase data:", error)
    } finally {
      setloading(false) // Finally block ensures that loading state is set to false regardless of success or failure
    }
  }

  //Fetch Expense Detail
  const fetchExpenseDetail = id => {
    const URL = `${apiUrl}/purchases/addPurchases.php`
    const post = new FormData()
    post.append("exp_id", id)
    post.append("app_method", "fetch_expens_det_byId")
    axios
      .post(URL, post)
      .then(response => {
        if (Array.isArray(response.data)) {
          if (response.data.length > 0) {
            const fetchedData = response.data.map(item => ({
              FEid: item.id || "",
              Fexpense_: item.expense_ || "",
              Fexpense_desc: item.expense_desc || "",
              Fexpense_amount: item.expense_amount || "",
            }))
            setFEdata(fetchedData)
          } else {
            setFEdata([])
          }
        } else {
          console.log("error")
          setFEdata([])
        }
      })
      .catch(error => console.log(error))
  }
  const fetchinitialExpense = [
    {
      FEid: 1,
      Fexpense_: "",
      Fexpense_desc: "",
      Fexpense_amount: "",
    },
  ]
  const [FEdata, setFEdata] = useState(fetchinitialExpense)
  const handleEditFE = (id, field, value) => {
    setFEdata(prevData =>
      prevData.map(item =>
        item.FEid === id
          ? {
              ...item,
              [field]: value,
            }
          : item
      )
    )
  }
  const deleteFetchRow = id => {
    const updatedData = FEdata.filter(item => item.FEid !== id)
    setFEdata(updatedData)
    let existingIds =
      JSON.parse(localStorage.getItem("delete_FEids")) == null
        ? []
        : JSON.parse(localStorage.getItem("delete_FEids"))

    // // Add the current ID to the array
    existingIds.push(id)
    // // Store the updated array back to local storage
    localStorage.setItem("delete_FEids", JSON.stringify(existingIds))
  }

  //New Expense Detail
  const initialExpense = []
  const [Edata, setEdata] = useState(initialExpense)
  const handleEditE = (id, field, value) => {
    if (value === "add_expense") {
      AddExpense()
    } else {
      setEdata(prevData =>
        prevData.map(item =>
          item.id === id
            ? {
                ...item,
                [field]: value,
              }
            : item
        )
      )
    }
  }
  const handleAddERow = () => {
    const newId = Edata.length + 1
    const newRow = {
      id: newId,
      expense_: "",
      expense_desc: "",
      expense_amount: "",
    }
    setEdata(prevData => [...prevData, newRow])
  }
  const deleteERow = id => {
    const updatedData2 = Edata.filter(item => item.id !== id)
    setEdata(updatedData2)
  }

  useEffect(() => {
    fetchPurchaseData(bill_id)
    fetchExpenseDetail(bill_id)
  }, [bill_id])

  //fetchExpenseDetail
  const fetchExpense = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const data = new FormData()
    data.append("app_method", "fetch_expense_det")

    axios
      .post(URL, data)
      .then(response => {
        if (Array.isArray(response.data)) {
          setExpenses(response.data)
        } else {
          console.log("error in fethc expense detail")
        }
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    fetchProducts()
    fetchExpense()
  }, [])

  useEffect(() => {
    let sumAmount = 0
    let sumExpenRate = 0

    let Discount = 0
    let FTotalAmount = 0
    let TaxPercentage = 0
    let TaxAmount = 0

    FEdata.forEach(item => {
      const amount = parseFloat(item.Fexpense_amount) || 0
      sumAmount += amount
      sumExpenRate += amount
    })

    Edata.forEach(item => {
      const amount2 = parseFloat(item.expense_amount) || 0
      sumAmount += amount2
      sumExpenRate += amount2
    })

    // Initialize final total amount with the sum amount
    FTotalAmount = sumAmount

    // Apply discount if provided
    if (formData.discount_Amount !== null && formData.discount_Amount !== "") {
      const discountValue = parseFloat(formData.discount_Amount)
      Discount = discountValue
      FTotalAmount -= Discount
    }

    // Apply tax if provided
    if (formData.tax_percentage !== null && formData.tax_percentage !== "") {
      TaxPercentage = parseFloat(formData.tax_percentage) || 0
      TaxAmount = FTotalAmount * (TaxPercentage / 100)
      FTotalAmount += TaxAmount
    }

    setSubTotalAmount(sumAmount)

    setFinalTotalAmount(FTotalAmount)
    setTax_amount(TaxAmount)

    setTotalExpenseAmount(sumExpenRate)
  }, [FEdata, Edata, formData.discount_Amount, formData.tax_percentage])

  //Submit Data
  const [BtnDisabled, setBtnDisabled] = useState(false)
  const paid_amount = formData.total_amount - formData.balance_
  const SubmitPurchaseBillData = async (e, isPrint) => {
    if (subTotalAmount < paid_amount) {
      toastr.error(
        `Total Amount is Equal or greater then paid amount <b>${paid_amount}</b>`
      )
    } else if (formData.project_name === "") {
      toastr.error("Set  Project")
    } else if (formData.date_ === "") {
      toastr.error("Set Date")
    } else if (!FEdata.every(row => row.Fexpense_.length > 0)) {
      toastr.error("Set Expense")
    } else if (!FEdata.every(row => row.Fexpense_amount.length > 0)) {
      toastr.error("Set Expense Amount")
    } else if (!Edata.every(row => row.expense_.length > 0)) {
      toastr.error("Set Expense")
    } else if (!Edata.every(row => row.expense_amount.length > 0)) {
      toastr.error("Set Expense Amount")
    } else if (formData.discount_Amount >= subTotalAmount) {
      toastr.error("Discount Amount is less than Sub Total")
    } else {
      setBtnDisabled(true)
      e.preventDefault()
      const URl = `${apiUrl}/purchases/addPurchases.php`
      const formdata = new FormData()
      formdata.append("Update_id", bill_id)

      //Fetch expense detail
      FEdata.forEach(row => {
        formdata.append("FEid[]", row.FEid)
        formdata.append("Fexpense_name[]", row.Fexpense_)
        formdata.append("Fexpense_desc[]", row.Fexpense_desc)
        formdata.append("Fexpense_amount[]", row.Fexpense_amount)
      })
      //New expense detail
      Edata.forEach(row2 => {
        formdata.append("NEid[]", row2.id)
        formdata.append("expense_name[]", row2.expense_)
        formdata.append("expense_desc[]", row2.expense_desc)
        formdata.append("expense_amount[]", row2.expense_amount)
      })

      //supplier detail
      formdata.append("project_name", formData.project_name)
      formdata.append("purchase_date", formData.date_)
      formdata.append(
        "discount_amount",
        formData.discount_Amount !== "" ? formData.discount_Amount : "0"
      )
      formdata.append(
        "tax_percentage",
        formData.tax_percentage !== "" ? formData.tax_percentage : "0"
      )
      formdata.append("purchase_bill_no", formData.bill_no)
      formdata.append("final_notes", formData.final_notes)

      formdata.append("tax_amount", Tax_amount !== "" ? Tax_amount : "0")
      formdata.append("sub_total", subTotalAmount)
      formdata.append("total_amount", FinalTotalAmount)

      formdata.append("total_expense_amount", TotalExpenseAmount)

      let deletedExpenseIds =
        JSON.parse(localStorage.getItem("delete_FEids")) || []
      deletedExpenseIds.forEach(id => {
        formdata.append("del_expense_id[]", id)
      })

      formdata.append("paid_amount", paid_amount)
      formdata.append("app_method", "update_purchase_bill")

      try {
        const response = await axios.post(URl, formdata)
        if (response.data === "success") {
          if (isPrint) {
            // Open print modal with returned ID
            handleEmail(bill_id)
            localStorage.removeItem("delete_FEids")
          } else {
            toastr.success("Add Successfully")
            navigate("/expenses/control/bills")
            localStorage.removeItem("delete_FEids")
          }
        } else {
          toastr.error("Error in Sumbittion")
          localStorage.removeItem("delete_FEids")
        }
      } catch (error) {
        console.log(error)
      } finally {
        setBtnDisabled(false)
      }
    }
  }

  //Products
  const fetchProducts = () => {
    const URL = `${apiUrl}/products/addproduct.php`
    const Product = new FormData()

    Product.append("app_method", "get_product_detail")
    axios
      .post(URL, Product)
      .then(response => {
        if (Array.isArray(response.data)) {
          setProducrts(response.data)
        } else {
          console.log("Error in fetch Data")
        }
      })
      .catch(error => console.log(error))
  }
  //Bank
  const fetchBank = () => {
    const URL = `${apiUrl}/BankManagemnt/addbank.php`
    const Data = new FormData()
    Data.append("app_method", "fetch_bank")

    axios
      .post(URL, Data)
      .then(response => {
        if (response.data) {
          setBank(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  //Pay Mode
  const fetchPayMode = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const pm = new FormData()
    pm.append("app_method", "fetch_pay_mode")

    axios
      .post(URL, pm)
      .then(response => {
        if (response.data) {
          setPaymode(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  //Projects
  const fetchProject = () => {
    const URL = `${apiUrl}/Projects/projects.php`
    const Pdata = new FormData()
    Pdata.append("app_method", "fetch_all_projects")

    axios
      .post(URL, Pdata)
      .then(response => {
        if (Array.isArray(response.data)) {
          setProjects(response.data)
          const code = response.data.find(
            item => item.project_name === formData.project_name
          )
          if (code) {
            setprjCode(code.project_code)
          }
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  useEffect(() => {
    fetchBank()
    fetchPayMode()
    fetchProject()
  }, [formData.project_name])

  //Close
  const close = () => {
    localStorage.removeItem("delete_FEids")
    navigate("/expenses/control/bills")
  }

  //Textarea lenght
  const [textareaHeights, setTextareaHeights] = useState({})

  const handleTextareaChange = (id, value) => {
    const textarea = document.getElementById(`textarea-${id}`)
    if (textarea) {
      textarea.style.height = "auto"
      textarea.style.height = textarea.scrollHeight + "px"
      setTextareaHeights(prevHeights => ({
        ...prevHeights,
        [id]: textarea.scrollHeight,
      }))
    }
  }
  useEffect(() => {
    FEdata.map(item => {
      setTextareaHeights(item.FEid, item.Fexpense_desc)
    })
  }, [FEdata])

  //EditBill
  const EditBill = () => {
    setEditMode(!EditMode)
  }

  //Trans History
  const fetchBankActivity = id => {
    const URL = `${apiUrl}/purchases/addPurchases.php`
    const data = new FormData()
    data.append("id", id)
    data.append("app_method", "fecth_purchase_payment_history_byID")

    axios
      .post(URL, data)
      .then(response => {
        if (Array.isArray(response.data)) {
          // Assuming invoice_id is defined elsewhere
          const invoiceData = response.data
          if (invoiceData.length > 0) {
            setPayments(invoiceData)
            setCount(true)
          } else {
            console.log("Error: Transaction is not found")
          }
        } else {
          console.log("Error: Response data is not an array")
        }
      })
      .catch(error => console.log("Error:", error))
  }

  useEffect(() => {
    fetchBankActivity(bill_id)
  }, [bill_id])

  const handleClickOutside = event => {
    if (iconRef.current && !iconRef.current.contains(event.target)) {
      setDiscountMode(false)
    }
    if (iconRef2.current && !iconRef2.current.contains(event.target)) {
      setTaxMode(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  // Function to handle "Save & Close" button click
  const handleSaveAndClose = e => {
    SubmitPurchaseBillData(e, false) // Call SubmitSaleData function with isPrint as false
  }
  // Function to handle "Save & Print" button click
  const handleSaveAndPrint = e => {
    SubmitPurchaseBillData(e, true) // Call SubmitSaleData function with isPrint as true
  }

  //Set Discount
  const [discountMode, setDiscountMode] = useState(false)
  const setDiscount = () => {
    setDiscountMode(!discountMode)
  }

  //Set Tax
  const [taxMode, setTaxMode] = useState(false)
  const setTax = () => {
    setTaxMode(!taxMode)
  }

  if (loading) {
    return (
      <div className="spinner-container">
        <img src={logo} alt="Loading" className="spinner-logo" />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="6">
              <div>
                <h5 className="mt-2">View Bill</h5>
              </div>
            </Col>
            <Col xl="6">
              <div className="float-end">
                <Button
                  className="btn btn-primary"
                  color="primary"
                  onClick={EditBill}
                >
                  {EditMode ? "In Edit Mode" : "Edit"}
                </Button>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <div className="accordion" id="accordion">
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col1,
                      })}
                      type="button"
                      onClick={t_col1}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Project Details
                    </button>
                  </h2>

                  <Collapse isOpen={col1} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row>
                        <Col xl={4}>
                          <div className="mb-3">
                            <Label
                              className="control-label"
                              htmlFor="project_name"
                            >
                              Project
                            </Label>
                            <InputGroup>
                              <select
                                defaultValue="0"
                                className="form-select"
                                onChange={handleChange}
                                disabled={!EditMode}
                                name="project_name"
                                value={formData.project_name}
                              >
                                <option value={formData.project_name}>
                                  {formData.project_name} ({prjCode})
                                </option>

                                {/* {projects.length > 0 ? (
                                  projects
                                    .filter(e => !!e)
                                    .map((e, index) => (
                                      <option
                                        key={index}
                                        value={e.project_name}
                                      >
                                        {e.project_name} ({e.project_code})
                                      </option>
                                    ))
                                ) : (
                                  <option value="" disabled>
                                    No Data Found
                                  </option>
                                )} */}
                              </select>
                            </InputGroup>
                          </div>
                        </Col>
                        <Col xl={4}>
                          <div className="mb-3">
                            <label>Date</label>
                            <Input
                              className="form-control"
                              type="date"
                              //   placeholder="Payment Mode"
                              name="date_"
                              value={formData.date_}
                              onChange={handleChange}
                              disabled={!EditMode}
                            ></Input>
                          </div>
                        </Col>

                        <Col xl={4}>
                          <div className="mb-3">
                            <label>Bill No.</label>
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="bill no."
                              disabled
                              name="bill_no"
                              value={formData.bill_no}
                              onChange={handleChange}
                            ></Input>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </div>

                {/* Expense Detail  */}
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne2">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col2,
                      })}
                      type="button"
                      onClick={t_col2}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Expense Detail
                    </button>
                  </h2>

                  <Collapse isOpen={col2} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row className="mb-3">
                        <Col xl={12}>
                          <table>
                            <thead>
                              <tr>
                                <th>Expense</th>
                                <th>{""}</th>
                                <th>{""}</th>
                                <th>Description</th>
                                <th>{""}</th>
                                <th>Amount</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {FEdata.map(item => (
                                <tr key={item.id}>
                                  <td style={{ display: "none" }}>{item.id}</td>
                                  <td colSpan={3}>
                                    <select
                                      className="form-select"
                                      type="text"
                                      value={item.Fexpense_}
                                      disabled={!EditMode}
                                      onChange={e =>
                                        handleEditFE(
                                          item.FEid,
                                          "Fexpense_",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">Select...</option>

                                      {expenses.length > 0 ? (
                                        expenses
                                          .filter(e => !!e)
                                          .map((e, index) => (
                                            <option
                                              key={index}
                                              value={e.expense_name}
                                            >
                                              {e.expense_name}
                                            </option>
                                          ))
                                      ) : (
                                        <option>No Data Found</option>
                                      )}
                                    </select>
                                  </td>
                                  <td colSpan={2}>
                                    <textarea
                                      type="text"
                                      disabled={!EditMode}
                                      value={item.Fexpense_desc}
                                      id={`textarea-${item.FEid}`}
                                      style={{
                                        height:
                                          textareaHeights[item.FEid] || "auto",
                                      }}
                                      onChange={e => {
                                        handleEditFE(
                                          item.FEid,
                                          "Fexpense_desc",
                                          e.target.value
                                        )
                                        handleTextareaChange(
                                          item.FEid,
                                          e.target.value
                                        )
                                      }}
                                    >
                                      {item.Fexpense_desc}
                                    </textarea>
                                  </td>

                                  <td>
                                    <input
                                      type="text"
                                      value={item.Fexpense_amount}
                                      disabled={!EditMode}
                                      onChange={e =>
                                        handleEditFE(
                                          item.FEid,
                                          "Fexpense_amount",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <i
                                      className="mdi mdi-delete font-size-18"
                                      onClick={
                                        EditMode
                                          ? () => deleteFetchRow(item.FEid)
                                          : undefined
                                      }
                                      style={{
                                        color: "#F46A6A",
                                        cursor: !EditMode
                                          ? "not-allowed"
                                          : "pointer",
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            <tbody>
                              {Edata.map(item => (
                                <tr key={item.id}>
                                  <td style={{ display: "none" }}>{item.id}</td>
                                  <td colSpan={3}>
                                    <select
                                      className="form-select"
                                      type="text"
                                      value={item.expense_}
                                      disabled={!EditMode}
                                      onChange={e =>
                                        handleEditE(
                                          item.id,
                                          "expense_",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">Select...</option>

                                      {expenses.length > 0 ? (
                                        expenses
                                          .filter(e => !!e)
                                          .map((e, index) => (
                                            <option
                                              key={index}
                                              value={e.expense_name}
                                            >
                                              {e.expense_name}
                                            </option>
                                          ))
                                      ) : (
                                        <option>No Data Found</option>
                                      )}
                                    </select>
                                  </td>
                                  <td colSpan={2}>
                                    <textarea
                                      type="text"
                                      disabled={!EditMode}
                                      value={item.expense_desc}
                                      id={`textarea-${item.id}`}
                                      style={{
                                        height:
                                          textareaHeights[item.id] || "auto",
                                      }}
                                      onChange={e => {
                                        handleEditE(
                                          item.id,
                                          "expense_desc",
                                          e.target.value
                                        )
                                        handleTextareaChange(
                                          item.id,
                                          e.target.value
                                        )
                                      }}
                                    >
                                      {item.expense_desc}
                                    </textarea>
                                  </td>

                                  <td>
                                    <input
                                      type="text"
                                      value={item.expense_amount}
                                      disabled={!EditMode}
                                      onChange={e =>
                                        handleEditE(
                                          item.id,
                                          "expense_amount",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <i
                                      className="mdi mdi-delete font-size-18"
                                      onClick={
                                        EditMode
                                          ? () => deleteERow(item.id)
                                          : undefined
                                      }
                                      style={{
                                        color: "#F46A6A",
                                        cursor: !EditMode
                                          ? "not-allowed"
                                          : "pointer",
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>

                            <tbody>
                              <thead></thead>
                              <tr
                                style={{
                                  borderTop: "1px solid black",
                                }}
                              >
                                <td colSpan={3}>
                                  <strong>Total</strong>
                                </td>
                                <td colSpan={2}>
                                  <div></div>
                                </td>

                                <td>
                                  <input
                                    type="text"
                                    value={TotalExpenseAmount.toFixed(2)}
                                    disabled
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>

                      {EditMode && (
                        <Row>
                          <Col>
                            <div className="my-3">
                              <Button
                                onClick={handleAddERow}
                                color="primary"
                                className="btn btn-sm"
                              >
                                <i className="fa fa-fw fa-plus" />
                                Add Row
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </Collapse>
                </div>

                <Card>
                  <CardBody>
                    <Row>
                      <Col xl={4}>
                        <label>Notes</label>
                        <Input
                          className="form-control"
                          type="textarea"
                          placeholder="Notes"
                          name="final_notes"
                          rows="7"
                          disabled={!EditMode}
                          value={formData.final_notes}
                          onChange={handleChange}
                        ></Input>
                      </Col>
                      <Col xl={4}></Col>
                      <Col xl={4}>
                        <Table className="table-nowrap">
                          <thead>
                            <tr>
                              <td className="">Sub Total</td>
                              <td className="text-end">
                                {subTotalAmount.toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Discount {""}
                                {EditMode && (
                                  <span>
                                    <i
                                      className="bx bx-edit font-size-18"
                                      style={{
                                        cursor: "pointer",
                                        fontWeight: "bold",
                                      }}
                                      onClick={setDiscount}
                                      ref={iconRef}
                                    />
                                  </span>
                                )}
                              </td>
                              {discountMode ? (
                                <td className="text-end">
                                  <input
                                    type="text"
                                    name="discount_Amount"
                                    placeholder="0.00"
                                    value={formData.discount_Amount}
                                    onChange={handleChange}
                                    ref={iconRef}
                                  />
                                </td>
                              ) : (
                                <td className="text-end">
                                  {formData.discount_Amount !== ""
                                    ? formatAmount(formData.discount_Amount)
                                    : "0.00"}
                                </td>
                              )}
                            </tr>
                            <tr>
                              <td className="">
                                Sale Tax (%)
                                {EditMode && (
                                  <span>
                                    <i
                                      className="bx bx-edit font-size-18"
                                      style={{
                                        cursor: "pointer",
                                        fontWeight: "bold",
                                      }}
                                      onClick={setTax}
                                      ref={iconRef2}
                                    />
                                  </span>
                                )}
                              </td>
                              {taxMode ? (
                                <td className="text-end">
                                  <input
                                    type="text"
                                    name="tax_percentage"
                                    placeholder="%"
                                    value={formData.tax_percentage}
                                    onChange={handleChange}
                                    ref={iconRef2}
                                  />
                                </td>
                              ) : (
                                <td className="text-end">
                                  {Tax_amount !== ""
                                    ? formatAmount(Tax_amount)
                                    : "0.00"}
                                </td>
                              )}
                            </tr>
                            <tr>
                              <td className="border-0">
                                <strong>Total</strong>
                              </td>
                              <td className="border-0 text-end">
                                <h4 className="m-0">
                                  {FinalTotalAmount.toFixed(2)}
                                </h4>
                              </td>
                            </tr>
                          </thead>
                        </Table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                {!EditMode && (
                  <div>
                    {count && (
                      <div className="accordion-item mb-3">
                        <h2 className="accordion-header" id="headingOne3">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              {
                                collapsed: !col3,
                              }
                            )}
                            type="button"
                            onClick={t_col3}
                            style={{
                              cursor: "pointer",
                              backgroundColor: "white",
                            }}
                          >
                            Payment Details
                          </button>
                        </h2>

                        <Collapse isOpen={col3} className="accordion-collapse">
                          <div className="accordion-body">
                            <Row>
                              <div className="table-responsive">
                                <Table className="table mb-0">
                                  <thead className="table-light">
                                    <tr>
                                      <th>No</th>
                                      <th>Date</th>
                                      <th>Bank</th>
                                      <th>Ref No</th>
                                      <th>Pay By</th>
                                      <th>Pay Amount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {payments.length > 0 ? (
                                      payments.map((e, index) =>
                                        e.bill_amount > 0 ? (
                                          <tr key={index}>
                                            <td>
                                              <Link
                                                to={`/expenses/control/payments/view/${e.vo_id}`}
                                              >
                                                {e.vo_id}
                                              </Link>
                                            </td>
                                            <td>{e.pay_date}</td>
                                            <td>{e.bank_name}</td>
                                            <td>{e.pay_ref_no}</td>
                                            <td>{e.pay_by}</td>
                                            <td>
                                              {formatAmount(e.total_pay_amount)}
                                            </td>
                                          </tr>
                                        ) : (
                                          <></>
                                        )
                                      )
                                    ) : (
                                      <td>No Data Found</td>
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                            </Row>
                          </div>
                        </Collapse>
                      </div>
                    )}
                  </div>
                )}
                <Row className="mb-3">
                  <Col xl={4}>
                    <Button
                      color="danger"
                      className="btn btn-danger waves-effect waves-light"
                      onClick={close}
                    >
                      Close
                    </Button>
                  </Col>
                  {EditMode ? (
                    <Col xl={8}>
                      <div className="d-flex flex-wrap  gap-2 float-end">
                        <Button
                          onClick={handleSaveAndClose}
                          className="btn btn-primary  me-2"
                          color="primary"
                          disabled={BtnDisabled}
                        >
                          {" "}
                          {BtnDisabled ? "Loading..." : "Save Edit & Close"}
                        </Button>
                      </div>
                      <div className="d-flex flex-wrap  gap-2 float-end">
                        <Button
                          onClick={handleSaveAndPrint}
                          className="btn btn-primary  me-2"
                          color="success"
                          disabled={BtnDisabled}
                        >
                          {" "}
                          {BtnDisabled ? "Loading..." : "Save Edit & Print"}
                        </Button>
                      </div>
                    </Col>
                  ) : (
                    <Col xl={8}>
                      <div className="d-flex flex-wrap gap-2 float-end">
                        <Link
                          to="#"
                          onClick={handleEmail}
                          className="btn btn-primary  me-2"
                        >
                          <i className="mdi mdi-email" /> Send Email
                        </Link>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        <div>
          <SocialModal
            isOpen={socialModal}
            toggle={toggleModal}
            EmailMode={EmailMode}
            ModalMailData={ModalMailData}
            handleModalMailData={handleModalMailData}
            SendEmail={SendEmail}
            pdfUrl={pdfUrl}
            setModalMailData={setModalMailData}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewPurchaseBill
