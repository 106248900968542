import React, { useEffect, useState, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import Select from "react-select"
import "../../../assets/style/style.css"

import { useReactToPrint } from "react-to-print"
import logo from "../../../assets/images/Pham logo black text.png"

import apiUrl from "config"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Collapse,
  Table,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  colspan,
  Modal,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
} from "reactstrap"
//Date Picker
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import classnames from "classnames"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import { formatAmount } from "components/Functoins/functions"
import ReceivePayment from "./receivepayment"

const ViewReceivePayment = props => {
  //   const { state } = useLocation()
  //   const { customer, project } = state
  const { id } = useParams()
  const vo_id = id

  document.title = "View Receive Payment"
  const navigate = useNavigate()

  const [totalCutBalance, setTotalCutBalance] = useState(0)
  const [totalRBalance, setTotalRBalance] = useState(0)
  const [customers, setCustomers] = useState([])
  const [cusCode, SetcusCode] = useState([])
  const [prjCode, SetprjCode] = useState([])
  const [custunallocated, setCustunallocated] = useState([])
  const [bank, setBank] = useState([])
  const [projects, setProjects] = useState([])

  const [pay_mode, setPaymode] = useState([])
  const [loading, setloading] = useState(false)

  const [PaymentData, setPaymentData] = useState({
    cust_name: "",
    cust_address: "",
    project_name: "",
    bank_: "",
    pay_date: "",
    total_pay_amount: "",
    pay_mode: "",
    ref_no: "",
    inv_pay_by: "",
  })

  const [orderData, setOrderData] = useState([])

  //Trans History
  const fetchBankActivity = async id => {
    try {
      setloading(true) // Set loading state to true while fetching data

      const URL = `${apiUrl}/Invoices/invoice.php`
      const data = new FormData()
      data.append("vo_id", id)
      data.append("app_method", "fecth_all_inv_payments_by_vo_id")

      const response = await axios.post(URL, data)

      if (Array.isArray(response.data)) {
        const firstItem = response.data[0]
        setPaymentData({
          cust_name: firstItem?.cust_name || "",
          cust_address: firstItem?.cust_address || "",
          project_name: firstItem?.project_name || "",
          bank_: firstItem?.bank_ || "",
          pay_date: firstItem?.pay_date || "",
          total_pay_amount: firstItem?.total_pay_amount || "",
          pay_mode: firstItem?.mode_ || "",
          ref_no: firstItem?.inv_ref_no || "",
          inv_pay_by: firstItem?.pay_by || "",
          advance_amount: firstItem?.advance_amount || "",
        })
        setOrderData([...response.data])
      } else {
        setOrderData([])
        setPaymentData({})
        console.log("Error in fetchBankActivity: Response data is not an array")
      }
    } catch (error) {
      console.error("Error fetching bank activity:", error)
    } finally {
      setloading(false) // Finally block ensures that loading state is set to false regardless of success or failure
    }
  }

  useEffect(() => {
    fetchBankActivity(vo_id)
  }, [vo_id])

  //Customer Detail
  //   const [formData, setFormData] = useState({
  //     bank_name: "",
  //     payment_mode: "",
  //     inv_ref_no: "",
  //     cust_name: "",
  //     project_name: "",
  //     address_: "",
  //     pay_amount: "0",
  //     date_: new Date().toISOString().substr(0, 10),
  //     cred_limt: "",
  //     unallocated_balance_: "",
  //   })

  const handleChange = e => {
    const { name, value } = e.target
    setPaymentData(prevData => ({
      ...prevData,
      [name]: value,
    }))

    //   if (name === "pay_amount") {
    //     let payamount = parseFloat(value)
    //     const newBalance = payamount - totalRBalance

    //     if (newBalance >= 0) {
    //       setCustunallocated(newBalance)
    //     } else {
    //       setCustunallocated("0")
    //     }

    //     const updatedFetchData = fetchData.map(row => {
    //       const rBalance = parseFloat(row.r_balance)

    //       let totalCal = 0

    //       if (payamount >= rBalance) {
    //         totalCal = rBalance
    //         payamount -= rBalance
    //       } else {
    //         totalCal = parseFloat(payamount)
    //         payamount = 0
    //       }

    //       return {
    //         ...row,
    //         total_cal: totalCal.toFixed(2),
    //       }
    //     })
    //     setfetchData(updatedFetchData)
    //   }
  }

  //   //Fetch Order Detail By ID
  //   const fetchOrderData = id => {
  //     const URL = "${apiUrl}/sales/orders.php"
  //     const post = new FormData()
  //     post.append("vo_id", id)
  //     post.append("app_method", "fetch_invoice_order_byId")
  //     axios
  //       .post(URL, post)
  //       .then(response => {
  //         if (Array.isArray(response.data)) {
  //           if (response.data.length > 0) {
  //             setFormData(prevData => ({
  //               ...prevData,
  //               cust_name: response.data[0].cust_name || "",
  //               address_: response.data[0].cust_add || "",
  //               final_notes: response.data[0].final_notes || "",
  //               project_name: response.data[0].project_name || "",
  //             }))
  //           } else {
  //             setFormData(prevData => ({
  //               ...prevData,
  //             }))
  //           }
  //         } else {
  //           console.log(response.data)
  //         }
  //       })
  //       .catch(error => console.log(error))
  //   }

  //   //   //Fetch Repaymet Order By ID
  //   //   const fetchRepaymentOrder = (customer, project) => {
  //   //     const URL = "${apiUrl}/sales/orders.php"
  //   //     const post2 = new FormData()
  //   //     // post2.append("vo_id", id)
  //   //     post2.append("cust_name", customer)
  //   //     post2.append("project_name", project)
  //   //     post2.append("app_method", "fetch_cust_rpayments")
  //   //     axios
  //   //       .post(URL, post2)
  //   //       .then(response => {
  //   //         if (Array.isArray(response.data)) {
  //   //           if (response.data.length > 0) {
  //   //             const fetchData = response.data.map(item => ({
  //   //               ...item,
  //   //               total_cal: item.total_col || "0",
  //   //             }))
  //   //             setfetchData(fetchData)
  //   //           } else {
  //   //             console.log("No Data Found")
  //   //           }
  //   //         } else {
  //   //           setfetchData([])
  //   //         }
  //   //       })
  //   //       .catch(error => console.log(error))
  //   //   }
  //   //   useEffect(() => {
  //   //     if (formData.cust_name && formData.project_name) {
  //   //       fetchRepaymentOrder(formData.cust_name, formData.project_name)
  //   //     }
  //   //   }, [formData.cust_name, formData.project_name])

  //   //   useEffect(() => {
  //   //     fetchOrderData(vo_id)
  //   //     fetchRepaymentOrder(customer, project)
  //   //   }, [vo_id, customer, project])

  //   // Handle Customer
  //   const handleChangeCustomer = selectedOption => {
  //     if (selectedOption && selectedOption.value) {
  //       setFormData(prevData => ({
  //         ...prevData,
  //         cust_name: selectedOption.value,
  //       }))
  //     }
  //   }
  //   //Handle Project
  //   const handleChangeProject = selectedOption => {
  //     if (selectedOption && selectedOption.value) {
  //       setFormData(prevData => ({
  //         ...prevData,
  //         project_name: selectedOption.value,
  //       }))
  //     }
  //   }

  //   //Fetch Repayment Order Data
  //   const initialData2 = [
  //     {
  //       id: "",
  //       order_date: "",
  //       order_doc_no: "",
  //       inv_no: "",
  //       due_date: "",
  //       total_amount: "",
  //       r_balance: "",
  //       discount_per: "",
  //       project_name: "",
  //       total_cal: "0.00",
  //     },
  //   ]
  //   const [fetchData, setfetchData] = useState(initialData2)

  //   const handleEditF = (id, field, value) => {
  //     setfetchData(prevData =>
  //       prevData.map(item => {
  //         if (item.id === id) {
  //           return {
  //             ...item,
  //             [field]: value,
  //             prd_amount:
  //               field === "prd_qty" || field === "prd_rate"
  //                 ? parseFloat(value) * parseFloat(item.prd_rate)
  //                 : item.prd_amount,
  //           }
  //         } else {
  //           return item
  //         }
  //       })
  //     )
  //   }
  //   const handleEditF2 = (id, field, value) => {
  //     setfetchData(prevData =>
  //       prevData.map(item => {
  //         if (item.id === id) {
  //           return {
  //             ...item,
  //             [field]: value,
  //             prd_amount:
  //               field === "prd_qty" || field === "prd_rate"
  //                 ? parseFloat(item.prd_qty) * parseFloat(value)
  //                 : item.prd_amount,
  //           }
  //         } else {
  //           return item
  //         }
  //       })
  //     )
  //   }

  //   //Calculation
  useEffect(() => {
    let sumCutBalance = 0
    let sumAmount = 0
    orderData.forEach(item => {
      const cutbalance = parseFloat(item.amount_) || 0
      const tremainbalance = parseFloat(item.open_balance) || 0

      sumCutBalance += cutbalance
      sumAmount += tremainbalance
    })

    setTotalCutBalance(sumCutBalance)
    setTotalRBalance(sumAmount)
  }, [orderData])

  //   //Submit Payment
  //   const SubmitPayment = e => {
  //     if (formData.cust_name === "") {
  //       toastr.error("Customer is required")
  //     } else if (formData.bank_name === "") {
  //       toastr.error("Select Bank")
  //     } else if (formData.project_name === "") {
  //       toastr.error("Select Project")
  //     } else if (formData.pay_amount === "0") {
  //       toastr.error("Set Some Amount")
  //     } else if (formData.date_ === "") {
  //       toastr.error("Data is Required")
  //     } else {
  //       e.preventDefault()
  //       const URl = "${apiUrl}/Invoices/invoice.php"
  //       const formdata = new FormData()
  //       //fetch row data
  //       fetchData.forEach(row => {
  //         formdata.append("id[]", row.id)
  //         formdata.append("invoice_id[]", row.inv_no)
  //         formdata.append("cutBalanceAmount[]", row.total_cal)
  //         formdata.append("project_name[]", row.project_name)
  //       })

  //       //customer data
  //       formdata.append("vo_id", vo_id)
  //       formdata.append("customer_name", formData.cust_name)
  //       formdata.append("prj_name", formData.project_name)
  //       formdata.append("customer_address", formData.address_)
  //       formdata.append("order_date", formData.date_)
  //       formdata.append("total_pay_amount", formData.pay_amount)
  //       formdata.append("bank_name", formData.bank_name)
  //       formdata.append("pay_mode", formData.payment_mode)
  //       formdata.append("inv_ref_no", formData.inv_ref_no)
  //       formdata.append("unallocated_balance_", custunallocated)
  //       formdata.append("tcut_balancAmount", totalCutBalance)

  //       formdata.append("app_method", "make_invoice_repayment")

  //       axios
  //         .post(URl, formdata)
  //         .then(response => {
  //           if (response.data === "success") {
  //             toastr.success("Add Successfully")
  //             navigate("/income/control/sales_invoice")
  //             // console.log(response.data)
  //           } else {
  //             toastr.error(response.data)
  //           }
  //         })
  //         .catch(error => console.log(error))
  //     }
  //   }
  //Fetch Customer
  const fetchCustomers = () => {
    const URL = `${apiUrl}/sales/customer.php`
    const Data = new FormData()

    Data.append("app_method", "fetch_customers")
    axios
      .post(URL, Data)
      .then(response => {
        if (Array.isArray(response.data)) {
          const code = response.data.find(
            item => (item.bussiness_name = PaymentData.cust_name)
          )
          if (code) {
            SetcusCode(code.acc_no)
          }
        } else {
          console.log("Error in fetch customers")
        }
      })
      .catch(error => console.log(error))
  }
  //Bank
  const fetchBank = () => {
    const URL = `${apiUrl}/BankManagemnt/addbank.php`
    const Data = new FormData()
    Data.append("app_method", "fetch_bank")

    axios
      .post(URL, Data)
      .then(response => {
        if (response.data) {
          setBank(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  //Pay Mode
  const fetchPayMode = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const pm = new FormData()
    pm.append("app_method", "fetch_pay_mode")

    axios
      .post(URL, pm)
      .then(response => {
        if (response.data) {
          setPaymode(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  //fetch Project
  const fetchProject = () => {
    const URL = `${apiUrl}/Projects/projects.php`
    const Pdata = new FormData()
    Pdata.append("app_method", "fetch_all_projects")

    axios
      .post(URL, Pdata)
      .then(response => {
        if (Array.isArray(response.data)) {
          const code = response.data.find(
            item => item.project_name === PaymentData.project_name
          )
          if (code) {
            SetprjCode(code.project_code)
          }
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  useEffect(() => {
    fetchCustomers()
    fetchBank()
    fetchPayMode()
    fetchProject()
  }, [PaymentData.cust_name, PaymentData.project_name])

  //   //Customer Detail
  //   const fetchCustomerDetail = custName => {
  //     const formData90 = {
  //       method: "fetch_customers",
  //     }
  //     axios
  //       .post(
  //         "${apiUrl}/sales/customer.php",
  //         formData90
  //       )
  //       .then(response => {
  //         if (Array.isArray(response.data)) {
  //           setCustomers(response.data)
  //           const selectedCustomer = response.data.find(
  //             customer => customer.bussiness_name === custName
  //           )
  //           if (selectedCustomer) {
  //             // setCustunallocated(selectedCustomer.open_balance || "0.00")
  //             // setCustCreditBlnc(selectedCustomer.credit_balance || "0.00")
  //             setFormData(prevData => ({
  //               ...prevData,
  //               address_: selectedCustomer.address_ || "",
  //             }))
  //           } else {
  //             console.log("Error: Customer not found")
  //           }
  //         } else {
  //           console.log("Error in fetch customers")
  //         }
  //       })
  //       .catch(error => console.error("Error fetching customers:", error))
  //   }
  //   useEffect(() => {
  //     if (formData.cust_name) {
  //       fetchCustomerDetail(formData.cust_name)
  //     }
  //   }, [formData.cust_name])

  //Close
  const close = e => {
    e.preventDefault()
    navigate("/income/control/sales_invoice")
  }

  //Print
  const printRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  })

  if (loading) {
    return (
      <div className="spinner-container">
        <img src={logo} alt="Loading" className="spinner-logo" />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="10">
              <div>
                <h5 className="mt-2">View Sale Receipt Payment</h5>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Card>
              <CardBody>
                <Row className="mb-5">
                  <Col xl={4}>
                    <div className="mb-3">
                      <Label className="control-label" htmlFor="cust_name">
                        Customers
                      </Label>
                      <InputGroup>
                        <select
                          defaultValue="0"
                          className="form-select"
                          onChange={e =>
                            handleChangeCustomer(e.target.selectedOptions[0])
                          }
                          name="cust_name"
                          value={PaymentData.cust_name}
                          disabled
                        >
                          <option value={PaymentData.cust_name}>
                            {PaymentData.cust_name} ({cusCode})
                          </option>
                        </select>
                      </InputGroup>
                    </div>
                    <div className="mb-3">
                      <label>Address</label>
                      <Input
                        className="form-control"
                        type="textarea"
                        placeholder="Address"
                        name="cust_address"
                        rows="5"
                        value={PaymentData.cust_address}
                        onChange={handleChange}
                        disabled
                      ></Input>
                    </div>
                    <div className="mb-3">
                      <Label className="control-label" htmlFor="project_name">
                        Project
                      </Label>
                      <InputGroup>
                        <select
                          defaultValue="0"
                          className="form-select"
                          onChange={e =>
                            handleChangeProject(e.target.selectedOptions[0])
                          }
                          name="project_name"
                          value={PaymentData.project_name}
                          disabled
                        >
                          <option value={PaymentData.project_name}>
                            {PaymentData.project_name} ({prjCode})
                          </option>
                        </select>
                      </InputGroup>
                    </div>
                  </Col>

                  <Col xl={4}>
                    <div className="mb-3">
                      <label className="" htmlFor="autoSizingSelect">
                        Bank
                      </label>
                      <select
                        defaultValue="0"
                        className="form-select"
                        onChange={handleChange}
                        name="bank_"
                        value={PaymentData.bank_}
                        disabled
                      >
                        <option value={null}>Select bank ...</option>
                        {bank.length > 0 ? (
                          bank
                            .filter(e => !!e)
                            .map((e, index) => (
                              <option key={index} value={e.bank_name}>
                                {e.bank_name} ({e.bank_code})
                              </option>
                            ))
                        ) : (
                          <option value="" disabled>
                            No Data Found
                          </option>
                        )}
                      </select>
                    </div>
                    <div className="mb-3">
                      <label>Date</label>
                      <Input
                        className="form-control"
                        type="date"
                        //   placeholder="Payment Mode"
                        name="pay_date"
                        value={PaymentData.pay_date}
                        onChange={handleChange}
                        disabled
                      ></Input>
                    </div>
                    <div className="mb-3">
                      <label>Amount</label>
                      <Input
                        className="form-control"
                        type="text"
                        min={0}
                        placeholder="0.00"
                        name="total_pay_amount"
                        value={formatAmount(PaymentData.total_pay_amount)}
                        onChange={handleChange}
                        disabled
                      ></Input>
                    </div>
                  </Col>
                  <Col xl={4}>
                    <div className="mb-3">
                      <label className="" htmlFor="autoSizingSelet">
                        Payment Mode
                      </label>
                      <select
                        className="form-select"
                        type="text"
                        value={PaymentData.pay_mode}
                        onChange={handleChange}
                        name="pay_mode"
                        disabled
                      >
                        <option value="">Mode...</option>
                        {pay_mode.length > 0 ? (
                          pay_mode
                            .filter(e => !!e)
                            .map((e, index) => (
                              <option key={index} value={e.mode_name}>
                                {e.mode_name}
                              </option>
                            ))
                        ) : (
                          <option value="" disabled>
                            No Data Found
                          </option>
                        )}
                      </select>
                    </div>
                    <Row>
                      <Col xl={6}>
                        <div className="mb-3">
                          <label>Ref No</label>
                          <Input
                            className="form-control"
                            type="text"
                            min={0}
                            placeholder="ref no"
                            name="ref_no"
                            value={PaymentData.ref_no}
                            onChange={handleChange}
                            disabled
                          ></Input>
                        </div>
                      </Col>
                      <Col xl={6}>
                        <div className="mb-3">
                          <label>Pay By</label>
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="name"
                            name="inv_pay_by"
                            value={PaymentData.inv_pay_by}
                            onChange={handleChange}
                            disabled
                          ></Input>
                        </div>
                      </Col>
                    </Row>

                    <div className="mb-3">
                      <label>Unallocated Balance</label>
                      <Input
                        className="form-control"
                        type="text"
                        disabled
                        min={0}
                        placeholder="0.00"
                        name="advance_amount"
                        value={formatAmount(PaymentData.advance_amount)}
                        onChange={handleChange}
                      ></Input>
                    </div>
                  </Col>
                </Row>

                <Row className="mb-5">
                  <div>
                    <h6>Outstanding Invoices</h6>
                  </div>
                  <Col xl={12}>
                    <table>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Doc No</th>
                          <th>Due Date</th>
                          <th>Inv No</th>
                          <th>Original Amount</th>
                          <th>Open Balance</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderData.map(item => (
                          <tr key={item.id}>
                            <td style={{ display: "none" }}>{item.id}</td>
                            <td>
                              <input
                                type="text"
                                value={item.order_date}
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                rows={2}
                                value={item.doc_no}
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={item.due_date}
                                disabled
                              />
                            </td>
                            <td>
                              <input type="text" value={item.inv_no} disabled />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={formatAmount(item.original_amount)}
                                min={1}
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                min={1}
                                value={formatAmount(item.open_balance)}
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={formatAmount(item.amount_)}
                                disabled
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>

                      <tbody>
                        <thead></thead>
                        <tr
                          style={{
                            borderTop: "1px solid black",
                          }}
                        >
                          <td colSpan={2}>
                            <strong>Total</strong>
                          </td>
                          <td>
                            <div></div>
                          </td>
                          <td>
                            <div></div>
                          </td>
                          <td>
                            <div></div>
                          </td>
                          <td>
                            <input
                              type="text"
                              value={formatAmount(totalRBalance)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={formatAmount(totalCutBalance)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xl={4}>
                    <Button
                      color="danger"
                      className="btn btn-danger waves-effect waves-light"
                      onClick={close}
                    >
                      Close
                    </Button>
                  </Col>
                  <Col xl={8}>
                    <div className="d-flex flex-wrap gap-2 float-end">
                      <Link
                        to="#"
                        onClick={handlePrint}
                        className="btn btn-success  me-2"
                      >
                        <i className="fa fa-print" />
                      </Link>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
        <div
          ref={printRef}
          className="paymentPrint"
          style={{ display: "none" }}
        >
          <Row>
            <Col xl={12}>
              <Row>
                <Col xl={6}>
                  <img
                    src={logo}
                    alt="logo-dark"
                    className="logo-dark-element"
                    height="80"
                  />
                </Col>
                <Col xl={6}>
                  <div className="float-end">
                    <h1>
                      <b>Pham Enterprises</b>
                    </h1>
                    <h7>
                      Office -8 Bashir Market Behind Hascol Pump
                      <br /> Near G15 G,T Road ISLAMABAD Pakistan <br />
                      <strong>Phone :</strong> 00923075727380
                    </h7>
                  </div>
                </Col>
              </Row>

              <hr />

              <Row>
                <Col sm="8">
                  <h3>
                    <b>Receipt</b>
                  </h3>
                  <address>
                    <strong>{PaymentData.cust_name}</strong>
                    <br />
                    {PaymentData.cust_address}
                  </address>
                </Col>
                <Col sm="4">
                  <div className="float-end">
                    <address>
                      <strong>Date : </strong>
                      {PaymentData.pay_date}
                    </address>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={12}>
                  <table className=" table-bordered border-black mb-0 mt-3">
                    <thead>
                      <tr className="black-head">
                        <th>Date</th>
                        <th>Inv ID</th>
                        <th>Doc No</th>
                        <th>Pay By</th>
                        <th>Invoiced Total</th>
                        <th>Amount</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderData.map(item => (
                        <tr key={item.id}>
                          <td style={{ display: "none" }}>{item.id}</td>
                          <td>{item.order_date}</td>
                          <td>{item.inv_no}</td>
                          <td>{item.doc_no}</td>
                          <td>{item.pay_by}</td>
                          <td>{formatAmount(item.original_amount)}</td>
                          <td>{formatAmount(item.amount_)}</td>
                          <td>{formatAmount(item.amount_)}</td>
                        </tr>
                      ))}
                    </tbody>
                    {PaymentData.advance_amount > 0 ? (
                      <tbody>
                        <tr>
                          <td>{PaymentData.pay_date}</td>
                          <td></td>
                          <td></td>
                          <td>{"0.00"}</td>
                          <td>{formatAmount(PaymentData.advance_amount)}</td>
                          <td>{formatAmount(PaymentData.advance_amount)}</td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody></tbody>
                    )}
                  </table>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <br />
          <br />
          <div className="mt-5 mb-5">
            <Row>
              <Col sm={2}>
                <div
                  style={{
                    borderTop: "1.5px solid black",
                    width: "100%",
                  }}
                ></div>
                <div className="text-center">
                  <b>Prepared By</b>
                </div>
              </Col>
              <Col sm={2}>
                <div
                  style={{
                    borderTop: "1.5px solid black",
                    width: "100%",
                  }}
                ></div>
                <div className="text-center">
                  <b>Reviewed By</b>
                </div>
              </Col>
              <Col sm={2}>
                <div
                  style={{
                    borderTop: "1.5px solid black",
                    width: "100%",
                  }}
                ></div>
                <div className="text-center">
                  <b>Approved By</b>
                </div>
              </Col>
              <Col sm={2}>
                <div
                  style={{
                    borderTop: "1.5px solid black",
                    width: "100%",
                  }}
                ></div>
                <div className="text-center">
                  <b>Received By</b>
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col xl="12">
                <div style={{ backgroundColor: "blue", padding: "10px" }}>
                  <img
                    src={`${apiUrl}/uploads/images/company_stamp.jpg`}
                    width="15%"
                    height="15%"
                  />

                  <p style={{ color: "white", marginTop: "5px" }}>
                    MUHAMMAD SUFYAN <br />
                    0092-307-572 7380 <br />
                    msufyan2011@gmail.com
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewReceivePayment
