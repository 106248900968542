import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Pages Calendar
import Calendar from "../pages/Calendar/index"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Charts
import ChartApex from "../pages/Charts/Apexcharts"
import ChartistChart from "../pages/Charts/ChartistChart"
import ChartjsChart from "../pages/Charts/ChartjsChart"
import EChart from "../pages/Charts/EChart"
import SparklineChart from "../pages/Charts/SparklineChart"
import ChartsKnob from "../pages/Charts/charts-knob"
import ReCharts from "../pages/Charts/ReCharts"

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import PagesTimeline from "../pages/Utility/pages-timeline"
import PagesFaqs from "../pages/Utility/pages-faqs"
import PagesPricing from "../pages/Utility/pages-pricing"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

import Dashboard from "../pages/Dashboard/index"

//BankManagement
import BankAccounts from "pages/BankManagement/BankAccounts"
import BankPayments from "pages/BankManagement/BankPayments"
import BankReceipt from "pages/BankManagement/BankReceipt"
import Transfers from "pages/BankManagement/Transfers"
import Reconcile from "pages/BankManagement/Reconcile"
//AddBankPayments
import AddBankPayments from "pages/BankManagement/AddBankPayments/addbankpayment"
//View Bank Payment
import ViewBankPayment from "pages/BankManagement/AddBankPayments/viewbankpayment"
//Bank Payment Report
import BankPaymentReport from "pages/BankManagement/AddBankPayments/bankpaymentreport"
//AddBank Receipts
import AddBankReceipts from "pages/BankManagement/AddBankReceipts/addbankreceipts"
import ViewBankReceipt from "pages/BankManagement/AddBankReceipts/viewbankreceipt"
import BankReceiptReport from "pages/BankManagement/AddBankReceipts/bankreceiptreport"
//AddBank Transers
import AddBankTransfers from "pages/BankManagement/AddTransfers/addTransfers"
import ViewBankTransfers from "pages/BankManagement/AddTransfers/viewTransfer"
import BankTransferReport from "pages/BankManagement/AddTransfers/banktransferreport"
//Bank Activity
import Bankdetails from "pages/BankManagement/BanksDetails/Bankdetails"
//Sale
import SalesInvoices from "pages/Sales/SalesInvoices"
import SalesReceipt from "pages/Sales/SalesReceipt"
import SalesAll from "pages/Sales/SalesAll"
import SalesOrder from "pages/Sales/SalesOrder"
import SalesCustomer from "pages/Sales/SalesCustomer"
//Create Sale
import CreateSale from "pages/Sales/CreateSale/createsale"
//Create Invoice
import CreateInvoice from "pages/Sales/CreateInvoice/CreateInvoice"
//Order To Invoice
import OrderToInvoice from "pages/Sales/Ordertoinvoice/ordertoinvoice"
//Make Sale Payment
import ReceivePayment from "pages/Sales/ReceivedPayments/receivepayment"
//View Sale Payment
import ViewReceivePayment from "pages/Sales/ReceivedPayments/viewreceivedpayment"
//View Order
import ViewOrder from "pages/Sales/vieworder/vieworder"
import SaleQuotationReport from "pages/Sales/vieworder/ordersreport"
//View Invoice
import ViewInvoice from "pages/Sales/ViewInvoice/viewinvoice"
//View Invoice Report
import SaleInvoiceReport from "pages/Sales/ViewInvoice/invoicereport"
//Sale Customer Report
import SaleCustomerReport from "pages/Sales/customerreport"
//Purchaces
import PurchaceBill from "pages/Purchaces/PurchaceBill"
import PurchacesPayment from "pages/Purchaces/PurchacesPayment"
import PurchaceAll from "pages/Purchaces/PurchaceAll"
import PurchaceOrder from "pages/Purchaces/PurchaceOrder"
import Suppliers from "pages/Purchaces/PurchaceSupplier"
//Supplier Report
import PurchaseSupplierReport from "pages/Purchaces/supplierReprort"
//Purchase Bill
import CreatePurchaseBill from "pages/Purchaces/AddPurchacesDetail/AddBill"
//View Bill
import ViewPurchaseBill from "pages/Purchaces/AddPurchacesDetail/viewBill"
import PurchaseBillReport from "pages/Purchaces/AddPurchacesDetail/BillReport"
//Purchase Payment
import SupplierPayment from "pages/Purchaces/Payment/addPayment"
//Purchase Payment
import PurchasePaymentReport from "pages/Purchaces/Payment/paymentReport"
import SupplierPaymentView from "pages/Purchaces/Payment/viewPayment"
//Bill To Payment
import SupplierBillPayment from "pages/Purchaces/Billtopayment/billtopayment"
//Inventory
import InventoryProducts from "pages/Inventory/InventoryProducts"
import InventoryStock from "pages/Inventory/InventoryStock"
//PharmProject
import PhamProjects from "pages/PhamProjects/AllProjects"
//Projects Report
import ProjectTransactionReport from "pages/PhamProjects/Reports/Transactions"
import ProjectInvoicesReport from "pages/PhamProjects/Reports/InvoicesReport"
import ProjectBillReport from "pages/PhamProjects/Reports/BillsReport"
//Report
import AccountReport from "pages/Report/AccountReport"
import AnalyticalReport from "pages/Report/AnalyticalReport"

//inventory//products

import StockProducts from "pages/Inventory/Addproducts/StockProducts"
import NonStockProducts from "pages/Inventory/Addproducts/NonStockProducts"
import StockProductsView from "pages/Inventory/ViewProduct/viewproduct"

//Setting
import Setting from "pages/Setting/setting"
import View_Parameters from "pages/Setting/ViewParameters"

const authProtectedRoutes = [
  //BankManagement
  { path: "/bank/control/bank_accounts", component: <BankAccounts /> },
  { path: "/bank/control/bank_payments", component: <BankPayments /> },
  { path: "/bank/control/bank_receipt", component: <BankReceipt /> },
  { path: "/bank/control/transfers", component: <Transfers /> },
  { path: "/bank/control/reconcile_management", component: <Reconcile /> },
  //BankPayment
  {
    path: "/bank/control/bank_payments/b_pay/add",
    component: <AddBankPayments />,
  },
  //View Bank Payment
  {
    path: "/bank/control/bank_payment/view/:id",
    component: <ViewBankPayment />,
  },
  //View Bank Payment Reoport
  {
    path: "/reports/bank_detail/bank_payment",
    component: <BankPaymentReport />,
  },

  //Add Bank Receipts
  {
    path: "/bank/control/bank_receipt/b_rec/add",
    component: <AddBankReceipts />,
  },
  // View Bank Receipt
  {
    path: "/bank/control/bank_receipt/view/:id",
    component: <ViewBankReceipt />,
  },
  //View Bank Receipt Report
  {
    path: "/reports/bank_detail/bank_receipt",
    component: <BankReceiptReport />,
  },

  //Add Bank Transfers
  {
    path: "/bank/control/bank_transfer/b_trans/add",
    component: <AddBankTransfers />,
  },
  // View Bank Transfer
  {
    path: "/bank/control/bank_transfer/view/:id",
    component: <ViewBankTransfers />,
  },
  //View Bank Transfer Report
  {
    path: "/reports/bank_detail/bank_transfer",
    component: <BankTransferReport />,
  },

  //Bank Activity

  { path: "bank/control/bank_activity/:id", component: <Bankdetails /> },

  //Sale
  { path: "/income/control/sales_invoice", component: <SalesInvoices /> },
  { path: "/income/control/sale_receipts", component: <SalesReceipt /> },
  { path: "/income/control/all_inv_receipts", component: <SalesAll /> },
  { path: "/income/sales/quotations", component: <SalesOrder /> },
  { path: "/income/control/customer_management", component: <SalesCustomer /> },
  { path: "/reports/sales/customers", component: <SaleCustomerReport /> },
  //Create Sale
  { path: "/income/sales/create_sale", component: <CreateSale /> },
  { path: "/reports/sales/quotations", component: <SaleQuotationReport /> },

  //Create Invoice
  {
    path: "/income/control/sales_invoice/add_invoice",
    component: <CreateInvoice />,
  },
  //Order To Invoice
  {
    path: "income/generate_invoice/sales_invoice/:id",
    component: <OrderToInvoice />,
  },
  //Make Sale Payment
  {
    path: "/income/generate_invoice/make_payment/:id",
    component: <ReceivePayment />,
  },
  //View Sale Payment
  {
    path: "/income/view_invoice/view_payment/:id",
    component: <ViewReceivePayment />,
  },
  //View Order

  { path: "/income/view_sale_order/:id/", component: <ViewOrder /> },
  //View Invoice
  { path: "/income/view_sale_invoice/:id", component: <ViewInvoice /> },
  //Invoice Report
  { path: "/reports/sales/invoices", component: <SaleInvoiceReport /> },

  //Purchaces
  { path: "/expenses/control/bills", component: <PurchaceBill /> },
  { path: "/expenses/control/payments", component: <PurchacesPayment /> },
  { path: "/expenses/control/all_bills_payments", component: <PurchaceAll /> },
  { path: "/po/control/management", component: <PurchaceOrder /> },
  {
    path: "/expenses/control/supplier_management",
    component: <Suppliers />,
  },
  //Supplier Reperot
  {
    path: "/reports/purchases/suppliers",
    component: <PurchaseSupplierReport />,
  },

  //Purchase Bill
  {
    path: "/expenses/control/bills/add",
    component: <CreatePurchaseBill />,
  },
  //Report
  {
    path: "/reports/purchases/bills",
    component: <PurchaseBillReport />,
  },

  //View Purchase Bill
  {
    path: "/expenses/control/bills/view/:id",
    component: <ViewPurchaseBill />,
  },

  //Payment
  {
    path: "/expenses/control/make_payment",
    component: <SupplierPayment />,
  },
  //View Payment
  {
    path: "/expenses/control/payments/view/:id",
    component: <SupplierPaymentView />,
  },
  //View Payment Report
  {
    path: "reports/purchases/payments",
    component: <PurchasePaymentReport />,
  },

  //Bill To Payment
  {
    path: "/expenses/control/bills/make_payment/:id",
    component: <SupplierBillPayment />,
  },

  //Inventory
  {
    path: "/products/control/list_management",
    component: <InventoryProducts />,
  },
  {
    path: "/stockadjustment/control/management",
    component: <InventoryStock />,
  },
  //Pharm Project
  { path: "/projects/management", component: <PhamProjects /> },
  //Projects Reports
  {
    path: "/report/projects/transactions",
    component: <ProjectTransactionReport />,
  },
  {
    path: "/report/projects/invoices",
    component: <ProjectInvoicesReport />,
  },
  {
    path: "/report/projects/bills",
    component: <ProjectBillReport />,
  },

  //inventory//products
  {
    path: "/products/control/list_management/stock_product",
    component: <StockProducts />,
  },
  {
    path: "/products/control/list_management/non_stock_product",
    component: <NonStockProducts />,
  },
  {
    path: "products/control/list_management/view/:id",
    component: <StockProductsView />,
  },

  //Report
  { path: "/reports/control/index", component: <AccountReport /> },
  {
    path: "/reports/control/analyticalreports",
    component: <AnalyticalReport />,
  },

  //Setting

  {
    path: "/setting",
    component: <Setting />,
  },
  {
    path: "/setting/view_parameters",
    component: <View_Parameters />,
  },

  { path: "/dashboard", component: <Dashboard /> },

  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },

  // //calendar
  { path: "/calendar", component: <Calendar /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },

  //Charts
  { path: "/apex-charts", component: <ChartApex /> },
  { path: "/chartist-charts", component: <ChartistChart /> },
  { path: "/chartjs-charts", component: <ChartjsChart /> },
  { path: "/e-charts", component: <EChart /> },
  { path: "/sparkline-charts", component: <SparklineChart /> },
  { path: "/charts-knob", component: <ChartsKnob /> },
  { path: "/re-charts", component: <ReCharts /> },

  //Utility
  { path: "/pages-starter", component: <PagesStarter /> },
  { path: "/pages-timeline", component: <PagesTimeline /> },
  { path: "/pages-faqs", component: <PagesFaqs /> },
  { path: "/pages-pricing", component: <PagesPricing /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
]

export { authProtectedRoutes, publicRoutes }
