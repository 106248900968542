import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import Select from "react-select"
import logo from "../../../assets/images/Pham logo black text.png"

import "../../../assets/style/style.css"
import apiUrl from "config"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Collapse,
  Table,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  colspan,
  Modal,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
} from "reactstrap"
//Date Picker
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import classnames from "classnames"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import { formatAmount } from "components/Functoins/functions"

const ReceivePayment = props => {
  const { state } = useLocation()
  const { customer, project } = state || {}
  const { id } = useParams()
  const inv_id = id

  document.title = "Payment Receive"
  const navigate = useNavigate()

  const [totalCutBalance, setTotalCutBalance] = useState(0)
  const [totalRBalance, setTotalRBalance] = useState(0)
  const [customers, setCustomers] = useState([])
  const [cusCode, SetcusCode] = useState([])
  const [custunallocated, setCustunallocated] = useState([])
  const [bank, setBank] = useState([])
  const [projects, setProjects] = useState([])

  const [loading, setloading] = useState(false)

  const [pay_mode, setPaymode] = useState([])

  const [modeModal, setModeModal] = useState(false)
  const [bankModal, setBankModal] = useState(false)

  //Customer Detail
  const [formData, setFormData] = useState({
    bank_name: "",
    payment_mode: "",
    inv_ref_no: "",
    inv_pay_by: "",
    cust_name: "",
    project_name: "",
    address_: "",
    pay_amount: "",
    date_: new Date().toISOString().substr(0, 10),
    cred_limt: "",
    unallocated_balance_: "",
  })

  const handleChange = e => {
    const { name, value } = e.target

    if (value === "add_mode") {
      addMode()
    } else if (value === "add_bank") {
      addBankAcc()
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
      }))
    }

    if (name === "pay_amount") {
      let payamount = parseFloat(value)
      const newBalance = payamount - totalRBalance

      if (newBalance >= 0) {
        setCustunallocated(newBalance)
      } else {
        setCustunallocated("0")
      }

      const updatedFetchData = fetchData.map(row => {
        const rBalance = parseFloat(row.r_balance)

        let totalCal = 0

        if (payamount >= rBalance) {
          totalCal = rBalance
          payamount -= rBalance
        } else {
          totalCal = parseFloat(payamount)
          payamount = 0
        }

        return {
          ...row,
          total_cal: totalCal.toFixed(2),
        }
      })
      setfetchData(updatedFetchData)
    }
  }

  //Fetch Order Detail By ID
  const fetchOrderData = async id => {
    const URL = `${apiUrl}/sales/orders.php`
    const post = new FormData()
    post.append("inv_id", id)
    post.append("app_method", "fetch_invoice_order_byId")

    try {
      setloading(true)
      const response = await axios.post(URL, post)
      if (Array.isArray(response.data)) {
        if (response.data.length > 0) {
          setFormData(prevData => ({
            ...prevData,
            cust_name: response.data[0].cust_name || "",
            address_: response.data[0].cust_add || "",
            final_notes: response.data[0].final_notes || "",
            project_name: response.data[0].project_name || "",
          }))
        } else {
          setFormData(prevData => ({
            ...prevData,
          }))
        }
      } else {
        console.log(response.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setloading(false)
    }
  }

  //Fetch Repaymet Order By ID
  const fetchRepaymentOrder = (customer, project) => {
    const URL = `${apiUrl}/sales/orders.php`
    const post2 = new FormData()
    // post2.append("inv_id", id)
    post2.append("cust_name", customer)
    post2.append("project_name", project)
    post2.append("app_method", "fetch_cust_rpayments")
    axios
      .post(URL, post2)
      .then(response => {
        if (Array.isArray(response.data)) {
          if (response.data.length > 0) {
            const fetchData = response.data.map(item => ({
              ...item,
              total_cal: item.total_col || "0",
            }))
            setfetchData(fetchData)
          } else {
            console.log("No Data Found")
          }
        } else {
          setfetchData([])
        }
      })
      .catch(error => console.log(error))
  }
  useEffect(() => {
    if (formData.cust_name && formData.project_name) {
      fetchRepaymentOrder(formData.cust_name, formData.project_name)
    }
  }, [formData.cust_name, formData.project_name])

  useEffect(() => {
    fetchOrderData(inv_id)
    fetchRepaymentOrder(customer, project)
  }, [inv_id, customer, project])

  // Handle Customer
  const handleChangeCustomer = selectedOption => {
    if (selectedOption && selectedOption.value) {
      setFormData(prevData => ({
        ...prevData,
        cust_name: selectedOption.value,
      }))
    }
  }
  //Handle Project
  const handleChangeProject = selectedOption => {
    if (selectedOption && selectedOption.value) {
      setFormData(prevData => ({
        ...prevData,
        project_name: selectedOption.value,
      }))
    }
  }

  //Fetch Repayment Order Data
  const initialData2 = [
    {
      id: "",
      order_date: "",
      order_doc_no: "",
      inv_no: "",
      due_date: "",
      total_amount: "",
      r_balance: "",
      discount_per: "",
      project_name: "",
      total_cal: "0.00",
    },
  ]
  const [fetchData, setfetchData] = useState(initialData2)

  const handleEditF = (id, field, value) => {
    setfetchData(prevData =>
      prevData.map(item => {
        if (item.id === id) {
          return {
            ...item,
            [field]: value,
            prd_amount:
              field === "prd_qty" || field === "prd_rate"
                ? parseFloat(value) * parseFloat(item.prd_rate)
                : item.prd_amount,
          }
        } else {
          return item
        }
      })
    )
  }
  const handleEditF2 = (id, field, value) => {
    setfetchData(prevData =>
      prevData.map(item => {
        if (item.id === id) {
          return {
            ...item,
            [field]: value,
            prd_amount:
              field === "prd_qty" || field === "prd_rate"
                ? parseFloat(item.prd_qty) * parseFloat(value)
                : item.prd_amount,
          }
        } else {
          return item
        }
      })
    )
  }

  //Calculation
  useEffect(() => {
    let sumCutBalance = 0
    let sumAmount = 0
    fetchData.forEach(item => {
      const cutbalance = parseFloat(item.total_cal) || 0
      const tremainbalance = parseFloat(item.r_balance) || 0

      sumCutBalance += cutbalance
      sumAmount += tremainbalance
    })

    setTotalCutBalance(sumCutBalance)
    setTotalRBalance(sumAmount)
  }, [fetchData])

  //Submit Payment
  const [BtnDisabled, setBtnDisabled] = useState(false)
  const SubmitPayment = async e => {
    if (fetchData.length === 0) {
      toastr.error("No <b>Quotation</b> available for payment")
    } else if (formData.cust_name === "") {
      toastr.error("Customer is required")
    } else if (formData.bank_name === "") {
      toastr.error("Select Bank")
    } else if (formData.project_name === "") {
      toastr.error("Select Project")
    } else if (formData.pay_amount === "0") {
      toastr.error("<b>Pay Amount</b> must be greater than <b>Zero</b>")
    } else if (formData.date_ === "") {
      toastr.error("Date is Required")
    } else {
      setBtnDisabled(true)
      e.preventDefault()
      const URl = `${apiUrl}/Invoices/invoice.php`
      const formdata = new FormData()

      //fetch row data
      fetchData.forEach(row => {
        formdata.append("id[]", row.id)
        formdata.append("invoice_id[]", row.inv_no)
        formdata.append("order_date[]", row.order_date)
        formdata.append("doc_no[]", row.order_doc_no)
        formdata.append("due_date[]", row.due_date)
        formdata.append("original_amount[]", row.total_amount)
        formdata.append("open_balance[]", row.r_balance)
        formdata.append("cutBalanceAmount[]", row.total_cal)
        formdata.append("project_name[]", row.project_name)
      })

      //customer data
      formdata.append("inv_id", inv_id)
      formdata.append("customer_name", formData.cust_name)
      formdata.append("prj_name", formData.project_name)
      formdata.append("customer_address", formData.address_)
      formdata.append("payment_date", formData.date_)
      formdata.append("total_pay_amount", formData.pay_amount)
      formdata.append("bank_name", formData.bank_name)
      formdata.append("pay_mode", formData.payment_mode)
      formdata.append("inv_ref_no", formData.inv_ref_no)
      formdata.append("inv_pay_by", formData.inv_pay_by)
      formdata.append("unallocated_balance_", custunallocated)
      formdata.append("tcut_balancAmount", totalCutBalance)

      formdata.append("app_method", "make_invoice_repayment")
      try {
        const response = await axios.post(URl, formdata)
        if (response.data === "success") {
          toastr.success("Add Successfully")
          navigate("/income/control/sales_invoice")
        } else {
          toastr.error(response.data)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setBtnDisabled(false)
      }
    }
  }
  //Fetch Customer
  const fetchCustomers = () => {
    const URL = `${apiUrl}/sales/customer.php`
    const Data = new FormData()

    Data.append("app_method", "fetch_customers")
    axios
      .post(URL, Data)
      .then(response => {
        if (Array.isArray(response.data)) {
          setCustomers(response.data)
          SetcusCode(response.data[0].acc_no)
        } else {
          console.log("Error in fetch customers")
        }
      })
      .catch(error => console.log(error))
  }
  //Bank
  const fetchBank = () => {
    const URL = `${apiUrl}/BankManagemnt/addbank.php`
    const Data = new FormData()
    Data.append("app_method", "fetch_bank")

    axios
      .post(URL, Data)
      .then(response => {
        if (response.data) {
          setBank(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  //Pay Mode
  const fetchPayMode = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const pm = new FormData()
    pm.append("app_method", "fetch_pay_mode")

    axios
      .post(URL, pm)
      .then(response => {
        if (response.data) {
          setPaymode(response.data)
        } else {
          console.log("error")
          console.log(response.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  //fetch Project
  const fetchProject = () => {
    const URL = `${apiUrl}/Projects/projects.php`
    const Pdata = new FormData()
    Pdata.append("app_method", "fetch_all_projects")

    axios
      .post(URL, Pdata)
      .then(response => {
        if (Array.isArray(response.data)) {
          setProjects(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  useEffect(() => {
    fetchCustomers()
    fetchBank()
    fetchPayMode()
    fetchProject()
  }, [])

  //Customer Detail
  const fetchCustomerDetail = custName => {
    const URL = `${apiUrl}/sales/customer.php`
    const Data = new FormData()

    Data.append("app_method", "fetch_customers")
    axios
      .post(URL, Data)
      .then(response => {
        if (Array.isArray(response.data)) {
          setCustomers(response.data)
          const selectedCustomer = response.data.find(
            customer => customer.bussiness_name === custName
          )
          if (selectedCustomer) {
            // setCustunallocated(selectedCustomer.open_balance || "0.00")
            // setCustCreditBlnc(selectedCustomer.credit_balance || "0.00")
            setFormData(prevData => ({
              ...prevData,
              address_: selectedCustomer.address_ || "",
            }))
          } else {
            console.log("Error: Customer not found")
          }
        } else {
          console.log("Error in fetch customers")
        }
      })
      .catch(error => console.error("Error fetching customers:", error))
  }
  useEffect(() => {
    if (formData.cust_name) {
      fetchCustomerDetail(formData.cust_name)
    }
  }, [formData.cust_name])

  //Bank Add Modal
  const [bankdata, setbankdata] = useState({
    bank_name: "",
    bank_code: "",
    bank_disc: "",
  })
  const onChangeModal2 = e => {
    const { name, value } = e.target
    setbankdata(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  const handleBankAccount = e => {
    const URL = `${apiUrl}/BankManagemnt/addbank.php`
    const data = new FormData()
    data.append("bank_name", bankdata.bank_name)
    data.append("bank_disc", bankdata.bank_disc)
    data.append("app_method", "add_bank_detail")

    e.preventDefault()
    if (bankdata.bank_name === "") {
      toastr.error("Bank name is Required")
    } else {
      axios
        .post(URL, data)
        .then(response => {
          if (response.data === "success") {
            setBankModal(!bankModal)
            toastr.success("Submit Successfully")
            fetchBank()
            setbankdata(prevData => ({
              ...prevData,
              bank_name: "",
              bank_code: "",
              bank_disc: "",
            }))
            setFormData(prevData => ({
              ...prevData,
              bank_name: bankdata.bank_name,
            }))
            // window.location.reload()
          } else {
            toastr.error(response.data)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
  const addBankAcc = () => {
    setBankModal(!bankModal)
  }

  //Add Mode
  const [mode, setMode] = useState("")
  const handleMode = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const modedata = new FormData()
    modedata.append("mode_name", mode)
    modedata.append("app_method", "payment_mode")
    if (mode === "") {
      toastr.error("Mode name is Required")
    } else {
      axios
        .post(URL, modedata)
        .then(response => {
          if (response.data === "success") {
            setModeModal(!modeModal)
            toastr.success("Add Successfully")
            fetchPayMode()
            setMode("")
            setFormData(prevData => ({
              ...prevData,
              payment_mode: mode,
            }))
          } else {
            toastr.error("Error in Adding")
          }
        })
        .catch(error => console.log(error))
    }
  }
  const addMode = () => {
    setModeModal(!modeModal)
  }

  //Close
  const close = e => {
    e.preventDefault()
    navigate("/income/control/sales_invoice")
  }

  if (loading) {
    return (
      <div className="spinner-container">
        <img src={logo} alt="Loading" className="spinner-logo" />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}

          <Row>
            <Col xl="10">
              <div>
                <h5 className="mt-2">Add Sales Invoice Receipt</h5>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Card>
              <CardBody>
                <Row className="mb-5">
                  <Col xl={4}>
                    <div className="mb-3">
                      <Label className="control-label" htmlFor="cust_name">
                        Customers
                      </Label>
                      <InputGroup>
                        <select
                          defaultValue="0"
                          className="form-select"
                          onChange={e =>
                            handleChangeCustomer(e.target.selectedOptions[0])
                          }
                          name="cust_name"
                          value={formData.cust_name}
                        >
                          {customers.length > 0 ? (
                            customers.map((e, index) => (
                              <option key={index} value={e.bussiness_name}>
                                {`${e.bussiness_name} (${e.acc_no})`}
                              </option>
                            ))
                          ) : (
                            <option> No Data Found </option>
                          )}
                        </select>
                      </InputGroup>
                    </div>
                    <div className="mb-3">
                      <label>Address</label>
                      <Input
                        className="form-control"
                        type="textarea"
                        placeholder="Address"
                        name="address_"
                        rows="5"
                        value={formData.address_}
                        onChange={handleChange}
                      ></Input>
                    </div>
                    <div className="mb-3">
                      <Label className="control-label" htmlFor="project_name">
                        Project
                      </Label>
                      <InputGroup>
                        <select
                          defaultValue="0"
                          className="form-select"
                          onChange={e =>
                            handleChangeProject(e.target.selectedOptions[0])
                          }
                          name="project_name"
                          value={formData.project_name}
                        >
                          {projects.length > 0 ? (
                            projects
                              .filter(e => !!e)
                              .map((e, index) => (
                                <option key={index} value={e.project_name}>
                                  {e.project_name} ({e.project_code})
                                </option>
                              ))
                          ) : (
                            <option value="" disabled>
                              No Data Found
                            </option>
                          )}
                        </select>
                      </InputGroup>
                    </div>
                  </Col>

                  <Col xl={4}>
                    <div className="mb-3">
                      <label className="" htmlFor="autoSizingSelect">
                        Bank
                      </label>
                      <select
                        defaultValue="0"
                        className="form-select"
                        onChange={handleChange}
                        name="bank_name"
                        value={formData.bank_name}
                      >
                        <option value={null}>Select bank ...</option>
                        <option value="add_bank" style={{ color: "blue" }}>
                          + Add New
                        </option>
                        {bank.length > 0 ? (
                          bank
                            .filter(e => !!e)
                            .map((e, index) => (
                              <option key={index} value={e.bank_name}>
                                {e.bank_name} ({e.bank_code})
                              </option>
                            ))
                        ) : (
                          <option value="" disabled>
                            No Data Found
                          </option>
                        )}
                      </select>
                    </div>
                    <div className="mb-3">
                      <label>Date</label>
                      <Input
                        className="form-control"
                        type="date"
                        //   placeholder="Payment Mode"
                        name="date_"
                        value={formData.date_}
                        onChange={handleChange}
                      ></Input>
                    </div>
                    <div className="mb-3">
                      <label>Amount</label>
                      <Input
                        className="form-control"
                        type="text"
                        min={0}
                        placeholder="0.00"
                        name="pay_amount"
                        value={formData.pay_amount}
                        onChange={handleChange}
                      ></Input>
                    </div>
                  </Col>
                  <Col xl={4}>
                    <div className="mb-3">
                      <label className="" htmlFor="autoSizingSelet">
                        Payment Mode
                      </label>
                      <select
                        className="form-select"
                        type="text"
                        value={formData.payment_mode}
                        onChange={handleChange}
                        name="payment_mode"
                      >
                        <option value="">Mode...</option>
                        <option value="add_mode" style={{ color: "blue" }}>
                          + Add new
                        </option>
                        {pay_mode.length > 0 ? (
                          pay_mode
                            .filter(e => !!e)
                            .map((e, index) => (
                              <option key={index} value={e.mode_name}>
                                {e.mode_name}
                              </option>
                            ))
                        ) : (
                          <option value="" disabled>
                            No Data Found
                          </option>
                        )}
                      </select>
                    </div>
                    <Row>
                      <Col xl={6}>
                        <div className="mb-3">
                          <label>Ref No</label>
                          <Input
                            className="form-control"
                            type="text"
                            min={0}
                            placeholder="ref no"
                            name="inv_ref_no"
                            value={formData.inv_ref_no}
                            onChange={handleChange}
                          ></Input>
                        </div>
                      </Col>
                      <Col xl={6}>
                        <div className="mb-3">
                          <label>Pay By</label>
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="name"
                            name="inv_pay_by"
                            value={formData.inv_pay_by}
                            onChange={handleChange}
                          ></Input>
                        </div>
                      </Col>
                    </Row>

                    <div className="mb-3">
                      <label>Unallocated Balance</label>
                      <Input
                        className="form-control"
                        type="text"
                        disabled
                        min={0}
                        placeholder="0.00"
                        name="unallocated_balance_"
                        value={Number(custunallocated).toFixed(2)}
                        onChange={handleChange}
                      ></Input>
                    </div>
                  </Col>
                </Row>

                <Row className="mb-5">
                  <div>
                    <h6>Outstanding Invoices</h6>
                  </div>
                  <Col xl={12}>
                    <table>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Doc No</th>
                          <th>Inv No</th>
                          <th>Due Date</th>
                          <th>Original Amount</th>
                          <th>Open Balance</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fetchData.map(item => (
                          <tr key={item.id}>
                            <td style={{ display: "none" }}>{item.id}</td>
                            <td name="project_name" style={{ display: "none" }}>
                              {item.project_name}
                            </td>
                            <td>
                              <input
                                type="text"
                                value={item.order_date}
                                onChange={e =>
                                  handleEditF(
                                    item.id,
                                    "order_date",
                                    e.target.value
                                  )
                                }
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                rows={2}
                                value={item.order_doc_no}
                                onChange={e =>
                                  handleEditF(
                                    item.id,
                                    "order_doc_no",
                                    e.target.value
                                  )
                                }
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={item.inv_no}
                                onChange={e =>
                                  handleEditF(item.id, "inv_no", e.target.value)
                                }
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={item.due_date}
                                onChange={e =>
                                  handleEditF(
                                    item.id,
                                    "due_date",
                                    e.target.value
                                  )
                                }
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={formatAmount(item.total_amount)}
                                min={1}
                                onChange={e =>
                                  handleEditF2(
                                    item.id,
                                    "total_amount",
                                    e.target.value
                                  )
                                }
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                min={1}
                                value={formatAmount(item.r_balance)}
                                onChange={e =>
                                  handleEditF(
                                    item.id,
                                    "r_balance",
                                    e.target.value
                                  )
                                }
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={item.total_cal}
                                onChange={e =>
                                  handleEditF(
                                    item.id,
                                    "total_cal",
                                    e.target.value
                                  )
                                }
                                disabled
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>

                      <tbody>
                        <thead></thead>
                        <tr
                          style={{
                            borderTop: "1px solid black",
                          }}
                        >
                          <td colSpan={2}>
                            <strong>Total</strong>
                          </td>
                          <td>
                            <div></div>
                          </td>
                          <td>
                            <div></div>
                          </td>
                          <td>
                            <div></div>
                          </td>
                          <td>
                            <input
                              type="text"
                              value={formatAmount(totalRBalance)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={formatAmount(totalCutBalance)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xl={4}>
                    <Button
                      color="danger"
                      className="btn btn-danger waves-effect waves-light"
                      onClick={close}
                    >
                      Close
                    </Button>
                  </Col>
                  <Col xl={8}>
                    <div className="d-flex flex-wrap gap-2 float-end">
                      <Button
                        color="success"
                        className="btn btn-secondary waves-effect waves-light"
                        onClick={SubmitPayment}
                        disabled={BtnDisabled}
                      >
                        {BtnDisabled ? "Loading" : "Save & Close"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
        <Modal
          isOpen={modeModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setModeModal(!modeModal)
          }}
        >
          <div>
            <Form>
              <ModalHeader
                toggle={() => {
                  setModeModal(!modeModal)
                }}
              >
                Add Mode
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col xl={8}>
                    <div className="mb-3">
                      <label>Payment Mode</label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Payment Mode"
                        name="payment_mode"
                        value={mode}
                        onChange={e => setMode(e.target.value)}
                      ></Input>
                    </div>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => {
                    setModeModal(!modeModal)
                  }}
                >
                  Close
                </Button>
                <Button color="primary" onClick={handleMode}>
                  Submit
                </Button>
              </ModalFooter>
            </Form>
          </div>
        </Modal>

        <Modal
          isOpen={bankModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setBankModal(!bankModal)
          }}
        >
          <div>
            <Form>
              <ModalHeader
                toggle={() => {
                  setBankModal(!bankModal)
                }}
              >
                Bank Account New
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <label>Bank Account *</label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Name"
                        name="bank_name"
                        value={bankdata.bank_name}
                        onChange={onChangeModal2}
                      ></Input>
                    </div>
                    <div className="mb-3">
                      <label>Code</label>
                      <Input
                        className="form-control"
                        type="number"
                        placeholder="code"
                        name="bank_code"
                        value={bankdata.bank_code}
                        onChange={onChangeModal2}
                        disabled
                      ></Input>
                    </div>
                    <div className="mb-3">
                      <label>Description</label>
                      <Input
                        className="form-control"
                        type="textarea"
                        placeholder="description"
                        name="bank_disc"
                        value={bankdata.bank_disc}
                        onChange={onChangeModal2}
                      />
                    </div>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => {
                    setBankModal(!bankModal)
                  }}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  onClick={handleBankAccount}
                >
                  Submit
                </Button>
              </ModalFooter>
            </Form>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default ReceivePayment
