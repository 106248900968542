import React, { useEffect, useState, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import "../../../assets/style/style.css"
import apiUrl from "config"
import logo from "assets/images/Pham logo black text.png"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  Modal,
  Row,
  InputGroup,
} from "reactstrap"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

//Date Picker
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import { formatAmount } from "components/Functoins/functions"

const AddBankPayments = props => {
  //meta title
  document.title = "Bank Payment"
  const navigate = useNavigate()

  const [projectModal, setProjectModal] = useState(false)
  const [bankModal, setBankModal] = useState(false)
  const [modeModal, setModeModal] = useState(false)
  const [nominalModal, setNominalModal] = useState(false)
  const [loading, setloading] = useState(false)

  const newDate = new Date()
  const date = newDate.getDate()
  const month = newDate.getMonth() + 1
  const year = newDate.getFullYear()
  const separator = "/"
  const todaydate = `${date}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${year}`

  const [bank, setBank] = useState([])
  const [projects, setProjects] = useState([])
  const [pay_mode, setPaymode] = useState([])
  const [nominal_acc, setNominal_acc] = useState([])
  const [state, setState] = useState({
    bank_name: "",
    project_name: "",
  })
  const [totalamount, setTotalamount] = useState("")

  const handleState = e => {
    const { name, value } = e.target
    setState(prData => ({
      ...prData,
      [name]: value,
    }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (data.length === 0) {
      toastr.error("<b>No Payment Set</b>")
    } else if (state.bank_name.length === 0) {
      toastr.error("Bank is Required")
    } else if (state.project_name.length === 0) {
      toastr.error("Set Project")
    } else if (!data.every(row => row.refNo.length > 0)) {
      toastr.error("Ref No is Required")
    } else if (!data.every(row => row.mode_.length > 0)) {
      toastr.error("Payment Mode is Required")
    } else if (!data.every(row => row.nominal_acc.length > 0)) {
      toastr.error("Nominal Account is Required")
    } else if (!data.every(row => row.amount.length > 0)) {
      toastr.error("Amount is Required")
    } else {
      const URl = `${apiUrl}/BankManagemnt/addbank.php`
      const formdata = new FormData()

      data.forEach(row => {
        formdata.append("id[]", row.id)
        formdata.append("date[]", row.date)
        formdata.append("ref_no[]", row.refNo)
        formdata.append("mode_[]", row.mode_)
        formdata.append("nominal_acc[]", row.nominal_acc)
        // formdata.append("project[]", row.project_)
        formdata.append("detail_[]", row.detail_)
        formdata.append("amount_[]", row.amount)
      })

      formdata.append("bank_name", state.bank_name)
      formdata.append("project_name", state.project_name)
      formdata.append("total_amount", totalamount)
      formdata.append("app_method", "add_bank_payments")

      axios
        .post(URl, formdata)
        .then(response => {
          if (response.data === "success") {
            toastr.success("Add Successfully")
            navigate("/bank/control/bank_payments")
          } else {
            toastr.error(response.data)
          }
        })
        .catch(error => console.log(error))
    }
  }
  const fetchProject = () => {
    const URL = `${apiUrl}/Projects/projects.php`
    const Pdata = new FormData()
    Pdata.append("app_method", "fetch_all_projects")

    axios
      .post(URL, Pdata)
      .then(response => {
        if (Array.isArray(response.data)) {
          setProjects(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  const fetchBank = async () => {
    const URL = `${apiUrl}/BankManagemnt/addbank.php`
    const Data = new FormData()
    Data.append("app_method", "fetch_bank")

    try {
      setloading(true)
      const response = await axios.post(URL, Data)
      if (Array.isArray(response.data)) {
        setBank(response.data)
      } else {
        console.log("error")
      }
    } catch (error) {
      console.log(error)
    } finally {
      setloading(false)
    }
  }

  const fetchPayMode = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const pm = new FormData()
    pm.append("app_method", "fetch_pay_mode")

    axios
      .post(URL, pm)
      .then(response => {
        if (response.data) {
          setPaymode(response.data)
        } else {
          console.log("error")
          console.log(response.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  const fetchNominalAcc = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const nmacc = new FormData()
    nmacc.append("app_method", "fetch_nominal_acc")

    axios
      .post(URL, nmacc)
      .then(response => {
        if (response.data) {
          setNominal_acc(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  useEffect(() => {
    fetchBank()
    fetchProject()
    fetchPayMode()
    fetchNominalAcc()
  }, [])

  const initialData = [
    {
      id: 1,
      date: todaydate,
      refNo: "",
      mode_: "",
      nominal_acc: "",
      // project_: [""],
      detail_: "",
      amount: "",
    },
  ]

  const [data, setData] = useState(initialData)
  const handleEdit = (id, field, value) => {
    if (value === "add_mode") {
      addMode()
    } else if (value === "add_nominal") {
      addNominalAcc()
    } else {
      setData(prevData =>
        prevData.map(item =>
          item.id === id ? { ...item, [field]: value } : item
        )
      )
    }
  }
  const handleAddRow = () => {
    const newId = data.length + 1
    const newRow = {
      id: newId,
      date: todaydate,
      refNo: "",
      mode_: "",
      nominal_acc: "",
      // project_: "",
      detail_: "",
      amount: "",
    }
    setData(prevData => [...prevData, newRow])
  }
  const deleteRow = id => {
    const updatedData = data.filter(item => item.id !== id)
    setData(updatedData)
  }
  useEffect(() => {
    const totalAmount = data.reduce(
      (acc, row) => acc + parseFloat(row.amount || 0),
      0.0
    )
    setTotalamount(totalAmount)
  })

  //Bank Add Modal
  const [formData, setFormData] = useState({
    bank_name: "",
    bank_code: "",
    bank_disc: "",
    method: "add_bank_detial",
  })
  const onChangeModal2 = e => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  const handleBankAccount = e => {
    const URL = `${apiUrl}/BankManagemnt/addbank.php`
    const data = new FormData()
    data.append("bank_name", formData.bank_name)
    data.append("bank_disc", formData.bank_disc)
    data.append("app_method", "add_bank_detail")

    e.preventDefault()
    if (formData.bank_name === "") {
      toastr.error("Bank name is Required")
    } else {
      axios
        .post(URL, data)
        .then(response => {
          if (response.data === "success") {
            setBankModal(!bankModal)
            fetchBank()
            setState(prevState => ({
              ...prevState,
              bank_name: formData.bank_name,
            }))
            setFormData({
              bank_name: "",
              bank_code: "",
              bank_disc: "",
            })
            toastr.success("Bank Add Successfully")
          } else {
            toastr.error(response.data)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
  const addBankAcc = () => {
    setBankModal(!bankModal)
  }

  //Project Add Modal
  const [formData3, setFormData3] = useState({
    project_name: "",
    project_code: "",
    project_detail: "",
    method: "add_project_detail",
  })
  const onChangeModal = e => {
    const { name, value } = e.target
    setFormData3(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  const handleProject = e => {
    e.preventDefault()
    if (formData3.project_name === "") {
      toastr.error("Project name is Required")
    } else {
      axios
        .post(`${apiUrl}/Projects/projects.php`, formData3)
        .then(response => {
          if (response.data === "success") {
            fetchProject()
            setProjectModal(!projectModal)
            setState(prevState => ({
              ...prevState,
              project_name: formData3.project_name,
            }))
            toastr.success("Submit Successfully")
            setFormData3({
              project_name: "",
              project_code: "",
              project_detail: "",
            })
          } else {
            toastr.error(response.data)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
  const addProject = () => {
    setProjectModal(!projectModal)
  }

  //Add Mode
  const [mode, setMode] = useState("")
  const handleMode = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const modedata = new FormData()
    modedata.append("mode_name", mode)
    modedata.append("app_method", "payment_mode")
    if (mode === "") {
      toastr.error("Mode name is Required")
    } else {
      axios
        .post(URL, modedata)
        .then(response => {
          if (response.data === "success") {
            setModeModal(!modeModal)
            fetchPayMode()
            setMode("")
            toastr.success("Mode Add Successfully")
            // Find the index of the first item with empty mode_
            const index = data.findIndex(item => item.mode_.includes(""))

            if (index !== -1) {
              setData(prevData =>
                prevData.map((item, i) =>
                  i === index ? { ...item, mode_: [mode] } : item
                )
              )
            } else {
              toastr.error("No rows found with empty mode")
            }
          } else {
            toastr.error(response.data)
          }
        })
        .catch(error => console.log(error))
    }
  }
  const addMode = () => {
    setModeModal(!modeModal)
  }

  //Add Nominal Acc
  const [nominal_acc2, setNominal_acc2] = useState("")
  const [nominal_acc_code, setNominal_acc_code] = useState("")
  const handleNlAcc = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const addNlAcc = new FormData()
    addNlAcc.append("nominal_acc_name", nominal_acc2)
    addNlAcc.append("nominal_acc_code", nominal_acc_code)
    addNlAcc.append("app_method", "add_nom_acc")
    if (nominal_acc2 === "") {
      toastr.error("Nominal Account Name is Required")
    } else if (nominal_acc_code === "") {
      toastr.error("Nominal Account Code is Required ")
    } else {
      axios
        .post(URL, addNlAcc)
        .then(response => {
          if (response.data === "success") {
            setNominalModal(!nominalModal)
            fetchNominalAcc()
            setNominal_acc2("")
            setNominal_acc_code("")
            toastr.success("Add Successfully")
          } else {
            toastr.error("Error in Adding")
          }
        })
        .catch(error => console.log(error))
    }
  }
  const addNominalAcc = () => {
    setNominalModal(!nominalModal)
  }

  //Close
  const close = e => {
    e.preventDefault()
    navigate("/bank/control/bank_payments")
  }

  if (loading) {
    return (
      <div className="spinner-container">
        <img src={logo} alt="Loading" className="spinner-logo" />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="9">
              <div>
                <h5 className="mt-2">Add Bank Payments</h5>
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col xl={6}>
                    <div className="col-sm-auto">
                      <label className="" htmlFor="autoSizingSelect">
                        Bank
                      </label>
                      <InputGroup>
                        <select
                          defaultValue="0"
                          className="form-select"
                          onChange={handleState}
                          name="bank_name"
                          value={state.bank_name}
                        >
                          <option value={null}>Select bank ...</option>
                          {bank.length > 0 ? (
                            bank
                              .filter(e => !!e)
                              .map((e, index) => (
                                <option key={index} value={e.bank_name}>
                                  {e.bank_name} ({e.bank_code})
                                </option>
                              ))
                          ) : (
                            <option value="" disabled>
                              No Data Found
                            </option>
                          )}
                        </select>
                        <div className="input-group-append">
                          <Button
                            type="button"
                            color="primary"
                            onClick={addBankAcc}
                          >
                            <i className="mdi mdi-plus" />
                          </Button>
                        </div>
                      </InputGroup>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-5">
                  <Col xl={6}>
                    <div className="col-sm-auto">
                      <label className="" htmlFor="autoSizingSelect">
                        Project
                      </label>
                      <InputGroup>
                        <select
                          defaultValue="0"
                          className="form-select"
                          onChange={handleState}
                          name="project_name"
                          value={state.project_name}
                        >
                          <option value={""}>Select project ...</option>
                          {projects.length > 0 ? (
                            projects
                              .filter(e => !!e)
                              .map((e, index) => (
                                <option key={index} value={e.project_name}>
                                  {e.project_name} ({e.project_code})
                                </option>
                              ))
                          ) : (
                            <option value="" disabled>
                              No project found
                            </option>
                          )}
                        </select>
                        <div className="input-group-append">
                          <Button
                            type="button"
                            color="primary"
                            onClick={addProject}
                          >
                            <i className="mdi mdi-plus" />
                          </Button>
                        </div>
                      </InputGroup>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xl={12}>
                    <table>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Ref. No</th>
                          <th>Mode</th>
                          <th>Nominal Account</th>
                          {/* <th>Projects</th> */}
                          <th>Detail</th>
                          <th>Amount</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map(item => (
                          <tr key={item.id}>
                            <td style={{ display: "none" }}>{item.id}</td>
                            <td>{item.date}</td>
                            <td>
                              <input
                                type="text"
                                value={item.refNo}
                                onChange={e =>
                                  handleEdit(item.id, "refNo", e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <select
                                className="form-select"
                                type="text"
                                value={item.mode_}
                                onChange={e =>
                                  handleEdit(item.id, "mode_", e.target.value)
                                }
                              >
                                <option value="">Mode...</option>
                                <option
                                  value="add_mode"
                                  style={{ color: "blue" }}
                                >
                                  + Add new
                                </option>
                                {pay_mode.length > 0 ? (
                                  pay_mode
                                    .filter(e => !!e)
                                    .map((e, index) => (
                                      <option key={index} value={e.mode_name}>
                                        {e.mode_name}
                                      </option>
                                    ))
                                ) : (
                                  <></>
                                )}
                              </select>
                            </td>
                            <td>
                              <select
                                className="form-select"
                                type="text"
                                value={item.nominal_acc}
                                onChange={e =>
                                  handleEdit(
                                    item.id,
                                    "nominal_acc",
                                    e.target.value
                                  )
                                }
                              >
                                <option value="">Select...</option>
                                <option
                                  value="add_nominal"
                                  style={{ color: "blue" }}
                                >
                                  + Add new
                                </option>
                                {nominal_acc.length > 0 ? (
                                  nominal_acc
                                    .filter(e => !!e)
                                    .map((e, index) => (
                                      <option
                                        key={index}
                                        value={`${e.account_name}(${e.acc_code})`}
                                      >
                                        {`${e.account_name}(${e.acc_code})`}
                                      </option>
                                    ))
                                ) : (
                                  <></>
                                )}
                              </select>
                            </td>
                            <td>
                              <input
                                type="text"
                                value={item.detail_}
                                onChange={e =>
                                  handleEdit(item.id, "detail_", e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={item.amount}
                                onChange={e =>
                                  handleEdit(item.id, "amount", e.target.value)
                                }
                                placeholder="0.00"
                              />
                            </td>
                            <td>
                              <i
                                className="mdi mdi-delete font-size-18"
                                onClick={() => deleteRow(item.id)}
                                style={{ color: "#F46A6A", cursor: "pointer" }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                  <Col xl={6} className="mt-2">
                    <h5 style={{ color: "black", fontSize: "14px" }}>Total</h5>
                  </Col>
                  <Col xl={4} className="mt-2">
                    <div className="float-end">
                      <strong>{formatAmount(totalamount)}</strong>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="my-3">
                      <Button
                        onClick={handleAddRow}
                        color="primary"
                        className="btn btn-sm"
                      >
                        <i className="fa fa-fw fa-plus" />
                        Add Row
                      </Button>
                    </div>
                  </Col>
                </Row>
                <div className="custom-horizontal-line"></div>
                <Row>
                  <Col xl={6}>
                    <Button
                      color="danger"
                      className="btn btn-danger waves-effect waves-light"
                      onClick={close}
                    >
                      Close
                    </Button>
                  </Col>
                  <Col xl={6}>
                    <div className="float-end">
                      <Button
                        className="btn btn-primary"
                        color="success"
                        type="submit"
                      >
                        Save and Close
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
      <Modal
        isOpen={projectModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setProjectModal(!projectModal)
        }}
      >
        <div>
          <Form onSubmit={handleProject}>
            <ModalHeader
              toggle={() => {
                setProjectModal(!projectModal)
              }}
            >
              Project Information
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col className="col-12">
                  <div className="mb-3">
                    <label>Project Name</label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Name"
                      name="project_name"
                      value={formData3.project_name}
                      onChange={onChangeModal}
                    ></Input>
                  </div>
                  <div className="mb-3">
                    <label>Code</label>
                    <Input
                      className="form-control"
                      type="number"
                      placeholder="code"
                      name="project_code"
                      value={formData3.project_code}
                      onChange={onChangeModal}
                      disabled
                    ></Input>
                  </div>
                  <div className="mb-3">
                    <label>Details</label>
                    <Input
                      className="form-control"
                      type="textarea"
                      placeholder="description"
                      name="project_detail"
                      value={formData3.project_detail}
                      onChange={onChangeModal}
                    />
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                  setProjectModal(!projectModal)
                }}
              >
                Close
              </Button>
              <Button type="submit" color="primary">
                Submit
              </Button>
            </ModalFooter>
          </Form>
        </div>
      </Modal>
      <Modal
        isOpen={bankModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setBankModal(!bankModal)
        }}
      >
        <div>
          <Form>
            <ModalHeader
              toggle={() => {
                setBankModal(!bankModal)
              }}
            >
              Bank Account New
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col className="col-12">
                  <div className="mb-3">
                    <label>Bank Account *</label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Name"
                      name="bank_name"
                      value={formData.bank_name}
                      onChange={onChangeModal2}
                    ></Input>
                  </div>
                  <div className="mb-3">
                    <label>Code</label>
                    <Input
                      className="form-control"
                      type="number"
                      placeholder="code"
                      name="bank_code"
                      value={formData.bank_code}
                      onChange={onChangeModal2}
                      disabled
                    ></Input>
                  </div>
                  <div className="mb-3">
                    <label>Description</label>
                    <Input
                      className="form-control"
                      type="textarea"
                      placeholder="description"
                      name="bank_disc"
                      value={formData.bank_disc}
                      onChange={onChangeModal2}
                    />
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                  setBankModal(!bankModal)
                }}
              >
                Close
              </Button>
              <Button type="submit" color="primary" onClick={handleBankAccount}>
                Submit
              </Button>
            </ModalFooter>
          </Form>
        </div>
      </Modal>
      <Modal
        isOpen={modeModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setModeModal(!modeModal)
        }}
      >
        <div>
          <Form>
            <ModalHeader
              toggle={() => {
                setModeModal(!modeModal)
              }}
            >
              Add Mode
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col xl={8}>
                  <div className="mb-3">
                    <label>Payment Mode</label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Payment Mode"
                      name="payment_mode"
                      value={mode}
                      onChange={e => setMode(e.target.value)}
                    ></Input>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                  setModeModal(!modeModal)
                }}
              >
                Close
              </Button>
              <Button color="primary" onClick={handleMode}>
                Submit
              </Button>
            </ModalFooter>
          </Form>
        </div>
      </Modal>
      <Modal
        isOpen={nominalModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setNominalModal(!nominalModal)
        }}
      >
        <div>
          <Form>
            <ModalHeader
              toggle={() => {
                setNominalModal(!nominalModal)
              }}
            >
              Add Nominal Account
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col xl={8}>
                  <div className="mb-3">
                    <label>Nominal Account</label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Nominal Account"
                      name="nominal_acc_"
                      value={nominal_acc2}
                      onChange={e => setNominal_acc2(e.target.value)}
                    ></Input>
                  </div>
                  <div className="mb-3">
                    <label>Account Code</label>
                    <Input
                      className="form-control"
                      type="number"
                      placeholder="Nominal Account Code"
                      name="nominal_acc_code"
                      value={nominal_acc_code}
                      onChange={e => setNominal_acc_code(e.target.value)}
                    ></Input>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                  setNominalModal(!nominalModal)
                }}
              >
                Close
              </Button>
              <Button color="primary" onClick={handleNlAcc}>
                Submit
              </Button>
            </ModalFooter>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default AddBankPayments
