import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import Select from "react-select"
import "../../../assets/style/style.css"
import logo from "assets/images/Pham logo black text.png"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Collapse,
  Table,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  colspan,
  Modal,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
} from "reactstrap"
//Date Picker
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import classnames from "classnames"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import { formatAmount } from "components/Functoins/functions"
import apiUrl from "config"

const SupplierBillPayment = props => {
  const { state } = useLocation()
  const { project } = state || {}
  const { id } = useParams()
  const pur_id = id

  document.title = "Bill Payment"
  const navigate = useNavigate()

  const [totalCutBalance, setTotalCutBalance] = useState(0)
  const [totalRBalance, setTotalRBalance] = useState(0)
  const [custunallocated, setCustunallocated] = useState([])
  const [bank, setBank] = useState([])
  const [pay_mode, setPaymode] = useState([])
  const [projects, setProjects] = useState([])

  const [modeModal, setModeModal] = useState(false)
  const [bankModal, setBankModal] = useState(false)

  const [loading, setloading] = useState(false)

  //Customer Detail
  const [formData, setFormData] = useState({
    bank_name: "",
    payment_mode: "",
    bill_ref_no: "",
    bill_pay_by: "",
    project_name: "",
    pay_amount: "",
    date_: new Date().toISOString().substr(0, 10),
    cred_limt: "",
    unallocated_balance_: "",
  })

  const handleChange = e => {
    const { name, value } = e.target
    if (value === "add_mode") {
      addMode()
    } else if (value === "add_bank") {
      addBankAcc()
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
      }))
    }

    if (name === "pay_amount") {
      let payamount = parseFloat(value)
      const newBalance = payamount - totalRBalance

      if (newBalance >= 0) {
        setCustunallocated(newBalance)
      } else {
        setCustunallocated("0")
      }

      const updatedFetchData = fetchData.map(row => {
        const rBalance = parseFloat(row.balance_)

        let totalCal = 0

        if (payamount >= rBalance) {
          totalCal = rBalance
          payamount -= rBalance
        } else {
          totalCal = parseFloat(payamount)
          payamount = 0
        }

        return {
          ...row,
          total_cal: totalCal.toFixed(2),
        }
      })
      setfetchData(updatedFetchData)
    }
  }

  //Fetch Purchase Detail By ID
  const fetchPurchaseData = async id => {
    const URL = `${apiUrl}/purchases/addPurchases.php`
    const post = new FormData()
    post.append("pur_id", id)
    post.append("app_method", "fetch_bill_det_byId")

    try {
      setloading(true) // Set loading to true before fetching
      const response = await axios.post(URL, post)
      if (Array.isArray(response.data)) {
        if (response.data.length > 0) {
          setFormData(prevData => ({
            ...prevData,
            project_name: response.data[0].project_name || "",
            final_notes: response.data[0].final_notes || "",
          }))
        } else {
          setFormData(prevData => ({
            ...prevData,
          }))
        }
      } else {
        console.log("error")
      }
    } catch (error) {
      console.log(error)
    } finally {
      setloading(false) // Set loading to false after fetching
    }
  }

  //Fetch Purchase Products By ID
  const fetchPurchaseProducts = async project2 => {
    const URL = `${apiUrl}/purchases/addPurchases.php`
    const post2 = new FormData()
    post2.append("project_name", project2)
    post2.append("app_method", "fetch_supplier_rpayments")

    try {
      const response = await axios.post(URL, post2)
      if (Array.isArray(response.data)) {
        if (response.data.length > 0) {
          const fetchedData = response.data.map(item => ({
            ...item,
            total_cal: item.total_col || "0",
          }))
          setfetchData(fetchedData)
        } else {
          console.log("No Data Found")
          setfetchData([])
        }
      } else {
        console.log("Invalid data format received")
        setfetchData([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchPurchaseData(pur_id)
    if (project) {
      fetchPurchaseProducts(project)
    }
  }, [pur_id, project])

  // Handle Project
  const handleChangeProject = selectedOption => {
    if (selectedOption && selectedOption.value) {
      setFormData(prevData => ({
        ...prevData,
        project_name: selectedOption.value,
      }))
    }
  }

  useEffect(() => {
    if (formData.project_name) {
      fetchPurchaseProducts(formData.project_name)
    }
  }, [formData.project_name])

  //Fetch Product Data
  const initialData2 = [
    {
      id: "",
      purchase_date: "",
      purchase_bill_no: "",
      purchase_id: "",
      total_amount: "",
      balance_: "",
      // discount_per: "",
      total_cal: "0.00",
    },
  ]
  const [fetchData, setfetchData] = useState(initialData2)

  const handleEditF = (id, field, value) => {
    setfetchData(prevData =>
      prevData.map(item => {
        if (item.id === id) {
          return {
            ...item,
            [field]: value,
            prd_amount:
              field === "prd_qty" || field === "prd_rate"
                ? parseFloat(value) * parseFloat(item.prd_rate)
                : item.prd_amount,
          }
        } else {
          return item
        }
      })
    )
  }
  const handleEditF2 = (id, field, value) => {
    setfetchData(prevData =>
      prevData.map(item => {
        if (item.id === id) {
          return {
            ...item,
            [field]: value,
            prd_amount:
              field === "prd_qty" || field === "prd_rate"
                ? parseFloat(item.prd_qty) * parseFloat(value)
                : item.prd_amount,
          }
        } else {
          return item
        }
      })
    )
  }

  //Calculation
  useEffect(() => {
    let sumCutBalance = 0
    let sumAmount = 0
    fetchData.forEach(item => {
      const cutbalance = parseFloat(item.total_cal) || 0
      const tremainbalance = parseFloat(item.balance_) || 0

      sumCutBalance += cutbalance
      sumAmount += tremainbalance
    })

    setTotalCutBalance(sumCutBalance)
    setTotalRBalance(sumAmount)
  }, [fetchData])

  //Submit Payment
  const [BtnDisabled, setBtnDisabled] = useState(false)
  const SubmitBillPayment = async e => {
    if (fetchData.length === 0) {
      toastr.error("No <b>Bill</b> available for payment")
    } else if (formData.project_name.length === 0) {
      toastr.error("Project is required")
    } else if (formData.bank_name === "") {
      toastr.error("Select Bank")
    } else if (formData.pay_mode === "") {
      toastr.error("Set Pay Mode")
    } else if (formData.pay_amount === "0" || formData.pay_amount === "") {
      toastr.error("<b>Pay Amount</b> must be greater than <b>Zero</b>")
    } else if (formData.date_ === "") {
      toastr.error("Date is Required")
    } else {
      setBtnDisabled(true)
      e.preventDefault()
      const URl = `${apiUrl}/purchases/payments.php`
      const formdata = new FormData()

      //fetch row data
      fetchData.forEach(row => {
        formdata.append("id[]", row.id)
        formdata.append("bill_date[]", row.purchase_date)
        formdata.append("bill_no[]", row.purchase_bill_no)
        formdata.append("bill_id[]", row.purchase_id)
        formdata.append("bill_original_amount[]", row.total_amount)
        formdata.append("bill_balance_amount[]", row.balance_)
        formdata.append("cutBalanceAmount[]", row.total_cal)
      })

      //customer data
      formdata.append("pur_id", pur_id)
      formdata.append("project_name", formData.project_name)
      formdata.append("purchase_date", formData.date_)
      formdata.append("total_pay_amount", formData.pay_amount)
      formdata.append("bank_name", formData.bank_name)
      formdata.append("pay_mode", formData.payment_mode)
      formdata.append("bill_ref_no", formData.bill_ref_no)
      formdata.append("bill_pay_by", formData.bill_pay_by)
      formdata.append("unallocated_balance_", custunallocated)
      formdata.append("tcut_balancAmount", totalCutBalance)

      formdata.append("app_method", "make_bill_payment")
      try {
        const response = await axios.post(URl, formdata)
        if (response.data.trim() === "success") {
          toastr.success("Add Successfully")
          navigate("/expenses/control/bills")
        } else {
          toastr.error("error")
        }
      } catch (error) {
        console.log(error)
      } finally {
        setBtnDisabled(false)
      }
    }
  }
  //Bank
  const fetchBank = () => {
    const URL = `${apiUrl}/BankManagemnt/addbank.php`
    const Data = new FormData()
    Data.append("app_method", "fetch_bank")

    axios
      .post(URL, Data)
      .then(response => {
        if (response.data) {
          setBank(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  //Pay Mode
  const fetchPayMode = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const pm = new FormData()
    pm.append("app_method", "fetch_pay_mode")

    axios
      .post(URL, pm)
      .then(response => {
        if (response.data) {
          setPaymode(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  //Projects
  const fetchProject = () => {
    const URL = `${apiUrl}/Projects/projects.php`
    const Pdata = new FormData()
    Pdata.append("app_method", "fetch_all_projects")

    axios
      .post(URL, Pdata)
      .then(response => {
        if (Array.isArray(response.data)) {
          setProjects(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    fetchBank()
    fetchProject()
    fetchPayMode()
  }, [])

  //Bank Add Modal
  const [bankdata, setbankdata] = useState({
    bank_name: "",
    bank_code: "",
    bank_disc: "",
    method: "add_bank_detial",
  })
  const onChangeModal2 = e => {
    const { name, value } = e.target
    setbankdata(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  const handleBankAccount = e => {
    const URL = `${apiUrl}/BankManagemnt/addbank.php`
    const data = new FormData()
    data.append("bank_name", bankdata.bank_name)
    data.append("bank_disc", bankdata.bank_disc)
    data.append("app_method", "add_bank_detail")

    e.preventDefault()
    if (bankdata.bank_name === "") {
      toastr.error("Bank name is Required")
    } else {
      axios
        .post(URL, data)
        .then(response => {
          if (response.data === "success") {
            setBankModal(!bankModal)
            fetchBank()
            toastr.success("Submit Successfully")
            setbankdata({
              bank_name: "",
              bank_code: "",
              bank_disc: "",
            })
            setFormData(pd => ({
              ...pd,
              bank_name: bankdata.bank_name,
            }))
            // window.location.reload()
          } else {
            toastr.error(response.data)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
  const addBankAcc = () => {
    setBankModal(!bankModal)
  }

  //Add Mode
  const [mode, setMode] = useState("")
  const handleMode = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const modedata = new FormData()
    modedata.append("mode_name", mode)
    modedata.append("app_method", "payment_mode")
    if (mode === "") {
      toastr.error("Mode name is Required")
    } else {
      axios
        .post(URL, modedata)
        .then(response => {
          if (response.data === "success") {
            setModeModal(!modeModal)
            fetchPayMode()
            setMode("")
            setFormData(pd => ({
              ...pd,
              payment_mode: mode,
            }))
            toastr.success("Add Successfully")
          } else {
            toastr.error("Error in Adding")
          }
        })
        .catch(error => console.log(error))
    }
  }
  const addMode = () => {
    setModeModal(!modeModal)
  }

  //Close
  const close = e => {
    e.preventDefault()
    navigate("/expenses/control/bills")
  }

  if (loading) {
    return (
      <div className="spinner-container">
        <img src={logo} alt="Loading" className="spinner-logo" />
      </div>
    )
  }

  const deleteBillPayment = id => {
    const updatedData = fetchData.filter(item => item.purchase_id !== id)
    setfetchData(updatedData)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}

          <Row>
            <Col xl="10">
              <div>
                <h5 className="mt-2">Bill To Payment</h5>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Card>
              <CardBody>
                <Row className="mb-5">
                  <Col xl={4}>
                    <div className="mb-3">
                      <Label className="control-label" htmlFor="project_name">
                        Project
                      </Label>
                      <select
                        defaultValue="0"
                        className="form-select"
                        onChange={e =>
                          handleChangeProject(e.target.selectedOptions[0])
                        }
                        name="project_name"
                        value={formData.project_name}
                      >
                        {projects.length > 0 ? (
                          projects
                            .filter(e => !!e)
                            .map((e, index) => (
                              <option key={index} value={e.project_name}>
                                {e.project_name} ({e.project_code})
                              </option>
                            ))
                        ) : (
                          <option value="" disabled>
                            No Data Found
                          </option>
                        )}
                      </select>
                    </div>
                    <div className="mb-3">
                      <label>Date</label>
                      <Input
                        className="form-control"
                        type="date"
                        //   placeholder="Payment Mode"
                        name="date_"
                        value={formData.date_}
                        onChange={handleChange}
                      ></Input>
                    </div>
                  </Col>

                  <Col xl={4}>
                    <div className="mb-3">
                      <label className="" htmlFor="autoSizingSelect">
                        Bank
                      </label>
                      <select
                        defaultValue="0"
                        className="form-select"
                        onChange={handleChange}
                        name="bank_name"
                        value={formData.bank_name}
                      >
                        <option value={null}>Select bank ...</option>
                        <option value="add_bank" style={{ color: "blue" }}>
                          + Add New
                        </option>
                        {bank.length > 0 ? (
                          bank
                            .filter(e => !!e)
                            .map((e, index) => (
                              <option key={index} value={e.bank_name}>
                                {e.bank_name} ({e.bank_code})
                              </option>
                            ))
                        ) : (
                          <option value="" disabled>
                            No Data Found
                          </option>
                        )}
                      </select>
                    </div>

                    <div className="mb-3">
                      <label>Amount</label>
                      <Input
                        className="form-control"
                        type="text"
                        min={0}
                        placeholder="0.00"
                        name="pay_amount"
                        value={formData.pay_amount}
                        onChange={handleChange}
                      ></Input>
                    </div>
                  </Col>
                  <Col xl={4}>
                    <div className="mb-3">
                      <label className="" htmlFor="autoSizingSelet">
                        Payment Mode
                      </label>
                      <select
                        className="form-select"
                        type="text"
                        value={formData.payment_mode}
                        onChange={handleChange}
                        name="payment_mode"
                      >
                        <option value="">Mode...</option>
                        <option value="add_mode" style={{ color: "blue" }}>
                          + Add new
                        </option>
                        {pay_mode.length > 0 ? (
                          pay_mode
                            .filter(e => !!e)
                            .map((e, index) => (
                              <option key={index} value={e.mode_name}>
                                {e.mode_name}
                              </option>
                            ))
                        ) : (
                          <option value="" disabled>
                            No Data Found
                          </option>
                        )}
                      </select>
                    </div>

                    <Row>
                      <Col xl={6}>
                        <div className="mb-3">
                          <label>Ref No</label>
                          <Input
                            className="form-control"
                            type="text"
                            min={0}
                            placeholder="ref no"
                            name="bill_ref_no"
                            value={formData.bill_ref_no}
                            onChange={handleChange}
                          ></Input>
                        </div>
                      </Col>
                      <Col xl={6}>
                        <div className="mb-3">
                          <label>Pay By</label>
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="name"
                            name="bill_pay_by"
                            value={formData.bill_pay_by}
                            onChange={handleChange}
                          ></Input>
                        </div>
                      </Col>
                    </Row>
                    <div className="mb-3">
                      <label>Unallocated Balance</label>
                      <Input
                        className="form-control"
                        type="text"
                        disabled
                        min={0}
                        placeholder="0.00"
                        name="unallocated_balance_"
                        value={formatAmount(Number(custunallocated))}
                        onChange={handleChange}
                      ></Input>
                    </div>
                  </Col>
                </Row>

                <Row className="mb-5">
                  <div>
                    <h5>Unpaid Bills</h5>
                  </div>
                  <Col xl={12}>
                    <table>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Bill No</th>
                          <th>Bill ID</th>
                          <th>Original Amount</th>
                          <th>Open Balance</th>
                          <th>Total</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fetchData.map((item, index) => (
                          <tr key={index}>
                            <td style={{ display: "none" }}>{item.id}</td>
                            <td>
                              <input
                                type="text"
                                value={item.purchase_date}
                                onChange={e =>
                                  handleEditF(
                                    item.id,
                                    "purchase_date",
                                    e.target.value
                                  )
                                }
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                rows={2}
                                value={item.purchase_bill_no}
                                onChange={e =>
                                  handleEditF(
                                    item.id,
                                    "purchase_bill_no",
                                    e.target.value
                                  )
                                }
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={item.purchase_id}
                                onChange={e =>
                                  handleEditF(
                                    item.id,
                                    "purchase_id",
                                    e.target.value
                                  )
                                }
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={formatAmount(item.total_amount)}
                                min={1}
                                onChange={e =>
                                  handleEditF2(
                                    item.id,
                                    "total_amount",
                                    e.target.value
                                  )
                                }
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                min={1}
                                value={formatAmount(item.balance_)}
                                onChange={e =>
                                  handleEditF(
                                    item.id,
                                    "balance_",
                                    e.target.value
                                  )
                                }
                                disabled
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                value={item.total_cal}
                                onChange={e =>
                                  handleEditF(
                                    item.id,
                                    "total_cal",
                                    e.target.value
                                  )
                                }
                                disabled
                              />
                            </td>

                            <td>
                              <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                  deleteBillPayment(item.purchase_id)
                                }}
                              >
                                <i
                                  className="mdi mdi-delete font-size-18"
                                  id={`deletetooltip-${index}`}
                                />
                                <UncontrolledTooltip
                                  placement="top"
                                  target={`deletetooltip-${index}`}
                                >
                                  Delete
                                </UncontrolledTooltip>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>

                      <tbody>
                        <thead></thead>
                        <tr
                          style={{
                            borderTop: "1px solid black",
                          }}
                        >
                          <td colSpan={4}>
                            <strong>Total</strong>
                          </td>
                          <td>
                            <input
                              type="text"
                              value={formatAmount(totalRBalance)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={formatAmount(totalCutBalance)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col xl={4}>
                    <Button
                      color="danger"
                      className="btn btn-danger waves-effect waves-light"
                      onClick={close}
                    >
                      Close
                    </Button>
                  </Col>
                  <Col xl={8}>
                    <div className="d-flex flex-wrap gap-2 float-end">
                      <Button
                        color="success"
                        className="btn btn-secondary waves-effect waves-light"
                        onClick={SubmitBillPayment}
                        disabled={BtnDisabled}
                      >
                        {BtnDisabled ? "Loading..." : "Save & Close"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
        <Modal
          isOpen={modeModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setModeModal(!modeModal)
          }}
        >
          <div>
            <Form>
              <ModalHeader
                toggle={() => {
                  setModeModal(!modeModal)
                }}
              >
                Add Mode
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col xl={8}>
                    <div className="mb-3">
                      <label>Payment Mode</label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Payment Mode"
                        name="payment_mode"
                        value={mode}
                        onChange={e => setMode(e.target.value)}
                      ></Input>
                    </div>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => {
                    setModeModal(!modeModal)
                  }}
                >
                  Close
                </Button>
                <Button color="primary" onClick={handleMode}>
                  Submit
                </Button>
              </ModalFooter>
            </Form>
          </div>
        </Modal>

        <Modal
          isOpen={bankModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setBankModal(!bankModal)
          }}
        >
          <div>
            <Form>
              <ModalHeader
                toggle={() => {
                  setBankModal(!bankModal)
                }}
              >
                Bank Account New
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <label>Bank Account *</label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Name"
                        name="bank_name"
                        value={bankdata.bank_name}
                        onChange={onChangeModal2}
                      ></Input>
                    </div>
                    <div className="mb-3">
                      <label>Code</label>
                      <Input
                        className="form-control"
                        type="number"
                        placeholder="code"
                        name="bank_code"
                        value={bankdata.bank_code}
                        onChange={onChangeModal2}
                        disabled
                      ></Input>
                    </div>
                    <div className="mb-3">
                      <label>Description</label>
                      <Input
                        className="form-control"
                        type="textarea"
                        placeholder="description"
                        name="bank_disc"
                        value={bankdata.bank_disc}
                        onChange={onChangeModal2}
                      />
                    </div>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => {
                    setBankModal(!bankModal)
                  }}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  onClick={handleBankAccount}
                >
                  Submit
                </Button>
              </ModalFooter>
            </Form>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default SupplierBillPayment
