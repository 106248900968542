import PropTypes from "prop-types"
import React, { useState } from "react"

import apiUrl from "config"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

//css
import "assets/style/style.css"

// import images
import profile from "assets/images/Pham logo black text.png"
import logo from "assets/images/logo.svg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"

import axios from "axios"

const Login = props => {
  //meta title
  document.title = "Login | Pham Enterprise"

  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const [formData, setFormData] = useState({
    email_: "",
    password_: "",
  })

  const handleChange = e => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  const login = e => {
    e.preventDefault()
    if (formData.email_.length == 0) {
      toastr.error("Email is required")
    } else if (formData.password_.length == 0) {
      toastr.error("Password is required")
    } else {
      const URL = `${apiUrl}/users/users.php`
      const data = new FormData()
      data.append("email_", formData.email_)
      data.append("password_", formData.password_)
      data.append("app_method", "login_function")

      axios
        .post(URL, data)
        .then(response => {
          if (response.data.success) {
            // window.location.href = "/dashboard"
            navigate("/dashboard")
            const userID = JSON.stringify(response.data)
            localStorage.setItem("authUser", userID)
            localStorage.setItem("userID", response.data.id)
            localStorage.setItem("userName", response.data.FullName)
            localStorage.setItem("userEmail", response.data.email)
            localStorage.setItem("userPass", response.data.password)
            localStorage.setItem("userRole", response.data.role)
            toastr.success(response.data.message)
          } else {
            toastr.error(response.data.error)
          }
        })
        .catch(error => toastr.error(error))
    }
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row
            className="justify-content-center"
            style={{ backgroundColor: "#F8F8FB" }}
          >
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={6}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">
                          Welcome Back <br /> To Pham Enterprises
                        </h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-center mb-3 mt-2">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Form className="form-horizontal">
                      {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email_"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={handleChange}
                          value={formData.email_}
                        />
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <InputGroup>
                          <Input
                            name="password_"
                            value={formData.password_}
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={handleChange}
                          />
                          <InputGroupText
                            onClick={togglePasswordVisibility}
                            style={{ cursor: "pointer" }}
                          >
                            <FontAwesomeIcon
                              icon={showPassword ? faEyeSlash : faEye}
                            />
                          </InputGroupText>
                        </InputGroup>
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          onClick={login}
                        >
                          Log In
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
