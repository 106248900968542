import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link, useLocation, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { formatAmount } from "components/Functoins/functions"
import logo from "../../../assets/images/Pham logo black text.png"
import apiUrl from "config"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  InputGroup,
  Button,
} from "reactstrap"
import "../../../assets/style/style.css"

import { useReactToPrint } from "react-to-print"
import axios from "axios"
import BankPayments from "../BankPayments"

const BankPaymentReport = props => {
  //meta title
  document.title = "Bank Report"

  const [payments, setPayments] = useState([])
  const [filterDiv, setFilterdiv] = useState(false)
  const [projects, setProjects] = useState([])
  const [bank, setBank] = useState([])
  const [totalamount, setTotalamount] = useState("")
  const [state, setState] = useState({
    bank_name: "",
    project_name: "",
  })

  const handleState = e => {
    const { name, value } = e.target
    setState(prData => ({
      ...prData,
      [name]: value,
    }))
  }

  const fetchProject = () => {
    const URL = `${apiUrl}/Projects/projects.php`
    const Pdata = new FormData()
    Pdata.append("app_method", "fetch_all_projects")

    axios
      .post(URL, Pdata)
      .then(response => {
        if (Array.isArray(response.data)) {
          setProjects(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  const fetchBank = () => {
    const URL = `${apiUrl}/BankManagemnt/addbank.php`
    const Data = new FormData()
    Data.append("app_method", "fetch_bank")

    axios
      .post(URL, Data)
      .then(response => {
        if (Array.isArray(response.data)) {
          setBank(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    fetchBank()
    fetchProject()
  }, [])

  const handleFilter = () => {
    const URL = `${apiUrl}/BankManagemnt/addbank.php`
    const Pdata = new FormData()
    Pdata.append("app_method", "fetch_payments")
    setFilterdiv(true)
    axios
      .post(URL, Pdata)
      .then(response => {
        if (Array.isArray(response.data)) {
          let filteredPayments = response.data

          if (state.bank_name && state.project_name) {
            // Filter payments based on both bank_name and project_name
            filteredPayments = response.data.filter(
              payment =>
                payment.bank_name === state.bank_name &&
                payment.project_name === state.project_name
            )
          } else if (state.bank_name && !state.project_name) {
            // Filter payments based on bank_name only
            filteredPayments = response.data.filter(
              payment => payment.bank_name === state.bank_name
            )
          } else if (!state.bank_name && state.project_name) {
            // Filter payments based on project_name only
            filteredPayments = response.data.filter(
              payment => payment.project_name === state.project_name
            )
          }

          setPayments(filteredPayments)
        } else {
          console.log("Error in fetch Data")
        }
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    const totalAmount = payments.reduce(
      (acc, row) => acc + parseFloat(row.trans_amount || 0),
      0.0
    )

    setTotalamount(totalAmount)
  })

  //Print
  const printRef = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  })
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Invoices" breadcrumbItem="Invoice Detail" /> */}
          <Row className="mb-3">
            <Col xl="12">
              <div>
                <h5 className="mt-2">Bank Payments Reports</h5>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg="12">
              <Card>
                <CardTitle>
                  <CardBody>
                    <Row>
                      <Col xl={3}>
                        <div className="col-sm-auto">
                          <label className="" htmlFor="autoSizingSelect">
                            Bank
                          </label>
                          <InputGroup>
                            <select
                              defaultValue="0"
                              className="form-select"
                              onChange={handleState}
                              name="bank_name"
                              value={state.bank_name}
                            >
                              <option value={""}>Select bank ...</option>
                              {bank.length > 0 ? (
                                bank
                                  .filter(e => !!e)
                                  .map((e, index) => (
                                    <option key={index} value={e.bank_name}>
                                      {e.bank_name} ({e.bank_code})
                                    </option>
                                  ))
                              ) : (
                                <option value="" disabled>
                                  No Data Found
                                </option>
                              )}
                            </select>
                          </InputGroup>
                        </div>
                      </Col>
                      <Col xl={3}>
                        <div className="col-sm-auto">
                          <label className="" htmlFor="autoSizingSelect">
                            Project
                          </label>
                          <InputGroup>
                            <select
                              defaultValue="0"
                              className="form-select"
                              onChange={handleState}
                              name="project_name"
                              value={state.project_name}
                            >
                              <option value={""}>Select project ...</option>
                              {projects.length > 0 ? (
                                projects
                                  .filter(e => !!e)
                                  .map((e, index) => (
                                    <option key={index} value={e.project_name}>
                                      {e.project_name} ({e.project_code})
                                    </option>
                                  ))
                              ) : (
                                <option value="" disabled>
                                  No project found
                                </option>
                              )}
                            </select>
                          </InputGroup>
                        </div>
                      </Col>
                      <Col xl={3}>
                        <Button
                          className="btn btn-primary mt-4"
                          color="primary"
                          onClick={handleFilter}
                        >
                          Filter
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </CardTitle>
              </Card>
            </Col>
          </Row>
          {filterDiv && (
            <Row>
              <Col xl={12}>
                <Card>
                  <CardBody>
                    <div className="invoice-title">
                      {/* <h4 className="float-end font-size-16">
                      Order # {orderNo}
                    </h4> */}
                      <div className="mb-4">
                        <img
                          src={logo}
                          alt="logo-dark"
                          className="logo-dark-element"
                          height="80"
                        />
                      </div>
                    </div>
                    {/* <hr /> */}
                    <Row className="mb-3">
                      <Col xl={12}>
                        <h3 className="text-center">Pham Enterprises</h3>
                        <h5 className="text-center">Bank Payments</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="6">
                        <address>
                          {state.bank_name && (
                            <>
                              <strong>Filter Bank : </strong> {state.bank_name}
                            </>
                          )}
                          <br />
                          {state.project_name && (
                            <>
                              <strong>Filter Project : </strong>{" "}
                              {state.project_name}
                            </>
                          )}
                        </address>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={12}>
                        <table>
                          <thead
                            style={{
                              border: "1px solid black",
                              borderCollapse: "-moz-initial",
                            }}
                          >
                            <tr className="black-head">
                              <th>V.ID</th>
                              <th>Date</th>
                              <th>Bank</th>
                              <th>Ref. No</th>
                              <th>Mode</th>
                              <th>Nominal Account</th>
                              <th>Projects</th>
                              <th>Detail</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {payments.length > 0 ? (
                              payments.map(item => (
                                <tr key={item.id}>
                                  <td>
                                    <Link
                                      to={`/bank/control/bank_payment/view/${item.trans_id}`}
                                    >
                                      {item.trans_id}
                                    </Link>
                                  </td>
                                  <td>{item.trans_date}</td>
                                  <td>
                                    {item.bank_name}({item.bank_code})
                                  </td>
                                  <td>{item.trans_ref_no}</td>
                                  <td>{item.trans_mode}</td>
                                  <td>{item.trans_nl_acc}</td>
                                  <td>
                                    {item.project_name}({item.project_code})
                                  </td>
                                  <td>{item.trans_detail}</td>
                                  <td>{formatAmount(item.trans_amount)}</td>
                                </tr>
                              ))
                            ) : (
                              <>
                                <tr>
                                  <td colSpan="9" className="border-0">
                                    <h6 className="text-center mt-2 mb-2">
                                      No Transactions Found{" "}
                                    </h6>
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                          <tbody>
                            <tr
                              style={{
                                border: "1px solid black",
                              }}
                            ></tr>
                            <tr>
                              <td colSpan={8}>
                                <b>Total</b>
                              </td>
                              <td>
                                <b> {formatAmount(totalamount)}</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="d-print-none">
                          <div className="float-end">
                            <Link
                              onClick={handlePrint}
                              className="btn btn-success  me-2"
                            >
                              <i className="fa fa-print" />
                            </Link>
                            {/* <Link to="#" className="btn btn-primary w-md ">
                        Send
                      </Link> */}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <div
            ref={printRef}
            className="paymentPrint"
            style={{ display: "none" }}
          >
            <Row>
              <Col xl={12}>
                <div className="invoice-title">
                  <div className="mb-4">
                    <img
                      src={logo}
                      alt="logo-dark"
                      className="logo-dark-element"
                      height="80"
                    />
                  </div>
                </div>
                <hr />
                <Row>
                  <Col xl={12}>
                    <h3 className="text-center">Pham Enterprises</h3>
                    <h5 className="text-center">Bank Payments</h5>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col xl={12}>
                    <table className="table-bordered border-black mt-2">
                      <thead>
                        <tr className="black-head">
                          <th>V.ID</th>
                          <th>Date</th>
                          <th>Bank</th>
                          <th>Ref. No</th>
                          <th>Mode</th>
                          <th>Nominal Account</th>
                          <th>Projects</th>
                          <th>Detail</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {payments.length > 0 ? (
                          payments
                            .filter(e => !!e)
                            .map((item, index) => (
                              <tr key={item.id}>
                                <td>{item.trans_id}</td>
                                <td>{item.trans_date}</td>
                                <td>{item.bank_name}</td>
                                <td>{item.trans_ref_no}</td>
                                <td>{item.trans_mode}</td>
                                <td>{item.trans_nl_acc}</td>
                                <td>{item.project_name}</td>
                                <td>{item.trans_detail}</td>
                                <td>{formatAmount(item.trans_amount)}</td>
                              </tr>
                            ))
                        ) : (
                          <tr>
                            <td colSpan="9" className="border-0">
                              <h6 className="text-center mt-2 mb-2">
                                No Transactions Found{" "}
                              </h6>
                            </td>
                          </tr>
                        )}
                        <tr style={{ border: "1px solid black" }}></tr>
                        <tr
                          style={{
                            border: "1px solid black",
                            // borderTop: "1px solid black",
                          }}
                        >
                          <td colSpan="8" className="border-0">
                            <strong>Total</strong>
                          </td>
                          <td className="border-0">
                            <strong>{formatAmount(totalamount)}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
                <br />
              </Col>
            </Row>
            <div className="mt-5 mb-5">
              <Row>
                <Col sm={2}>
                  <div
                    style={{
                      borderTop: "1.5px solid black",
                      width: "100%",
                    }}
                  ></div>
                  <div className="text-center">
                    <b>Prepared By</b>
                  </div>
                </Col>
                <Col sm={2}>
                  <div
                    style={{
                      borderTop: "1.5px solid black",
                      width: "100%",
                    }}
                  ></div>
                  <div className="text-center">
                    <b>Reviewed By</b>
                  </div>
                </Col>
                <Col sm={2}>
                  <div
                    style={{
                      borderTop: "1.5px solid black",
                      width: "100%",
                    }}
                  ></div>
                  <div className="text-center">
                    <b>Approved By</b>
                  </div>
                </Col>
                <Col sm={2}>
                  <div
                    style={{
                      borderTop: "1.5px solid black",
                      width: "100%",
                    }}
                  ></div>
                  <div className="text-center">
                    <b>Received By</b>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col xl="12">
                  <div style={{ backgroundColor: "blue", padding: "10px" }}>
                    <img
                      src={`${apiUrl}/uploads/images/company_stamp.jpg`}
                      width="15%"
                      height="15%"
                    />

                    <p style={{ color: "white", marginTop: "5px" }}>
                      MUHAMMAD SUFYAN <br />
                      0092-307-572 7380 <br />
                      msufyan2011@gmail.com
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

BankPaymentReport.propTypes = {
  match: PropTypes.any,
}

export default withRouter(BankPaymentReport)
