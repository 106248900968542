import React, { useEffect, useState, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import Select from "react-select"
import "../../../assets/style/style.css"
import apiUrl from "config"
import logo from "assets/images/Pham logo black text.png"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Collapse,
  Table,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  colspan,
  Modal,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  ToastHeader,
} from "reactstrap"
//Date Picker
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import classnames from "classnames"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import { formatAmount } from "components/Functoins/functions"

const CreatePurchaseBill = props => {
  //meta title
  document.title = "Create Purchase Bill"

  const iconRef = useRef(null)
  const iconRef2 = useRef(null)

  const navigate = useNavigate()

  const [subTotalAmount, setSubTotalAmount] = useState(0)
  const [FinalTotalAmount, setFinalTotalAmount] = useState(0)
  const [Tax_amount, setTax_amount] = useState(0)

  const [TotalExpenseAmount, setTotalExpenseAmount] = useState(0)

  const [loading, setloading] = useState(false)

  const [expenses, setExpenses] = useState([])
  const [products, setProducrts] = useState([])
  const [totalamount, setTotalamount] = useState("")
  const [projects, setProjects] = useState([])

  const [projectModal, setProjectModal] = useState(false)
  const [modeModal, setModeModal] = useState(false)
  const [bankModal, setBankModal] = useState(false)
  const [ExpenseModal, setExpenseModal] = useState(false)

  const [bank, setBank] = useState([])
  const [pay_mode, setPaymode] = useState([])
  //accordion
  const [col1, setcol1] = useState(true)
  const [col2, setcol2] = useState(true)
  const [col3, setcol3] = useState(true)
  const [col4, setcol4] = useState(true)

  const t_col1 = () => {
    setcol1(!col1)
  }
  const t_col2 = () => {
    setcol2(!col2)
  }
  const t_col3 = () => {
    setcol3(!col3)
  }
  const t_col4 = () => {
    setcol4(!col4)
  }

  //Supplier Datail Accordion
  const [formData, setFormData] = useState({
    payment_date_: new Date().toISOString().substr(0, 10),
    bank_name: "",
    project_name: "",
    payment_mode: "",
    payment_ref_no: "",
    payment_amount: "",
    date_: new Date().toISOString().substr(0, 10),
    bill_no: "PHAM-B-",
    cred_limt: "",
    balance_: "",
    final_notes: "",
    bill_pay_by: "",
    discount_Amount: "",
    tax_percentage: "",
  })
  const handleChange = e => {
    const { name, value } = e.target
    if (value === "add_mode") {
      addMode()
    } else if (value === "add_bank") {
      addBankAcc()
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
      }))
    }

    if (name === "discount_Amount") {
      const Amount = parseFloat(value)
      if (Amount >= subTotalAmount) {
        toastr.error("Discount Amount is less than Sub Total")
      } else {
        setFormData(prevData => ({
          ...prevData,
          discount_Amount: value,
        }))
      }
    }
  }

  //Expense Detail
  const initialData2 = [
    {
      id2: 1,
      expense_: "",
      expense_desc: "",
      expense_amount: "",
    },
  ]
  const [data2, setData2] = useState(initialData2)
  const handleEdit2 = (id, field, value) => {
    if (value === "add_expense") {
      AddExpense()
    } else {
      setData2(prevData =>
        prevData.map(item =>
          item.id2 === id
            ? {
                ...item,
                [field]: value,
              }
            : item
        )
      )
    }
  }
  const handleAddRow2 = () => {
    const newId2 = data2.length + 1
    const newRow2 = {
      id2: newId2,
      expense_: "",
      expense_desc: "",
      expense_amount: "",
    }
    setData2(prevData => [...prevData, newRow2])
  }
  const deleteRow2 = id => {
    const updatedData2 = data2.filter(item => item.id2 !== id)
    setData2(updatedData2)
  }

  //fetchExpenseDetail
  const fetchExpense = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const data = new FormData()
    data.append("app_method", "fetch_expense_det")

    axios
      .post(URL, data)
      .then(response => {
        if (Array.isArray(response.data)) {
          setExpenses(response.data)
        } else {
          console.log("error in fethc expense detail")
        }
      })
      .catch(error => console.log(error))
  }
  useEffect(() => {
    fetchExpense()
  }, [])

  useEffect(() => {
    let sumAmount = 0
    let sumExpenRate = 0

    let Discount = 0
    let FTotalAmount = 0
    let TaxPercentage = 0
    let TaxAmount = 0

    data2.forEach(item => {
      const amount2 = parseFloat(item.expense_amount) || 0
      sumAmount += amount2
      sumExpenRate += amount2
    })

    // Initialize final total amount with the sum amount
    FTotalAmount = sumAmount

    // Apply discount if provided
    if (formData.discount_Amount !== null && formData.discount_Amount !== "") {
      const discountValue = parseFloat(formData.discount_Amount)
      Discount = discountValue
      FTotalAmount -= Discount
    }

    // Apply tax if provided
    if (formData.tax_percentage !== null && formData.tax_percentage !== "") {
      TaxPercentage = parseFloat(formData.tax_percentage) || 0
      TaxAmount = FTotalAmount * (TaxPercentage / 100)
      FTotalAmount += TaxAmount
    }

    setSubTotalAmount(sumAmount)
    setFinalTotalAmount(FTotalAmount)
    setTax_amount(TaxAmount)

    setTotalExpenseAmount(sumExpenRate)
  }, [data2, formData.discount_Amount, formData.tax_percentage])

  //Submit Data
  const [BtnDisabled, setBtnDisabled] = useState(false)
  const SubmitPurchaseBillData = async (e, isPrint) => {
    if (data2.length === 0) {
      toastr.error("Set Some Expense / Product")
    } else if (formData.project_name === "") {
      toastr.error("Set  Project")
    } else if (formData.date_ === "") {
      toastr.error("Set Date")
    } else if (!data2.every(row => row.expense_.length > 0)) {
      toastr.error("Set Expense")
    } else if (!data2.every(row => row.expense_amount.length > 0)) {
      toastr.error("Set Expense Amount")
    } else if (formData.bank_name === "") {
      toastr.error("Set Bank")
    } else if (formData.payment_mode === "") {
      toastr.error("Set Pay Mode")
    } else if (formData.payment_amount === "") {
      toastr.error("Set Some Pay Amount")
    } else if (formData.payment_amount > subTotalAmount) {
      toastr.error("Pay Amount is less then or equal to Total Amount")
    } else if (formData.discount_Amount >= subTotalAmount) {
      toastr.error("Discount Amount is less than Sub Total")
    } else {
      setBtnDisabled(true)
      e.preventDefault()
      const URl = `${apiUrl}/purchases/addPurchases.php`
      const formdata = new FormData()

      //expense detail
      data2.forEach(row2 => {
        formdata.append("id2[]", row2.id2)
        formdata.append("expense_name[]", row2.expense_)
        formdata.append("expense_desc[]", row2.expense_desc)
        formdata.append("expense_amount[]", row2.expense_amount)
      })
      //payment detail
      formdata.append("payment_date", formData.payment_date_)
      formdata.append("payBank", formData.bank_name)
      formdata.append("payMode", formData.payment_mode)
      formdata.append("payment_ref_no", formData.payment_ref_no)
      formdata.append("bill_pay_by", formData.bill_pay_by)
      formdata.append("payment_amount", formData.payment_amount)
      //supplier detail
      formdata.append("project_name", formData.project_name)
      formdata.append("purchase_date", formData.date_)
      formdata.append(
        "discount_amount",
        formData.discount_Amount !== "" ? formData.discount_Amount : "0"
      )
      formdata.append(
        "tax_percentage",
        formData.tax_percentage !== "" ? formData.tax_percentage : "0"
      )
      formdata.append("final_notes", formData.final_notes)

      formdata.append("total_expense_amount", TotalExpenseAmount)

      formdata.append("tax_amount", Tax_amount !== "" ? Tax_amount : "0")
      formdata.append("sub_total", subTotalAmount)
      formdata.append("total_amount", FinalTotalAmount)
      formdata.append("app_method", "add_purchase_bill")

      try {
        const response = await axios.post(URl, formdata)
        if (response.data.message === "success") {
          if (isPrint) {
            // Open print modal with returned ID
            handleEmail(response.data.print_Id) // Assuming response contains ID
          } else {
            toastr.success("Add Successfully")
            navigate("/expenses/control/bills")
          }
        } else {
          toastr.error("Error in Sumbittion")
        }
      } catch (error) {
        console.log(error)
      } finally {
        setBtnDisabled(false)
      }
    }
  }

  //Bank
  const fetchBank = async () => {
    const URL = `${apiUrl}/BankManagemnt/addbank.php`
    const Data = new FormData()
    Data.append("app_method", "fetch_bank")

    try {
      setloading(true)
      const response = await axios.post(URL, Data)
      if (Array.isArray(response.data)) {
        setBank(response.data)
      } else {
        console.log("error")
      }
    } catch (error) {
      console.log(error)
    } finally {
      setloading(false)
    }
  }
  //Pay Mode
  const fetchPayMode = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const pm = new FormData()
    pm.append("app_method", "fetch_pay_mode")

    axios
      .post(URL, pm)
      .then(response => {
        if (response.data) {
          setPaymode(response.data)
        } else {
          console.log("error")
          console.log(response.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  //Projects
  const fetchProject = () => {
    const URL = `${apiUrl}/Projects/projects.php`
    const Pdata = new FormData()
    Pdata.append("app_method", "fetch_all_projects")

    axios
      .post(URL, Pdata)
      .then(response => {
        if (Array.isArray(response.data)) {
          setProjects(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    fetchBank()
    fetchPayMode()
    fetchProject()
  }, [])

  //Project Add Modal
  const [formData3, setFormData3] = useState({
    project_name: "",
    project_code: "",
    project_detail: "",
    method: "add_project_detail",
  })
  const onChangeModal = e => {
    const { name, value } = e.target
    setFormData3(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  const handleProject = e => {
    e.preventDefault()
    if (formData3.project_name === "") {
      toastr.error("Project name is Required")
    } else {
      axios
        .post(`${apiUrl}/Projects/projects.php`, formData3)
        .then(response => {
          if (response.data === "success") {
            fetchProject()
            toastr.success("Submit Successfully")
            setProjectModal(!projectModal)
            setFormData3(prevData => ({
              ...prevData,
              project_name: "",
              project_code: "",
              project_detail: "",
            }))
            setFormData(pd => ({
              ...pd,
              project_name: formData3.project_name,
            }))
          } else {
            toastr.error(response.data)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
  const addProject = () => {
    setProjectModal(!projectModal)
  }

  //Bank Add Modal
  const [bankdata, setbankdata] = useState({
    bank_name: "",
    bank_code: "",
    bank_disc: "",
    method: "add_bank_detial",
  })
  const onChangeModal2 = e => {
    const { name, value } = e.target
    setbankdata(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  const handleBankAccount = e => {
    const URL = `${apiUrl}/BankManagemnt/addbank.php`
    const data = new FormData()
    data.append("bank_name", bankdata.bank_name)
    data.append("bank_disc", bankdata.bank_disc)
    data.append("app_method", "add_bank_detail")

    e.preventDefault()
    if (bankdata.bank_name === "") {
      toastr.error("Bank name is Required")
    } else {
      axios
        .post(URL, data)
        .then(response => {
          if (response.data === "success") {
            setBankModal(!bankModal)
            fetchBank()
            toastr.success("Submit Successfully")
            setbankdata({
              bank_name: "",
              bank_code: "",
              bank_disc: "",
            })
            setFormData(pd => ({
              ...pd,
              bank_name: bankdata.bank_name,
            }))
            // window.location.reload()
          } else {
            toastr.error(response.data)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
  const addBankAcc = () => {
    setBankModal(!bankModal)
  }

  //Add Mode
  const [mode, setMode] = useState("")
  const handleMode = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const modedata = new FormData()
    modedata.append("mode_name", mode)
    modedata.append("app_method", "payment_mode")
    if (mode === "") {
      toastr.error("Mode name is Required")
    } else {
      axios
        .post(URL, modedata)
        .then(response => {
          if (response.data === "success") {
            setModeModal(!modeModal)
            fetchPayMode()
            setMode("")
            setFormData(pd => ({
              ...pd,
              payment_mode: mode,
            }))
            toastr.success("Add Successfully")
          } else {
            toastr.error("Error in Adding")
          }
        })
        .catch(error => console.log(error))
    }
  }
  const addMode = () => {
    setModeModal(!modeModal)
  }

  //Add Expense Modal
  const [expense_name, setExpense_name] = useState("")
  const [expense_code, setExpense_code] = useState("")
  const handleExpense = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const addExpense = new FormData()
    addExpense.append("expense_name", expense_name)
    addExpense.append("expense_code", expense_code)
    addExpense.append("app_method", "add_expense_detail")
    if (expense_name === "") {
      toastr.error("Expense Name is Required")
    } else {
      axios
        .post(URL, addExpense)
        .then(response => {
          if (response.data === "success") {
            setExpenseModal(!ExpenseModal)
            fetchExpense()
            setExpense_name("")
            setExpense_code("")
            toastr.success("Add Successfully")
          } else {
            toastr.error("Error in Adding")
          }
        })
        .catch(error => console.log(error))
    }
  }
  const AddExpense = () => {
    setExpenseModal(!ExpenseModal)
  }

  //Close
  const close = () => {
    navigate("/expenses/control/bills")
  }

  //Textarea lenght
  const [textareaHeights, setTextareaHeights] = useState({})

  const handleTextareaChange = (id, value) => {
    const textarea = document.getElementById(`textarea-${id}`)
    if (textarea) {
      textarea.style.height = "auto"
      textarea.style.height = textarea.scrollHeight + "px"
      setTextareaHeights(prevHeights => ({
        ...prevHeights,
        [id]: textarea.scrollHeight,
      }))
    }
  }

  // Function to handle "Save & Close" button click
  const handleSaveAndClose = e => {
    SubmitPurchaseBillData(e, false) // Call SubmitSaleData function with isPrint as false
  }
  // Function to handle "Save & Print" button click
  const handleSaveAndPrint = e => {
    SubmitPurchaseBillData(e, true) // Call SubmitSaleData function with isPrint as true
  }

  //
  const handleClickOutside = event => {
    if (iconRef.current && !iconRef.current.contains(event.target)) {
      setDiscountMode(false)
    }
    if (iconRef2.current && !iconRef2.current.contains(event.target)) {
      setTaxMode(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  //Set Discount
  const [discountMode, setDiscountMode] = useState(false)
  const setDiscount = () => {
    setDiscountMode(!discountMode)
  }

  //Set Tax
  const [taxMode, setTaxMode] = useState(false)
  const setTax = () => {
    setTaxMode(!taxMode)
  }

  if (loading) {
    return (
      <div className="spinner-container">
        <img src={logo} alt="Loading" className="spinner-logo" />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="10">
              <div>
                <h5 className="mt-2">Bill Detail</h5>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <div className="accordion" id="accordion">
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col1,
                      })}
                      type="button"
                      onClick={t_col1}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Project Detail
                    </button>
                  </h2>

                  <Collapse isOpen={col1} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row>
                        <Col xl={4}>
                          <div className="mb-3">
                            <Label
                              className="control-label"
                              htmlFor="project_name"
                            >
                              Project
                            </Label>
                            <InputGroup>
                              <select
                                defaultValue="0"
                                className="form-select"
                                onChange={handleChange}
                                name="project_name"
                                value={formData.project_name}
                              >
                                <option value={""}>Select ...</option>

                                {projects.length > 0 ? (
                                  projects
                                    .filter(e => !!e)
                                    .map((e, index) => (
                                      <option
                                        key={index}
                                        value={e.project_name}
                                      >
                                        {e.project_name} ({e.project_code})
                                      </option>
                                    ))
                                ) : (
                                  <option value="" disabled>
                                    No Data Found
                                  </option>
                                )}
                              </select>
                              <div className="input-group-append">
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={addProject}
                                >
                                  <i className="mdi mdi-plus" />
                                </Button>
                              </div>
                            </InputGroup>
                          </div>
                        </Col>
                        <Col xl={4}>
                          <div className="mb-3">
                            <label>Date</label>
                            <Input
                              className="form-control"
                              type="date"
                              //   placeholder="Payment Mode"
                              name="date_"
                              value={formData.date_}
                              onChange={handleChange}
                            ></Input>
                          </div>
                        </Col>
                        <Col xl={4}>
                          <div className="mb-3">
                            <label>Bill No.</label>
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="bill no."
                              name="bill_no"
                              value={formData.bill_no}
                              onChange={handleChange}
                              disabled
                            ></Input>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </div>
                {/* Expense Detail  */}
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne2">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col2,
                      })}
                      type="button"
                      onClick={t_col2}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Expense Detail
                    </button>
                  </h2>

                  <Collapse isOpen={col2} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row className="mb-3">
                        <Col xl={12}>
                          <table>
                            <thead>
                              <tr>
                                <th>Expense</th>
                                <th>{""}</th>
                                <th>{""}</th>
                                <th>Description</th>
                                <th>{""}</th>
                                <th>Amount</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data2.map(item => (
                                <tr key={item.id}>
                                  <td style={{ display: "none" }}>{item.id}</td>
                                  <td colSpan={3}>
                                    <select
                                      className="form-select"
                                      type="text"
                                      value={item.expense_}
                                      onChange={e =>
                                        handleEdit2(
                                          item.id2,
                                          "expense_",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">Select...</option>
                                      <option
                                        value="add_expense"
                                        style={{ color: "blue" }}
                                      >
                                        + Add New
                                      </option>

                                      {expenses.length > 0 ? (
                                        expenses
                                          .filter(e => !!e)
                                          .map((e, index) => (
                                            <option
                                              key={index}
                                              value={e.expense_name}
                                            >
                                              {e.expense_name}
                                            </option>
                                          ))
                                      ) : (
                                        <option>No Data Found</option>
                                      )}
                                    </select>
                                  </td>

                                  <td colSpan={2}>
                                    <textarea
                                      type="text"
                                      placeholder="discription"
                                      value={item.expense_desc}
                                      id={`textarea-${item.id2}`}
                                      style={{
                                        height:
                                          textareaHeights[item.id2] || "auto",
                                      }}
                                      onChange={e => {
                                        handleEdit2(
                                          item.id2,
                                          "expense_desc",
                                          e.target.value
                                        )
                                        handleTextareaChange(
                                          item.id2,
                                          e.target.value
                                        )
                                      }}
                                    >
                                      {item.expense_desc}
                                    </textarea>
                                  </td>

                                  <td>
                                    <input
                                      type="text"
                                      value={data2.expense_amount}
                                      placeholder="0.00"
                                      onChange={e =>
                                        handleEdit2(
                                          item.id2,
                                          "expense_amount",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <i
                                      className="mdi mdi-delete font-size-18"
                                      onClick={() => deleteRow2(item.id2)}
                                      style={{
                                        color: "#F46A6A",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>

                            <tbody>
                              <thead></thead>
                              <tr
                                style={{
                                  borderTop: "1px solid black",
                                }}
                              >
                                <td colSpan={3}>
                                  <strong>Total</strong>
                                </td>
                                <td colSpan={2}>
                                  <div></div>
                                </td>

                                <td>
                                  <input
                                    type="text"
                                    value={formatAmount(TotalExpenseAmount)}
                                    disabled
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="my-3">
                            <Button
                              onClick={handleAddRow2}
                              color="primary"
                              className="btn btn-sm"
                            >
                              <i className="fa fa-fw fa-plus" />
                              Add Row
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </div>

                <Card>
                  <CardBody>
                    <Row>
                      <Col xl={4}>
                        <label>Notes</label>
                        <Input
                          className="form-control"
                          type="textarea"
                          placeholder="Notes"
                          name="final_notes"
                          rows="7"
                          value={formData.final_notes}
                          onChange={handleChange}
                        ></Input>
                      </Col>
                      <Col xl={4}></Col>
                      <Col xl={4}>
                        <Table className="table-nowrap">
                          <thead>
                            <tr>
                              <td className="">Sub Total</td>
                              <td className="text-end">
                                {formatAmount(subTotalAmount)}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Discount {""}
                                <span>
                                  <i
                                    className="bx bx-edit font-size-18"
                                    style={{
                                      cursor: "pointer",
                                      fontWeight: "bold",
                                    }}
                                    onClick={setDiscount}
                                    ref={iconRef}
                                  />
                                </span>
                              </td>
                              {discountMode ? (
                                <td className="text-end">
                                  <input
                                    type="text"
                                    name="discount_Amount"
                                    placeholder="0.00"
                                    value={formData.discount_Amount}
                                    onChange={handleChange}
                                    ref={iconRef}
                                  />
                                </td>
                              ) : (
                                <td className="text-end">
                                  {formData.discount_Amount !== ""
                                    ? formatAmount(formData.discount_Amount)
                                    : "0.00"}
                                </td>
                              )}
                            </tr>
                            <tr>
                              <td className="">
                                Sale Tax (%)
                                <span>
                                  <i
                                    className="bx bx-edit font-size-18"
                                    style={{
                                      cursor: "pointer",
                                      fontWeight: "bold",
                                    }}
                                    onClick={setTax}
                                    ref={iconRef2}
                                  />
                                </span>
                              </td>
                              {taxMode ? (
                                <td className="text-end">
                                  <input
                                    type="text"
                                    name="tax_percentage"
                                    placeholder="%"
                                    value={formData.tax_percentage}
                                    onChange={handleChange}
                                    ref={iconRef2}
                                  />
                                </td>
                              ) : (
                                <td className="text-end">
                                  {Tax_amount !== ""
                                    ? formatAmount(Tax_amount)
                                    : "0.00"}
                                </td>
                              )}
                            </tr>
                            <tr>
                              <td className="border-0">
                                <strong>Total</strong>
                              </td>
                              <td className="border-0 text-end">
                                <h4 className="m-0">
                                  {formatAmount(FinalTotalAmount)}
                                </h4>
                              </td>
                            </tr>
                          </thead>
                        </Table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne3">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col4,
                      })}
                      type="button"
                      onClick={t_col4}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Add Payment
                    </button>
                  </h2>

                  <Collapse isOpen={col4} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row>
                        <Col xl={2}>
                          <div className="mb-3">
                            <label>Date</label>
                            <Input
                              className="form-control"
                              type="date"
                              //   placeholder="Payment Mode"
                              name="payment_date_"
                              value={formData.payment_date_}
                              onChange={handleChange}
                            ></Input>
                          </div>
                        </Col>
                        <Col xl={2}>
                          <div className="mb-3">
                            <label className="" htmlFor="autoSizingSelect">
                              Bank
                            </label>
                            <select
                              defaultValue="0"
                              className="form-select"
                              onChange={handleChange}
                              name="bank_name"
                              value={formData.bank_name}
                            >
                              <option value={null}>Select bank ...</option>
                              <option
                                value="add_bank"
                                style={{ color: "blue" }}
                              >
                                + Add New
                              </option>
                              {bank.length > 0 ? (
                                bank
                                  .filter(e => !!e)
                                  .map((e, index) => (
                                    <option key={index} value={e.bank_name}>
                                      {e.bank_name} ({e.bank_code})
                                    </option>
                                  ))
                              ) : (
                                <option value="" disabled>
                                  No Data Found
                                </option>
                              )}
                            </select>
                          </div>
                        </Col>
                        <Col xl={2}>
                          <div className="mb-3">
                            <label className="" htmlFor="autoSizingSelet">
                              Payment Mode
                            </label>
                            <select
                              className="form-select"
                              type="text"
                              value={formData.payment_mode}
                              onChange={handleChange}
                              name="payment_mode"
                            >
                              <option value="">Mode...</option>
                              <option
                                value="add_mode"
                                style={{ color: "blue" }}
                              >
                                + Add new
                              </option>
                              {pay_mode.length > 0 ? (
                                pay_mode
                                  .filter(e => !!e)
                                  .map((e, index) => (
                                    <option key={index} value={e.mode_name}>
                                      {e.mode_name}
                                    </option>
                                  ))
                              ) : (
                                <option value="" disabled>
                                  No Data Found
                                </option>
                              )}
                            </select>
                          </div>
                        </Col>
                        <Col xl={2}>
                          <div className="mb-3">
                            <label>Ref No</label>
                            <Input
                              className="form-control"
                              type="text"
                              min={0}
                              placeholder="0"
                              name="payment_ref_no"
                              value={formData.payment_ref_no}
                              onChange={handleChange}
                            ></Input>
                          </div>
                        </Col>
                        <Col xl={2}>
                          <div className="mb-3">
                            <label>Pay By</label>
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="name"
                              name="bill_pay_by"
                              value={formData.bill_pay_by}
                              onChange={handleChange}
                            ></Input>
                          </div>
                        </Col>
                        <Col xl={2}>
                          <div className="mb-3">
                            <label>Amount</label>
                            <Input
                              className="form-control"
                              type="text"
                              min={0}
                              placeholder="0.00"
                              name="payment_amount"
                              value={formData.payment_amount}
                              onChange={handleChange}
                            ></Input>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </div>

                <Row className="mb-3">
                  <Col xl={4}>
                    <Button
                      color="danger"
                      className="btn btn-danger waves-effect waves-light"
                      onClick={close}
                    >
                      Close
                    </Button>
                  </Col>
                  <Col xl={8}>
                    <div className="d-flex flex-wrap  float-end">
                      <Button
                        onClick={handleSaveAndClose}
                        className="btn btn-primary  me-2"
                        color="primary"
                        disabled={BtnDisabled}
                      >
                        {BtnDisabled ? "Loading..." : "Save & Close"}
                      </Button>
                    </div>

                    <div className="d-flex flex-wrap  float-end">
                      <Button
                        onClick={handleSaveAndPrint}
                        className="btn btn-primary  me-2"
                        color="success"
                        disabled={BtnDisabled}
                      >
                        {" "}
                        {BtnDisabled ? "Loading..." : "Save & Print"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={projectModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setProjectModal(!projectModal)
        }}
      >
        <div>
          <Form onSubmit={handleProject}>
            <ModalHeader
              toggle={() => {
                setProjectModal(!projectModal)
              }}
            >
              Project Information
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col className="col-12">
                  <div className="mb-3">
                    <label>Project Name</label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Name"
                      name="project_name"
                      value={formData3.project_name}
                      onChange={onChangeModal}
                    ></Input>
                  </div>
                  <div className="mb-3">
                    <label>Code</label>
                    <Input
                      className="form-control"
                      type="number"
                      placeholder="code"
                      name="project_code"
                      value={formData3.project_code}
                      onChange={onChangeModal}
                      disabled
                    ></Input>
                  </div>
                  <div className="mb-3">
                    <label>Details</label>
                    <Input
                      className="form-control"
                      type="textarea"
                      placeholder="description"
                      name="project_detail"
                      value={formData3.project_detail}
                      onChange={onChangeModal}
                    />
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                  setProjectModal(!projectModal)
                }}
              >
                Close
              </Button>
              <Button type="submit" color="primary">
                Submit
              </Button>
            </ModalFooter>
          </Form>
        </div>
      </Modal>

      <Modal
        isOpen={modeModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setModeModal(!modeModal)
        }}
      >
        <div>
          <Form>
            <ModalHeader
              toggle={() => {
                setModeModal(!modeModal)
              }}
            >
              Add Mode
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col xl={8}>
                  <div className="mb-3">
                    <label>Payment Mode</label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Payment Mode"
                      name="payment_mode"
                      value={mode}
                      onChange={e => setMode(e.target.value)}
                    ></Input>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                  setModeModal(!modeModal)
                }}
              >
                Close
              </Button>
              <Button color="primary" onClick={handleMode}>
                Submit
              </Button>
            </ModalFooter>
          </Form>
        </div>
      </Modal>

      <Modal
        isOpen={bankModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setBankModal(!bankModal)
        }}
      >
        <div>
          <Form>
            <ModalHeader
              toggle={() => {
                setBankModal(!bankModal)
              }}
            >
              Bank Account New
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col className="col-12">
                  <div className="mb-3">
                    <label>Bank Account *</label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Name"
                      name="bank_name"
                      value={bankdata.bank_name}
                      onChange={onChangeModal2}
                    ></Input>
                  </div>
                  <div className="mb-3">
                    <label>Code</label>
                    <Input
                      className="form-control"
                      type="number"
                      placeholder="code"
                      name="bank_code"
                      value={bankdata.bank_code}
                      onChange={onChangeModal2}
                      disabled
                    ></Input>
                  </div>
                  <div className="mb-3">
                    <label>Description</label>
                    <Input
                      className="form-control"
                      type="textarea"
                      placeholder="description"
                      name="bank_disc"
                      value={bankdata.bank_disc}
                      onChange={onChangeModal2}
                    />
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                  setBankModal(!bankModal)
                }}
              >
                Close
              </Button>
              <Button type="submit" color="primary" onClick={handleBankAccount}>
                Submit
              </Button>
            </ModalFooter>
          </Form>
        </div>
      </Modal>
      <Modal
        isOpen={ExpenseModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setExpenseModal(!ExpenseModal)
        }}
      >
        <div>
          <Form>
            <ModalHeader
              toggle={() => {
                setExpenseModal(!ExpenseModal)
              }}
            >
              Add Expense
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col xl={8}>
                  <div className="mb-3">
                    <label>Expense Name</label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="name"
                      name="expense_name"
                      value={expense_name}
                      onChange={e => setExpense_name(e.target.value)}
                    ></Input>
                  </div>
                  <div className="mb-3">
                    <label>Expense Code</label>
                    <Input
                      className="form-control"
                      type="number"
                      placeholder="code"
                      name="expense_code"
                      value={expense_code}
                      onChange={e => setExpense_code(e.target.value)}
                      disabled
                    ></Input>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                  setExpenseModal(!ExpenseModal)
                }}
              >
                Close
              </Button>
              <Button color="primary" onClick={handleExpense}>
                Submit
              </Button>
            </ModalFooter>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default CreatePurchaseBill
