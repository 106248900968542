import React, { useEffect, useState, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import Select from "react-select"
import "../../../assets/style/style.css"
import apiUrl from "config"
import logo from "assets/images/Pham logo black text.png"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Collapse,
  Table,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  colspan,
  Modal,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
} from "reactstrap"
//Date Picker
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import classnames from "classnames"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import { esMX } from "handsontable/i18n"
import { formatAmount } from "components/Functoins/functions"
import SocialModal from "components/SocialControl/Social"

const CreateSale = props => {
  //meta title
  document.title = "Create Order"
  const iconRef = useRef(null)
  const iconRef2 = useRef(null)
  const navigate = useNavigate()

  const [totalQuantity, setTotalQuantity] = useState(0)
  const [subTotalAmount, setSubTotalAmount] = useState(0)
  const [FinalTotalAmount, setFinalTotalAmount] = useState(0)
  const [Tax_amount, setTax_amount] = useState(0)

  const [loading, setloading] = useState(false)

  const [customers, setCustomers] = useState([])
  const [products, setProducrts] = useState([])
  const [accountModal, setAccountModal] = useState(false)
  const [custOpenBlnc, setCustOpenBlnc] = useState([])
  const [custCreditBlnc, setCustCreditBlnc] = useState([])
  const [idincremental, setIdincremental] = useState(0)
  const [total, setTotal] = useState()
  const [projects, setProjects] = useState([])
  const [projectModal, setProjectModal] = useState(false)

  //Handle Email
  const [socialModal, setSocialModal] = useState(false)
  const [pdfUrl, setPdfUrl] = useState("")

  //Modal Mail Data
  const [ModalMailData, setModalMailData] = useState({
    from_mail: "",
    to_mail: "",
    mail_subj: "",
    email_message:
      "<p>Please find attached confirmation for your Order.<br>Thank you for your business.<br>Best Regards.<br><strong>Pham Enterprises</strong> ",
  })
  const handleModalMailData = e => {
    const { name, value } = e.target
    setModalMailData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  //Eamil
  const [EmailMode, setEmailMode] = useState(false)
  const handleEmail = id => {
    const URL = `${apiUrl}/sales/orders.php`
    const post = new FormData()
    post.append("order_id", id)
    post.append("app_method", "fetch_order_byId")
    axios
      .post(URL, post)
      .then(response => {
        if (Array.isArray(response.data)) {
          setSocialModal(!socialModal)
          setPdfUrl(response.data[0].pdf_url)
          setEmailMode(true)
        } else {
          console.log(response.data)
        }
      })
      .catch(error => console.log(error))
  }
  const SendEmail = () => {
    if (ModalMailData.to_mail === "") {
      toastr.error("Sent Email is Required")
    } else {
      const data = new FormData()
      data.append("from_mail", ModalMailData.from_mail)
      data.append("to_mail", ModalMailData.to_mail)
      data.append("mail_subj", ModalMailData.mail_subj)
      data.append("email_message", ModalMailData.email_message)
      data.append("pdf_url", pdfUrl)
      data.append("app_method", "send_email")
      const URL = `${apiUrl}/socialaccounts/emailhandle.php`
      axios
        .post(URL, data)
        .then(response => {
          if (response.data.message === "success") {
            toastr.success("Email Sent Successfully")
            setSocialModal(!socialModal)
            navigate("/income/sales/quotations")
          } else {
            // Handle case where response.data is not an array
            console.error("Response data is not an array.")
          }
        })
        .catch(error => console.log(error))
    }
  }

  //Toggle
  const toggleModal = () => {
    setSocialModal(!socialModal)
    navigate("/income/sales/quotations")
  }

  const [formData, setFormData] = useState({
    cust_name: "",
    project_name: "",
    address_: "",
    date_: new Date().toISOString().substr(0, 10),
    term_days: "",
    due_date: new Date().toISOString().substr(0, 10),
    doc_no: "PHAM-",
    cred_limt: "",
    balance_: "",
    final_notes: "",
    discount_Amount: "",
    tax_percentage: "",
  })
  const handleChange = e => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))

    if (name === "term_days") {
      const termDays = parseInt(value)
      const today = new Date(formData.date_)
      const dueDate = new Date(
        today.getTime() + parseInt(value) * 24 * 60 * 60 * 1000
      )
      if (!isNaN(termDays) && termDays > 0) {
        setFormData({
          ...formData,
          due_date: dueDate.toISOString().substr(0, 10),
          term_days: value,
        })
      } else {
        setFormData({
          ...formData,
          due_date: new Date().toISOString().substr(0, 10),
          term_days: value,
        })
      }
    }

    if (name === "discount_Amount") {
      const Amount = parseFloat(value)
      if (Amount >= subTotalAmount) {
        toastr.error("Discount Amount is less than Sub Total")
      } else {
        setFormData(prevData => ({
          ...prevData,
          discount_Amount: value,
        }))
      }
    }
  }

  const handleChangeCustomer = selectedOption => {
    if (selectedOption && selectedOption.value) {
      setFormData(prevData => ({
        ...prevData,
        cust_name: selectedOption.value,
      }))
    }
  }
  const [col1, setcol1] = useState(true)
  const [col2, setcol2] = useState(true)

  const t_col1 = () => {
    setcol1(!col1)
  }
  const t_col2 = () => {
    setcol2(!col2)
  }

  //Row Data
  const initialData = [
    {
      id: 1,
      product_: "",
      prd_desc: "",
      prd_unit: "",
      prd_qty: "",
      prd_rate: "",
      prd_amount: "0.00",
      discount_per: "",
      discount_: "",
      tax_rate: "",
      sale_tex_: "",
      net_rate: "",
      prd_code: "",
    },
  ]
  const [data, setData] = useState(initialData)
  const handleEdit = (id, field, value) => {
    setData(prevData =>
      prevData.map(item => {
        if (item.id === id) {
          return {
            ...item,
            [field]: value,
            prd_amount:
              field === "prd_qty" || field === "prd_rate"
                ? Number(
                    parseFloat(value !== "" ? value : "0") *
                      parseFloat(item.prd_rate !== "" ? item.prd_rate : "0")
                  )
                : item.prd_amount,
          }
        } else {
          return item
        }
      })
    )
    if (field === "product_") {
      fetchProductDetail(value, id)
    }
  }
  const handleEdit2 = (id, field, value) => {
    setData(prevData =>
      prevData.map(item => {
        if (item.id === id) {
          return {
            ...item,
            [field]: value,
            prd_amount:
              field === "prd_qty" || field === "prd_rate"
                ? Number(
                    parseFloat(item.prd_qty !== "" ? item.prd_qty : "0") *
                      parseFloat(value !== "" ? value : "0")
                  )
                : item.prd_amount,
          }
        } else {
          return item
        }
      })
    )
  }
  const handleAddRow = () => {
    const newId = data.length + 1
    setIdincremental(newId)
    const newRow = {
      id: newId,
      product_: "",
      prd_desc: "",
      prd_unit: "",
      prd_qty: "",
      prd_rate: "",
      prd_amount: "0.00",
      discount_per: "",
      discount_: "",
      tax_rate: "",
      sale_tex_: "",
      net_rate: "",
      prd_code: "",
    }
    setData(prevData => [...prevData, newRow])
  }
  const deleteRow = id => {
    const updatedData = data.filter(item => item.id !== id)
    setData(updatedData)
  }
  const ResetTotalAmount = id => {
    setData(prevData => {
      return prevData.map(item =>
        item.id === id
          ? {
              ...item,
              prd_amount: Number(
                parseFloat(item.prd_qty !== "" ? item.prd_qty : "0") *
                  parseFloat(item.prd_rate !== "" ? item.prd_rate : "0")
              ),
            }
          : item
      )
    })
  }

  //Customer
  const fetchCustomers = () => {
    const URL = `${apiUrl}/sales/customer.php`
    const Data = new FormData()

    Data.append("app_method", "fetch_customers")
    axios
      .post(URL, Data)
      .then(response => {
        if (Array.isArray(response.data)) {
          setCustomers(response.data)
        } else {
          console.log("Error in fetch customers")
        }
      })
      .catch(error => console.log(error))
  }
  const fetchCustomerDetail = custName => {
    const URL = `${apiUrl}/sales/customer.php`
    const Data = new FormData()

    Data.append("app_method", "fetch_customers")
    axios
      .post(URL, Data)
      .then(response => {
        if (Array.isArray(response.data)) {
          setCustomers(response.data)
          const selectedCustomer = response.data.find(
            customer => customer.bussiness_name === custName
          )
          if (selectedCustomer) {
            setCustOpenBlnc(selectedCustomer.open_balance || "0.00")
            setCustCreditBlnc(selectedCustomer.credit_balance || "0.00")
            setFormData(prevData => ({
              ...prevData,
              address_: selectedCustomer.address_ || "",
            }))
          } else {
            console.log("Error: Customer not found")
          }
        } else {
          console.log("Error in fetch customers")
        }
      })
      .catch(error => console.error("Error fetching customers:", error))
  }

  //Products
  const fetchProducts = () => {
    const URL = `${apiUrl}/products/addproduct.php`
    const Product = new FormData()

    Product.append("app_method", "get_product_detail")
    axios
      .post(URL, Product)

      .then(response => {
        if (Array.isArray(response.data)) {
          setProducrts(response.data)
        } else {
          console.log("Error in fetch Data")
        }
      })
      .catch(error => console.log(error))
  }
  const fetchProductDetail = (prd_name, id) => {
    const URL = `${apiUrl}/products/addproduct.php`
    const Product = new FormData()

    Product.append("app_method", "get_product_detail")
    axios
      .post(URL, Product)
      .then(response => {
        if (Array.isArray(response.data)) {
          const SelectedProduct = response.data.find(
            response => response.prd_name === prd_name
          )
          if (SelectedProduct) {
            setData(prevData =>
              prevData.map(item =>
                item.id === id
                  ? {
                      ...item,
                      prd_desc: SelectedProduct.prd_sale_info || "",
                      prd_unit: SelectedProduct.prd_unit || "",
                      prd_rate: SelectedProduct.prd_sale_price || "",
                      prd_code: SelectedProduct.prd_code || "",
                    }
                  : item
              )
            )
            ResetTotalAmount(id)
          } else {
            console.log("Error in fetch Detail")
          }
        } else {
          console.log("Error in fetch Data")
        }
      })
      .catch(error => console.log(error))
  }
  const [BtnDisabled, setBtnDisabled] = useState(false)
  //Submit All Data
  const SubmitSaleData = async (e, isPrint) => {
    if (formData.cust_name === "") {
      toastr.error("<b>Customer</b> Is Required")
    } else if (formData.project_name === "") {
      toastr.error("<b>Project</b> Is Required")
    } else if (data.length === 0) {
      toastr.error("Select <b>Product</b>")
    } else if (!data.every(row => row.product_.length > 0)) {
      toastr.error("Select <b>Product</b>")
    } else if (!data.every(row => row.prd_desc.length > 0)) {
      toastr.error("Add Product Description")
    } else if (!data.every(row => row.prd_unit.length > 0)) {
      toastr.error("Set Product Unit")
    } else if (!data.every(row => row.prd_qty > 0)) {
      toastr.error("Set Product Quantity")
    } else if (!data.every(row => row.prd_rate > 0)) {
      toastr.error("Set Product Rate")
    } else if (formData.discount_Amount >= subTotalAmount) {
      toastr.error("Discount Amount is less than Sub Total")
    } else {
      e.preventDefault()
      setBtnDisabled(true)
      const URL = `${apiUrl}/sales/orders.php`
      const formdata = new FormData()

      data.forEach(row => {
        formdata.append("id[]", row.id)
        formdata.append("product_name[]", row.product_)
        formdata.append("product_desc[]", row.prd_desc)
        formdata.append("product_unit[]", row.prd_unit)
        formdata.append("product_qty[]", row.prd_qty)
        formdata.append("product_rate[]", row.prd_rate)
        formdata.append("prd_code[]", row.prd_code)
        formdata.append("product_amount[]", row.prd_amount)
      })
      formdata.append("customer_name", formData.cust_name)
      formdata.append("project_name", formData.project_name)
      formdata.append("customer_address", formData.address_)
      formdata.append("order_date", formData.date_)
      formdata.append(
        "term_days",
        formData.term_days !== "" ? formData.term_days : "0"
      )
      formdata.append("order_due_date", formData.due_date)
      formdata.append(
        "discount_amount",
        formData.discount_Amount !== "" ? formData.discount_Amount : "0"
      )
      formdata.append(
        "tax_percentage",
        formData.tax_percentage !== "" ? formData.tax_percentage : "0"
      )
      formdata.append("customer_cred_limt", custCreditBlnc)
      formdata.append("customer_balance", custOpenBlnc)
      formdata.append("final_notes", formData.final_notes)

      formdata.append("total_quantity", totalQuantity)
      formdata.append("tax_amount", Tax_amount !== "" ? Tax_amount : "0")
      formdata.append("sub_total", subTotalAmount)
      formdata.append("total_amount", FinalTotalAmount)
      formdata.append("app_method", "add_sale")
      try {
        const response = await axios.post(URL, formdata)
        if (response.data.message === "success") {
          if (isPrint) {
            // Open print modal with returned ID
            handleEmail(response.data.print_Id) // Assuming response contains ID
          } else {
            toastr.success("Add Successfully")
            navigate("/income/sales/quotations")
          }
        } else {
          toastr.error("Error in Sumbittion")
        }
      } catch (error) {
        console.log(error)
      } finally {
        setBtnDisabled(false)
      }
    }
  }

  //Projects
  const fetchProject = async () => {
    const URL = `${apiUrl}/Projects/projects.php`
    const Pdata = new FormData()
    Pdata.append("app_method", "fetch_all_projects")

    try {
      setloading(true)
      const response = await axios.post(URL, Pdata)
      if (Array.isArray(response.data)) {
        setProjects(response.data)
      } else {
        console.log("error")
      }
    } catch (error) {
      console.log(error)
    } finally {
      setloading(false)
    }
  }

  //UseEffects
  useEffect(() => {
    if (formData.cust_name) {
      fetchCustomerDetail(formData.cust_name)
    }
  }, [formData.cust_name])

  useEffect(() => {
    const subTotalAmount = data.reduce(
      (acc, row) => acc + parseFloat(row.amount || 0),
      0.0
    )
    setSubTotalAmount(subTotalAmount)
    fetchCustomers()
    fetchProducts()
    fetchProject()
  }, [])

  useEffect(() => {
    let sumQuantity = 0
    let sumAmount = 0

    let Discount = 0
    let FTotalAmount = 0
    let TaxPercentage = 0
    let TaxAmount = 0

    // Calculate total quantity and amount
    data.forEach(item => {
      const quantity = parseFloat(item.prd_qty) || 0
      const amount = parseFloat(item.prd_amount) || 0

      sumQuantity += quantity
      sumAmount += amount
    })

    // Initialize final total amount with the sum amount
    FTotalAmount = sumAmount

    // Apply discount if provided
    if (formData.discount_Amount !== null && formData.discount_Amount !== "") {
      const discountValue = parseFloat(formData.discount_Amount)
      Discount = discountValue
      FTotalAmount -= Discount
    }

    // Apply tax if provided
    if (formData.tax_percentage !== null && formData.tax_percentage !== "") {
      TaxPercentage = parseFloat(formData.tax_percentage) || 0
      TaxAmount = FTotalAmount * (TaxPercentage / 100)
      FTotalAmount += TaxAmount
    }

    // Update state values
    setTotalQuantity(sumQuantity)
    setSubTotalAmount(sumAmount)
    setFinalTotalAmount(FTotalAmount)
    setTax_amount(TaxAmount)
  }, [data, formData.discount_Amount, formData.tax_percentage])

  //Close
  const close = e => {
    e.preventDefault()
    navigate("/income/sales/quotations")
  }

  //Customer Add Modal
  const [formData2, setFormData2] = useState({
    business_name: "",
    email: "",
    acc_no: "",
    full_address: "",
    title_: "",
    first_name: "",
    last_name: "",
    mobile_: "",
    phone: "",
    website_: "",
    notes_: "",
    method: "add_customer",
  })
  const handleChange2 = e => {
    const { name, value } = e.target
    setFormData2(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  const handleSubmit2 = e => {
    if (formData2.business_name === "") {
      toastr.error("Business Name is required")
    } else if (formData2.first_name === "") {
      toastr.error("First name is required")
    } else {
      axios
        .post(`${apiUrl}/sales/customer.php`, formData2)
        .then(response => {
          if (response.data === "success") {
            toastr.success("Customer Add Successfully")
            setAccountModal(!accountModal)
            fetchCustomers()
            setFormData2(prevData => ({
              ...prevData,
              business_name: "",
              email: "",
              acc_no: "",
              full_address: "",
              title_: "",
              first_name: "",
              last_name: "",
              mobile_: "",
              phone: "",
              website_: "",
              notes_: "",
            }))
            setFormData(prevData => ({
              ...prevData,
              cust_name: formData2.business_name,
            }))
          } else {
            toastr.error(response.data)
          }
        })
        .catch(error => console.log(error))
    }
  }
  const AddCustomer = () => {
    setFormData(prevData => ({
      ...prevData,
    }))
    setAccountModal(!accountModal)
  }

  //Project Add Modal
  const [formData3, setFormData3] = useState({
    project_name: "",
    project_code: "",
    project_detail: "",
    method: "add_project_detail",
  })
  const onChangeModal = e => {
    const { name, value } = e.target
    setFormData3(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  const handleSubmit = e => {
    e.preventDefault()
    if (formData3.project_name === "") {
      toastr.error("Project name is Required")
    } else {
      axios
        .post(`${apiUrl}/Projects/projects.php`, formData3)
        .then(response => {
          if (response.data === "success") {
            fetchProject()
            toastr.success("Submit Successfully")
            setProjectModal(!projectModal)
            setFormData3(prevData => ({
              ...prevData,
              project_name: "",
              project_code: "",
              project_detail: "",
            }))
            setFormData(prevData => ({
              ...prevData,
              project_name: formData3.project_name,
            }))
          } else {
            toastr.error(response.data)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
  const addProject = () => {
    setProjectModal(!projectModal)
  }

  // Function to handle "Save & Close" button click
  const handleSaveAndClose = e => {
    SubmitSaleData(e, false) // Call SubmitSaleData function with isPrint as false
  }
  // Function to handle "Save & Print" button click
  const handleSaveAndPrint = e => {
    SubmitSaleData(e, true) // Call SubmitSaleData function with isPrint as true
  }

  //Textarea lenght
  const [textareaHeights, setTextareaHeights] = useState({})

  const handleTextareaChange = (id, value) => {
    const textarea = document.getElementById(`textarea-${id}`)
    if (textarea) {
      textarea.style.height = "auto"
      textarea.style.height = textarea.scrollHeight + "px"
      setTextareaHeights(prevHeights => ({
        ...prevHeights,
        [id]: textarea.scrollHeight,
      }))
    }
  }

  useEffect(() => {
    data.map(item => {
      handleTextareaChange(item.id, item.prd_desc)
    })
  }, [data])

  const handleClickOutside = event => {
    if (iconRef.current && !iconRef.current.contains(event.target)) {
      setDiscountMode(false)
    }
    if (iconRef2.current && !iconRef2.current.contains(event.target)) {
      setTaxMode(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  //Set Discount
  const [discountMode, setDiscountMode] = useState(false)
  const setDiscount = () => {
    setDiscountMode(!discountMode)
  }

  //Set Tax
  const [taxMode, setTaxMode] = useState(false)
  const setTax = () => {
    setTaxMode(!taxMode)
  }

  if (loading) {
    return (
      <div className="spinner-container">
        <img src={logo} alt="Loading" className="spinner-logo" />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="10">
              <div>
                <h5 className="mt-2">Add Sales Order</h5>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <div className="accordion" id="accordion">
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col1,
                      })}
                      type="button"
                      onClick={t_col1}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Customer Details
                    </button>
                  </h2>

                  <Collapse isOpen={col1} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row>
                        <Col xl={4}>
                          <div className="mb-3">
                            <Label
                              className="control-label"
                              htmlFor="cust_name"
                            >
                              Customers *
                            </Label>
                            <InputGroup>
                              <select
                                defaultValue="0"
                                className="form-select"
                                onChange={e =>
                                  handleChangeCustomer(
                                    e.target.selectedOptions[0]
                                  )
                                }
                                name="cust_name"
                                value={formData.cust_name}
                              >
                                <option value={""}>Select Customer...</option>

                                {customers.length > 0 ? (
                                  customers
                                    .filter(e => !!e)
                                    .map((e, index) => (
                                      <option
                                        key={index}
                                        value={e.bussiness_name}
                                      >
                                        {`${e.bussiness_name} (${e.acc_no})`}
                                      </option>
                                    ))
                                ) : (
                                  <option value="" disabled>
                                    No Data Found
                                  </option>
                                )}
                              </select>

                              <div className="input-group-append">
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={AddCustomer}
                                >
                                  <i className="mdi mdi-plus" />
                                </Button>
                              </div>
                            </InputGroup>
                          </div>
                          <div className="mb-3">
                            <label>Address</label>
                            <Input
                              className="form-control"
                              type="textarea"
                              placeholder="Address"
                              name="address_"
                              rows="5"
                              value={formData.address_}
                              onChange={handleChange}
                            ></Input>
                          </div>
                          <div className="mb-3">
                            <Label
                              className="control-label"
                              htmlFor="project_name"
                            >
                              Project *
                            </Label>
                            <InputGroup>
                              <select
                                defaultValue="0"
                                className="form-select"
                                onChange={handleChange}
                                name="project_name"
                                value={formData.project_name}
                              >
                                <option value={""}>Select ...</option>

                                {projects.length > 0 ? (
                                  projects
                                    .filter(e => !!e)
                                    .map((e, index) => (
                                      <option
                                        key={index}
                                        value={e.project_name}
                                      >
                                        {e.project_name} ({e.project_code})
                                      </option>
                                    ))
                                ) : (
                                  <option value="" disabled>
                                    No Data Found
                                  </option>
                                )}
                              </select>
                              <div className="input-group-append">
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={addProject}
                                >
                                  <i className="mdi mdi-plus" />
                                </Button>
                              </div>
                            </InputGroup>
                          </div>
                        </Col>

                        <Col xl={4}>
                          <div className="mb-3">
                            <label>Date *</label>
                            <Input
                              className="form-control"
                              type="date"
                              //   placeholder="Payment Mode"
                              name="date_"
                              value={formData.date_}
                              onChange={handleChange}
                            ></Input>
                          </div>
                          <div className="mb-3">
                            <label>Term Days</label>
                            <Input
                              className="form-control"
                              type="text"
                              min={0}
                              placeholder="0"
                              name="term_days"
                              value={formData.term_days}
                              onChange={handleChange}
                            ></Input>
                          </div>
                          <div className="mb-3">
                            <label>Due Date *</label>
                            <Input
                              className="form-control"
                              type="date"
                              placeholder="Payment Mode"
                              name="due_date"
                              value={formData.due_date}
                              onChange={handleChange}
                            ></Input>
                          </div>
                          <div className="mb-3">
                            <label>Doc No.</label>
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="doc no."
                              name="doc_no"
                              value={formData.doc_no}
                              onChange={handleChange}
                              disabled
                            ></Input>
                          </div>
                        </Col>
                        <Col xl={4}>
                          <div className="mb-3">
                            <label>Credit Limit</label>
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="0.00"
                              name="cred_limt"
                              disabled
                              value={formatAmount(Number(custCreditBlnc))}
                              onChange={handleChange}
                            ></Input>
                          </div>
                          <div className="mb-3">
                            <label>Balance</label>
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="0.00"
                              disabled
                              name="balance_"
                              value={formatAmount(Number(custOpenBlnc))}
                              onChange={handleChange}
                            ></Input>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne2">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col2,
                      })}
                      type="button"
                      onClick={t_col2}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Product Detail
                    </button>
                  </h2>

                  <Collapse isOpen={col2} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row className="mb-3">
                        <Col xl={12}>
                          <table>
                            <thead>
                              <tr>
                                <th colSpan={2}>Product</th>
                                <th>{""}</th>
                                <th>Description</th>
                                <th>Unit</th>
                                <th>Qty</th>
                                <th>Rate</th>
                                <th>Amount</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map(item => (
                                <tr key={item.id}>
                                  <td style={{ display: "none" }}>{item.id}</td>
                                  <td colSpan={3}>
                                    <select
                                      className="form-select"
                                      type="text"
                                      value={item.product_}
                                      onChange={e =>
                                        handleEdit(
                                          item.id,
                                          "product_",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">Select...</option>
                                      {products.length > 0 ? (
                                        products
                                          .filter(e => !!e)
                                          .map((e, index) => (
                                            <option
                                              key={index}
                                              value={e.prd_name}
                                            >
                                              {e.prd_type == "Stock"
                                                ? `${e.prd_name} (${
                                                    e.prd_qty_onhand !== null
                                                      ? Number(e.prd_qty_onhand)
                                                      : Number(0)
                                                  }) `
                                                : e.prd_name}
                                            </option>
                                          ))
                                      ) : (
                                        <option>No Data Found</option>
                                      )}
                                    </select>
                                  </td>
                                  <td>
                                    <textarea
                                      type="text"
                                      value={item.prd_desc}
                                      id={`textarea-${item.id}`}
                                      style={{
                                        height:
                                          textareaHeights[item.id] || "auto",
                                      }}
                                      onChange={e => {
                                        handleEdit(
                                          item.id,
                                          "prd_desc",
                                          e.target.value
                                        )
                                        handleTextareaChange(
                                          item.id,
                                          e.target.value
                                        )
                                      }}
                                    >
                                      {item.prd_desc}
                                    </textarea>
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      value={item.prd_unit}
                                      onChange={e =>
                                        handleEdit(
                                          item.id,
                                          "prd_unit",
                                          e.target.value
                                        )
                                      }
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      value={item.prd_qty}
                                      placeholder="0"
                                      min={1}
                                      onChange={e =>
                                        handleEdit(
                                          item.id,
                                          "prd_qty",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      value={item.prd_rate}
                                      placeholder="0"
                                      min={0}
                                      onChange={e =>
                                        handleEdit2(
                                          item.id,
                                          "prd_rate",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      min={1}
                                      value={formatAmount(item.prd_amount)}
                                      onChange={e =>
                                        handleEdit(
                                          item.id,
                                          "prd_amount",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <i
                                      className="mdi mdi-delete font-size-18"
                                      onClick={() => deleteRow(item.id)}
                                      style={{
                                        color: "#F46A6A",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>

                            <tbody>
                              <thead></thead>
                              <tr
                                style={{
                                  borderTop: "1px solid black",
                                }}
                              >
                                <td colSpan={3}>
                                  <strong>Total</strong>
                                </td>
                                <td></td>
                                <td></td>
                                <td>
                                  <input
                                    type="text"
                                    value={totalQuantity}
                                    disabled
                                  />
                                </td>
                                <td></td>
                                <td>
                                  <input
                                    type="text"
                                    value={formatAmount(subTotalAmount)}
                                    disabled
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="my-3">
                            <Button
                              onClick={handleAddRow}
                              color="primary"
                              className="btn btn-sm"
                            >
                              <i className="fa fa-fw fa-plus" />
                              Add Row
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </div>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl={4}>
                        <label>Notes</label>
                        <Input
                          className="form-control"
                          type="textarea"
                          placeholder="Notes"
                          name="final_notes"
                          rows="7"
                          value={formData.final_notes}
                          onChange={handleChange}
                        ></Input>
                      </Col>
                      <Col xl={4}></Col>
                      <Col xl={4}>
                        <Table className="table-nowrap">
                          <thead>
                            <tr>
                              <td className="">Sub Total</td>
                              <td className="text-end">
                                {formatAmount(subTotalAmount)}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Discount {""}
                                <span>
                                  <i
                                    className="bx bx-edit font-size-18"
                                    style={{
                                      cursor: "pointer",
                                      fontWeight: "bold",
                                    }}
                                    onClick={setDiscount}
                                    ref={iconRef}
                                  />
                                </span>
                              </td>
                              {discountMode ? (
                                <td className="text-end">
                                  <input
                                    type="text"
                                    name="discount_Amount"
                                    placeholder="0.00"
                                    value={formData.discount_Amount}
                                    onChange={handleChange}
                                    ref={iconRef}
                                  />
                                </td>
                              ) : (
                                <td className="text-end">
                                  {formData.discount_Amount !== ""
                                    ? formatAmount(formData.discount_Amount)
                                    : "0.00"}
                                </td>
                              )}
                            </tr>
                            <tr>
                              <td className="">
                                Sale Tax (%)
                                <span>
                                  <i
                                    className="bx bx-edit font-size-18"
                                    style={{
                                      cursor: "pointer",
                                      fontWeight: "bold",
                                    }}
                                    onClick={setTax}
                                    ref={iconRef2}
                                  />
                                </span>
                              </td>
                              {taxMode ? (
                                <td className="text-end">
                                  <input
                                    type="text"
                                    name="tax_percentage"
                                    placeholder="%"
                                    value={formData.tax_percentage}
                                    onChange={handleChange}
                                    ref={iconRef2}
                                  />
                                </td>
                              ) : (
                                <td className="text-end">
                                  {Tax_amount !== ""
                                    ? formatAmount(Tax_amount)
                                    : "0.00"}
                                </td>
                              )}
                            </tr>
                            <tr>
                              <td className="border-0">
                                <strong>Total</strong>
                              </td>
                              <td className="border-0 text-end">
                                <h4 className="m-0">
                                  {formatAmount(FinalTotalAmount)}
                                </h4>
                              </td>
                            </tr>
                          </thead>
                        </Table>
                      </Col>
                    </Row>
                    <div className="custom-horizontal-line-dropdown mb-3"></div>
                    <Row>
                      <Col xl={4}>
                        <Button
                          color="danger"
                          className="btn btn-danger waves-effect waves-light"
                          onClick={close}
                        >
                          Close
                        </Button>
                      </Col>
                      <Col xl={8}>
                        <div className="d-flex flex-wrap  float-end">
                          <Button
                            onClick={handleSaveAndClose}
                            className="btn btn-primary  me-2"
                            color="primary"
                            disabled={BtnDisabled}
                          >
                            {BtnDisabled ? "Loading..." : "Save & Close"}
                          </Button>
                        </div>
                        <div className="d-flex flex-wrap  float-end">
                          <Button
                            onClick={handleSaveAndPrint}
                            className="btn btn-primary  me-2"
                            color="success"
                            disabled={BtnDisabled}
                          >
                            {" "}
                            {BtnDisabled ? "Loading..." : "Save & Print"}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={accountModal}
        size="lg"
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setAccountModal(!accountModal)
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setAccountModal(!accountModal)
            }}
          >
            Customer Information
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col className="col-6">
                <div className="mb-3">
                  <label>Business Name</label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Business Name"
                    name="business_name"
                    value={formData2.business_name}
                    onChange={handleChange2}
                  ></Input>
                </div>
                <div className="mb-3">
                  <label>Email</label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="email"
                    name="email"
                    value={formData2.email}
                    onChange={handleChange2}
                  ></Input>
                </div>
                <div className="mb-3">
                  <label>Account No</label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="account num"
                    name="acc_no"
                    value={formData2.acc_no}
                    onChange={handleChange2}
                  ></Input>
                </div>
                <div className="mb-3">
                  <label>Full Address</label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="address"
                    name="full_address"
                    onChange={handleChange2}
                    value={formData2.full_address}
                  ></Input>
                </div>
              </Col>
              <Col className="col-6">
                <div className="mb-3">
                  <Row>
                    <Col className="col-2">
                      <label>Title</label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Mr/Ms"
                        name="title_"
                        onChange={handleChange2}
                        value={formData2.title_}
                      ></Input>
                    </Col>
                    <Col className="col-5">
                      <label>First Name</label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="First Name"
                        value={formData2.first_name}
                        onChange={handleChange2}
                        name="first_name"
                      ></Input>
                    </Col>
                    <Col className="col-5">
                      <label>Last Name</label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Last Name"
                        value={formData2.last_name}
                        name="last_name"
                        onChange={handleChange2}
                      ></Input>
                    </Col>
                  </Row>
                </div>
                <div className="mb-3">
                  <Row>
                    <Col className="col-6">
                      <label>Mobile</label>
                      <Input
                        className="form-control"
                        type="number"
                        name="mobile_"
                        value={formData2.mobile_}
                        onChange={handleChange2}
                        placeholder="mobile number"
                      ></Input>
                    </Col>
                    <Col className="col-6">
                      <label>Phone</label>
                      <Input
                        className="form-control"
                        type="number"
                        placeholder="phone number"
                        name="phone"
                        value={formData2.phone}
                        onChange={handleChange2}
                      ></Input>
                    </Col>
                  </Row>
                </div>
                <div className="mb-3">
                  <label>Website</label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="website"
                    name="website_"
                    value={formData2.website_}
                    onChange={handleChange2}
                  ></Input>
                </div>
                <div className="mb-3">
                  <div className="mb-3">
                    <label>Notes</label>
                    <Input
                      className="form-control"
                      type="textarea"
                      placeholder="notes"
                      value={formData2.notes_}
                      name="notes_"
                      onChange={handleChange2}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setAccountModal(!accountModal)
              }}
            >
              Close
            </Button>
            <Button onClick={handleSubmit2} type="button" color="primary">
              Save
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      <Modal
        isOpen={projectModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setProjectModal(!projectModal)
        }}
      >
        <div>
          <Form onSubmit={handleSubmit}>
            <ModalHeader
              toggle={() => {
                setProjectModal(!projectModal)
              }}
            >
              Project Information
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col className="col-12">
                  <div className="mb-3">
                    <label>Project Name</label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Name"
                      name="project_name"
                      value={formData3.project_name}
                      onChange={onChangeModal}
                    ></Input>
                  </div>
                  <div className="mb-3">
                    <label>Code</label>
                    <Input
                      className="form-control"
                      type="number"
                      placeholder="code"
                      name="project_code"
                      value={formData3.project_code}
                      onChange={onChangeModal}
                      disabled
                    ></Input>
                  </div>
                  <div className="mb-3">
                    <label>Details</label>
                    <Input
                      className="form-control"
                      type="textarea"
                      placeholder="description"
                      name="project_detail"
                      value={formData3.project_detail}
                      onChange={onChangeModal}
                    />
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                  setProjectModal(!projectModal)
                }}
              >
                Close
              </Button>
              <Button type="submit" color="primary">
                Submit
              </Button>
            </ModalFooter>
          </Form>
        </div>
      </Modal>
      <div>
        <SocialModal
          isOpen={socialModal}
          toggle={toggleModal}
          EmailMode={EmailMode}
          ModalMailData={ModalMailData}
          handleModalMailData={handleModalMailData}
          SendEmail={SendEmail}
          pdfUrl={pdfUrl}
          setModalMailData={setModalMailData}
        />
      </div>
    </React.Fragment>
  )
}

export default CreateSale
