import React, { useEffect, useState, useMemo } from "react"
import PropTypes, { element } from "prop-types"
import { Link } from "react-router-dom"
import DeleteModal from "components/Common/DeleteModal"
import "assets/style/style.css"
import logo from "assets/images/Pham logo black text.png"
import apiUrl from "config"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  FormGroup,
  Label,
  UncontrolledTooltip,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

import {
  Pdate,
  Type,
  Value,
  ValueinUSD,
  Status,
  Coin,
  Tracked,
} from "./SalesData"

import TableContainer from "../../components/Common/TableContainer"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import img2 from "../../assets/images/no-time-off.59f54e64.svg"

//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { getCryptoOrders } from "store/crypto/actions"
import axios from "axios"
import { formatAmount } from "components/Functoins/functions"

const SalesCustomers = props => {
  //meta title
  document.title = "Sales Customers"

  const [loading, setloading] = useState(false)

  const [formData, setFormData] = useState({
    business_name: "",
    email: "",
    acc_no: "",
    full_address: "",
    title_: "",
    first_name: "",
    last_name: "",
    mobile_: "",
    phone: "",
    website_: "",
    notes_: "",
    method: "add_customer",
  })

  const handleChange = e => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  const [orders, setOrders] = useState([])
  const [accountModal, setAccountModal] = useState(false)
  const [edit, setEdit] = useState(false)

  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeletId] = useState("")

  const handleFilter = () => {
    var allProduct = orders.filter(order => {
      return (
        order.type === selectType &&
        order.coin === selectCoin &&
        order.status === selectStatus
      )
    })
    setSetProductData(allProduct)
  }
  const addAccount = () => {
    setAccountModal(!accountModal)
    setEdit(false)
    setFormData({
      business_name: "",
      email: "",
      acc_no: "",
      full_address: "",
      title_: "",
      first_name: "",
      last_name: "",
      mobile_: "",
      phone: "",
      website_: "",
      notes_: "",
      method: "add_customer",
    })
  }
  const columns = useMemo(
    () => [
      {
        Header: "Business Name",
        accessor: "bussiness_name",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "Account No.",
        accessor: "acc_no",
        disableFilters: true,
        Cell: cellProps => {
          const account = cellProps.value
          return <>{account}</>
        },
      },
      {
        Header: "Customer Name",
        accessor: "Name",
        disableFilters: true,
        Cell: cellProps => {
          const fullName =
            cellProps.row.original.name_title +
            " " +
            cellProps.row.original.first_name +
            " " +
            cellProps.row.original.last_name
          return <>{fullName}</>
        },
      },

      {
        Header: "Balance",
        accessor: "open_balance",
        disableFilters: true,
        Cell: cellProps => {
          const number = formatAmount(cellProps.value)
          return <>{number}</>
        },
      },
      {
        Header: "Advance",
        accessor: "credit_balance",
        disableFilters: true,
        Cell: cellProps => {
          const number = formatAmount(cellProps.value)
          return <>{number}</>
        },
      },
      {
        Header: "Action",
        accessor: "id",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                onClick={() => handleEdit(cellProps.value)}
                className="text-primary"
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  deleteCustomer(cellProps.value)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )
  const deleteCustomer = id => {
    setDeletId(id)
    setDeleteModal(true)
  }
  const handleDeleteOrder = () => {
    const URL = `${apiUrl}/sales/customer.php`
    const formData3 = new FormData()
    formData3.append("del_id", deleteId)
    formData3.append("app_method", "delete_customer")
    axios
      .post(URL, formData3)
      .then(response => {
        if (response.data === "success") {
          toastr.success("Delete Successfully")
          setDeleteModal(false)
          fetchCustomers()
        } else {
          setDeleteModal(false)
          toastr.error("Customer's used in Transactions can't be deleted")
        }
      })
      .catch(error => console.log(error))
  }
  const handleSubmit = e => {
    e.preventDefault()
    if (formData.business_name == "") {
      toastr.error("Business Name is Required")
    } else if (formData.first_name == "") {
      toastr.error("First Name is Required")
    } else {
      axios
        .post(`${apiUrl}/sales/customer.php`, formData)
        .then(response => {
          if (response.data === "success") {
            toastr.success("Customer Add Successfully")
            fetchCustomers()
            setAccountModal(!accountModal)
            setFormData({
              business_name: "",
              email: "",
              acc_no: "",
              full_address: "",
              title_: "",
              first_name: "",
              last_name: "",
              mobile_: "",
              phone: "",
              website_: "",
              notes_: "",
            })
          } else {
            toastr.error(response.data)
          }
        })
        .catch(error => console.log(error))
    }
  }
  const fetchCustomers = async () => {
    const URL = `${apiUrl}/sales/customer.php`
    const Data = new FormData()

    Data.append("app_method", "fetch_customers")

    try {
      setloading(true)
      const response = await axios.post(URL, Data)
      const data = Array.isArray(response.data) ? response.data : []
      setOrders(data)
    } catch (error) {
      console.log(error)
    } finally {
      setloading(false)
    }
  }
  const handleEdit = id => {
    const formData4 = {
      fetch_id: id,
      method: "fetch_val_byid",
    }
    axios
      .post(`${apiUrl}/sales/customer.php`, formData4)
      .then(response => {
        if (Array.isArray(response.data)) {
          setFormData({
            edit_id: id,
            business_name: response.data[0]?.bussiness_name || "",
            email: response.data[0]?.email_ || "",
            acc_no: response.data[0].acc_no || "",
            full_address: response.data[0].address_,
            title_: response.data[0]?.name_title || "",
            first_name: response.data[0]?.first_name || "",
            last_name: response.data[0]?.last_name || "",
            mobile_: response.data[0]?.mobile_ || "",
            phone: response.data[0]?.phone_ || "",
            website_: response.data[0]?.website_ || "",
            notes_: response.data[0]?.notes_ || "",
            method: "edit_customer_data",
          })
          setAccountModal(!accountModal)
          setEdit(true)
        } else {
          console.log("error")
        }
      })
      .catch(error => console.log(error))
  }
  useEffect(() => {
    fetchCustomers()
    handleEdit()
  }, [])
  const handleSubmit2 = e => {
    if (formData.business_name == "") {
      toastr.error("Business Name is Required")
    } else if (formData.first_name == "") {
      toastr.error("First Name is Required")
    } else {
      e.preventDefault()
      axios
        .post(`${apiUrl}/sales/customer.php`, formData)
        .then(response => {
          if (response.data === "success") {
            toastr.success("Edit Successfully")
            fetchCustomers()
            setAccountModal(!accountModal)
            setFormData({
              business_name: "",
              email: "",
              acc_no: "",
              full_address: "",
              title_: "",
              first_name: "",
              last_name: "",
              mobile_: "",
              phone: "",
              website_: "",
              notes_: "",
            })
          } else {
            toastr.error(response.data)
          }
        })
        .catch(error => console.log(error))
    }
  }

  if (loading) {
    return (
      <div className="spinner-container">
        <img src={logo} alt="Loading" className="spinner-logo" />
      </div>
    )
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="9">
              <div>
                <h5 className="mt-2">Customers</h5>
              </div>
            </Col>
            <Col xl="3">
              <div className="d-flex gap-2 flex-wrap float-end">
                <Button
                  type="button"
                  color="primary"
                  className="w-md btn btn-sm"
                  onClick={() => addAccount()}
                >
                  <i className="fa fa-fw fa-plus" />
                  Add New
                </Button>

                <Button className="btn btn-sm btn-light">
                  <i
                    className="mdi mdi-file-import font-size-12"
                    id="importtooltip"
                  />
                  <UncontrolledTooltip placement="top" target="importtooltip">
                    Import
                  </UncontrolledTooltip>
                </Button>
                <Button className="btn btn-sm btn-danger">
                  <i
                    className="mdi mdi-delete font-size-12"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Button>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={orders}
                    // isGlobalFilter={true}
                    // isAddOptions={false}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={accountModal}
        size="lg"
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setAccountModal(!accountModal)
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setAccountModal(!accountModal)
            }}
          >
            Customer Information
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col className="col-6">
                <div className="mb-3">
                  <label>Business Name</label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Business Name"
                    name="business_name"
                    value={formData.business_name}
                    onChange={handleChange}
                  ></Input>
                </div>
                <div className="mb-3">
                  <label>Email</label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  ></Input>
                </div>
                <div className="mb-3">
                  <label>Account No</label>
                  <Input
                    className="form-control"
                    type="text"
                    disabled
                    placeholder="account num"
                    name="acc_no"
                    value={formData.acc_no}
                    onChange={handleChange}
                  ></Input>
                </div>
                <div className="mb-3">
                  <label>Full Address</label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="address"
                    name="full_address"
                    onChange={handleChange}
                    value={formData.full_address}
                  ></Input>
                </div>
              </Col>
              <Col className="col-6">
                <div className="mb-3">
                  <Row>
                    <Col className="col-2">
                      <label>Title</label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Mr/Ms"
                        name="title_"
                        onChange={handleChange}
                        value={formData.title_}
                      ></Input>
                    </Col>
                    <Col className="col-5">
                      <label>First Name</label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="First Name"
                        value={formData.first_name}
                        onChange={handleChange}
                        name="first_name"
                      ></Input>
                    </Col>
                    <Col className="col-5">
                      <label>Last Name</label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Last Name"
                        value={formData.last_name}
                        name="last_name"
                        onChange={handleChange}
                      ></Input>
                    </Col>
                  </Row>
                </div>
                <div className="mb-3">
                  <Row>
                    <Col className="col-6">
                      <label>Mobile</label>
                      <Input
                        className="form-control"
                        type="number"
                        name="mobile_"
                        value={formData.mobile_}
                        onChange={handleChange}
                        placeholder="mobile number"
                      ></Input>
                    </Col>
                    <Col className="col-6">
                      <label>Phone</label>
                      <Input
                        className="form-control"
                        type="number"
                        placeholder="phone number"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                      ></Input>
                    </Col>
                  </Row>
                </div>
                <div className="mb-3">
                  <label>Website</label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="website"
                    name="website_"
                    value={formData.website_}
                    onChange={handleChange}
                  ></Input>
                </div>
                <div className="mb-3">
                  <div className="mb-3">
                    <label>Notes</label>
                    <Input
                      className="form-control"
                      type="textarea"
                      placeholder="notes"
                      value={formData.notes_}
                      name="notes_"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setAccountModal(!accountModal)
              }}
            >
              Close
            </Button>
            {edit ? (
              <Button onClick={handleSubmit2} type="button" color="primary">
                Edit
              </Button>
            ) : (
              <Button onClick={handleSubmit} type="button" color="primary">
                Save
              </Button>
            )}
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default SalesCustomers
