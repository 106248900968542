import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import DeleteModal from "components/Common/DeleteModal"
import apiUrl from "config"
import "assets/style/style.css"
import logo from "assets/images/Pham logo black text.png"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Modal,
  UncontrolledTooltip,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import { Pdate, Type, Coin } from "./InventoryData"

import TableContainer from "../../components/Common/TableContainer"
//Import Breadcrumb
import axios from "axios"
import { data } from "autoprefixer"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const InventoryProducts = props => {
  //meta title
  document.title = "Inventory Products"

  // const { orders, onGetOrders } = props
  const [orders, setOrders] = useState([])
  const [productData, setSetProductData] = useState([orders])
  const [accountModal, setAccountModal] = useState(false)
  const [alllocation2, setAlllocation2] = useState(false)

  const [loading, setloading] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeletId] = useState("")

  const handleFilter = () => {
    var allProduct = orders.filter(order => {
      return (
        order.type === selectType &&
        order.coin === selectCoin &&
        order.status === selectStatus
      )
    })
    setSetProductData(allProduct)
  }

  // const handleOrderClicks = () => {
  //   setOrderList("")
  //   setIsEdit(false)
  //   toggle()
  // }

  const fetchProducts = async () => {
    try {
      setloading(true) // Set loading state to true while fetching data

      const URL = `${apiUrl}/products/addproduct.php`
      const Product = new FormData()
      Product.append("app_method", "get_product_detail")

      const response = await axios.post(URL, Product)

      if (Array.isArray(response.data)) {
        setOrders(response.data)
      } else {
        console.log("Error in fetchProducts: Response is not an array")
      }
    } catch (error) {
      console.error("Error fetching products:", error)
    } finally {
      setloading(false) // Finally block ensures that loading state is set to false regardless of success or failure
    }
  }

  useEffect(() => {
    fetchProducts()
  }, [])
  // Table Data

  const columns = useMemo(
    () => [
      {
        Header: "Code",
        accessor: "prd_code",
        disableFilters: true,
        Cell: cellProps => {
          return <Pdate {...cellProps} />
        },
      },
      {
        Header: "Name",
        accessor: "prd_name",
        disableFilters: true,
        Cell: cellProps => {
          return <Pdate {...cellProps} />
        },
      },
      {
        Header: "Type",
        accessor: "prd_type",
        disableFilters: true,
        Cell: cellProps => {
          return <Type {...cellProps} />
        },
      },
      {
        Header: "Category",
        accessor: "prd_category",
        disableFilters: true,
        Cell: cellProps => {
          return <Coin {...cellProps} />
        },
      },
      {
        Header: "Sale Price",
        accessor: "prd_sale_price",
        disableFilters: true,
        Cell: cellProps => {
          return <Coin {...cellProps} />
        },
      },
      {
        Header: "Qty on Hand",
        accessor: "prd_qty_onhand",
        disableFilters: true,
        Cell: cellProps => {
          return <Coin {...cellProps} />
        },
      },
      {
        Header: "Low Stock",
        accessor: "prd_low_stock",
        disableFilters: true,
        Cell: cellProps => {
          return <Coin {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "id",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={{
                  pathname: `view/${cellProps.value}`,
                }}
                className="text-primary"
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              {/* <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link> */}
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  deleteproduct(cellProps.value)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const deleteproduct = id => {
    setDeletId(id)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    const formData2 = {
      method: "delete_inven_prd",
      del_id: deleteId,
    }

    axios
      .post(`${apiUrl}/products/addproduct.php`, formData2)
      .then(response => {
        if (response.data === "success") {
          setDeleteModal(false)
          toastr.success("Product Delete Sucessfully")
          fetchProducts()
        } else {
          setDeleteModal(false)
          toastr.error("Product Use In Quotations/Bills")
        }
      })
      .catch(error => console.log(error))
  }

  if (loading) {
    return (
      <div className="spinner-container">
        <img src={logo} alt="Loading" className="spinner-logo" />
      </div>
    )
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="9">
              <div>
                <h5 className="mt-2">Products</h5>
              </div>
            </Col>
            <Col xl="3">
              <div className="d-flex gap-2 flex-wrap float-end">
                <Dropdown
                  isOpen={alllocation2}
                  className="dropdown-mega d-none d-lg-block ms-2"
                  toggle={() => setAlllocation2(!alllocation2)}
                >
                  <DropdownToggle
                    tag="button"
                    className="btn btn-sm btn-primary px-3 font-size-13"
                  >
                    Add New
                    <span>
                      <i className="mdi mdi-chevron-down" />
                    </span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <Link to="/products/control/list_management/stock_product">
                      <DropdownItem>Stock Products</DropdownItem>
                    </Link>
                    <Link to="/products/control/list_management/non_stock_product">
                      <DropdownItem>Non-Stock Products</DropdownItem>
                    </Link>
                  </DropdownMenu>
                </Dropdown>

                <Button className="btn btn-sm btn-light">
                  <i
                    className="mdi mdi-file-import font-size-12"
                    id="importtooltip"
                  />
                  <UncontrolledTooltip placement="top" target="importtooltip">
                    Import
                  </UncontrolledTooltip>
                </Button>
                <Button className="btn btn-sm btn-danger">
                  <i
                    className="mdi mdi-delete font-size-12"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Button>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* <Form>
                    <Row className="mb-2">
                      <Col sm={6} className="col-xl">
                        <FormGroup className="mb-3">
                          <Label className="form-label">Data Range</Label>
                          <select
                            className="form-control select2-search-disable"
                            value={selectCoin}
                            onChange={e => {
                              setselectCoin(e.target.value)
                            }}
                          >
                            <option value="Bitcoin">Admin</option>
                            <option value="Ethereum">Manager</option>
                            <option value="Litecoin">Member</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col sm={6} className="col-xl">
                        <FormGroup className="mb-3">
                          <Label className="form-label">Bank</Label>
                          <select
                            className="form-control select2-search-disable"
                            value={selectStatus}
                            onChange={e => {
                              setselectStatus(e.target.value)
                            }}
                          >
                            <option value="Completed">Select..</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col sm={6} className="col-xl">
                        <FormGroup className="mb-3">
                          <Label className="form-label">Nominal A/C</Label>
                          <select
                            className="form-control select2-search-disable"
                            value={selectStatus}
                            onChange={e => {
                              setselectStatus(e.target.value)
                            }}
                          >
                            <option value="Pending">user</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col xl="2" sm={6} className="col-xl align-self-end ">
                        <div className="mb-3">
                          <Button
                            type="button"
                            color="primary"
                            className="w-md"
                            onClick={() => handleFilter()}
                          >
                            Filter
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form> */}
                  <TableContainer
                    columns={columns}
                    data={orders}
                    isGlobalFilter={true}
                    // isAddOptions={true}
                    // handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={accountModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setAccountModal(!accountModal)
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setAccountModal(!accountModal)
            }}
          >
            Bank Account New
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col className="col-12">
                <div className="mb-3">
                  <label>Bank Account *</label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Bank Account"
                  ></Input>
                </div>
                <div className="mb-3">
                  <label>Code</label>
                  <Input
                    className="form-control"
                    type="number"
                    placeholder="code"
                  ></Input>
                </div>
                <div className="mb-3">
                  <label>Description</label>
                  <Input
                    className="form-control"
                    type="textarea"
                    placeholder="description"
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setAccountModal(!accountModal)
              }}
            >
              Close
            </Button>
            <Button
              type="button"
              color="primary"
              onClick={() => {
                setAccountModal(!accountModal)
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}

// InventoryProducts.propTypes = {
//   orders: PropTypes.array,
//   onGetOrders: PropTypes.func,
// }

// const mapStateToProps = ({ crypto }) => ({
//   orders: crypto.orders,
// })

// const mapDispatchToProps = dispatch => ({
//   onGetOrders: () => dispatch(getCryptoOrders()),
// })

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(InventoryProducts))

export default InventoryProducts
