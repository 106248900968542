import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link, useLocation, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap"
import "../../../assets/style/style.css"

import "assets/style/style.css"

import apiUrl from "config"

import { useReactToPrint } from "react-to-print"

//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb"

import logo from "../../../assets/images/Pham logo black text.png"
//redux
import axios from "axios"
import { formatAmount } from "components/Functoins/functions"

const InvoiceDetail = props => {
  //meta title
  document.title = "Bank Activity"
  let { state } = useLocation()
  const [bankName, setBankName] = useState("")
  const [bankCode2, setBankCode2] = useState("")
  const [loading, setloading] = useState(false)

  // let bankName = state.Name
  // let bankCode2 = state.Code

  //Print
  const printRef = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  })

  const [bankActivity, SetBankActivity] = useState([])

  const fetchBankActivity = async bankName => {
    const URL = `${apiUrl}/BankManagemnt/bankActivity.php`
    const data = new FormData()
    data.append("bank_name", bankName)
    data.append("app_method", "fetch_bank_activity")

    try {
      setloading(true)
      const response = await axios.post(URL, data)
      const fdata = Array.isArray(response.data) ? response.data : []
      SetBankActivity(fdata)
    } catch (error) {
      console.log(error)
    } finally {
      setloading(false)
    }
  }

  useEffect(() => {
    if (state && state.Name && state.Code) {
      setBankName(state.Name)
      setBankCode2(state.Code)
      fetchBankActivity(state.Name)
    }
  }, [state])

  if (loading) {
    return (
      <div className="spinner-container">
        <img src={logo} alt="Loading" className="spinner-logo" />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Invoices" breadcrumbItem="Invoice Detail" /> */}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="invoice-title">
                    {/* <h4 className="float-end font-size-16">
                      Order # {orderNo}
                    </h4> */}
                    <div className="mb-4">
                      <img
                        src={logo}
                        alt="logo-dark"
                        className="logo-dark-element"
                        height="80"
                      />
                    </div>
                  </div>
                  <hr />
                  <Row>
                    <Col xl={12}>
                      <h3 className="text-center">Pham Enterprises</h3>
                      <h5 className="text-center">Bank Activity</h5>
                      <h5 className="text-center">
                        {bankName}
                        {""}({bankCode2})
                      </h5>
                    </Col>
                  </Row>

                  <div className="py-2 mt-3">
                    <h3 className="font-size-15 fw-bold">
                      Transactions Summary
                    </h3>
                  </div>
                  <div className="table-responsive">
                    <Table className="table-nowrap">
                      <thead
                        style={{
                          border: "1px solid black",
                          borderCollapse: "-moz-initial",
                        }}
                      >
                        <tr>
                          <th style={{ width: "70px" }}>No.</th>
                          <th>Type</th>
                          <th>Date</th>
                          <th>Account</th>
                          <th>Ref.</th>
                          <th>Detail</th>
                          <th>Mode</th>
                          <th>Payments</th>
                          <th>Receipts</th>
                          <th>Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bankActivity.length > 0 ? (
                          bankActivity
                            .filter(e => !!e)
                            .map((e, index) => {
                              // Initialize the balance
                              let balance = 0
                              let link = ""

                              // Calculate the balance for each row
                              for (let i = 0; i <= index; i++) {
                                const transaction = bankActivity[i]
                                const payments =
                                  parseFloat(transaction.payments_) || 0
                                const receipts =
                                  parseFloat(transaction.receipts_) || 0

                                if (transaction.p_type === "payment") {
                                  balance -= payments
                                } else if (transaction.p_type === "receipt") {
                                  balance += receipts
                                }

                                if (transaction.trans_type === "IBP") {
                                  link = `/bank/control/bank_payment/view/${transaction.v_id}`
                                } else if (transaction.trans_type === "IBR") {
                                  link = `/bank/control/bank_receipt/view/${transaction.v_id}`
                                } else if (transaction.trans_type === "IBT") {
                                  link = `/bank/control/bank_transfer/view/${transaction.v_id}`
                                } else if (transaction.trans_type === "SR") {
                                  link = `/income/view_invoice/view_payment/${transaction.v_id}`
                                } else if (transaction.trans_type === "PB") {
                                  link = `/expenses/control/payments/view/${transaction.v_id}`
                                }
                              }

                              return (
                                <tr key={index}>
                                  <td>
                                    <Link to={link}>{e.v_id}</Link>
                                  </td>
                                  <td>{e.trans_type}</td>
                                  <td>{e.created_date}</td>
                                  <td>{e.acc_name}</td>
                                  <td>{e.ref_no}</td>
                                  <td>{e.pay_details}</td>
                                  <td>{e.pay_mode}</td>
                                  <td>{formatAmount(e.payments_)}</td>
                                  <td>{formatAmount(e.receipts_)}</td>
                                  <td>{formatAmount(balance)}</td>{" "}
                                </tr>
                              )
                            })
                        ) : (
                          <tr>
                            <td colSpan="10" className="border-0">
                              <h6 className="text-center mt-3 mb-3">
                                No Transactions Found{" "}
                              </h6>
                            </td>
                          </tr>

                          // <div className="text-center">No data Found</div>
                        )}
                        <tr style={{ border: "1px solid black" }}></tr>
                        <tr
                          style={{
                            border: "1px solid black",
                            // borderTop: "1px solid black",
                          }}
                        >
                          <td colSpan="7" className="border-0">
                            <strong>Total</strong>
                          </td>
                          <td colSpan="1" className="border-0">
                            <strong>
                              {bankActivity
                                .reduce(
                                  (total, e) =>
                                    total + parseFloat(e.payments_ || 0),
                                  0
                                )
                                .toFixed(2)}
                            </strong>
                          </td>
                          <td colSpan="2" className="border-0">
                            <strong>
                              {bankActivity
                                .reduce(
                                  (total, e) =>
                                    total + parseFloat(e.receipts_ || 0),
                                  0
                                )
                                .toFixed(2)}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div className="d-print-none">
                    <div className="float-end">
                      <Link
                        onClick={handlePrint}
                        className="btn btn-success  me-2"
                      >
                        <i className="fa fa-print" />
                      </Link>
                      {/* <Link to="#" className="btn btn-primary w-md ">
                        Send
                      </Link> */}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div
          ref={printRef}
          className="paymentPrint"
          style={{ display: "none" }}
        >
          <Row>
            <Col xl={12}>
              <div className="invoice-title">
                {/* <h4 className="float-end font-size-16">
                      Order # {orderNo}
                    </h4> */}
                <div className="mb-4">
                  <img
                    src={logo}
                    alt="logo-dark"
                    className="logo-dark-element"
                    height="80"
                  />
                </div>
              </div>
              <hr />
              <Row>
                <Col xl={12}>
                  <h3 className="text-center">Pham Enterprises</h3>
                  <h5 className="text-center">Bank Payment</h5>
                  <h5 className="text-center">
                    {bankName}
                    {""}({bankCode2})
                  </h5>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xl={12}>
                  <table className="table-bordered border-black mb-0">
                    <thead>
                      <tr className="black-head">
                        <th>No.</th>
                        <th>Type</th>
                        <th>Date</th>
                        <th>Account</th>
                        <th>Ref.</th>
                        <th>Detail</th>
                        <th>Mode</th>
                        <th>Payments</th>
                        <th>Receipts</th>
                        <th>Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bankActivity.length > 0 ? (
                        bankActivity
                          .filter(e => !!e)
                          .map((e, index) => {
                            // Initialize the balance
                            let balance = 0

                            // Calculate the balance for each row
                            for (let i = 0; i <= index; i++) {
                              const transaction = bankActivity[i]
                              const payments =
                                parseFloat(transaction.payments_) || 0
                              const receipts =
                                parseFloat(transaction.receipts_) || 0

                              if (transaction.p_type === "payment") {
                                balance -= payments
                              } else if (transaction.p_type === "receipt") {
                                balance += receipts
                              }
                            }

                            return (
                              <tr key={index}>
                                <td>{e.v_id}</td>
                                <td>{e.trans_type}</td>
                                <td>{e.created_date}</td>
                                <td>{e.acc_name}</td>
                                <td>{e.ref_no}</td>
                                <td>{e.pay_details}</td>
                                <td>{e.pay_mode}</td>
                                <td>{formatAmount(e.payments_)}</td>
                                <td>{formatAmount(e.receipts_)}</td>
                                <td>{formatAmount(balance)}</td>{" "}
                              </tr>
                            )
                          })
                      ) : (
                        <tr>
                          <td colSpan="10" className="border-0">
                            <h6 className="text-center mt-3 mb-3">
                              No Transactions Found{" "}
                            </h6>
                          </td>
                        </tr>

                        // <div className="text-center">No data Found</div>
                      )}
                      <tr style={{ border: "1px solid black" }}></tr>
                      <tr
                        style={{
                          border: "1px solid black",
                          // borderTop: "1px solid black",
                        }}
                      >
                        <td colSpan="7" className="border-0">
                          <strong>Total</strong>
                        </td>
                        <td colSpan="1" className="border-0">
                          <strong>
                            {bankActivity
                              .reduce(
                                (total, e) =>
                                  total + parseFloat(e.payments_ || 0),
                                0
                              )
                              .toFixed(2)}
                          </strong>
                        </td>
                        <td colSpan="2" className="border-0">
                          <strong>
                            {bankActivity
                              .reduce(
                                (total, e) =>
                                  total + parseFloat(e.receipts_ || 0),
                                0
                              )
                              .toFixed(2)}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
              <br />
            </Col>
          </Row>
          <div className="mt-5 mb-5">
            <Row>
              <Col sm={2}>
                <div
                  style={{
                    borderTop: "1.5px solid black",
                    width: "100%",
                  }}
                ></div>
                <div className="text-center">
                  <b>Prepared By</b>
                </div>
              </Col>
              <Col sm={2}>
                <div
                  style={{
                    borderTop: "1.5px solid black",
                    width: "100%",
                  }}
                ></div>
                <div className="text-center">
                  <b>Reviewed By</b>
                </div>
              </Col>
              <Col sm={2}>
                <div
                  style={{
                    borderTop: "1.5px solid black",
                    width: "100%",
                  }}
                ></div>
                <div className="text-center">
                  <b>Approved By</b>
                </div>
              </Col>
              <Col sm={2}>
                <div
                  style={{
                    borderTop: "1.5px solid black",
                    width: "100%",
                  }}
                ></div>
                <div className="text-center">
                  <b>Received By</b>
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col xl="12">
                <div style={{ backgroundColor: "blue", padding: "10px" }}>
                  <img
                    src={`${apiUrl}/uploads/images/company_stamp.jpg`}
                    width="15%"
                    height="15%"
                  />

                  <p style={{ color: "white", marginTop: "5px" }}>
                    MUHAMMAD SUFYAN <br />
                    0092-307-572 7380 <br />
                    msufyan2011@gmail.com
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

InvoiceDetail.propTypes = {
  match: PropTypes.any,
}

export default withRouter(InvoiceDetail)
