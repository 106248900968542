import React, { useEffect, useState } from "react"
import { Link, Router } from "react-router-dom"
import "assets/style/style.css"
import logo from "assets/images/Pham logo black text.png"
import apiUrl from "config"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Collapse,
  Table,
  Container,
  Form,
  Input,
  Label,
  Row,
  CardHeader,
} from "reactstrap"
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import axios from "axios"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { useParams } from "react-router-dom"
import { Quarters } from "react-native-inline-datepicker/constants/Constants"
import { useNavigate } from "react-router-dom/dist"
import { use } from "i18next"

const StockProductsView = () => {
  //meta title
  document.title = "View Product"
  const navigate = useNavigate()
  const { id } = useParams()
  const [col1, setcol1] = useState(false)
  const [edit, SetEdit] = useState(false)
  const [stocklevel, setStocklevel] = useState("")
  const [quantity, setQuantity] = useState("")
  const [showStockdivs, setShowStockdivs] = useState(false)

  const [loading, setloading] = useState(false)

  const [category, setCategory] = useState([])
  const [unit, setUnit] = useState([])

  const [formData, setFormData] = useState({
    prd_name: "",
    code_number: "",
    sale_info: "",
    category: "",
    prd_note: "",
    prd_weight: "",
    w_unit: "",
    sale_price: "",
    open_qun: "",
    low_stock_lvl: "",
    id: id,
    method: "edit_stock_product",
    typeofStock: "",
  })

  const handleChange = e => {
    if (edit) {
      const { name, value } = e.target
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
      }))
    }
  }
  const handleCategory = selectedOption => {
    if (selectedOption) {
      setFormData(prevData => ({
        ...prevData,
        category: selectedOption.value,
      }))
    }
  }
  const handleCategory2 = selectedOption => {
    if (selectedOption) {
      setFormData(prevData => ({
        ...prevData,
        w_unit: selectedOption.value,
        // Keep the entire selected option object
      }))
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (formData.prd_name === "") {
      toastr.error("Product Name is Required")
    } else if (formData.category === "") {
      toastr.error("Select Category")
    } else {
      axios
        .post(`${apiUrl}/products/addproduct.php`, formData)
        .then(response => {
          if (response.data === "success") {
            navigate(`/products/control/list_management/`)
            toastr.success("Form Submit Successfully")
          } else {
            toastr.error(response.data)
          }
        })
        .catch(error => {
          console.log("Error submitting form:", error)
        })
    }
  }

  const fetchProductValues = async id => {
    try {
      setloading(true) // Set loading state to true while fetching data

      const URL = `${apiUrl}/products/addproduct.php`
      const data = new FormData()
      data.append("id", id)
      data.append("app_method", "fetch_product_by_id")

      const response = await axios.post(URL, data)

      if (response.data) {
        setFormData({
          prd_name: response.data[0]?.prd_name || "",
          code_number: response.data[0]?.prd_code || "",
          sale_info: response.data[0]?.prd_sale_info || "",
          category: response.data[0]?.prd_category || "",
          prd_note: response.data[0]?.prd_note || "",
          prd_weight: response.data[0]?.prd_weight || "",
          w_unit: response.data[0]?.prd_unit || "",
          sale_price: response.data[0]?.prd_sale_price || "",
          open_qun: response.data[0]?.prd_qty_onhand || "",
          low_stock_lvl: response.data[0]?.prd_low_stock || "",
          id: id,
          method: "edit_stock_product",
        })
        setQuantity(response.data[0]?.prd_qty_onhand || "")
        setStocklevel(response.data[0]?.prd_low_stock || "")
      } else {
        console.log("Error in fetchProductValues: No data returned")
      }
    } catch (error) {
      console.error("Error fetching product values:", error)
    } finally {
      setloading(false) // Finally block ensures that loading state is set to false regardless of success or failure
    }
  }

  const addTostock = () => {
    setShowStockdivs(!showStockdivs)
    SetEdit(!edit)
    setFormData(prevData => ({
      ...prevData,
      typeofStock: "Stock",
    }))
  }

  useEffect(() => {
    fetchProductValues(id)
  }, [id])

  const editForm = () => {
    SetEdit(!edit)
  }

  const fetchCategories = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const category2 = new FormData()
    category2.append("app_method", "fetch_product_cat")
    axios
      .post(URL, category2)
      .then(response => {
        if (Array.isArray(response.data)) {
          setCategory(response.data)
        } else {
          console.log("error in fecth category")
        }
      })
      .catch(error => console.log(error))
  }
  const fetchUnits = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const unitdata = new FormData()
    unitdata.append("app_method", "fetch_weight_unit")
    axios
      .post(URL, unitdata)
      .then(response => {
        if (Array.isArray(response.data)) {
          setUnit(response.data)
        } else {
          console.log("error in fetch unit")
        }
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    fetchCategories()
    fetchUnits()
  }, [])

  if (loading) {
    return (
      <div className="spinner-container">
        <img src={logo} alt="Loading" className="spinner-logo" />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Products" breadcrumbItem="View Stock Product" /> */}
          <Row className="mb-3">
            <Col>
              <div>
                <h5>View Product</h5>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <Col xl={12}>
                      <div className="float-end">
                        <div className="d-flex gap-2 flex-wrap">
                          {(quantity === "" || stocklevel === "") && (
                            <Button
                              className="btn btn-sm btn-primary"
                              onClick={addTostock}
                            >
                              {showStockdivs === true
                                ? "Add to Non-Stock"
                                : "Add to Stock"}
                            </Button>
                          )}
                          <Button
                            className="btn btn-sm btn-secondary"
                            onClick={editForm}
                          >
                            {edit ? "In Edit Mode" : "Edit"}
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col sm="4">
                        <div className="mb-3">
                          <Label htmlFor="prd_name">Name*</Label>
                          <Input
                            id="prd_name"
                            name="prd_name"
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            value={formData.prd_name}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="code_number">Code / Number</Label>
                          <Input
                            id="code_number"
                            name="code_number"
                            value={formData.code_number}
                            onChange={handleChange}
                            type="number"
                            className="form-control"
                            placeholder="Code / Number"
                            disabled
                          />
                        </div>
                        <div className="mb-3">
                          <Label className="control-label" htmlFor="category">
                            Category
                          </Label>
                          <Select
                            className="select2-selection"
                            placeholder={formData.category}
                            title="Country"
                            name="category"
                            isDisabled={edit ? false : true}
                            options={
                              category.length > 0
                                ? category.map(e => ({
                                    value: e.category_name,
                                    label: e.category_name,
                                  }))
                                : [{ value: "", label: "No data Found" }]
                            }
                            onChange={handleCategory}
                          />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="sale_information">
                            Sale Information
                          </Label>
                          <textarea
                            className="form-control mb-3"
                            id="sale_information"
                            rows="3"
                            placeholder=""
                            name="sale_info"
                            value={formData.sale_info}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="notes">Notes</Label>
                          <textarea
                            className="form-control mb-3"
                            id="notes"
                            rows="3"
                            name="prd_note"
                            onChange={handleChange}
                            value={formData.prd_note}
                            placeholder=""
                          />
                        </div>
                      </Col>

                      <Col sm="4">
                        <div className="mb-3">
                          <Row>
                            <Col sm="6">
                              <Label htmlFor="_weight">Weight</Label>
                              <Input
                                id="_weight"
                                name="prd_weight"
                                type="number"
                                value={formData.prd_weight}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="0"
                              />
                            </Col>
                            <Col sm="6">
                              <Label className="control-label" htmlFor="w_unit">
                                Unit
                              </Label>
                              <Select
                                className="select2-selection"
                                placeholder={formData.w_unit}
                                title="Country"
                                onChange={e => handleCategory2(e)}
                                options={
                                  unit.length > 0
                                    ? unit.map(e => ({
                                        value: e.unit_name,
                                        label: e.unit_name,
                                      }))
                                    : [{ value: "", label: "No data found" }]
                                }
                                name="w_unit"
                                isDisabled={edit ? false : true}
                              />
                            </Col>
                          </Row>
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="sale_price">Sale Price / Rate</Label>
                          <Input
                            id="sale_price"
                            name="sale_price"
                            value={formData.sale_price}
                            onChange={handleChange}
                            type="number"
                            className="form-control"
                            placeholder="0"
                          />
                        </div>
                        {quantity !== "" && (
                          <div className="mb-3">
                            <Label htmlFor="open_qun">Opening Quantity*</Label>
                            <Input
                              id="open_qun"
                              name="open_qun"
                              value={formData.open_qun}
                              onChange={handleChange}
                              type="number"
                              className="form-control"
                              placeholder="0"
                              disabled
                            />
                          </div>
                        )}
                        {stocklevel !== "" && (
                          <div className="mb-3">
                            <Label htmlFor="low_stock_lvl">
                              Low Stock Level
                            </Label>
                            <Input
                              id="low_stock_lvl"
                              name="low_stock_lvl"
                              value={formData.low_stock_lvl}
                              onChange={handleChange}
                              type="number"
                              className="form-control"
                              placeholder="0"
                              disabled
                            />
                          </div>
                        )}

                        {showStockdivs && (
                          <>
                            <div className="mb-3">
                              <Label htmlFor="open_qun">
                                Opening Quantity*
                              </Label>
                              <Input
                                id="open_qun"
                                name="open_qun"
                                value={formData.open_qun}
                                onChange={handleChange}
                                type="number"
                                className="form-control"
                                placeholder="0"
                              />
                            </div>

                            <div style={{ display: "none" }}>
                              <Input
                                name="Nstock_to_stock"
                                id="Nstock_to_stock"
                                value={formData.Nstock_to_stock}
                              />
                            </div>
                          </>
                        )}
                      </Col>

                      <Col sm="12">
                        {edit && (
                          <>
                            <div className="custom-horizontal-line"></div>
                            <div className="float-end">
                              <Button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                                color="primary"
                              >
                                Submit
                              </Button>
                            </div>
                          </>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default StockProductsView
