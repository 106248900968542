import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import { connect } from "react-redux"
import { Row, Col, Label, Input, TabPane, TabContent } from "reactstrap"
import { Link } from "react-router-dom"
import {
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  ModalFooter,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap"

import classnames from "classnames"

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import "../../assets/style/style.css"

// import images
import logo from "../../assets/images/Pham logo.png"
import logoLightSvg from "../../assets/images/logo-light.svg"

import modalimage1 from "../../assets/images/product/img-7.png"
import profile from "../../assets/images/users/avatar-1.jpg"

import modalimage2 from "../../assets/images/product/img-4.png"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  logoutUserSuccess,
} from "../../store/actions"
import TableContainer from "components/Common/TableContainer"
import { useMediaQuery } from "react-responsive"

const Header = props => {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  const [modal, setmodal] = useState(false)
  const [activeTab, setActiveTab] = useState("1")
  const [alllocation, setAlllocation] = useState(false)
  const [startsession, setStartsession] = useState(false)
  const [timer, setTimer] = useState(0)
  const [isRunning, setIsRunning] = useState(false)
  const [lastout, setLastout] = useState("")

  useEffect(() => {
    let intervalId

    if (isRunning) {
      intervalId = setInterval(() => {
        setTimer(prevTimer => prevTimer + 1)
      }, 1000)
    } else {
      clearInterval(intervalId)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [isRunning])

  const toggleModal = () => {
    setmodal(!modal)
  }
  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  const ResumeTimeRecord = () => {
    const currentDateTime = new Date()
    const options = {
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    }
    const formattedDateTime = currentDateTime.toLocaleString(undefined, options)
    setLastout(formattedDateTime)
    localStorage.setItem("lasttime2", formattedDateTime)
    setIsRunning(!isRunning)
    setStartsession(!startsession)
    setmodal(false)
  }
  useEffect(() => {
    const lasttime3 = localStorage.getItem("lasttime2")
    if (lasttime3) {
      setLastout(lasttime3)
    }
  }, [])

  return (
    <React.Fragment>
      <header id="page-topbar">
        <Row>
          <div className="navbar-header">
            <Col xl={6}>
              <div className="d-flex">
                <div className="navbar-brand-box d-lg-none d-md-block">
                  <Link to="/" className="logo logo-dark"> 
                    <span className="logo-sm">
                      <img src={logo} alt="" width={30} />
                    </span>
                  </Link>
                  <Link to="/" className="logo logo-light">
                    <span className="logo-sm">
                      <img src={logo} alt="" width={30} />
                    </span>
                  </Link>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    tToggle()
                  }}
                  className="btn btn-sm px-3 font-size-16 header-item "
                  id="vertical-menu-btn"
                >
                  <i className="fa fa-fw fa-bars" />
                </button>

                <div className="app-search d-none d-lg-block">
                  <div
                    className="position-relative mt-2 font-size-15"
                    style={{ color: "#50A5F1" }}
                  >
                    Pham Enterprises Pvt Ltd
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={6} style={{ display: "flex", justifyContent: "end" }}>
              <div className="float-end">
                {/* <button
                  type="button"
                  className="btn btn-sm btn-primary px-3 font-size-13"
                  id="vertical-menu-btn"
                >
                  Support
                  <i className="fa fa-fw fa-user" />
                </button> */}
                {/* <button
                  type="button"
                  className="btn btn-sm px-3 font-size-16 header-item "
                  id="vertical-menu-btn"
                  onClick={toggleModal}
                >
                  <i className="fa fa-fw fa-cog" />
                </button> */}

                <ProfileMenu />
              </div>
            </Col>
          </div>
        </Row>
      </header>
      <Modal
        isOpen={modal}
        // role="dialog"
        // autoFocus={true}
        // centered={true}
        size="xl"
        // className="exampleModa2"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal)
        }}
        style={{}}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal)
            }}
          ></ModalHeader>
          <ModalBody>
            <Row>
              <Col sm={12}>
                <Row>
                  <Col md={3}>
                    <h5
                      className="font-size-14 mt-0"
                      style={{ color: "#2BA6F0" }}
                    >
                      {props.t("Journals")}
                    </h5>
                    <div className="custom-horizontal-line-dropdown-blue"></div>
                    <ul className="list-unstyled megamenu-list">
                      <li>
                        <Link to="#">{props.t("Journals")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                    </ul>
                    <h5
                      className="font-size-14 mt-0"
                      style={{ color: "#2BA6F0" }}
                    >
                      {props.t("Chart of Account")}
                    </h5>
                    <div className="custom-horizontal-line-dropdown-blue"></div>
                    <ul className="list-unstyled megamenu-list">
                      <li>
                        <Link to="#">{props.t("Nominals")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">{props.t("Nominals")}</Link>
                      </li>
                    </ul>
                    <h5
                      className="font-size-14 mt-0"
                      style={{ color: "#2BA6F0" }}
                    >
                      {props.t("User & Roles")}
                    </h5>
                    <div className="custom-horizontal-line-dropdown-blue"></div>
                    <ul className="list-unstyled megamenu-list">
                      <li>
                        <Link to="#">{props.t("User Management")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">{props.t("Roles Management")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">{props.t("Lock Data")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">{props.t("OP Method")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">{props.t("Log Management")}</Link>
                      </li>
                    </ul>
                  </Col>
                  <Col md={3}>
                    <h5
                      className="font-size-14 mt-0"
                      style={{ color: "#2BA6F0" }}
                    >
                      {props.t("Settings")}
                    </h5>
                    <div className="custom-horizontal-line-dropdown-blue"></div>
                    <ul className="list-unstyled megamenu-list">
                      <li>
                        <Link to="#">{props.t("Smart Settings")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">{props.t("Taxes and Year End")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">{props.t("Business Information")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">{props.t("Filters Management")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">{props.t("Column Management")}</Link>
                      </li>
                    </ul>
                    <h5
                      className="font-size-14 mt-0"
                      style={{ color: "#2BA6F0" }}
                    >
                      {props.t("Recurrence")}
                    </h5>
                    <div className="custom-horizontal-line-dropdown-blue"></div>
                    <ul className="list-unstyled megamenu-list">
                      <li>
                        <Link to="#">{props.t("Missed Recurrence")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                    </ul>
                    <h5
                      className="font-size-14 mt-0"
                      style={{ color: "#2BA6F0" }}
                    >
                      {props.t("Email & SMS")}
                    </h5>
                    <div className="custom-horizontal-line-dropdown-blue"></div>
                    <ul className="list-unstyled megamenu-list">
                      <li>
                        <Link to="#">{props.t("Email Settings")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">{props.t("Sent Emails")}</Link>
                      </li>
                    </ul>
                  </Col>
                  <Col md={3}>
                    <h5
                      className="font-size-14 mt-0"
                      style={{ color: "#2BA6F0" }}
                    >
                      {props.t("Sales Printing")}
                    </h5>
                    <div className="custom-horizontal-line-dropdown-blue"></div>
                    <ul className="list-unstyled megamenu-list">
                      <li>
                        <Link to="#">{props.t("Sales Invoice (SI)")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">{props.t("Sales Credit (SC)")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">{props.t("Sales Order (SO)")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">{props.t("Sales Receipt (SR)")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">{props.t("Delivery Note (GDNSI)")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">{props.t("Delivery Note (GDNSO)")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">{props.t("Customer (CUS)")}</Link>
                      </li>
                    </ul>
                    <h5
                      className="font-size-14 mt-0"
                      style={{ color: "#2BA6F0" }}
                    >
                      {props.t("Purchases Printing")}
                    </h5>
                    <div className="custom-horizontal-line-dropdown-blue"></div>
                    <ul className="list-unstyled megamenu-list">
                      <li>
                        <Link to="#">{props.t("Supplier Bill (VI)")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">{props.t("Supplier Credit (VC)")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">{props.t("Purchase Order (PO)")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">{props.t("Bill Payment (VP)")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">
                          {props.t("Goods Receipt Note (GRNPO)")}
                        </Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">
                          {props.t("Goods Receipt Note (GRNVI)")}
                        </Link>
                      </li>
                    </ul>
                    <h5
                      className="font-size-14 mt-0"
                      style={{ color: "#2BA6F0" }}
                    >
                      {props.t("Journal Printing")}
                    </h5>
                    <div className="custom-horizontal-line-dropdown-blue"></div>
                    <ul className="list-unstyled megamenu-list">
                      <li>
                        <Link to="#">{props.t("Journal")}</Link>
                      </li>
                    </ul>
                  </Col>
                  <Col md={3}>
                    <h5
                      className="font-size-14 mt-0"
                      style={{ color: "#2BA6F0" }}
                    >
                      {props.t("Journals")}
                    </h5>
                    <div className="custom-horizontal-line-dropdown-blue"></div>
                    <ul className="list-unstyled megamenu-list">
                      <li>
                        <Link to="#">{props.t("Journals")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                    </ul>
                    <h5
                      className="font-size-14 mt-0"
                      style={{ color: "#2BA6F0" }}
                    >
                      {props.t("Chart of Account")}
                    </h5>
                    <div className="custom-horizontal-line-dropdown-blue"></div>
                    <ul className="list-unstyled megamenu-list">
                      <li>
                        <Link to="#">{props.t("Nominals")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">{props.t("Nominals")}</Link>
                      </li>
                    </ul>
                    <h5
                      className="font-size-14 mt-0"
                      style={{ color: "#2BA6F0" }}
                    >
                      {props.t("User & Roles")}
                    </h5>
                    <div className="custom-horizontal-line-dropdown-blue"></div>
                    <ul className="list-unstyled megamenu-list">
                      <li>
                        <Link to="#">{props.t("User Management")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">{props.t("Roles Management")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">{props.t("Lock Data")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">{props.t("OP Method")}</Link>
                      </li>
                      <div className="custom-horizontal-line-dropdown"></div>
                      <li>
                        <Link to="#">{props.t("Log Management")}</Link>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
