import React, { useEffect, useState, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate, useParams } from "react-router-dom"
import Select from "react-select"
import "../../../assets/style/style.css"
import logo from "../../../assets/images/Pham logo black text.png"
import apiUrl from "config"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Collapse,
  Table,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  colspan,
  Modal,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
} from "reactstrap"
//Date Picker
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import classnames from "classnames"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import { formatAmount } from "components/Functoins/functions"

const OrderToInvoice = props => {
  const iconRef = useRef(null)
  const iconRef2 = useRef(null)
  const { id } = useParams()
  const order_id = id
  document.title = "Create Sale Invoice"
  const navigate = useNavigate()

  const [loading, setloading] = useState(false)

  const [totalQuantity, setTotalQuantity] = useState(0)
  const [subTotalAmount, setSubTotalAmount] = useState(0)
  const [FinalTotalAmount, setFinalTotalAmount] = useState(0)
  const [Tax_amount, setTax_amount] = useState(0)

  const [customers, setCustomers] = useState([])
  const [cusCode, SetcusCode] = useState([])
  const [prjCode, SetprjCode] = useState([])
  const [products, setProducrts] = useState([])
  const [custOpenBlnc, setCustOpenBlnc] = useState([])
  const [custCreditBlnc, setCustCreditBlnc] = useState([])
  const [projects, setProjects] = useState([])

  const [modeModal, setModeModal] = useState(false)
  const [bankModal, setBankModal] = useState(false)

  const [bank, setBank] = useState([])
  const [pay_mode, setPaymode] = useState([])
  //Invoice Data
  const [state, setState] = useState({
    inv_date_: new Date().toISOString().substr(0, 10),
    bank_name: "",
    payment_mode: "",
    inv_ref_no: "",
    inv_pay_by: "",
    inv_amount: "",
  })
  const handleState2 = e => {
    const { name, value } = e.target
    if (value === "add_mode") {
      addMode()
    } else if (value === "add_bank") {
      addBankAcc()
    } else {
      setState(prevData => ({
        ...prevData,
        [name]: value,
      }))
    }
  }
  //Customer Detail
  const [formData, setFormData] = useState({
    cust_name: "",
    project_name: "",
    address_: "",
    date_: new Date().toISOString().substr(0, 10),
    term_days: "0",
    due_date: new Date().toISOString().substr(0, 10),
    doc_no: "",
    cred_limt: "",
    balance_: "",
    final_notes: "",
    discount_Amount: "",
    tax_percentage: "",
  })

  const handleChange = e => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))

    if (name === "term_days") {
      const termDays = parseInt(value)
      const today = new Date(formData.date_)
      const dueDate = new Date(
        today.getTime() + parseInt(value) * 24 * 60 * 60 * 1000
      )
      if (!isNaN(termDays) && termDays > 0) {
        setFormData({
          ...formData,
          due_date: dueDate.toISOString().substr(0, 10),
          term_days: value,
        })
      } else {
        setFormData({
          ...formData,
          due_date: new Date().toISOString().substr(0, 10),
          term_days: value,
        })
      }
    }

    if (name === "discount_Amount") {
      const Amount = parseFloat(value)
      if (Amount >= subTotalAmount) {
        toastr.error("Discount Amount is less than Sub Total")
      } else {
        setFormData(prevData => ({
          ...prevData,
          discount_Amount: value,
        }))
      }
    }
  }
  //Fetch Order Detail By ID
  const fetchOrderData = id => {
    const URL = `${apiUrl}/sales/orders.php`
    const post = new FormData()
    post.append("order_id", id)
    post.append("app_method", "fetch_order_byId")
    axios
      .post(URL, post)
      .then(response => {
        if (Array.isArray(response.data)) {
          if (response.data.length > 0) {
            setFormData({
              cust_name: response.data[0].cust_name || "",
              address_: response.data[0].cust_add || "",
              project_name: response.data[0].project_name || "",
              date_:
                response.data[0].order_date ||
                new Date().toISOString().substr(0, 10),
              term_days: response.data[0].delivery_day || "0",
              due_date:
                response.data[0].due_date ||
                new Date().toISOString().substr(0, 10),
              doc_no: response.data[0].order_doc_no || "",
              cred_limt: "",
              balance_: "",
              final_notes: response.data[0].final_notes || "",
              tax_percentage: response.data[0].tax_percentage || "",
              discount_Amount: response.data[0].discount || "",
            })
            setTax_amount(parseFloat(response.data[0].tax_amount) || 0)
            setSubTotalAmount(parseFloat(response.data[0].sub_total) || 0)
            setFinalTotalAmount(parseFloat(response.data[0].total_amount) || 0)
            setTotalQuantity(parseFloat(response.data[0].total_quantity) || 0)
          } else {
            setFormData({
              cust_name: "",
              project_name: "",
              address_: "",
              date_: new Date().toISOString().substr(0, 10),
              term_days: "0",
              due_date: new Date().toISOString().substr(0, 10),
              doc_no: "",
              cred_limt: "",
              balance_: "",
              final_notes: "",

              discount_Amount: "",
              tax_percentage: "",
            })
          }
        } else {
          console.log(response.data)
        }
      })
      .catch(error => console.log(error))
  }
  //Fetch Order Products By ID
  const fetchOrderProducts = id => {
    const URL = `${apiUrl}/sales/orders.php`
    const post2 = new FormData()
    post2.append("order_id", id)
    post2.append("app_method", "fetch_orderProduct_byId")
    axios
      .post(URL, post2)
      .then(response => {
        if (Array.isArray(response.data)) {
          if (response.data.length > 0) {
            const fetchData = response.data
            setfetchData([...fetchData])
          } else {
            console.log("error")
          }
        } else {
          console.log(response.data)
        }
      })
      .catch(error => console.log(error))
  }
  useEffect(() => {
    fetchOrderData(order_id)
    fetchOrderProducts(order_id)
  }, [order_id])

  // Handle Customer
  const handleChangeCustomer = selectedOption => {
    if (selectedOption && selectedOption.value) {
      setFormData(prevData => ({
        ...prevData,
        cust_name: selectedOption.value,
      }))
    }
  }

  //Cards
  const [col1, setcol1] = useState(true)
  const [col2, setcol2] = useState(true)
  const [col3, setcol3] = useState(true)

  const t_col1 = () => {
    setcol1(!col1)
  }
  const t_col2 = () => {
    setcol2(!col2)
  }
  const t_col3 = () => {
    setcol3(!col3)
  }

  //Fetch Product Data

  const initialData2 = [
    {
      id: 1,
      product_name: "",
      prd_code: "",
      prd_des: "",
      prd_unit: "",
      prd_qty: "",
      prd_rate: "",
      prd_amount: "0.00",
      discount_per: "",
      discount_: "",
      tax_rate: "",
      sale_tex_: "",
      net_rate: "",
    },
  ]
  const [fetchData, setfetchData] = useState(initialData2)

  const handleEditF = (id, field, value) => {
    setfetchData(prevData =>
      prevData.map(item => {
        if (item.id === id) {
          return {
            ...item,
            [field]: value,
            prd_amount:
              field === "prd_qty" || field === "prd_rate"
                ? parseFloat(value !== "" ? value : "0") *
                  parseFloat(item.prd_rate !== "" ? item.prd_rate : "0")
                : item.prd_amount,
          }
        } else {
          return item
        }
      })
    )
    if (field === "product_name") {
      fetchEditProductDetail(value, id)
    }
  }
  const handleEditF2 = (id, field, value) => {
    setfetchData(prevData =>
      prevData.map(item => {
        if (item.id === id) {
          return {
            ...item,
            [field]: value,
            prd_amount:
              field === "prd_qty" || field === "prd_rate"
                ? parseFloat(item.prd_qty !== "" ? item.prd_qty : "0") *
                  parseFloat(value !== "" ? value : "0")
                : item.prd_amount,
          }
        } else {
          return item
        }
      })
    )
  }
  const deleteFetchRow = id => {
    const updatedData = fetchData.filter(item => item.id !== id)
    setfetchData(updatedData)
  }
  const fetchEditProductDetail = (prd_name, id) => {
    const URL = `${apiUrl}/products/addproduct.php`
    const Product = new FormData()

    Product.append("app_method", "get_product_detail")
    axios
      .post(URL, Product)
      .then(response => {
        if (Array.isArray(response.data)) {
          const SelectedProduct = response.data.find(
            response => response.prd_name === prd_name
          )
          if (SelectedProduct) {
            setfetchData(prevData =>
              prevData.map(item =>
                item.id === id
                  ? {
                      ...item,
                      prd_des: SelectedProduct.prd_sale_info || "",
                      prd_unit: SelectedProduct.prd_unit || "",
                      prd_rate: SelectedProduct.prd_sale_price || "",
                    }
                  : item
              )
            )
            ResetTotalAmount(id)
          } else {
            console.log("Error in fetch Detail")
          }
        } else {
          console.log("Error in fetch Data")
        }
      })
      .catch(error => console.log(error))
  }
  const ResetTotalAmount = id => {
    setfetchData(prevData => {
      return prevData.map(item =>
        item.id === id
          ? {
              ...item,
              prd_amount:
                parseFloat(item.prd_qty !== "" ? item.prd_qty : "0") *
                parseFloat(item.prd_rate !== "" ? item.prd_rate : "0"),
            }
          : item
      )
    })
  }

  //Initial Products Data
  //   const initialData = [
  //     {
  //       id2: 1,
  //       product_name2: "",
  //       prd_des2: "",
  //       prd_unit2: "",
  //       prd_qty2: "",
  //       prd_rate2: "",
  //       prd_amount2: "0.00",
  //       discount_per2: "",
  //       discount_2: "",
  //       tax_rate2: "",
  //       sale_tex_2: "",
  //       net_rate2: "",
  //     },
  //   ]
  const [data, setData] = useState([])
  const handleEdit = (id2, field, value) => {
    setData(prevData =>
      prevData.map(item => {
        if (item.id2 === id2) {
          return {
            ...item,
            [field]: value,
            prd_amount2:
              field === "prd_qty2" || field === "prd_rate2"
                ? parseFloat(value !== "" ? value : "0") *
                  parseFloat(item.prd_rate2 !== "" ? item.prd_rate2 : "0")
                : item.prd_amount2,
          }
        } else {
          return item
        }
      })
    )
    if (field === "product_name2") {
      fetchProductDetail(value, id2)
    }
  }
  const handleEdit2 = (id2, field, value) => {
    setData(prevData =>
      prevData.map(item => {
        if (item.id2 === id2) {
          return {
            ...item,
            [field]: value,
            prd_amount2:
              field === "prd_qty2" || field === "prd_rate2"
                ? parseFloat(item.prd_qty2 !== "" ? item.prd_qty2 : "0") *
                  parseFloat(value !== "" ? value : "0")
                : item.prd_amount2,
          }
        } else {
          return item
        }
      })
    )
  }
  const handleAddRow = () => {
    const newId = `${data.length + 1}N`
    const newRow = {
      id2: newId,
      product_name2: "",
      prd_des2: "",
      prd_unit2: "",
      prd_qty2: "",
      prd_code2: "",
      prd_rate2: "",
      prd_amount2: "0.00",
      discount_per2: "",
      discount_2: "",
      tax_rate2: "",
      sale_tex_2: "",
      net_rate2: "",
    }
    setData(prevData => [...prevData, newRow])
  }
  const deleteRow = id => {
    const updatedData = data.filter(item => item.id2 !== id)
    setData(updatedData)
  }
  const fetchProductDetail = (prd_name, id) => {
    const URL = `${apiUrl}/products/addproduct.php`
    const Product = new FormData()

    Product.append("app_method", "get_product_detail")
    axios
      .post(URL, Product)
      .then(response => {
        if (Array.isArray(response.data)) {
          const SelectedProduct = response.data.find(
            response => response.prd_name === prd_name
          )
          if (SelectedProduct) {
            setData(prevData =>
              prevData.map(item =>
                item.id2 === id
                  ? {
                      ...item,
                      prd_des2: SelectedProduct.prd_sale_info || "",
                      prd_unit2: SelectedProduct.prd_unit || "",
                      prd_rate2: SelectedProduct.prd_sale_price || "",
                    }
                  : item
              )
            )
            ResetNewTotalAmount(id)
          } else {
            console.log("Error in fetch Detail")
          }
        } else {
          console.log("Error in fetch Data")
        }
      })
      .catch(error => console.log(error))
  }
  const ResetNewTotalAmount = id => {
    setData(prevData => {
      return prevData.map(item =>
        item.id2 === id
          ? {
              ...item,
              prd_amount2:
                parseFloat(item.prd_qty2 !== "" ? item.prd_qty2 : "0") *
                parseFloat(item.prd_rate2 !== "" ? item.prd_rate2 : "0"),
            }
          : item
      )
    })
  }

  //Fetch Customer
  const fetchCustomers = async () => {
    try {
      setloading(true) // Set loading state to true while fetching data
      const URL = `${apiUrl}/sales/customer.php`
      const Data = new FormData()

      Data.append("app_method", "fetch_customers")
      const response = await axios.post(URL, Data)

      if (Array.isArray(response.data)) {
        setCustomers(response.data)
      } else {
        console.log("Error in fetch customers")
      }
    } catch (error) {
      console.error("Error fetching customers:", error)
    } finally {
      setloading(false) // Finally block ensures that loading state is set to false regardless of success or failure
    }
  }
  useEffect(() => {
    const subTotalAmount = data.reduce(
      (acc, row) => acc + parseFloat(row.amount || 0),
      0.0
    )
    setSubTotalAmount(subTotalAmount)
    fetchCustomers()
    fetchProducts()
  }, [])

  useEffect(() => {
    if (formData.cust_name) {
      fetchCustomerDetail(formData.cust_name)
    }
  }, [formData.cust_name])

  const fetchCustomerDetail = custName => {
    const URL = `${apiUrl}/sales/customer.php`
    const Data = new FormData()

    Data.append("app_method", "fetch_customers")
    axios
      .post(URL, Data)
      .then(response => {
        if (Array.isArray(response.data)) {
          setCustomers(response.data)
          const selectedCustomer = response.data.find(
            customer => customer.bussiness_name === custName
          )
          if (selectedCustomer) {
            setCustOpenBlnc(selectedCustomer.open_balance || "0.00")
            setCustCreditBlnc(selectedCustomer.credit_balance || "0.00")
            setFormData(prevData => ({
              ...prevData,
            }))
            SetcusCode(selectedCustomer.acc_no)
          } else {
            console.log("Error: Customer not found")
          }
        } else {
          console.log("Error in fetch customers")
        }
      })
      .catch(error => console.error("Error fetching customers:", error))
  }

  const fetchProducts = () => {
    const URL = `${apiUrl}/products/addproduct.php`
    const Product = new FormData()

    Product.append("app_method", "get_product_detail")
    axios
      .post(URL, Product)
      .then(response => {
        if (Array.isArray(response.data)) {
          setProducrts(response.data)
        } else {
          console.log("Error in fetch Data")
        }
      })
      .catch(error => console.log(error))
  }

  //Add Function
  useEffect(() => {
    let sumQuantity = 0
    let sumAmount = 0
    let Discount = 0
    let FTotalAmount = 0
    let TaxPercentage = 0
    let TaxAmount = 0

    data.forEach(item => {
      const quantity = parseFloat(item.prd_qty2) || 0
      const amount = parseFloat(item.prd_amount2) || 0

      sumQuantity += quantity
      sumAmount += amount
    })

    fetchData.forEach(item => {
      const quantity2 = parseFloat(item.prd_qty) || 0
      const amount2 = parseFloat(item.prd_amount) || 0

      sumQuantity += quantity2
      sumAmount += amount2
    })

    // Initialize final total amount with the sum amount
    FTotalAmount = sumAmount

    // Apply discount if provided
    if (formData.discount_Amount !== null && formData.discount_Amount !== "") {
      const discountValue = parseFloat(formData.discount_Amount)
      Discount = discountValue
      FTotalAmount -= Discount
    }

    // Apply tax if provided
    if (formData.tax_percentage !== null && formData.tax_percentage !== "") {
      TaxPercentage = parseFloat(formData.tax_percentage) || 0
      TaxAmount = FTotalAmount * (TaxPercentage / 100)
      FTotalAmount += TaxAmount
    }

    // Update state values
    setTotalQuantity(sumQuantity)
    setSubTotalAmount(sumAmount)
    setFinalTotalAmount(FTotalAmount)
    setTax_amount(TaxAmount)
  }, [data, fetchData, formData.discount_Amount, formData.tax_percentage])
  const [BtnDisabled, setBtnDisabled] = useState(false)
  //Submit Data
  const SubmitOrderToInvData = async e => {
    if (state.inv_amount > FinalTotalAmount) {
      toastr.error(`<b>Pay Amount</b> is not more then <b>Total Amount</b>`)
    } else if (formData.cust_name === "") {
      toastr.error("Customer is Required")
    } else if (formData.date_ === "") {
      toastr.error("Select Date")
    } else if (formData.due_date === "") {
      toastr.error("Due Date is Required")
    } else if (!fetchData.every(row => row.prd_des.length > 0)) {
      toastr.error("Set Product Description")
    } else if (!fetchData.every(row => row.prd_qty > 0)) {
      toastr.error("Set Prdouct Quantity")
    } else if (!fetchData.every(row => row.prd_rate > 0)) {
      toastr.error("Set Product Rate")
    } else if (!data.every(row => row.product_name2.length > 0)) {
      toastr.error("Select Product")
    } else if (!data.every(row => row.prd_des2.length > 0)) {
      toastr.error("Add Product description")
    } else if (!data.every(row => row.prd_unit2.length > 0)) {
      toastr.error("Set Product Unit")
    } else if (!data.every(row => row.prd_qty2 > 0)) {
      toastr.error("Set Product Quantity")
    } else if (!data.every(row => row.prd_rate2 > 0)) {
      toastr.error("Set Product Rate")
    } else if (!state.bank_name.length > 0) {
      toastr.error("Bank is Required")
    } else if (state.inv_amount <= 0) {
      toastr.error("Some Amount is Required for Invoice")
    } else if (formData.discount_Amount >= subTotalAmount) {
      toastr.error("Discount Amount is less than Sub Total")
    } else {
      setBtnDisabled(true)
      e.preventDefault()
      const URl = `${apiUrl}/Invoices/invoice.php`
      const formdata = new FormData()
      //fetch row data
      fetchData.forEach(row => {
        formdata.append("id[]", row.id)
        formdata.append("product_name[]", row.product_name)
        formdata.append("product_desc[]", row.prd_des)
        formdata.append("product_unit[]", row.prd_unit)
        formdata.append("product_qty[]", row.prd_qty)
        formdata.append("product_code[]", row.prd_code)
        formdata.append("product_rate[]", row.prd_rate)
        formdata.append("product_amount[]", row.prd_amount)
      })
      //new row data
      data.forEach(row => {
        formdata.append("id2[]", row.id2)
        formdata.append("product_name2[]", row.product_name2)
        formdata.append("product_desc2[]", row.prd_des2)
        formdata.append("product_unit2[]", row.prd_unit2)
        formdata.append("product_qty2[]", row.prd_qty2)
        formdata.append("product_code2[]", row.prd_code2)
        formdata.append("product_rate2[]", row.prd_rate2)
        formdata.append("product_amount2[]", row.prd_amount2)
      })
      //customer data
      formdata.append("order_id", order_id)
      formdata.append("customer_name", formData.cust_name)
      formdata.append("project_name", formData.project_name)
      formdata.append("customer_address", formData.address_)
      formdata.append("order_date", formData.date_)
      formdata.append("order_due_date", formData.due_date)
      formdata.append("order_doc_no", formData.doc_no)
      formdata.append("customer_cred_limt", custCreditBlnc)
      formdata.append("customer_balance", custOpenBlnc)
      formdata.append("final_notes", formData.final_notes)
      formdata.append(
        "term_days",
        formData.term_days !== "" ? formData.term_days : "0"
      )
      formdata.append(
        "discount_amount",
        formData.discount_Amount !== "" ? formData.discount_Amount : "0"
      )
      formdata.append(
        "tax_percentage",
        formData.tax_percentage !== "" ? formData.tax_percentage : "0"
      )
      //invoice data
      formdata.append("inv_date", state.inv_date_)
      formdata.append("inv_bank", state.bank_name)
      formdata.append("inv_pay_mode", state.payment_mode)
      formdata.append("inv_ref_no", state.inv_ref_no)
      formdata.append("inv_pay_by", state.inv_pay_by)
      formdata.append("inv_amount", state.inv_amount)

      //cal
      formdata.append("total_quantity", totalQuantity)
      formdata.append("tax_amount", Tax_amount !== "" ? Tax_amount : "0")
      formdata.append("sub_total", subTotalAmount)
      formdata.append("total_amount", FinalTotalAmount)
      formdata.append("app_method", "order_to_invoice")
      try {
        const response = await axios.post(URl, formdata)

        if (response.data === "success") {
          toastr.success("Add Successfully")
          navigate("/income/control/sales_invoice")
          // console.log(response.data)
        } else {
          toastr.error("Error in Sumbittion")
          console.log(response.data)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setBtnDisabled(false)
      }
    }
  }

  //Bank
  const fetchBank = () => {
    const URL = `${apiUrl}/BankManagemnt/addbank.php`
    const Data = new FormData()
    Data.append("app_method", "fetch_bank")

    axios
      .post(URL, Data)
      .then(response => {
        if (response.data) {
          setBank(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  //Pay Mode
  const fetchPayMode = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const pm = new FormData()
    pm.append("app_method", "fetch_pay_mode")

    axios
      .post(URL, pm)
      .then(response => {
        if (response.data) {
          setPaymode(response.data)
        } else {
          console.log("error")
          console.log(response.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  //Fetch Projects
  const fetchProject = () => {
    const URL = `${apiUrl}/Projects/projects.php`
    const Pdata = new FormData()
    Pdata.append("app_method", "fetch_all_projects")

    axios
      .post(URL, Pdata)
      .then(response => {
        if (Array.isArray(response.data)) {
          setProjects(response.data)
          const code = response.data.find(
            item => item.project_name === formData.project_name
          )
          if (code) {
            SetprjCode(code.project_code)
          }
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    fetchBank()
    fetchPayMode()
    fetchProject()
  }, [formData.project_name])

  //Bank Add Modal
  const [bankdata, setbankdata] = useState({
    bank_name: "",
    bank_code: "",
    bank_disc: "",
    method: "add_bank_detial",
  })
  const onChangeModal2 = e => {
    const { name, value } = e.target
    setbankdata(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  const handleBankAccount = e => {
    const URL = `${apiUrl}/BankManagemnt/addbank.php`
    const data = new FormData()
    data.append("bank_name", bankdata.bank_name)
    data.append("bank_disc", bankdata.bank_disc)
    data.append("app_method", "add_bank_detail")

    e.preventDefault()
    if (bankdata.bank_name === "") {
      toastr.error("Bank name is Required")
    } else {
      axios
        .post(URL, data)
        .then(response => {
          if (response.data === "success") {
            setbankdata({
              bank_name: "",
              bank_code: "",
              bank_disc: "",
            })
            toastr.success("Submit Successfully")
            setBankModal(!bankModal)
            fetchBank()
            setState(prevData => ({
              ...prevData,
              bank_name: bankdata.bank_name,
            }))
            // window.location.reload()
          } else {
            toastr.error(response.data)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
  const addBankAcc = () => {
    setBankModal(!bankModal)
  }

  //Add Mode
  const [mode, setMode] = useState("")
  const handleMode = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const modedata = new FormData()
    modedata.append("mode_name", mode)
    modedata.append("app_method", "payment_mode")
    if (mode === "") {
      toastr.error("Mode name is Required")
    } else {
      axios
        .post(URL, modedata)
        .then(response => {
          if (response.data === "success") {
            setModeModal(!modeModal)
            toastr.success("Add Successfully")
            fetchPayMode()
            setMode("")
            setState(prevData => ({
              payment_mode: mode,
            }))
          } else {
            toastr.error("Error in Adding")
          }
        })
        .catch(error => console.log(error))
    }
  }
  const addMode = () => {
    setModeModal(!modeModal)
  }

  //Close
  const close = e => {
    e.preventDefault()
    navigate("/income/sales/quotations")
  }

  //Textarea lenght
  const [textareaHeights, setTextareaHeights] = useState({})
  const [textareaHeights2, setTextareaHeights2] = useState({})

  const handleTextareaChange = (id, value) => {
    const textarea = document.getElementById(`textarea-${id}`)
    if (textarea) {
      textarea.style.height = "auto"
      textarea.style.height = textarea.scrollHeight + "px"
      setTextareaHeights(prevHeights => ({
        ...prevHeights,
        [id]: textarea.scrollHeight,
      }))
    }
  }
  const handleTextareaChange2 = (id, value) => {
    const textarea = document.getElementById(`textarea-${id}`)
    if (textarea) {
      textarea.style.height = "auto"
      textarea.style.height = textarea.scrollHeight + "px"
      setTextareaHeights2(prevHeights => ({
        ...prevHeights,
        [id]: textarea.scrollHeight,
      }))
    }
  }

  useEffect(() => {
    fetchData.map(item => {
      handleTextareaChange(item.id, item.prd_des)
    })
    data.map(item => {
      handleTextareaChange2(item.id2, item.prd_desc)
    })
  }, [data, fetchData])

  const handleClickOutside = event => {
    if (iconRef.current && !iconRef.current.contains(event.target)) {
      setDiscountMode(false)
    }
    if (iconRef2.current && !iconRef2.current.contains(event.target)) {
      setTaxMode(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  //Set Discount
  const [discountMode, setDiscountMode] = useState(false)
  const setDiscount = () => {
    setDiscountMode(!discountMode)
  }

  //Set Tax
  const [taxMode, setTaxMode] = useState(false)
  const setTax = () => {
    setTaxMode(!taxMode)
  }

  if (loading) {
    return (
      <div className="spinner-container">
        <img src={logo} alt="Loading" className="spinner-logo" />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="10">
              <div>
                <h5 className="mt-2">Create Sales Invoice</h5>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <div className="accordion" id="accordion">
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col1,
                      })}
                      type="button"
                      onClick={t_col1}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Customer Details
                    </button>
                  </h2>

                  <Collapse isOpen={col1} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row>
                        <Col xl={4}>
                          <div className="mb-3">
                            <Label
                              className="control-label"
                              htmlFor="cust_name"
                            >
                              Customers
                            </Label>
                            <InputGroup>
                              <select
                                defaultValue="0"
                                className="form-select"
                                onChange={e =>
                                  handleChangeCustomer(
                                    e.target.selectedOptions[0]
                                  )
                                }
                                name="cust_name"
                                value={formData.cust_name}
                              >
                                <option value={""}>Select ...</option>
                                <option value={formData.cust_name}>
                                  {formData.cust_name} ({cusCode})
                                </option>

                                {/* {customers.length > 0 ? (
                                  customers
                                    .filter(e => !!e)
                                    .map((e, index) => (
                                      <option
                                        key={index}
                                        value={e.bussiness_name}
                                      >
                                        {`${e.bussiness_name} (${e.acc_no})`}
                                      </option>
                                    ))
                                ) : (
                                  <option value="" disabled>
                                    No Data Found
                                  </option>
                                )} */}
                              </select>
                            </InputGroup>
                          </div>
                          <div className="mb-3">
                            <label>Address</label>
                            <Input
                              className="form-control"
                              type="textarea"
                              placeholder="Address"
                              name="address_"
                              rows="5"
                              value={formData.address_}
                              onChange={handleChange}
                            ></Input>
                          </div>
                          <div className="mb-3">
                            <Label
                              className="control-label"
                              htmlFor="project_name"
                            >
                              Project
                            </Label>
                            <select
                              defaultValue="0"
                              className="form-select"
                              onChange={handleChange}
                              name="project_name"
                              value={formData.project_name}
                            >
                              <option value={""}>Select ...</option>
                              <option value={formData.project_name}>
                                {formData.project_name} ({prjCode})
                              </option>
                              {/* {projects.length > 0 ? (
                                projects
                                  .filter(e => !!e)
                                  .map((e, index) => (
                                    <option
                                      key={index}
                                      value={e.project_name}
                                      selected={
                                        e.project_name === formData.project_name
                                      }
                                    >
                                      {e.project_name} ({e.project_code})
                                    </option>
                                  ))
                              ) : (
                                <option value="" disabled>
                                  No Data Found
                                </option>
                              )} */}
                            </select>
                          </div>
                        </Col>

                        <Col xl={4}>
                          <div className="mb-3">
                            <label>Date</label>
                            <Input
                              className="form-control"
                              type="date"
                              //   placeholder="Payment Mode"
                              name="date_"
                              value={formData.date_}
                              onChange={handleChange}
                            ></Input>
                          </div>
                          <div className="mb-3">
                            <label>Term Days</label>
                            <Input
                              className="form-control"
                              type="text"
                              min={0}
                              placeholder="0"
                              name="term_days"
                              value={formData.term_days}
                              onChange={handleChange}
                            ></Input>
                          </div>
                          <div className="mb-3">
                            <label>Due Date</label>
                            <Input
                              className="form-control"
                              type="date"
                              placeholder="Payment Mode"
                              name="due_date"
                              value={formData.due_date}
                              onChange={handleChange}
                            ></Input>
                          </div>
                          <div className="mb-3">
                            <label>Doc No.</label>
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="doc no."
                              name="doc_no"
                              value={formData.doc_no}
                              onChange={handleChange}
                              disabled
                            ></Input>
                          </div>
                        </Col>
                        <Col xl={4}>
                          <div className="mb-3">
                            <label>Credit Limit</label>
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="0.00"
                              disabled
                              name="cred_limt"
                              value={formatAmount(custCreditBlnc)}
                              onChange={handleChange}
                            ></Input>
                          </div>
                          <div className="mb-3">
                            <label>Balance</label>
                            <Input
                              className="form-control"
                              type="text"
                              disabled
                              placeholder="0.00"
                              name="balance_"
                              value={formatAmount(custOpenBlnc)}
                              onChange={handleChange}
                            ></Input>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne2">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col2,
                      })}
                      type="button"
                      onClick={t_col2}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Product Detail
                    </button>
                  </h2>

                  <Collapse isOpen={col2} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row className="mb-3">
                        <Col xl={12}>
                          <table>
                            <thead>
                              <tr>
                                <th>Product</th>
                                <th>{""}</th>
                                <th>Description</th>
                                <th>Unit</th>
                                <th>Qty</th>
                                <th>Rate</th>
                                <th>Amount</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {fetchData.map(item => (
                                <tr key={item.id}>
                                  <td style={{ display: "none" }}>{item.id}</td>
                                  <td colSpan={2.5}>
                                    <select
                                      className="form-select"
                                      type="text"
                                      value={item.product_name}
                                      onChange={e =>
                                        handleEditF(
                                          item.id,
                                          "product_name",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">Select...</option>
                                      {products.length > 0 ? (
                                        products
                                          .filter(e => !!e)
                                          .map((e, index) => (
                                            <option
                                              key={index}
                                              value={e.prd_name}
                                            >
                                              {e.prd_type == "Stock"
                                                ? `${e.prd_name} (${
                                                    e.prd_qty_onhand !== null
                                                      ? Number(e.prd_qty_onhand)
                                                      : Number(0)
                                                  }) `
                                                : e.prd_name}
                                            </option>
                                          ))
                                      ) : (
                                        <option>No Data Found</option>
                                      )}
                                    </select>
                                  </td>
                                  <td>
                                    <textarea
                                      type="text"
                                      id={`textarea-${item.id}`}
                                      style={{
                                        height:
                                          textareaHeights[item.id] || "auto",
                                      }}
                                      value={item.prd_des}
                                      onChange={e => {
                                        handleEditF(
                                          item.id,
                                          "prd_des",
                                          e.target.value
                                        )
                                        handleTextareaChange(
                                          item.id,
                                          e.target.value
                                        )
                                      }}
                                    >
                                      {item.prd_des}
                                    </textarea>
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      value={item.prd_unit}
                                      onChange={e =>
                                        handleEditF(
                                          item.id,
                                          "prd_unit",
                                          e.target.value
                                        )
                                      }
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      value={item.prd_qty}
                                      min={1}
                                      placeholder="0"
                                      onChange={e =>
                                        handleEditF(
                                          item.id,
                                          "prd_qty",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      value={item.prd_rate}
                                      placeholder="0"
                                      min={1}
                                      onChange={e =>
                                        handleEditF2(
                                          item.id,
                                          "prd_rate",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      min={1}
                                      value={formatAmount(item.prd_amount)}
                                      onChange={e =>
                                        handleEditF(
                                          item.id,
                                          "prd_amount",
                                          e.target.value
                                        )
                                      }
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <i
                                      className="mdi mdi-delete font-size-18"
                                      onClick={() => deleteFetchRow(item.id)}
                                      style={{
                                        color: "#F46A6A",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            <tbody>
                              {data.map(item => (
                                <tr key={item.id2}>
                                  <td style={{ display: "none" }}>
                                    {item.id2}
                                  </td>
                                  <td colSpan={2}>
                                    <select
                                      className="form-select"
                                      type="text"
                                      value={item.product_name2}
                                      onChange={e =>
                                        handleEdit(
                                          item.id2,
                                          "product_name2",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">Select...</option>
                                      {products.length > 0 ? (
                                        products
                                          .filter(e => !!e)
                                          .map((e, index) => (
                                            <option
                                              key={index}
                                              value={e.prd_name}
                                            >
                                              {e.prd_type == "Stock"
                                                ? `${e.prd_name} (${
                                                    e.prd_qty_onhand !== null
                                                      ? Number(e.prd_qty_onhand)
                                                      : Number(0)
                                                  }) `
                                                : e.prd_name}
                                            </option>
                                          ))
                                      ) : (
                                        <option>No Data Found</option>
                                      )}
                                    </select>
                                  </td>
                                  <td>
                                    <textarea
                                      type="text"
                                      rows={2}
                                      id={`textarea-${item.id2}`}
                                      value={item.prd_des2}
                                      style={{
                                        height:
                                          textareaHeights2[item.id2] || "auto",
                                      }}
                                      onChange={e => {
                                        handleEdit(
                                          item.id2,
                                          "prd_des2",
                                          e.target.value
                                        )
                                        handleTextareaChange2(
                                          item.id2,
                                          e.target.value
                                        )
                                      }}
                                    >
                                      {item.prd_des2}
                                    </textarea>
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      value={item.prd_unit2}
                                      onChange={e =>
                                        handleEdit(
                                          item.id2,
                                          "prd_unit2",
                                          e.target.value
                                        )
                                      }
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      value={item.prd_qty2}
                                      min={1}
                                      placeholder="0"
                                      onChange={e =>
                                        handleEdit(
                                          item.id2,
                                          "prd_qty2",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      value={item.prd_rate2}
                                      min={1}
                                      placeholder="0"
                                      onChange={e =>
                                        handleEdit2(
                                          item.id2,
                                          "prd_rate2",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      min={1}
                                      value={parseFloat(
                                        item.prd_amount2
                                      ).toFixed(2)}
                                      onChange={e =>
                                        handleEdit(
                                          item.id2,
                                          "prd_amount2",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <i
                                      className="mdi mdi-delete font-size-18"
                                      onClick={() => deleteRow(item.id2)}
                                      style={{
                                        color: "#F46A6A",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>

                            <tbody>
                              <thead></thead>
                              <tr
                                style={{
                                  borderTop: "1px solid black",
                                }}
                              >
                                <td colSpan={2}>
                                  <strong>Total</strong>
                                </td>
                                <td>
                                  <div></div>
                                </td>
                                <td>
                                  <div></div>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={totalQuantity}
                                    disabled
                                  />
                                </td>
                                <td>
                                  <div></div>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={formatAmount(subTotalAmount)}
                                    disabled
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="my-3">
                            <Button
                              onClick={handleAddRow}
                              color="primary"
                              className="btn btn-sm"
                            >
                              <i className="fa fa-fw fa-plus" />
                              Add Row
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </div>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl={4}>
                        <label>Notes</label>
                        <Input
                          className="form-control"
                          type="textarea"
                          placeholder="Notes"
                          name="final_notes"
                          rows="7"
                          value={formData.final_notes}
                          onChange={handleChange}
                        ></Input>
                      </Col>
                      <Col xl={4}></Col>
                      <Col xl={4}>
                        <Table className="table-nowrap">
                          <thead>
                            <tr>
                              <td className="">Sub Total</td>
                              <td className="text-end">
                                {formatAmount(subTotalAmount)}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Discount {""}
                                <span>
                                  <i
                                    className="bx bx-edit font-size-18"
                                    style={{
                                      cursor: "pointer",
                                      fontWeight: "bold",
                                    }}
                                    onClick={setDiscount}
                                    ref={iconRef}
                                  />
                                </span>
                              </td>
                              {discountMode ? (
                                <td className="text-end">
                                  <input
                                    type="text"
                                    name="discount_Amount"
                                    placeholder="0.00"
                                    value={formData.discount_Amount}
                                    onChange={handleChange}
                                    ref={iconRef}
                                  />
                                </td>
                              ) : (
                                <td className="text-end">
                                  {formData.discount_Amount !== ""
                                    ? formatAmount(formData.discount_Amount)
                                    : "0.00"}
                                </td>
                              )}
                            </tr>
                            <tr>
                              <td className="">
                                Sale Tax (%)
                                <span>
                                  <i
                                    className="bx bx-edit font-size-18"
                                    style={{
                                      cursor: "pointer",
                                      fontWeight: "bold",
                                    }}
                                    onClick={setTax}
                                    ref={iconRef2}
                                  />
                                </span>
                              </td>
                              {taxMode ? (
                                <td className="text-end">
                                  <input
                                    type="text"
                                    name="tax_percentage"
                                    placeholder="%"
                                    value={formData.tax_percentage}
                                    onChange={handleChange}
                                    ref={iconRef2}
                                  />
                                </td>
                              ) : (
                                <td className="text-end">
                                  {Tax_amount !== ""
                                    ? formatAmount(Tax_amount)
                                    : "0.00"}
                                </td>
                              )}
                            </tr>
                            <tr>
                              <td className="border-0">
                                <strong>Total</strong>
                              </td>
                              <td className="border-0 text-end">
                                <h4 className="m-0">
                                  {formatAmount(FinalTotalAmount)}
                                </h4>
                              </td>
                            </tr>
                          </thead>
                        </Table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <div className="accordion-item mb-3">
                  <h2 className="accordion-header" id="headingOne3">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col3,
                      })}
                      type="button"
                      onClick={t_col3}
                      style={{ cursor: "pointer", backgroundColor: "white" }}
                    >
                      Add Receipt
                    </button>
                  </h2>

                  <Collapse isOpen={col3} className="accordion-collapse">
                    <div className="accordion-body">
                      <Row>
                        <Col xl={2}>
                          <div className="mb-3">
                            <label>Date</label>
                            <Input
                              className="form-control"
                              type="date"
                              //   placeholder="Payment Mode"
                              name="inv_date_"
                              value={state.inv_date_}
                              onChange={handleState2}
                            ></Input>
                          </div>
                        </Col>
                        <Col xl={2}>
                          <div className="mb-3">
                            <label className="" htmlFor="autoSizingSelect">
                              Bank
                            </label>
                            <select
                              defaultValue="0"
                              className="form-select"
                              onChange={handleState2}
                              name="bank_name"
                              value={state.bank_name}
                            >
                              <option value={null}>Select bank ...</option>
                              <option
                                value="add_bank"
                                style={{ color: "blue" }}
                              >
                                + Add New
                              </option>
                              {bank.length > 0 ? (
                                bank
                                  .filter(e => !!e)
                                  .map((e, index) => (
                                    <option key={index} value={e.bank_name}>
                                      {e.bank_name} ({e.bank_code})
                                    </option>
                                  ))
                              ) : (
                                <option value="" disabled>
                                  No Data Found
                                </option>
                              )}
                            </select>
                          </div>
                        </Col>
                        <Col xl={2}>
                          <div className="mb-3">
                            <label className="" htmlFor="autoSizingSelet">
                              Payment Mode
                            </label>
                            <select
                              className="form-select"
                              type="text"
                              value={state.payment_mode}
                              onChange={handleState2}
                              name="payment_mode"
                            >
                              <option value="">Mode...</option>
                              <option
                                value="add_mode"
                                style={{ color: "blue" }}
                              >
                                + Add new
                              </option>
                              {pay_mode.length > 0 ? (
                                pay_mode
                                  .filter(e => !!e)
                                  .map((e, index) => (
                                    <option key={index} value={e.mode_name}>
                                      {e.mode_name}
                                    </option>
                                  ))
                              ) : (
                                <option value="" disabled>
                                  No Data Found
                                </option>
                              )}
                            </select>
                          </div>
                        </Col>
                        <Col xl={2}>
                          <div className="mb-3">
                            <label>Ref No</label>
                            <Input
                              className="form-control"
                              type="text"
                              min={0}
                              placeholder="0"
                              name="inv_ref_no"
                              value={state.inv_ref_no}
                              onChange={handleState2}
                            ></Input>
                          </div>
                        </Col>
                        <Col xl={2}>
                          <div className="mb-3">
                            <label>Pay By</label>
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="name"
                              name="inv_pay_by"
                              value={state.inv_pay_by}
                              onChange={handleState2}
                            ></Input>
                          </div>
                        </Col>
                        <Col xl={2}>
                          <div className="mb-3">
                            <label>Amount</label>
                            <Input
                              className="form-control"
                              type="text"
                              min={0}
                              placeholder="0.00"
                              name="inv_amount"
                              value={state.inv_amount}
                              onChange={handleState2}
                            ></Input>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </div>
              </div>
              <Row className="mb-3">
                <Col xl={4}>
                  <Button
                    color="danger"
                    className="btn btn-danger waves-effect waves-light"
                    onClick={close}
                  >
                    Close
                  </Button>
                </Col>
                <Col xl={8}>
                  <div className="d-flex flex-wrap gap-2 float-end">
                    <Button
                      color="success"
                      className="btn btn-secondary waves-effect waves-light"
                      onClick={SubmitOrderToInvData}
                      disabled={BtnDisabled}
                    >
                      {BtnDisabled ? "Loading..." : "Save & Close"}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Modal
          isOpen={modeModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setModeModal(!modeModal)
          }}
        >
          <div>
            <Form>
              <ModalHeader
                toggle={() => {
                  setModeModal(!modeModal)
                }}
              >
                Add Mode
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col xl={8}>
                    <div className="mb-3">
                      <label>Payment Mode</label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Payment Mode"
                        name="payment_mode"
                        value={mode}
                        onChange={e => setMode(e.target.value)}
                      ></Input>
                    </div>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => {
                    setModeModal(!modeModal)
                  }}
                >
                  Close
                </Button>
                <Button color="primary" onClick={handleMode}>
                  Submit
                </Button>
              </ModalFooter>
            </Form>
          </div>
        </Modal>

        <Modal
          isOpen={bankModal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setBankModal(!bankModal)
          }}
        >
          <div>
            <Form>
              <ModalHeader
                toggle={() => {
                  setBankModal(!bankModal)
                }}
              >
                Bank Account New
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <label>Bank Account *</label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Name"
                        name="bank_name"
                        value={bankdata.bank_name}
                        onChange={onChangeModal2}
                      ></Input>
                    </div>
                    <div className="mb-3">
                      <label>Code</label>
                      <Input
                        className="form-control"
                        type="number"
                        placeholder="code"
                        name="bank_code"
                        value={bankdata.bank_code}
                        onChange={onChangeModal2}
                        disabled
                      ></Input>
                    </div>
                    <div className="mb-3">
                      <label>Description</label>
                      <Input
                        className="form-control"
                        type="textarea"
                        placeholder="description"
                        name="bank_disc"
                        value={bankdata.bank_disc}
                        onChange={onChangeModal2}
                      />
                    </div>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => {
                    setBankModal(!bankModal)
                  }}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  onClick={handleBankAccount}
                >
                  Submit
                </Button>
              </ModalFooter>
            </Form>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default OrderToInvoice
