import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link, useLocation, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { formatAmount } from "components/Functoins/functions"
import logo from "../../../assets/images/Pham logo black text.png"
import apiUrl from "config"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  InputGroup,
  Button,
} from "reactstrap"
import "../../../assets/style/style.css"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { useReactToPrint } from "react-to-print"
import axios from "axios"
import { upperCase } from "lodash"

const ProjectInvoicesReport = props => {
  //meta title
  document.title = "Bank Report"

  const [payments, setPayments] = useState([])
  const [filterDiv, setFilterdiv] = useState(false)
  const [projects, setProjects] = useState([])
  const [totalInvoicedamount, setTotalInvoicedamount] = useState("")
  const [totalPayamount, setTotalPayamount] = useState("")
  const [state, setState] = useState({
    project_name: "",
  })

  const handleState = e => {
    const { name, value } = e.target
    setState(prData => ({
      ...prData,
      [name]: value,
    }))
  }

  const fetchProject = () => {
    const data2 = {
      method: "fetch_all_projects",
    }
    axios
      .post(`${apiUrl}/Projects/projects.php`, data2)
      .then(response => {
        if (Array.isArray(response.data)) {
          setProjects(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    fetchProject()
  }, [])

  const handleFilter = () => {
    if (state.project_name.length === 0) {
      toastr.error("Set Project")
    } else {
      const URL = `${apiUrl}/Invoices/invoice.php`
      const Pdata = new FormData()
      Pdata.append("app_method", "fetch_all_invoices")
      setFilterdiv(true)
      axios
        .post(URL, Pdata)
        .then(response => {
          if (Array.isArray(response.data)) {
            let filteredPayments = response.data

            if (state.project_name) {
              filteredPayments = response.data.filter(
                payment => payment.project_name === state.project_name
              )
            }

            setPayments(filteredPayments)
          } else {
            console.log("Error in fetch Data")
          }
        })
        .catch(error => console.log(error))
    }
  }

  useEffect(() => {
    const totalAmount = payments.reduce(
      (acc, row) => acc + parseFloat(row.total_amount || 0),
      0.0
    )
    const totalpayamount = payments.reduce(
      (acc, row) => acc + parseFloat(row.paid_amount || 0),
      0.0
    )

    setTotalInvoicedamount(totalAmount)
    setTotalPayamount(totalpayamount)
  })

  //Print
  const printRef = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  })
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Invoices" breadcrumbItem="Invoice Detail" /> */}
          <Row className="mb-3">
            <Col xl="12">
              <div>
                <h5 className="mt-2">Projects Invoices Reports</h5>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg="12">
              <Card>
                <CardTitle>
                  <CardBody>
                    <Row>
                      <Col xl={3}>
                        <div className="col-sm-auto">
                          <label className="" htmlFor="autoSizingSelect">
                            Project
                          </label>
                          <InputGroup>
                            <select
                              defaultValue="0"
                              className="form-select"
                              onChange={handleState}
                              name="project_name"
                              value={state.project_name}
                            >
                              <option value={""}>Select project ...</option>
                              {projects.length > 0 ? (
                                projects
                                  .filter(e => !!e)
                                  .map((e, index) => (
                                    <option key={index} value={e.project_name}>
                                      {e.project_name} ({e.project_code})
                                    </option>
                                  ))
                              ) : (
                                <option value="" disabled>
                                  No project found
                                </option>
                              )}
                            </select>
                          </InputGroup>
                        </div>
                      </Col>
                      <Col xl={3}>
                        <Button
                          className="btn btn-primary mt-4"
                          color="primary"
                          onClick={handleFilter}
                        >
                          Filter
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </CardTitle>
              </Card>
            </Col>
          </Row>
          {filterDiv && (
            <Row>
              <Col xl={12}>
                <Card>
                  <CardBody>
                    <div className="invoice-title">
                      {/* <h4 className="float-end font-size-16">
                      Order # {orderNo}
                    </h4> */}
                      <div className="mb-4">
                        <img
                          src={logo}
                          alt="logo-dark"
                          className="logo-dark-element"
                          height="80"
                        />
                      </div>
                    </div>
                    {/* <hr /> */}
                    <Row className="mb-3">
                      <Col xl={12}>
                        <h3 className="text-center">Pham Enterprises</h3>
                        <h5 className="text-center">Project Invoices</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl={12}>
                        <table>
                          <thead
                            style={{
                              border: "1px solid black",
                              borderCollapse: "-moz-initial",
                            }}
                          >
                            <tr className="black-head">
                              <th>Inv ID</th>
                              <th>Date</th>
                              <th>Customer</th>
                              <th>Project</th>
                              <th>Invoice Amount</th>
                              <th>Paid Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {payments.length > 0 ? (
                              payments.map((item, index) => (
                                <tr key={item.id}>
                                  <td>
                                    <Link
                                      to={`/income/view_sale_invoice/${item.inv_no}`}
                                    >
                                      {item.inv_no}
                                    </Link>
                                  </td>
                                  <td>{item.created_date}</td>
                                  <td>{item.cust_name}</td>
                                  <td>
                                    {item.project_name}({item.project_code})
                                  </td>

                                  <td>{formatAmount(item.total_amount)}</td>
                                  <td>{formatAmount(item.paid_amount)}</td>
                                </tr>
                              ))
                            ) : (
                              <>
                                <tr>
                                  <td colSpan="9" className="border-0">
                                    <h6 className="text-center mt-2 mb-2">
                                      No Transactions Found{" "}
                                    </h6>
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                          <tbody>
                            <tr
                              style={{
                                border: "1px solid black",
                              }}
                            ></tr>
                            <tr>
                              <td colSpan={4}>
                                <b>Total</b>
                              </td>
                              <td>
                                <b> {formatAmount(totalInvoicedamount)}</b>
                              </td>
                              <td>
                                <b> {formatAmount(totalPayamount)}</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="d-print-none">
                          <div className="float-end">
                            <Link
                              onClick={handlePrint}
                              className="btn btn-success  me-2"
                            >
                              <i className="fa fa-print" />
                            </Link>
                            {/* <Link to="#" className="btn btn-primary w-md ">
                        Send
                      </Link> */}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <div
            ref={printRef}
            className="paymentPrint"
            style={{ display: "none" }}
          >
            <Row>
              <Col xl={12}>
                <div className="invoice-title">
                  <div className="mb-4">
                    <img
                      src={logo}
                      alt="logo-dark"
                      className="logo-dark-element"
                      height="80"
                    />
                  </div>
                </div>
                <hr />
                <Row>
                  <Col xl={12}>
                    <h3 className="text-center">Pham Enterprises</h3>
                    <h5 className="text-center">Project Invoices</h5>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col xl={12}>
                    <table className="table-bordered border-black mt-2">
                      <thead>
                        <tr className="black-head">
                          <th>Inv ID</th>
                          <th>Date</th>
                          <th>Customer</th>
                          <th>Project</th>
                          <th>Invoice Amount</th>
                          <th>Paid Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {payments.length > 0 ? (
                          payments
                            .filter(e => !!e)
                            .map((item, index) => (
                              <tr key={item.id}>
                                <td>{item.inv_no}</td>
                                <td>{item.created_date}</td>
                                <td>{item.cust_name}</td>
                                <td>
                                  {item.project_name}({item.project_code})
                                </td>

                                <td>{formatAmount(item.total_amount)}</td>
                                <td>{formatAmount(item.paid_amount)}</td>
                              </tr>
                            ))
                        ) : (
                          <tr>
                            <td colSpan="9" className="border-0">
                              <h6 className="text-center mt-2 mb-2">
                                No Transactions Found{" "}
                              </h6>
                            </td>
                          </tr>
                        )}
                        <tr style={{ border: "1px solid black" }}></tr>
                        <tr
                          style={{
                            border: "1px solid black",
                            // borderTop: "1px solid black",
                          }}
                        >
                          <td colSpan="4" className="border-0">
                            <strong>Total</strong>
                          </td>
                          <td className="border-0">
                            <strong>{formatAmount(totalInvoicedamount)}</strong>
                          </td>
                          <td className="border-0">
                            <strong>{formatAmount(totalPayamount)}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
                <br />
              </Col>
            </Row>
            <div className="mt-5 mb-5">
              <Row>
                <Col sm={2}>
                  <div
                    style={{
                      borderTop: "1.5px solid black",
                      width: "100%",
                    }}
                  ></div>
                  <div className="text-center">
                    <b>Prepared By</b>
                  </div>
                </Col>
                <Col sm={2}>
                  <div
                    style={{
                      borderTop: "1.5px solid black",
                      width: "100%",
                    }}
                  ></div>
                  <div className="text-center">
                    <b>Reviewed By</b>
                  </div>
                </Col>
                <Col sm={2}>
                  <div
                    style={{
                      borderTop: "1.5px solid black",
                      width: "100%",
                    }}
                  ></div>
                  <div className="text-center">
                    <b>Approved By</b>
                  </div>
                </Col>
                <Col sm={2}>
                  <div
                    style={{
                      borderTop: "1.5px solid black",
                      width: "100%",
                    }}
                  ></div>
                  <div className="text-center">
                    <b>Received By</b>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col xl="12">
                  <div style={{ backgroundColor: "blue", padding: "10px" }}>
                    <img
                      src={`${apiUrl}/uploads/images/company_stamp.jpg`}
                      width="15%"
                      height="15%"
                    />

                    <p style={{ color: "white", marginTop: "5px" }}>
                      MUHAMMAD SUFYAN <br />
                      0092-307-572 7380 <br />
                      msufyan2011@gmail.com
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

ProjectInvoicesReport.propTypes = {
  match: PropTypes.any,
}

export default withRouter(ProjectInvoicesReport)
