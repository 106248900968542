import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Modal,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import classnames from "classnames"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

import {
  Pdate,
  Type,
  Value,
  ValueinUSD,
  Status,
  Coin,
  Tracked,
} from "./PurchaceData"

import TableContainer from "../../components/Common/TableContainer"

import img2 from "../../assets/images/no-time-off.59f54e64.svg"

//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { getCryptoOrders } from "store/crypto/actions"

const PurchaseAll = props => {
  //meta title
  document.title = "Purchaces All"

  const { orders, onGetOrders } = props
  const [selectCoin, setselectCoin] = useState(null)
  const [selectType, setselectType] = useState("Buy")
  const [selectStatus, setselectStatus] = useState("Completed")
  const [productData, setSetProductData] = useState([orders])
  const [accountModal, setAccountModal] = useState(false)
  const [alllocation, setAlllocation] = useState(false)

  useEffect(() => {
    onGetOrders()
    setSetProductData(orders)
    console.log(props)
  }, [onGetOrders])

  const handleFilter = () => {
    var allProduct = orders.filter(order => {
      return (
        order.type === selectType &&
        order.coin === selectCoin &&
        order.status === selectStatus
      )
    })
    setSetProductData(allProduct)
  }

  const handleOrderClicks = () => {
    setOrderList("")
    setIsEdit(false)
    toggle()
  }

  // Table Data

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <>
              <input
                type="checkbox"
                className="form-check-input"
                id="customCheckcolor1"
              />

              <label
                className="form-check-label"
                htmlFor="customCheckcolor1"
              ></label>
            </>
          )
        },
      },
      {
        Header: "V.No",
        accessor: "pdate",
        disableFilters: true,
        Cell: cellProps => {
          return <Pdate {...cellProps} />
        },
      },
      {
        Header: "Code",
        accessor: "type",
        disableFilters: true,
        Cell: cellProps => {
          return <Type {...cellProps} />
        },
      },
      {
        Header: "Reconcile Date",
        accessor: "coin",
        disableFilters: true,
        Cell: cellProps => {
          return <Coin {...cellProps} />
        },
      },
      {
        Header: "Balance",
        accessor: "value",
        disableFilters: true,
        Cell: cellProps => {
          return <Value {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link to="#" className="text-primary">
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="12">
              <div>
                <h5 className="mt-2">Purchases All</h5>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form>
                    <Row className="mb-2">
                      <Col sm={6} className="col-xl-3">
                        <FormGroup className="mb-3">
                          <Label className="form-label">Suppliers</Label>
                          <select
                            className="form-control select2-search-disable"
                            value={selectStatus}
                            onChange={e => {
                              setselectStatus(e.target.value)
                            }}
                          >
                            <option value="Completed">Select..</option>
                          </select>
                        </FormGroup>
                      </Col>

                      <Col xl="2" sm={6} className="col-xl align-self-end ">
                        <div className="mb-3">
                          <Button
                            type="button"
                            color="primary"
                            className="w-md"
                            onClick={() => handleFilter()}
                          >
                            Filter
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <TableContainer
                    columns={columns}
                    data={orders}
                    isGlobalFilter={true}
                    // isAddOptions={true}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={accountModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setAccountModal(!accountModal)
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setAccountModal(!accountModal)
            }}
          >
            Bank Account New
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col className="col-12">
                <div className="mb-3">
                  <label>Bank Account *</label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Bank Account"
                  ></Input>
                </div>
                <div className="mb-3">
                  <label>Code</label>
                  <Input
                    className="form-control"
                    type="number"
                    placeholder="code"
                  ></Input>
                </div>
                <div className="mb-3">
                  <label>Description</label>
                  <Input
                    className="form-control"
                    type="textarea"
                    placeholder="description"
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setAccountModal(!accountModal)
              }}
            >
              Close
            </Button>
            <Button
              type="button"
              color="primary"
              onClick={() => {
                setAccountModal(!accountModal)
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}

PurchaseAll.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

const mapStateToProps = ({ crypto }) => ({
  orders: crypto.orders,
})

const mapDispatchToProps = dispatch => ({
  onGetOrders: () => dispatch(getCryptoOrders()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PurchaseAll))
