export function formatAmount(value) {
  if (value === null) {
    return "0.00" // Return "0.00" for null values
  } else if (!isNaN(value) && value !== "") {
    return parseFloat(value).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  } else if (value === 0 || value === "0") {
    return "0.00" // Return "0.00" for zero values
  } else {
    return value // Return the value as is for non-numeric or empty values
  }
}
