import React, { useEffect, useState, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import "assets/style/style.css"
import logo from "assets/images/Pham logo black text.png"
import apiUrl from "config"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  UncontrolledTooltip,
  Modal,
  Row,
} from "reactstrap"

import { Type, Coin } from "./BankAccountData"

import TableContainer from "../../components/Common/TableContainer"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import DeleteModal from "components/Common/DeleteModal"

function formatAmountCurrency(amount, currency) {
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
  })
}

const BankAccounts = props => {
  //meta title
  document.title = "Bank Accounts"
  const navigate = useNavigate()

  const [orders, setOrders] = useState([])
  const [accountModal, setAccountModal] = useState(false)
  const [loading, setloading] = useState(false)
  const [formData, setFormData] = useState({
    bank_name: "",
    bank_code: "",
    bank_disc: "",
    method: "add_bank_detial",
  })

  const onChangeModal = e => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }

  const fetchBankAccount = async () => {
    setOrders([])
    const URL = `${apiUrl}/BankManagemnt/addbank.php`
    const Data = new FormData()
    Data.append("app_method", "fetch_bank")

    try {
      setloading(true)
      const response = await axios.post(URL, Data)
      const data = Array.isArray(response.data) ? response.data : []
      setOrders(data)
    } catch (error) {
      console.log(error)
    } finally {
      setloading(false)
    }
  }

  const handleSubmit = e => {
    const URL = `${apiUrl}/BankManagemnt/addbank.php`
    const data = new FormData()
    data.append("bank_name", formData.bank_name)
    data.append("bank_disc", formData.bank_disc)
    data.append("app_method", "add_bank_detail")

    e.preventDefault()
    if (formData.bank_name === "") {
      toastr.error("Bank name is Required")
    } else {
      axios
        .post(URL, data)
        .then(response => {
          if (response.data === "success") {
            setFormData({
              bank_name: "",
              bank_code: "",
              bank_disc: "",
            })
            toastr.success("Submit Successfully")
            setAccountModal(!accountModal)
            fetchBankAccount()
            // window.location.reload()
          } else {
            toastr.error(response.data)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
  useEffect(() => {
    fetchBankAccount()
  }, [])

  // const handleFilter = () => {
  //   var allProduct = orders.filter(order => {
  //     return (
  //       order.type === selectType &&
  //       order.coin === selectCoin &&
  //       order.status === selectStatus
  //     )
  //   })
  //   setSetProductData(allProduct)
  // }

  const addAccount = () => {
    setAccountModal(!accountModal)
  }

  const [deleteModal, setDeleteModal] = useState(false)
  const [deletedata, setDeletedata] = useState([])
  const deleteConfirmation = name => {
    setDeletedata({
      name: name,
    })
    setDeleteModal(true)
  }

  const deleteBank = () => {
    const URL = `${apiUrl}/BankManagemnt/addbank.php`
    const del_data = new FormData()
    del_data.append("bank_name", deletedata.name)
    del_data.append("app_method", "del_bank_")
    axios
      .post(URL, del_data)
      .then(response => {
        if (response.data === "success") {
          toastr.success("Delete Successfully")
          setDeleteModal(false)
          fetchBankAccount()
        } else {
          toastr.error(response.data)
          setDeleteModal(false)
        }
      })
      .catch(error => {
        setDeleteModal(false)
        console.log(error)
      })
  }

  // Table Data

  const columns = useMemo(
    () => [
      {
        Header: "Bank",
        accessor: "bank_name",
        disableFilters: true,
        Cell: cellProps => {
          const bankName = cellProps.value
          const bankID = cellProps.row.original.id
          const bankcode = cellProps.row.original.bank_code
          return (
            <Link
              to={`/bank/control/bank_activity/${bankID}`}
              state={{
                Name: bankName,
                Code: bankcode,
              }}
            >
              {cellProps.value}
            </Link>
          )
        },
      },
      {
        Header: "Code",
        accessor: "bank_code",
        disableFilters: true,
        Cell: cellProps => {
          return <Type {...cellProps} />
        },
      },
      {
        Header: "Reconcile Date",
        accessor: "created_date",
        disableFilters: true,
        Cell: cellProps => {
          return <Coin {...cellProps} />
        },
      },
      {
        Header: "Debit Amount",
        accessor: "debit_amount",
        disableFilters: true,
        Cell: cellProps => {
          return <>{formatAmountCurrency(parseFloat(cellProps.value), "PKR")}</>
        },
      },
      {
        Header: "Credit Amount",
        accessor: "credit_amount",
        disableFilters: true,
        Cell: cellProps => {
          return <>{formatAmountCurrency(parseFloat(cellProps.value), "PKR")}</>
        },
      },
      {
        Header: "Balance",
        accessor: "amount_in",
        disableFilters: true,
        Cell: cellProps => {
          const credit = parseFloat(cellProps.row.original.credit_amount)
          const debit = parseFloat(cellProps.row.original.debit_amount)
          const balance = credit - debit
          return <>{formatAmountCurrency(balance, "PKR")}</>
        },
      },
      {
        Header: "Action",
        accessor: "id2",
        disableFilters: true,
        Cell: cellProps => {
          const name = cellProps.row.original.bank_name
          return (
            <div className="d-flex gap-2">
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  deleteConfirmation(name)
                }}
              >
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip2"
                />
                <UncontrolledTooltip placement="top" target="deletetooltip2">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  if (loading) {
    return (
      <div className="spinner-container">
        <img src={logo} alt="Loading" className="spinner-logo" />
      </div>
    )
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={deleteBank}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="6">
              <div>
                <h5 className="mt-2">Account Balance</h5>
              </div>
            </Col>
            <Col>
              <div className="float-end">
                <span>
                  <Button
                    type="button"
                    color="primary"
                    className="w-md btn btn-sm"
                    onClick={() => addAccount()}
                  >
                    <i className="fa fa-fw fa-plus" />
                    New Account
                  </Button>
                </span>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={orders}
                    isGlobalFilter={true}
                    // isAddOptions={false}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={accountModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setAccountModal(!accountModal)
        }}
      >
        <div>
          <Form>
            <ModalHeader
              toggle={() => {
                setAccountModal(!accountModal)
              }}
            >
              Bank Account New
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col className="col-12">
                  <div className="mb-3">
                    <label>Bank Account *</label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Name"
                      name="bank_name"
                      value={formData.bank_name}
                      onChange={onChangeModal}
                    ></Input>
                  </div>
                  <div className="mb-3">
                    <label>Code</label>
                    <Input
                      className="form-control"
                      type="number"
                      placeholder="code"
                      name="bank_code"
                      value={formData.bank_code}
                      onChange={onChangeModal}
                      disabled
                    ></Input>
                  </div>
                  <div className="mb-3">
                    <label>Description</label>
                    <Input
                      className="form-control"
                      type="textarea"
                      placeholder="description"
                      name="bank_disc"
                      value={formData.bank_disc}
                      onChange={onChangeModal}
                    />
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                  setAccountModal(!accountModal)
                }}
              >
                Close
              </Button>
              <Button type="submit" color="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </ModalFooter>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default BankAccounts
