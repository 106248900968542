import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import DeleteModal from "components/Common/DeleteModal"

import "assets/style/style.css"
import logo from "assets/images/Pham logo black text.png"
import apiUrl from "config"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Modal,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Dropdown,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import classnames from "classnames"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

import {
  Pdate,
  Type,
  Value,
  ValueinUSD,
  Status,
  Coin,
  Tracked,
} from "./SalesData"

import TableContainer from "../../components/Common/TableContainer"

import img2 from "../../assets/images/no-time-off.59f54e64.svg"

//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { getCryptoOrders } from "store/crypto/actions"
import axios from "axios"
import { formatAmount } from "components/Functoins/functions"
import SocialModal from "components/SocialControl/Social"

const SaleInvoices = props => {
  //meta title
  document.title = "Sale Invoices"
  const navigate = useNavigate()
  const [del_id, setDel_id] = useState()

  const [loading, setloading] = useState(false)
  const [socialModal, setSocialModal] = useState(false)
  const [pdfUrl, setPdfUrl] = useState("")

  //Modal Mail Data
  const [ModalMailData, setModalMailData] = useState({
    from_mail: "",
    to_mail: "",
    mail_subj: "",
    email_message:
      "<p>Please find attached confirmation for your Order.<br>Thank you for your business.<br>Best Regards.<br><strong>Pham Enterprises</strong> ",
  })
  const handleModalMailData = e => {
    const { name, value } = e.target
    setModalMailData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  //Eamil
  const [EmailMode, setEmailMode] = useState(false)
  const handleEmail = id => {
    const URL = `${apiUrl}/Invoices/invoice.php`
    const post = new FormData()
    post.append("invoice_id", id)
    post.append("app_method", "fetch_invoice_byId")
    axios
      .post(URL, post)
      .then(response => {
        if (Array.isArray(response.data)) {
          setSocialModal(!socialModal)
          setPdfUrl(response.data[0].pdf_url)
          setWhatsAppMode(false)
          setEmailMode(true)
        } else {
          console.log(response.data)
        }
      })
      .catch(error => console.log(error))
  }
  const SendEmail = () => {
    if (ModalMailData.to_mail === "") {
      toastr.error("Sent Email is Required")
    } else {
      const data = new FormData()
      data.append("from_mail", ModalMailData.from_mail)
      data.append("to_mail", ModalMailData.to_mail)
      data.append("mail_subj", ModalMailData.mail_subj)
      data.append("email_message", ModalMailData.email_message)
      data.append("pdf_url", pdfUrl)
      data.append("app_method", "send_email")
      const URL = `${apiUrl}/socialaccounts/emailhandle.php`
      axios
        .post(URL, data)
        .then(response => {
          if (response.data.message === "success") {
            toastr.success("Email Sent Successfully")
            setSocialModal(!socialModal)
          } else {
            // Handle case where response.data is not an array
            console.error("Response data is not an array.")
          }
        })
        .catch(error => console.log(error))
    }
  }

  //Toggle
  const toggleModal = () => {
    setSocialModal(!socialModal)
  }

  const [orders, setOrders] = useState([])
  const fetrchAllInvoices = async () => {
    const URL = `${apiUrl}/Invoices/invoice.php`
    const inv = new FormData()
    inv.append("app_method", "fetch_all_invoices")

    try {
      setloading(true)
      const response = await axios.post(URL, inv)
      const data = Array.isArray(response.data) ? response.data : []
      setOrders(data)
    } catch (error) {
      console.log(error)
    } finally {
      setloading(false)
    }
  }

  useEffect(() => {
    fetrchAllInvoices()
  }, [])

  const handleOrderClicks = () => {
    setOrderList("")
    setIsEdit(false)
    toggle()
  }

  // Table Data

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "created_date",
        disableFilters: true,
        Cell: cellProps => {
          return <Pdate {...cellProps} />
        },
      },
      {
        Header: "A/C No",
        accessor: "acc_no",
        disableFilters: true,
        Cell: cellProps => {
          return <Type {...cellProps} />
        },
      },
      {
        Header: "Customer",
        accessor: "cust_name",
        disableFilters: true,
        Cell: cellProps => {
          return <Coin {...cellProps} />
        },
      },
      {
        Header: "Project",
        accessor: "project_name",
        disableFilters: true,
        Cell: cellProps => {
          const code = cellProps.row.original.project_code
          return (
            <>
              {cellProps.value} ({code})
            </>
          )
        },
      },
      {
        Header: "Inv No",
        accessor: "inv_no",
        disableFilters: true,
        Cell: cellProps => {
          return <Value {...cellProps} />
        },
      },
      {
        Header: "Doc No",
        accessor: "order_doc_no",
        disableFilters: true,
        Cell: cellProps => {
          return <Value {...cellProps} />
        },
      },
      {
        Header: "Total",
        accessor: "total_amount",
        disableFilters: true,
        Cell: cellProps => {
          const number = formatAmount(cellProps.value)
          return <>{number}</>
        },
      },
      {
        Header: "Balance",
        accessor: "r_balance",
        disableFilters: true,
        Cell: cellProps => {
          const number = formatAmount(cellProps.value)
          return <>{number}</>
        },
      },

      {
        Header: "Action",
        accessor: "ID",
        disableFilters: true,
        Cell: cellProps => {
          const status = cellProps.row.original.payment_status
          const orderid = cellProps.row.original.order_id
          const invNo = cellProps.row.original.inv_no
          const customer = cellProps.row.original.cust_name
          const project = cellProps.row.original.project_name
          return (
            <div className="d-flex gap-2">
              {status !== "Paid" && (
                <Link
                  to={{
                    pathname: `/income/generate_invoice/make_payment/${invNo}`,
                  }}
                  state={{ customer: customer, project: project }}
                  className="text-primary"
                >
                  <i
                    className="mdi mdi-currency-usd font-size-18"
                    id="paymenttooltip"
                  />
                  <UncontrolledTooltip placement="top" target="paymenttooltip">
                    Receive Payment
                  </UncontrolledTooltip>
                </Link>
              )}
              <Link className="text-primary">
                <i
                  className="mdi mdi-email font-size-18"
                  id="viewtooltip11"
                  onClick={() => handleEmail(invNo)}
                />
                <UncontrolledTooltip placement="top" target="viewtooltip11">
                  Email
                </UncontrolledTooltip>
              </Link>
              <Link
                to={{
                  pathname: `/income/view_sale_invoice/${invNo}`,
                  state: { customer },
                }}
                className="text-primary"
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip2" />
                <UncontrolledTooltip placement="top" target="viewtooltip2">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  deleteOrder(orderid)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const changeStatus = (value, mainID, inv_id, customer, project) => {
    if (value === "receveid-payment") {
      navigate(`/income/generate_invoice/make_payment/${inv_id}`, {
        state: {
          customer: customer,
          project: project,
        },
      })
    }
  }

  //Delete Modal
  const [deleteModal, setDeleteModal] = useState(false)
  const deleteOrder = id => {
    setDel_id(id)
    setDeleteModal(true)
  }
  const handleDeleteOrder = () => {
    const URL = `${apiUrl}/Invoices/invoice.php`
    const data = new FormData()
    data.append("del_id", del_id)
    data.append("app_method", "delete_invoice")

    axios
      .post(URL, data)
      .then(response => {
        if (response.data === "success") {
          toastr.success("Invoice Delete Succefully")
          setOrders()
          setDeleteModal(false)
        } else {
          toastr.error("Paid Orders/credits can't be deleted")
          setDeleteModal(false)
        }
      })
      .catch(error => console.log(error))
  }

  if (loading) {
    return (
      <div className="spinner-container">
        <img src={logo} alt="Loading" className="spinner-logo" />
      </div>
    )
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="9">
              <div>
                <h5 className="mt-2">Sales Invoices</h5>
              </div>
            </Col>
            <Col xl="3">
              <div className="d-flex gap-2 flex-wrap float-end">
                <Link to="/income/control/sales_invoice/add_invoice">
                  <Button
                    type="button"
                    color="primary"
                    className="w-md btn btn-sm"
                  >
                    <i className="fa fa-fw fa-plus" />
                    Add New
                  </Button>
                </Link>

                <Button className="btn btn-sm btn-light">
                  <i
                    className="mdi mdi-file-import font-size-12"
                    id="importtooltip"
                  />
                  <UncontrolledTooltip placement="top" target="importtooltip">
                    Import
                  </UncontrolledTooltip>
                </Button>
                <Button className="btn btn-sm btn-danger">
                  <i
                    className="mdi mdi-delete font-size-12"
                    id="deletetooltip3"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip3">
                    Delete
                  </UncontrolledTooltip>
                </Button>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={orders}
                    isGlobalFilter={true}
                    // isAddOptions={true}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <SocialModal
          isOpen={socialModal}
          toggle={toggleModal}
          EmailMode={EmailMode}
          ModalMailData={ModalMailData}
          handleModalMailData={handleModalMailData}
          SendEmail={SendEmail}
          pdfUrl={pdfUrl}
          setModalMailData={setModalMailData}
        />
      </div>
    </React.Fragment>
  )
}

export default SaleInvoices
