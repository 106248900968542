import React from "react"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Row,
  Col,
} from "reactstrap"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

const SocialModal = ({
  isOpen,
  toggle,
  EmailMode,
  ModalMailData,
  handleModalMailData,
  SendEmail,
  pdfUrl,
  setModalMailData,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      size="xl"
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div>
        <ModalHeader toggle={toggle}>Send EMAIL</ModalHeader>
        <ModalBody>
          <Row>
            <Col className="col-4">
              <div className="mb-3">
                <label>From Email</label>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="info@phamenterprises.com.pk"
                  name="from_mail"
                  value={ModalMailData.from_mail}
                  onChange={handleModalMailData}
                  disabled
                ></Input>
              </div>
              <div className="mb-3">
                <label>To Email</label>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="email address"
                  name="to_mail"
                  value={ModalMailData.to_mail}
                  onChange={handleModalMailData}
                ></Input>
              </div>
              <div className="mb-3">
                <label>Subject</label>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="subject"
                  name="mail_subj"
                  value={ModalMailData.mail_subj}
                  onChange={handleModalMailData}
                ></Input>
              </div>
              <div className="mb-3">
                <label>Message</label>
                <div className="editor-container">
                  <CKEditor
                    editor={ClassicEditor}
                    style={{ height: 300 }}
                    data={ModalMailData.email_message}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setModalMailData(prev => ({
                        ...prev,
                        email_message: data,
                      }))
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col className="col-8">
              <iframe
                src={pdfUrl} // Replace pdfUrl with the actual URL of the PDF
                style={{ width: "100%", height: "480px" }} // Adjust the width and height as needed
                frameBorder="0"
                scrolling="auto"
                title="PDF Viewer"
              ></iframe>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="primary" onClick={SendEmail}>
            <i className="mdi mdi-email" /> Send Email
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

export default SocialModal
