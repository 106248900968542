import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import DeleteModal from "components/Common/DeleteModal"
import "assets/style/style.css"
import logo from "assets/images/Pham logo black text.png"
import apiUrl from "config"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  UncontrolledTooltip,
  Modal,
  Row,
} from "reactstrap"

import { Type, Value, Coin } from "./ProjectsData"

import TableContainer from "../../components/Common/TableContainer"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

const PhamProjects = props => {
  //meta title
  document.title = "All Projects"
  const navigate = useNavigate()

  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeletId] = useState("")

  const [orders, setOrders] = useState([])
  const [projectModal, setProjectModal] = useState(false)

  const [viewmode, setviewmode] = useState(false)
  const [loading, setloading] = useState(false)

  const [formData, setFormData] = useState({
    id: "",
    project_name: "",
    project_code: "",
    project_detail: "",
    method: "add_project_detail",
  })

  const onChangeModal = e => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  const fetchProjects = async () => {
    try {
      setloading(true) // Set loading state to true while fetching data
      setOrders([]) // Clear existing orders data

      const URL = `${apiUrl}/Projects/projects.php`
      const Pdata = new FormData()
      Pdata.append("app_method", "fetch_all_projects")

      const response = await axios.post(URL, Pdata)

      if (Array.isArray(response.data)) {
        setOrders(response.data)
      } else {
        console.log("Error in fetchProjects: Response is not an array")
      }
    } catch (error) {
      console.error("Error fetching projects:", error)
    } finally {
      setloading(false) // Finally block ensures that loading state is set to false regardless of success or failure
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (formData.project_name === "") {
      toastr.error("Project name is Required")
    } else {
      axios
        .post(`${apiUrl}/Projects/projects.php`, formData)
        .then(response => {
          if (response.data === "success") {
            fetchProjects()
            toastr.success("Submit Successfully")
            setProjectModal(!projectModal)
            setFormData({
              project_name: "",
              project_code: "",
              project_detail: "",
            })
          } else {
            toastr.error(response.data)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
  useEffect(() => {
    fetchProjects()
  }, [])

  const addProject = () => {
    setFormData(prevData => ({
      ...prevData,
      project_name: "",
      project_code: "code",
      project_detail: "",
      id: "",
      method: "add_project_detail",
    }))
    setviewmode(false)
    setProjectModal(!projectModal)
  }

  // Table Data

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        disableFilters: true,
        Cell: cellProps => {
          //   const { row } = cellProps
          //   const bankId = row.original.id
          return <Value {...cellProps} />
        },
      },
      {
        Header: "Project Name",
        accessor: "project_name",
        disableFilters: true,
        Cell: cellProps => {
          return <Type {...cellProps} />
        },
      },
      {
        Header: "Code",
        accessor: "project_code",
        disableFilters: true,
        Cell: cellProps => {
          return <Coin {...cellProps} />
        },
      },
      {
        Header: "Details",
        accessor: "project_detail",
        disableFilters: true,
        Cell: cellProps => {
          return <Value {...cellProps} />
        },
      },
      {
        Header: "",
        accessor: "project_detail2",
        disableFilters: true,
        Cell: cellProps => {
          const Income = Number(cellProps.row.original.income_).toFixed(2)
          const Expense = Number(cellProps.row.original.expense_).toFixed(2)
          return (
            <>
              <Row>
                <Col xl={6}>Income</Col>
                <Col xl={6}>
                  <div className="float-end">{Income}</div>
                </Col>
              </Row>
              <Row>
                <Col xl={6}>Expense</Col>
                <Col xl={6}>
                  <div className="float-end">{Expense}</div>
                </Col>
              </Row>
            </>
          )
        },
      },
      {
        Header: "Profit Margin",
        accessor: "profit_margin",
        disableFilters: true,
        Cell: cellProps => {
          const income = Number(cellProps.row.original.income_)
          const expense = Number(cellProps.row.original.expense_)
          let profitMargin = "0"
          if (
            income !== null &&
            expense !== null &&
            income !== 0 &&
            expense !== 0
          ) {
            const Profit = ((income - expense) / income) * 100
            profitMargin = Profit.toFixed(2)
          }

          return <>{profitMargin}%</>
        },
      },
      {
        Header: "Status",
        accessor: "status_",
        disableFilters: true,
        Cell: cellProps => {
          return <Value {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action_id",
        disableFilters: true,
        Cell: cellProps => {
          const id = cellProps.row.original.id
          return (
            <div className="d-flex gap-2">
              <Link onClick={() => viewProject(id)} className="text-primary">
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  deleteProject(id)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const viewProject = id => {
    const Data = {
      method: "fetch_project_by_id",
      id: id,
    }
    axios
      .post(`${apiUrl}/Projects/projects.php`, Data)
      .then(response => {
        if (Array.isArray(response.data)) {
          const detail = response.data
          if (detail.length > 0) {
            setFormData(prevData => ({
              ...prevData,
              id: id,
              project_name: detail[0].project_name || "",
              project_code: detail[0].project_code,
              project_detail: detail[0].project_detail || "",
              method: "edit_project_by_id",
            }))
            setviewmode(true)
            setProjectModal(!projectModal)
          }
        } else {
          console.log("error in fetch")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const editProject = () => {
    if (formData.project_name === "") {
      toastr.error("Project name is Required")
    } else {
      axios
        .post(`${apiUrl}/Projects/projects.php`, formData)
        .then(response => {
          if (response.data === "success") {
            fetchProjects()
            toastr.success("Edit Successfully")
            setProjectModal(!projectModal)
            setFormData({
              project_name: "",
              project_code: "",
              project_detail: "",
              id: "",
              method: "add_project_detail",
            })
          } else {
            toastr.error(response.data)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  const deleteProject = id => {
    setDeletId(id)
    setDeleteModal(true)
  }

  const handleDeleteOrder = () => {
    const formData2 = {
      method: "delete_project_",
      del_id: deleteId,
    }
    axios
      .post(`${apiUrl}/Projects/projects.php`, formData2)
      .then(response => {
        if (response.data === "success") {
          setDeleteModal(false)
          toastr.success("Project Delete Sucessfully")
          fetchProjects()
        } else {
          setDeleteModal(false)
          toastr.error("Project used in <b>Transaction</b>")
        }
      })
      .catch(error => console.log(error))
  }

  if (loading) {
    return (
      <div className="spinner-container">
        <img src={logo} alt="Loading" className="spinner-logo" />
      </div>
    )
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="6">
              <div>
                <h5 className="mt-2">Projects</h5>
              </div>
            </Col>
            <Col>
              <div className="float-end">
                <span>
                  <Button
                    type="button"
                    color="primary"
                    className="w-md btn btn-sm"
                    onClick={() => addProject()}
                  >
                    <i className="fa fa-fw fa-plus" />
                    Add New
                  </Button>
                </span>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={orders}
                    isGlobalFilter={true}
                    // isAddOptions={false}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={projectModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setProjectModal(!projectModal)
        }}
      >
        <div>
          <Form onSubmit={handleSubmit}>
            <ModalHeader
              toggle={() => {
                setProjectModal(!projectModal)
              }}
            >
              Project Information
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col className="col-12">
                  <div className="mb-3">
                    <label>Project Name</label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Name"
                      name="project_name"
                      value={formData.project_name}
                      onChange={onChangeModal}
                    ></Input>
                  </div>
                  <div className="mb-3">
                    <label>Code</label>
                    <Input
                      className="form-control"
                      type="number"
                      placeholder={viewmode ? formData.project_code : "code"}
                      name="project_code"
                      value={formData.project_code}
                      onChange={onChangeModal}
                      disabled
                    ></Input>
                  </div>
                  <div className="mb-3">
                    <label>Details</label>
                    <Input
                      className="form-control"
                      type="textarea"
                      placeholder="description"
                      name="project_detail"
                      value={formData.project_detail}
                      onChange={onChangeModal}
                    />
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                  setProjectModal(!projectModal)
                }}
              >
                Close
              </Button>
              {viewmode ? (
                <Button onClick={editProject} color="primary">
                  Save Edit
                </Button>
              ) : (
                <Button type="submit" color="primary">
                  Submit
                </Button>
              )}
            </ModalFooter>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default PhamProjects
