import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import DeleteModal from "components/Common/DeleteModal"
import "assets/style/style.css"
import logo from "assets/images/Pham logo black text.png"
import apiUrl from "config"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Modal,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import classnames from "classnames"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

import {
  Pdate,
  Type,
  Value,
  ValueinUSD,
  Status,
  Coin,
  Tracked,
} from "./PurchaceData"

import TableContainer from "../../components/Common/TableContainer"

import img2 from "../../assets/images/no-time-off.59f54e64.svg"

//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { getCryptoOrders } from "store/crypto/actions"
import axios from "axios"

const PurchacePayment = props => {
  //meta title
  document.title = "Supplier Payments"
  const navigate = useNavigate()
  const [orders, SetOrders] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [loading, setloading] = useState(false)
  // const handleFilter = () => {
  //   var allProduct = orders.filter(order => {
  //     return (
  //       order.type === selectType &&
  //       order.coin === selectCoin &&
  //       order.status === selectStatus
  //     )
  //   })
  //   setSetProductData(allProduct)
  // }

  const handleOrderClicks = () => {
    setOrderList("")
    setIsEdit(false)
    toggle()
  }

  // Table Data

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "created_date",
        disableFilters: true,
        Cell: cellProps => {
          return <Pdate {...cellProps} />
        },
      },

      {
        Header: "Supplier",
        accessor: "supplier_name",
        disableFilters: true,
        Cell: cellProps => {
          const code = cellProps.row.original.acc_no
          return (
            <>
              {cellProps.value} ({code})
            </>
          )
        },
      },
      {
        Header: "Project",
        accessor: "project_name",
        disableFilters: true,
        Cell: cellProps => {
          const code = cellProps.row.original.project_code
          return (
            <>
              {cellProps.value} ({code})
            </>
          )
        },
      },
      {
        Header: "Type",
        accessor: "type_",
        disableFilters: true,
        Cell: cellProps => {
          return <Coin {...cellProps} />
        },
      },
      {
        Header: "Ref No.",
        accessor: "purchase_ref_no",
        disableFilters: true,
        Cell: cellProps => {
          return <Value {...cellProps} />
        },
      },

      {
        Header: "Total",
        accessor: "total_amount",
        disableFilters: true,
        Cell: cellProps => {
          const total = Number(cellProps.value).toFixed(2)
          return <>{total}</>
        },
      },
      {
        Header: "Pay Amount",
        accessor: "pay_amount",
        disableFilters: true,
        Cell: cellProps => {
          const total = Number(cellProps.value).toFixed(2)
          return <>{total}</>
        },
      },
      {
        Header: "Advance",
        accessor: "advance_",
        disableFilters: true,
        Cell: cellProps => {
          const balance = Number(cellProps.value).toFixed(2)
          return <>{balance}</>
        },
      },

      {
        Header: "",
        accessor: "ID",
        disableFilters: true,
        Cell: cellProps => {
          const view_id = cellProps.row.original.vo_id
          return (
            <div className="d-flex gap-3">
              <Link
                to={`/expenses/control/payments/view/${view_id}`}
                className="text-primary"
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderid = cellProps.row.original.order_id
                  deleteBill(orderid)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const fetchPayments = async () => {
    const URL = `${apiUrl}/purchases/payments.php`
    const data = new FormData()
    data.append("app_method", "fetch_purchase_payment")
    try {
      setloading(true)
      const response = await axios.post(URL, data)
      const data3 = Array.isArray(response.data) ? response.data : []
      SetOrders(data3)
    } catch (error) {
      console.log(error)
    } finally {
      setloading(false)
    }
  }

  const deleteBill = id => {
    setDeleteModal(true)
  }
  const handleDeleteBill = () => {
    toastr.error("Payments/credits can't be deleted")
    setDeleteModal(false)
  }

  useEffect(() => {
    fetchPayments()
  }, [])

  if (loading) {
    return (
      <div className="spinner-container">
        <img src={logo} alt="Loading" className="spinner-logo" />
      </div>
    )
  }
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteBill}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="9">
              <div>
                <h5 className="mt-2">Supplier Payments</h5>
              </div>
            </Col>
            <Col xl="3">
              <div className="d-flex gap-2 flex-wrap float-end">
                <Link to="/expenses/control/make_payment">
                  <Button
                    type="button"
                    color="primary"
                    className="w-md btn btn-sm"
                  >
                    <i className="fa fa-fw fa-plus" />
                    Add New
                  </Button>
                </Link>

                <Button className="btn btn-sm btn-light">
                  <i
                    className="mdi mdi-file-import font-size-12"
                    id="importtooltip"
                  />
                  <UncontrolledTooltip placement="top" target="importtooltip">
                    Import
                  </UncontrolledTooltip>
                </Button>
                <Button className="btn btn-sm btn-danger">
                  <i
                    className="mdi mdi-delete font-size-12"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Button>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={orders}
                    isGlobalFilter={true}
                    // isAddOptions={true}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PurchacePayment
