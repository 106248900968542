import React, { useEffect, useState, useMemo, useRef } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import "../../../assets/style/style.css"

import logo from "assets/images/Pham logo black text.png"
import apiUrl from "config"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  Modal,
  Row,
  InputGroup,
} from "reactstrap"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { formatAmount } from "components/Functoins/functions"
import SocialModal from "components/SocialControl/Social"

const ViewBankTransfers = props => {
  //meta title
  document.title = "View Bank Receipts"
  const navigate = useNavigate()

  const [projectModal, setProjectModal] = useState(false)
  const [modeModal, setModeModal] = useState(false)

  const { id } = useParams()
  const viewId = id
  const printRef = useRef()

  const newDate = new Date()
  const date = newDate.getDate()
  const month = newDate.getMonth() + 1
  const year = newDate.getFullYear()
  const separator = "/"
  const todaydate = `${date}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${year}`

  const [projects, setProjects] = useState([])
  const [bank, setBank] = useState([])
  const [pay_mode, setPaymode] = useState([])

  const [loading, setloading] = useState(false)
  const [state, setState] = useState({
    project_name: "",
  })
  const [totalamount, setTotalamount] = useState("")
  const handleState = e => {
    const { name, value } = e.target
    setState(prData => ({
      ...prData,
      [name]: value,
    }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (state.project_name.length === 0) {
      toastr.error("Project is required")
    } else if (data.length === 0) {
      toastr.error("<b>No Transfer is Set</b>")
    } else if (!data.every(row => row.from_bank.length > 0)) {
      toastr.error("From Bank is Required")
    } else if (!data.every(row => row.to_bank.length > 0)) {
      toastr.error("To Bank is Required")
    } else if (!data.every(row => row.refNo.length > 0)) {
      toastr.error("Ref No is Required")
    } else if (!data.every(row => row.mode_.length > 0)) {
      toastr.error("Payment Mode is Required")
    } else if (!data.every(row => row.amount.length > 0)) {
      toastr.error("Amount is Required")
    } else {
      const URl = `${apiUrl}/BankManagemnt/viewBankTransfer.php`
      const formdata = new FormData()

      data.forEach(row => {
        formdata.append("id", row.id)
        formdata.append("date", row.date)
        formdata.append("FromBank", row.from_bank)
        formdata.append("ToBank", row.to_bank)
        formdata.append("ref_no", row.refNo)
        formdata.append("mode_", row.mode_)
        // formdata.append("project[]", row.project_)
        formdata.append("detail_", row.detail_)
        formdata.append("amount_", row.amount)
      })

      formdata.append("project_name", state.project_name)
      formdata.append("total_amount", totalamount)
      formdata.append("app_method", "update_bank_transfer")

      axios
        .post(URl, formdata)
        .then(response => {
          if (response.data === "success") {
            toastr.success("Add Successfully")
            navigate("/bank/control/transfers")
          } else {
            toastr.error("Error in Sumbittion")
          }
        })
        .catch(error => console.log(error))
    }
  }

  const fetchProject = () => {
    const URL = `${apiUrl}/Projects/projects.php`
    const Pdata = new FormData()
    Pdata.append("app_method", "fetch_all_projects")

    axios
      .post(URL, Pdata)
      .then(response => {
        if (Array.isArray(response.data)) {
          setProjects(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  const fetchBank = () => {
    const URL = `${apiUrl}/BankManagemnt/addbank.php`
    const Data = new FormData()
    Data.append("app_method", "fetch_bank")

    axios
      .post(URL, Data)
      .then(response => {
        if (Array.isArray(response.data)) {
          setBank(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  const fetchPayMode = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const pm = new FormData()
    pm.append("app_method", "fetch_pay_mode")

    axios
      .post(URL, pm)
      .then(response => {
        if (response.data) {
          setPaymode(response.data)
        } else {
          console.log("error")
          console.log(response.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  useEffect(() => {
    fetchBank()
    fetchProject()
    fetchPayMode()
  }, [])
  const initialData = [
    {
      id: 1,
      date: todaydate,
      refNo: "",
      from_bank: [""],
      to_bank: [""],
      mode_: [""],
      detail_: "",
      amount: "",
    },
  ]
  const [data, setData] = useState(initialData)
  const handleEdit = (id, field, value) => {
    if (value === "add_mode") {
      addMode()
    } else {
      setData(prevData =>
        prevData.map(item =>
          item.id === id ? { ...item, [field]: value } : item
        )
      )
    }
  }
  const handleAddRow = () => {
    const newId = data.length + 1
    const newRow = {
      id: newId,
      date: todaydate,
      refNo: "",
      mode_: "",
      from_bank: "",
      to_bank: "",
      // project_: "",
      detail_: "",
      amount: "",
    }
    setData(prevData => [...prevData, newRow])
  }
  const deleteRow = id => {
    const updatedData = data.filter(item => item.id !== id)
    setData(updatedData)
  }
  useEffect(() => {
    const totalAmount = data.reduce(
      (acc, row) => acc + parseFloat(row.amount || 0),
      0.0
    )
    setTotalamount(totalAmount)
  })

  //Fetch TransData
  const fetchTransferData = id => {
    const data = new FormData()
    data.append("id", id)
    data.append("app_method", "fetch_payment_detail")
    const URL = `${apiUrl}/BankManagemnt/viewBankTransfer.php`
    axios
      .post(URL, data)
      .then(response => {
        if (Array.isArray(response.data)) {
          setData(
            response.data.map(dataItem => ({
              id: dataItem.pay_id || "",
              date: dataItem.created_date || todaydate,
              refNo: dataItem.trans_ref_no || "0",
              from_bank: dataItem.from_bank || "0",
              to_bank: dataItem.to_bank || "0",
              mode_: dataItem.trans_mode || "0",
              nominal_acc: dataItem.trans_nl_acc || "0",
              detail_: dataItem.trans_detail || "0",
              amount: dataItem.trans_amount || "0",
            }))
          )
          setState(prevData => ({
            ...prevData,
            project_name: response.data[0]?.project_name || "",
          }))
        } else {
          // Handle case where response.data is not an array
          console.error("Response data is not an array.")
        }
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    fetchTransferData(viewId)
  }, [viewId])

  //Close
  const close = e => {
    e.preventDefault()
    navigate("/bank/control/transfers")
  }

  //Edit Payment
  const [edit, setEdit] = useState(true)
  const EditPayment = () => {
    setEdit(prevEdit => !prevEdit)
  }

  //Project Add Modal
  const [formData3, setFormData3] = useState({
    project_name: "",
    project_code: "",
    project_detail: "",
    method: "add_project_detail",
  })
  const onChangeModal = e => {
    const { name, value } = e.target
    setFormData3(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  const handleProject = e => {
    e.preventDefault()
    if (formData3.project_name === "") {
      toastr.error("Project name is Required")
    } else {
      axios
        .post(`${apiUrl}/Projects/projects.php`, formData3)
        .then(response => {
          if (response.data === "success") {
            fetchProject()
            toastr.success("Project Add Successfully")
            setProjectModal(!projectModal)
            setState(prevData => ({
              ...prevData,
              project_name: formData3.project_name,
            }))
            setFormData3({
              project_name: "",
              project_code: "",
              project_detail: "",
            })
          } else {
            toastr.error(response.data)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
  const addProject = () => {
    setProjectModal(!projectModal)
  }

  //Add Mode
  const [mode, setMode] = useState("")
  const handleMode = () => {
    const URL = `${apiUrl}/SettingModules/modules.php`
    const modedata = new FormData()
    modedata.append("mode_name", mode)
    modedata.append("app_method", "payment_mode")
    if (mode === "") {
      toastr.error("Mode name is Required")
    } else {
      axios
        .post(URL, modedata)
        .then(response => {
          if (response.data === "success") {
            fetchPayMode()
            setMode("")
            setModeModal(!modeModal)
            toastr.success("Mode Add Successfully")

            // Find the index of the first item with empty mode_
            const index = data.findIndex(item => item.mode_.includes(""))

            if (index !== -1) {
              setData(prevData =>
                prevData.map((item, i) =>
                  i === index ? { ...item, mode_: [mode] } : item
                )
              )
            } else {
              toastr.error("No rows found with empty mode")
            }
          } else {
            toastr.error("Error in Adding")
          }
        })
        .catch(error => console.log(error))
    }
  }
  const addMode = () => {
    setModeModal(!modeModal)
  }

  const [socialModal, setSocialModal] = useState(false)
  const [pdfUrl, setPdfUrl] = useState("")
  //Modal Mail Data
  const [ModalMailData, setModalMailData] = useState({
    from_mail: "",
    to_mail: "",
    mail_subj: "",
    email_message:
      "<p>Please find attached confirmation for your Order.<br>Thank you for your business.<br>Best Regards.<br><strong>Pham Enterprises</strong> ",
  })
  const handleModalMailData = e => {
    const { name, value } = e.target
    setModalMailData(prevData => ({
      ...prevData,
      [name]: value,
    }))
  }
  //Eamil
  const [EmailMode, setEmailMode] = useState(false)
  const handleEmail = () => {
    const data = new FormData()
    data.append("id", viewId)
    data.append("app_method", "fetch_payment_detail")
    const URL = `${apiUrl}/BankManagemnt/viewBankTransfer.php`
    axios
      .post(URL, data)
      .then(response => {
        if (Array.isArray(response.data)) {
          setSocialModal(!socialModal)
          setPdfUrl(response.data[0].pdf_url)
          setEmailMode(true)
        } else {
          console.log(response.data)
        }
      })
      .catch(error => console.log(error))
  }
  const SendEmail = () => {
    if (ModalMailData.to_mail === "") {
      toastr.error("Sent Email is Required")
    } else {
      const data = new FormData()
      data.append("from_mail", ModalMailData.from_mail)
      data.append("to_mail", ModalMailData.to_mail)
      data.append("mail_subj", ModalMailData.mail_subj)
      data.append("email_message", ModalMailData.email_message)
      data.append("pdf_url", pdfUrl)
      data.append("app_method", "send_email")
      const URL = `${apiUrl}/socialaccounts/emailhandle.php`
      axios
        .post(URL, data)
        .then(response => {
          if (response.data.message === "success") {
            toastr.success("Email Sent Successfully")
            setSocialModal(!socialModal)
            setModalMailData(prevData => ({
              ...prevData,
              to_mail: "",
              mail_subj: "",
            }))
          } else {
            // Handle case where response.data is not an array
            console.error("Response data is not an array.")
          }
        })
        .catch(error => console.log(error))
    }
  }
  //Toggle
  const toggleModal = () => {
    setSocialModal(!socialModal)
  }

  if (loading) {
    return (
      <div className="spinner-container">
        <img src={logo} alt="Loading" className="spinner-logo" />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="9">
              <div>
                <h5 className="mt-2">View Bank Transfer</h5>
              </div>
            </Col>
            <Col xl="3">
              <div className="float-end">
                <Button
                  className="btn btn-sm "
                  color="primary"
                  onClick={EditPayment}
                >
                  {" "}
                  {edit ? "Edit" : "In Edit Mode"}
                </Button>
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row className="mb-5">
                  <Col xl={6}>
                    <div className="col-sm-auto">
                      <label className="" htmlFor="autoSizingSelect">
                        Project
                      </label>
                      <InputGroup>
                        <select
                          defaultValue="0"
                          className="form-select"
                          onChange={handleState}
                          name="project_name"
                          value={state.project_name}
                          disabled={edit}
                        >
                          <option value={null}>Select project ...</option>
                          {projects.length > 0 ? (
                            projects
                              .filter(e => !!e)
                              .map((e, index) => (
                                <option key={index} value={e.project_name}>
                                  {e.project_name} ({e.project_code})
                                </option>
                              ))
                          ) : (
                            <option value="" disabled>
                              No Data Found
                            </option>
                          )}
                        </select>
                        {!edit && (
                          <div className="input-group-append gap-2">
                            <Button
                              type="button"
                              color="primary"
                              onClick={addProject}
                            >
                              <i className="mdi mdi-plus" />
                            </Button>
                          </div>
                        )}
                      </InputGroup>
                    </div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xl={12}>
                    <table>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>From Bank</th>
                          <th>Ref. No</th>
                          <th>Mode</th>
                          <th>To Bank</th>
                          {/* <th>Project</th> */}
                          <th>Detail</th>
                          <th>Amount</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map(item => (
                          <tr key={item.id}>
                            <td style={{ display: "none" }}>{item.id}</td>
                            <td>{item.date}</td>
                            <td>
                              <select
                                className="form-select"
                                type="text"
                                value={item.from_bank}
                                disabled
                                onChange={e =>
                                  handleEdit(
                                    item.id,
                                    "from_bank",
                                    e.target.value
                                  )
                                }
                              >
                                <option value="">Select...</option>
                                {bank.length > 0 ? (
                                  bank
                                    .filter(e => !!e)
                                    .map((e, index) => (
                                      <option key={index} value={e.bank_name}>
                                        {e.bank_name}
                                      </option>
                                    ))
                                ) : (
                                  <option value="" disabled>
                                    No Data Found
                                  </option>
                                )}
                              </select>
                            </td>
                            <td>
                              <input
                                type="text"
                                value={item.refNo}
                                disabled={edit}
                                onChange={e =>
                                  handleEdit(item.id, "refNo", e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <select
                                className="form-select"
                                type="text"
                                value={item.mode_}
                                disabled={edit}
                                onChange={e =>
                                  handleEdit(item.id, "mode_", e.target.value)
                                }
                              >
                                <option value="">Mode...</option>
                                <option
                                  value="add_mode"
                                  style={{ color: "blue" }}
                                >
                                  + Add new
                                </option>
                                {pay_mode.length > 0 ? (
                                  pay_mode
                                    .filter(e => !!e)
                                    .map((e, index) => (
                                      <option key={index} value={e.mode_name}>
                                        {e.mode_name}
                                      </option>
                                    ))
                                ) : (
                                  <option value="" disabled>
                                    No Data Found
                                  </option>
                                )}
                              </select>
                            </td>
                            <td>
                              <select
                                className="form-select"
                                type="text"
                                value={item.to_bank}
                                disabled
                                onChange={e =>
                                  handleEdit(item.id, "to_bank", e.target.value)
                                }
                              >
                                <option value="">Select...</option>
                                {bank.length > 0 ? (
                                  bank
                                    .filter(e => !!e)
                                    .map((e, index) => (
                                      <option key={index} value={e.bank_name}>
                                        {e.bank_name}
                                      </option>
                                    ))
                                ) : (
                                  <option value="" disabled>
                                    No Data Found
                                  </option>
                                )}
                              </select>
                            </td>
                            <td>
                              <input
                                type="text"
                                value={item.detail_}
                                disabled={edit}
                                onChange={e =>
                                  handleEdit(item.id, "detail_", e.target.value)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={item.amount}
                                disabled={edit}
                                onChange={e =>
                                  handleEdit(item.id, "amount", e.target.value)
                                }
                                placeholder="0.00"
                              />
                            </td>
                            <td>
                              <i
                                className="mdi mdi-delete font-size-18"
                                onClick={
                                  !edit ? () => deleteRow(item.id) : undefined
                                }
                                style={{
                                  color: "#F46A6A",
                                  cursor: edit ? "not-allowed" : "pointer",
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                  <Col xl={6} className="mt-2">
                    <h5 style={{ color: "black", fontSize: "14px" }}>Total</h5>
                  </Col>
                  <Col xl={4} className="mt-2">
                    <div className="float-end">
                      <strong>{formatAmount(totalamount)}</strong>
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                  <Col>
                    <div className="my-3">
                      <Button
                        onClick={handleAddRow}
                        color="primary"
                        className="btn btn-sm"
                      >
                        <i className="fa fa-fw fa-plus" />
                        Add Row
                      </Button>
                    </div>
                  </Col>
                </Row> */}
                <div className="custom-horizontal-line"></div>
                <Row>
                  <Col xl={6}>
                    <Button
                      color="danger"
                      className="btn btn-danger waves-effect waves-light"
                      onClick={close}
                    >
                      Close
                    </Button>
                  </Col>
                  <Col xl={6}>
                    <div className="float-end">
                      {edit && (
                        <Button
                          type="button"
                          color="primary"
                          onClick={handleEmail}
                        >
                          <i className="mdi mdi-email" /> Send Email
                        </Button>
                      )}
                      {!edit && (
                        <Button
                          className="btn btn-primary"
                          color="primary"
                          type="submit"
                        >
                          Save and Close
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Container>
        <div>
          <SocialModal
            isOpen={socialModal}
            toggle={toggleModal}
            EmailMode={EmailMode}
            ModalMailData={ModalMailData}
            handleModalMailData={handleModalMailData}
            SendEmail={SendEmail}
            pdfUrl={pdfUrl}
            setModalMailData={setModalMailData}
          />
        </div>
      </div>
      <Modal
        isOpen={projectModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setProjectModal(!projectModal)
        }}
      >
        <div>
          <Form onSubmit={handleProject}>
            <ModalHeader
              toggle={() => {
                setProjectModal(!projectModal)
              }}
            >
              Project Information
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col className="col-12">
                  <div className="mb-3">
                    <label>Project Name</label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Name"
                      name="project_name"
                      value={formData3.project_name}
                      onChange={onChangeModal}
                    ></Input>
                  </div>
                  <div className="mb-3">
                    <label>Code</label>
                    <Input
                      className="form-control"
                      type="number"
                      placeholder="code"
                      name="project_code"
                      value={formData3.project_code}
                      onChange={onChangeModal}
                      disabled
                    ></Input>
                  </div>
                  <div className="mb-3">
                    <label>Details</label>
                    <Input
                      className="form-control"
                      type="textarea"
                      placeholder="description"
                      name="project_detail"
                      value={formData3.project_detail}
                      onChange={onChangeModal}
                    />
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                  setProjectModal(!projectModal)
                }}
              >
                Close
              </Button>
              <Button type="submit" color="primary">
                Submit
              </Button>
            </ModalFooter>
          </Form>
        </div>
      </Modal>
      <Modal
        isOpen={modeModal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setModeModal(!modeModal)
        }}
      >
        <div>
          <Form>
            <ModalHeader
              toggle={() => {
                setModeModal(!modeModal)
              }}
            >
              Add Mode
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col xl={8}>
                  <div className="mb-3">
                    <label>Payment Mode</label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Payment Mode"
                      name="payment_mode"
                      value={mode}
                      onChange={e => setMode(e.target.value)}
                    ></Input>
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                  setModeModal(!modeModal)
                }}
              >
                Close
              </Button>
              <Button color="primary" onClick={handleMode}>
                Submit
              </Button>
            </ModalFooter>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ViewBankTransfers
