import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import TableContainer from "components/Common/TableContainer"

import {
  Pdate,
  Type,
  Value,
  ValueinUSD,
  Status,
  Coin,
  Tracked,
} from "../Purchaces/PurchaceData"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Collapse,
  Table,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Modal,
  UncontrolledTooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//Date Picker
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"
import classnames from "classnames"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import { use } from "i18next"

const View_Parameters = props => {
  //meta title
  document.title = "View Parameters"
  const [activeTab, setActiveTab] = useState("1")
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  //View Payment Mode
  const [paymentData, setPaymentData] = useState([])
  const viewpaymentmodes = () => {
    const URL = "https://api.phamenterprises.com.pk/SettingModules/modules.php"
    const pm = new FormData()
    pm.append("app_method", "fetch_pay_mode")

    axios
      .post(URL, pm)
      .then(response => {
        if (Array.isArray(response.data)) {
          setPaymentData(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  const paymentColumn = useMemo(() => [
    {
      Header: "No",
      accessor: "id",
      disableFilters: true,
      Cell: ({ row }) => {
        return <>{row.index + 1}</>
      },
    },
    {
      Header: "Mode",
      accessor: "mode_name",
      disableFilters: true,
      Cell: cellProps => {
        return <Type {...cellProps} />
      },
    },
    {
      Header: "Date",
      accessor: "created_date",
      disableFilters: true,
      Cell: cellProps => {
        return <Type {...cellProps} />
      },
    },
    // {
    //   Header: "Action",
    //   accessor: "action",
    //   disableFilters: true,
    //   Cell: cellProps => {
    //     return (
    //       <div className="d-flex gap-3">
    //         <Link
    //           to="#"
    //           className="text-danger"
    //           onClick={() => {
    //             const orderData = cellProps.row.original
    //           }}
    //         >
    //           <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
    //           <UncontrolledTooltip placement="top" target="deletetooltip">
    //             Delete
    //           </UncontrolledTooltip>
    //         </Link>
    //       </div>
    //     )
    //   },
    // },
  ])

  //   //View Nominal Account
  const [nominalAccData, setNominalAccData] = useState([])
  const viewnominalaccount = () => {
    const URL = "https://api.phamenterprises.com.pk/SettingModules/modules.php"
    const nmacc = new FormData()
    nmacc.append("app_method", "fetch_nominal_acc")

    axios
      .post(URL, nmacc)
      .then(response => {
        if (Array.isArray(response.data)) {
          setNominalAccData(response.data)
        } else {
          console.log("error")
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  const nominalAccColumn = useMemo(() => [
    {
      Header: "No",
      accessor: "id",
      disableFilters: true,
      Cell: ({ row }) => {
        return <>{row.index + 1}</>
      },
    },
    {
      Header: "Acc Name",
      accessor: "account_name",
      disableFilters: true,
      Cell: cellProps => {
        return <Type {...cellProps} />
      },
    },
    {
      Header: "Code",
      accessor: "acc_code",
      disableFilters: true,
      Cell: cellProps => {
        return <Type {...cellProps} />
      },
    },
    {
      Header: "Date",
      accessor: "created_date",
      disableFilters: true,
      Cell: cellProps => {
        return <Type {...cellProps} />
      },
    },
    // {
    //   Header: "Action",
    //   accessor: "action",
    //   disableFilters: true,
    //   Cell: cellProps => {
    //     return (
    //       <div className="d-flex gap-3">
    //         <Link
    //           to="#"
    //           className="text-danger"
    //           onClick={() => {
    //             const orderData = cellProps.row.original
    //           }}
    //         >
    //           <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
    //           <UncontrolledTooltip placement="top" target="deletetooltip">
    //             Delete
    //           </UncontrolledTooltip>
    //         </Link>
    //       </div>
    //     )
    //   },
    // },
  ])

  //fetchExpenseDetail
  const [expenseData, setExpenseData] = useState([])
  const fetchExpense = () => {
    const URL = "https://api.phamenterprises.com.pk/SettingModules/modules.php"
    const data = new FormData()
    data.append("app_method", "fetch_expense_det")

    axios
      .post(URL, data)
      .then(response => {
        if (Array.isArray(response.data)) {
          setExpenseData(response.data)
        } else {
          console.log("error in fethc expense detail")
        }
      })
      .catch(error => console.log(error))
  }

  const ExpenseColumn = useMemo(() => [
    {
      Header: "No",
      accessor: "id",
      disableFilters: true,
      Cell: ({ row }) => {
        return <>{row.index + 1}</>
      },
    },
    {
      Header: "Expense Name",
      accessor: "expense_name",
      disableFilters: true,
      Cell: cellProps => {
        return <Type {...cellProps} />
      },
    },

    {
      Header: "Date",
      accessor: "created_date",
      disableFilters: true,
      Cell: cellProps => {
        return <Type {...cellProps} />
      },
    },
    // {
    //   Header: "Action",
    //   accessor: "action",
    //   disableFilters: true,
    //   Cell: cellProps => {
    //     return (
    //       <div className="d-flex gap-3">
    //         <Link
    //           to="#"
    //           className="text-danger"
    //           onClick={() => {
    //             const orderData = cellProps.row.original
    //           }}
    //         >
    //           <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
    //           <UncontrolledTooltip placement="top" target="deletetooltip">
    //             Delete
    //           </UncontrolledTooltip>
    //         </Link>
    //       </div>
    //     )
    //   },
    // },
  ])
  //Category Data
  const [categoriesData, setCategoriesData] = useState([])
  const fetchCategories = () => {
    const URL = "https://api.phamenterprises.com.pk/SettingModules/modules.php"
    const category2 = new FormData()
    category2.append("app_method", "fetch_product_cat")
    axios
      .post(URL, category2)
      .then(response => {
        if (Array.isArray(response.data)) {
          setCategoriesData(response.data)
        } else {
          console.log("error in fecth category")
        }
      })
      .catch(error => console.log(error))
  }

  const CategoriesColumns = useMemo(() => [
    {
      Header: "No",
      accessor: "id",
      disableFilters: true,
      Cell: ({ row }) => {
        return <>{row.index + 1}</>
      },
    },
    {
      Header: "Category Name",
      accessor: "category_name",
      disableFilters: true,
      Cell: cellProps => {
        return <Type {...cellProps} />
      },
    },

    {
      Header: "Date",
      accessor: "created_date",
      disableFilters: true,
      Cell: cellProps => {
        return <Type {...cellProps} />
      },
    },
    // {
    //   Header: "Action",
    //   accessor: "action",
    //   disableFilters: true,
    //   Cell: cellProps => {
    //     return (
    //       <div className="d-flex gap-3">
    //         <Link
    //           to="#"
    //           className="text-danger"
    //           onClick={() => {
    //             const orderData = cellProps.row.original
    //           }}
    //         >
    //           <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
    //           <UncontrolledTooltip placement="top" target="deletetooltip">
    //             Delete
    //           </UncontrolledTooltip>
    //         </Link>
    //       </div>
    //     )
    //   },
    // },
  ])
  //Fetch Unit
  const [UnitData, setUnitData] = useState([])
  const fetchUnits = () => {
    const URL = "https://api.phamenterprises.com.pk/SettingModules/modules.php"
    const unitdata = new FormData()
    unitdata.append("app_method", "fetch_weight_unit")
    axios
      .post(URL, unitdata)
      .then(response => {
        if (Array.isArray(response.data)) {
          setUnitData(response.data)
        } else {
          console.log("error in fetch unit")
        }
      })
      .catch(error => console.log(error))
  }

  const UnitColumns = useMemo(() => [
    {
      Header: "No",
      accessor: "id",
      disableFilters: true,
      Cell: ({ row }) => {
        return <>{row.index + 1}</>
      },
    },
    {
      Header: "Unit Name",
      accessor: "unit_name",
      disableFilters: true,
      Cell: cellProps => {
        return <Type {...cellProps} />
      },
    },

    {
      Header: "Date",
      accessor: "created_date",
      disableFilters: true,
      Cell: cellProps => {
        return <Type {...cellProps} />
      },
    },
    // {
    //   Header: "Action",
    //   accessor: "action",
    //   disableFilters: true,
    //   Cell: cellProps => {
    //     return (
    //       <div className="d-flex gap-3">
    //         <Link
    //           to="#"
    //           className="text-danger"
    //           onClick={() => {
    //             const orderData = cellProps.row.original
    //           }}
    //         >
    //           <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
    //           <UncontrolledTooltip placement="top" target="deletetooltip">
    //             Delete
    //           </UncontrolledTooltip>
    //         </Link>
    //       </div>
    //     )
    //   },
    // },
  ])

  //All UseEffect
  useEffect(() => {
    viewpaymentmodes()
    viewnominalaccount()
    fetchExpense()
    fetchCategories()
    fetchUnits()
  }, [])

  //Hide View Mode

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="8">
              <div>
                <h5 className="mt-2">View Parameters</h5>
              </div>
            </Col>
          </Row>

          <Row className="mt-3 mb-5">
            <Col lg="12">
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        Payment Modes
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        Nominal Acc
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggleTab("3")
                        }}
                      >
                        Expense's
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "4",
                        })}
                        onClick={() => {
                          toggleTab("4")
                        }}
                      >
                        Product Categories
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "5",
                        })}
                        onClick={() => {
                          toggleTab("5")
                        }}
                      >
                        Weight Unit
                      </NavLink>
                    </NavItem>
                  </ul>

                  <TabContent activeTab={activeTab} className="p-3">
                    <TabPane tabId="1" id="payments">
                      <div>
                        <TableContainer
                          columns={paymentColumn}
                          data={paymentData}
                          isGlobalFilter={true}
                          // isAddOptions={true}
                          // handleOrderClicks={handleOrderClicks}
                          customPageSize={10}
                        />
                      </div>
                    </TabPane>
                    <TabPane tabId="2" id="nominal_acc">
                      <div>
                        <TableContainer
                          columns={nominalAccColumn}
                          data={nominalAccData}
                          isGlobalFilter={true}
                          // isAddOptions={true}
                          // handleOrderClicks={handleOrderClicks}
                          customPageSize={10}
                        />
                      </div>
                    </TabPane>
                    <TabPane tabId="3" id="expenses">
                      <div>
                        <TableContainer
                          columns={ExpenseColumn}
                          data={expenseData}
                          isGlobalFilter={true}
                          // isAddOptions={true}
                          // handleOrderClicks={handleOrderClicks}
                          customPageSize={10}
                        />
                      </div>
                    </TabPane>
                    <TabPane tabId="4" id="prd_categories">
                      <div>
                        <TableContainer
                          columns={CategoriesColumns}
                          data={categoriesData}
                          isGlobalFilter={true}
                          // isAddOptions={true}
                          // handleOrderClicks={handleOrderClicks}
                          customPageSize={10}
                        />
                      </div>
                    </TabPane>
                    <TabPane tabId="5" id="weight_units">
                      <div>
                        <TableContainer
                          columns={UnitColumns}
                          data={UnitData}
                          isGlobalFilter={true}
                          // isAddOptions={true}
                          // handleOrderClicks={handleOrderClicks}
                          customPageSize={10}
                        />
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default View_Parameters
